import { Text } from '@holdbar-com/pixel';
import { renderDateTime } from '@holdbar-com/utils-date';
import { Skeleton, Stack, styled } from '@mui/material';

import { useGetBookingsForEvent } from '../../../../Hooks/data/useBookings';
import { useExperience } from '../../../../Hooks/useExperience';
import { languagesOptionValues } from '../../../../Utils/config';
import { TEventWithTitle } from '../../../../Utils/eventHelpers';
import { EventStatus } from '../../shared/EventStatus';
import { GuestCountText } from '../../shared/GuestCountText';
import { StrikeOutStack } from '../../shared/StrikeOutStack';
import { useEventPopover } from '../event-popover/event-popover-context';
import { TicketTypes } from './TicketTypes';

export const ListItem = ({ event }: { event: TEventWithTitle }) => {
  const {
    bookingsForEvent: { data: bookingsForEvent, isLoading: isBookingsLoading },
  } = useGetBookingsForEvent(event.id);
  const {
    experience: { data: experience, isLoading: isExperienceLoading },
  } = useExperience(event.experienceId);

  const { handleSelectEvent } = useEventPopover();

  const isLoading = isBookingsLoading || isExperienceLoading;

  const strikeOut = event.status === 'cancelled' || event.status === 'inactive';
  const PossiblyStrikeOutStack = strikeOut ? StrikeOutStack : Stack;

  return (
    <StyledEventItem
      sx={{
        backgroundColor: event.colors,
      }}
      onClick={(e) => handleSelectEvent(event, e)}
    >
      <EventStatus event={event} />
      <PossiblyStrikeOutStack direction="row" gap={1} alignItems="center">
        <Text variant="medium" fontSize="xsmall">
          {renderDateTime(event.startDateTime, event.endDateTime)}
        </Text>
        <Text variant="medium" fontSize="xsmall">
          {event.title}
        </Text>
        {!!event.languages?.length && (
          <Stack direction="row" gap={0.5}>
            {event.languages.map((language, index) => (
              <img
                src={languagesOptionValues[language].icon}
                key={index}
                alt={language}
                width={16}
                height={16}
              />
            ))}
          </Stack>
        )}
        {!isLoading ? (
          <>
            <GuestCountText
              {...event.slots}
              eventVisibility={event.visibility}
              fontSize="xsmall"
            />
            <TicketTypes
              bookings={bookingsForEvent}
              experience={experience}
              fontSize="xsmall"
            />
          </>
        ) : (
          <Skeleton height={19.2} width={200} />
        )}
      </PossiblyStrikeOutStack>
    </StyledEventItem>
  );
};

const StyledEventItem = styled(Stack)({
  flexDirection: 'row',
  borderRadius: 8,
  padding: 8,
  gap: 8,
  alignItems: 'center',
  overflow: 'hidden',
  '& *:not(:last-child)': {
    flexShrink: 0,
  },
  '& :last-child': {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  '&:hover': {
    cursor: 'pointer',
  },
});
