import styled, { CSSObject } from '@emotion/styled';
import { lightTheme, Text } from '@holdbar-com/pixel';
import React from 'react';

export type BadgeProps = {
  children: React.ReactNode;
  size?: 'small' | 'medium';
  appearance?: 'default' | 'success' | 'error' | 'warning';
};

type RequiredBadgeProps = Required<BadgeProps>;

const colorVariants: Record<RequiredBadgeProps['appearance'], CSSObject> = {
  success: {
    color: lightTheme.palette.success.s400,
    backgroundColor: lightTheme.palette.success.s100,
  },
  error: {
    color: lightTheme.palette.error.e500,
    backgroundColor: lightTheme.palette.error.e100,
  },
  default: {
    color: lightTheme.palette.neutral.n400,
    backgroundColor: lightTheme.palette.neutral.n50,
  },
  warning: {
    color: lightTheme.palette.warning.w500,
    backgroundColor: lightTheme.palette.warning.w100,
  },
};

const sizeVariants: Record<RequiredBadgeProps['size'], CSSObject> = {
  medium: {
    height: 32,
    borderRadius: 8,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 1,
  },
  small: {
    height: 24,
    borderRadius: 4,
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 1,
  },
};

const StyledBadge = styled(Text, {
  shouldForwardProp: (prop) => prop !== 'appearance' && prop !== 'size',
})<Pick<RequiredBadgeProps, 'appearance' | 'size'>>(
  {
    display: 'inline-flex',
    gap: 6,
    alignItems: 'center',
    lineHeight: '100%',
  },
  (props) => ({
    ...colorVariants[props.appearance],
    ...sizeVariants[props.size],
  })
);

const BadgePart = styled('span')({
  display: 'inline-flex',
});

export function Badge({
  children,
  appearance = 'default',
  size = 'medium',
}: BadgeProps) {
  return (
    <StyledBadge
      appearance={appearance}
      size={size}
      variant="medium"
      fontSize={size === 'medium' ? 'medium' : 'small'}
    >
      {React.Children.map(children, (child) =>
        typeof child === 'string' && child.trim().length === 0 ? null : (
          <BadgePart>{child}</BadgePart>
        )
      )}
    </StyledBadge>
  );
}
