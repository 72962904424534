import { Experience } from '@holdbar-com/utils-types';
import { differenceInCalendarDays } from 'date-fns';

import { getLocalized } from '../../../../Hooks/useBookings';
import { TEvent } from '../../../../Utils/eventHelpers';
import { getFormattedDate } from '../../../helpers';

export const eventProperties = (experience: Experience, events: TEvent[]) => {
  const {
    id: experience_id,
    headline,
    languages: language_list,
    visibility,
  } = experience;

  const {
    number_of_upcoming_events,
    number_of_active_events,
    event_dates_array,
    event_capacity,
    number_of_guests,
    earliest_event_date,
    guide_set,
  } = events.reduce(
    (acc, event) => {
      if (event.states.isUpcoming) {
        acc.number_of_upcoming_events++;
      }

      if (event.status === 'active') {
        acc.number_of_active_events++;
      }

      acc.event_dates_array.push(
        getFormattedDate(new Date(event.startDateTime))
      );

      acc.event_capacity += event.slots.total;

      acc.number_of_guests += event.slots.booked ?? 0;

      const eventDate = new Date(event.startDateTime);
      if (!acc.earliest_event_date || eventDate < acc.earliest_event_date) {
        acc.earliest_event_date = eventDate;
      }

      event.assignedGuides.forEach((guide) => acc.guide_set.add(guide));

      return acc;
    },
    {
      number_of_upcoming_events: 0,
      number_of_active_events: 0,
      event_dates_array: [] as string[],
      event_capacity: 0,
      number_of_guests: 0,
      earliest_event_date: null as Date | null,
      guide_set: new Set<string>(),
    }
  );

  const days_until_first_event_starts = earliest_event_date
    ? differenceInCalendarDays(earliest_event_date, new Date())
    : undefined;

  return {
    experience_name: getLocalized(headline, 'en') ?? '',
    experience_id,
    number_of_upcoming_events,
    number_of_active_events,
    event_dates_array,
    language_list,
    event_capacity,
    number_of_guests,
    is_private: visibility === 'private',
    days_until_first_event_starts,
    number_of_guides: guide_set.size,
  };
};
