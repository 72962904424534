import { ArrowBackRounded } from '@mui/icons-material';
import {
  Box,
  BoxProps,
  Card,
  IconButton,
  styled,
  Typography,
} from '@mui/material';
import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

import { LanguageSelect } from '../../Components/LanguageSelect/LanguageSelect';
import { Logo } from '../../Components/Logo/Logo';
import { Page } from '../../Components/Page/Page';
import { useAuth } from '../../Hooks/useAuth';
import { DEFAULT_PAGE } from '../../Utils/constants';

const Styled = styled(Page)(({ theme }) => ({
  minHeight: '100vh',
  margin: '0 auto',
  background: 'rgba(33, 33, 33, 1)',
  display: 'flex',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',

  // mobile breakpoint  600px to 0px
  [theme.breakpoints.down('sm')]: {
    background: 'rgb(33, 33, 33)',
    padding: '16px',
  },

  [theme.breakpoints.down('md')]: {
    margin: '0',
    justifyContent: 'flex-start',
  },
}));

const AuthCard = styled(Card)(({ theme }) => ({
  flexGrow: '0',
  maxWidth: '480px',
  width: '100%',
  padding: '56px',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '16px',
  background: 'white',
  boxShadow: '0 0 16px 0 rgba(0,0,0,0.18)',
  [theme.breakpoints.down('sm')]: {
    background: '#F8F8F9',
    padding: '32px 32px 8px 32px',
  },
}));

const StyledFooter = styled((props: BoxProps) => (
  <Box component={'footer'} {...props} />
))(({ theme }) => ({
  padding: 24,
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  [theme.breakpoints.down('sm')]: {
    marginTop: '40px',
  },
}));

const StyledCard = styled(Card)({
  display: 'flex',
  alignItems: 'center',
  maxWidth: 1400,
  borderRadius: 0,
  boxShadow: 'none',
  width: '100%',
  height: '100%',
  maxHeight: 800,
  padding: 0,
  ['& .auth-layout-header']: {
    width: '100%',
    maxWidth: 440,
    position: 'relative',
    height: '100%',
    display: 'flex',
    padding: '80px 48px 24px 48px',
    flexDirection: 'column',
    justifyContent: 'center',
    background: '#F8F8F9',
    ['& .logo']: {
      marginBottom: 16,
    },
    ['@media screen and (max-width: 699px)']: {
      maxWidth: '100%',
    },
  },
  ['& .auth-layout-cover']: {
    flexGrow: 1,
    objectSize: 'cover',
    height: '100%',
    borderLeft: '1px solid rgba(200,200,200,0.25)',
    ['@media screen and (max-width: 699px)']: {
      display: 'none',
    },
  },
  ['@media screen and (max-width: 699px)']: {
    maxHeight: '100%',
  },
});

export const AuthLayout = ({
  children,
  title,
  description,
  onBack,
  hideFooter = false,
  imgSrc = '',
}: {
  children?: ReactNode;
  hideFooter?: boolean;
  onBack?: () => void;
  title: string;
  description?: string;
  imgSrc?: string;
}) => {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    return <Navigate to={DEFAULT_PAGE} replace />;
  }

  return (
    <Styled>
      <AuthCard className={'auth-layout-header'}>
        {!onBack && <Logo mt={{ xs: 1, md: 0 }} mr={'auto'} height={32} />}

        {onBack && (
          <Box>
            <IconButton onClick={onBack}>
              <ArrowBackRounded />
            </IconButton>
          </Box>
        )}
        <Typography
          mb={description ? 1 : 2}
          variant={'h3'}
          sx={{
            mt: {
              xs: onBack ? 3 : '56px',
              md: onBack ? 3 : 5,
            },
          }}
        >
          {title}
        </Typography>
        {description && (
          <Typography
            mb={2}
            dangerouslySetInnerHTML={{ __html: description }}
          ></Typography>
        )}
        {children}
        {!hideFooter && (
          <StyledFooter>
            <LanguageSelect />
          </StyledFooter>
        )}
      </AuthCard>
    </Styled>
  );
};
