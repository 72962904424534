import { ampli } from '../../Ampli';
import { AccumulatedMetricsItem } from '../../Sections/DashboardSections/useMetrics';
import { eventProperties, transformToUserProperties } from './eventProperties';

export const trackOverviewPageViewed = (
  accumulatedMetricsItems: AccumulatedMetricsItem[],
  currency: string,
  fromDate: Date,
  toDate: Date,
  userId?: string
) => {
  const properties = eventProperties(
    accumulatedMetricsItems,
    currency,
    fromDate,
    toDate
  );
  const userProperties = transformToUserProperties(properties);

  if (userId) ampli.identify(userId, userProperties);
  ampli.overviewPageViewed(properties);

  return properties;
};
