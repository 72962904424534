import { differenceInCalendarDays } from 'date-fns';

import { BookingsPageViewedProperties } from '../../../Ampli';
import { TBooking } from '../../../Hooks/useBookings';

export const eventProperties = (bookings: TBooking[]) => {
  const number_of_bookings = bookings.length;

  const number_of_paid_bookings = bookings.filter(
    (booking) => booking.status === 'active'
  ).length;

  const number_unpaid_bookings = bookings.filter(
    (booking) => booking.status === 'unpaid'
  ).length;

  const number_cancelled_bookings = bookings.filter(
    (booking) => booking.status === 'cancelled'
  ).length;

  const number_of_experiences_booked = new Set(
    bookings.map((booking) => booking.experienceId)
  ).size;

  const days_until_upcoming_booking =
    bookings.length > 0
      ? Math.min(
          ...bookings
            .map(({ startDateTime }) =>
              differenceInCalendarDays(new Date(startDateTime), new Date())
            )
            .filter((days) => days >= 0)
        )
      : undefined;

  return {
    number_of_bookings,
    number_of_paid_bookings,
    number_unpaid_bookings,
    number_cancelled_bookings,
    number_of_experiences_booked,
    days_until_upcoming_booking,
  };
};

export const transformToUserProperties = (
  properties: BookingsPageViewedProperties
) => ({
  '# Bookings': properties.number_of_bookings,
  '# Paid Bookings': properties.number_of_paid_bookings,
  '# Unpaid Bookings': properties.number_unpaid_bookings,
  '# Cancelled Bookings': properties.number_cancelled_bookings,
  '# Experiences Booked': properties.number_of_experiences_booked,
  '# Days To Upcoming Booking': properties.days_until_upcoming_booking,
});
