import { useGetLocations } from '../../../Hooks/data/useLocations';
import { useProfile } from '../../../Hooks/useProfile';
import { useTranslate } from '../../../Hooks/useTranslate';
import { useCalendar } from '../context';
import { Filter } from './view/Filter';

export const LocationsFilter = () => {
  const { company } = useProfile();
  const {
    locations: { data, isLoading },
  } = useGetLocations(company.data?.id ?? '');

  const { locationFilters, setLocationFilters } = useCalendar();
  const { t } = useTranslate('utils.generic');

  return (
    <Filter
      inputId="locations-filter"
      label={t('locations')}
      options={
        data?.map((location) => ({
          id: location.locationId ?? location.locationName,
          name: location.locationName,
        })) ?? []
      }
      checkedIds={locationFilters}
      onChange={setLocationFilters}
      isLoading={isLoading}
      sortOptions={(a, b) => a.name.localeCompare(b.name)}
    />
  );
};
