import { useFlags } from 'launchdarkly-react-client-sdk';

import FeatureItem from '../../../../Components/FeatureItem/FeatureItem';
import { useProfile } from '../../../../Hooks/useProfile';
import FeatureInput from '../inputs/FeatureInput';

const FEATURE_ID = 'requests';

export const EventRequestsFeature = () => {
  const flags = useFlags();

  const {
    company: { data: company },
    companyProfileUrl,
  } = useProfile();

  if (!flags.featureEventRequests) {
    return null;
  }

  return (
    <FeatureItem feature={FEATURE_ID} link={companyProfileUrl() + '/request'}>
      <FeatureInput
        name="emailAddress"
        defaultValue={
          company?.features?.requests?.params?.emailAddress ??
          company?.companyEmail ??
          ''
        }
        type="input"
        required={true}
      />
    </FeatureItem>
  );
};
