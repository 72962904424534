import { useMemo } from 'react';

import { ILocationView } from '../../../../types/api/location';

export enum LocationFormVariant {
  edit = 'edit',
  create = 'create',
}

export const createLocation = (values?: ILocationView) => ({
  locationId: values?.locationId ?? undefined,
  locationName: values?.locationName ?? '',
  address: {
    line1: values?.address?.line1 ?? '',
    line2: values?.address?.line2 ?? '',
    city: values?.address?.city ?? '',
    state: values?.address?.state ?? '',
    postalCode: values?.address?.postalCode ?? '',
    country: values?.address?.country ?? '',
  },
  revision: values?.revision ?? undefined,
});

export const useGetLocationInitialFormValues = (values?: ILocationView) =>
  useMemo(() => createLocation(values), [values]);
