import { createContext, useContext, useMemo, useState } from 'react';

import { trackEventFlowGoToPreviousStep } from '../../../../tracking/events/flow/trackEventFlowGoToPreviousStep';
import {
  EventStepType,
  EventStepTypes,
  EventType,
  InitializeEventType,
  UpdateEventFieldType,
} from './types';
import { createEvent, useEventInitial } from './use_event_initial_values';

interface StateProps<T extends EventType = EventType> {
  currentEvent: Readonly<T>;
  initializeEvent: InitializeEventType<T>;
  updateEventField: UpdateEventFieldType;
  updateMultipleEventFields: (fields: Partial<T>) => void;
  updateEventStep: (step: EventStepType) => void;
  updateEventStepLatest: () => void;
}

export const EventUpsertContext = createContext<StateProps | null>(null);

export const useEventUpsert = () => {
  const context = useContext(EventUpsertContext);
  if (!context) {
    throw new Error(
      'useEventUpsert must be used within a EventUpsertContextProvider'
    );
  }
  return context;
};

interface EventUpsertContextProviderProps {
  children: React.ReactNode;
}

export const EventUpsertContextProvider = ({
  children,
}: EventUpsertContextProviderProps) => {
  const [currentEvent, setCurrentEvent] =
    useState<EventType>(useEventInitial());

  const api = useMemo(
    () => ({
      currentEvent,
      initializeEvent: (event?: EventType) => {
        if (event) {
          setCurrentEvent({ ...event });
          return;
        }
        setCurrentEvent(createEvent());
      },
      updateEventField: (field: any, value: any) => {
        if (Array.isArray(field)) {
          // Update nested field
          setCurrentEvent((prev) => updateNestedObject(prev, field, value));
        } else {
          // Update top-level field
          setCurrentEvent((prev) => ({ ...prev, [field]: value }));
        }
      },
      updateMultipleEventFields: (fields: Partial<EventType>) => {
        setCurrentEvent((prev) => ({ ...prev, ...fields }));
      },
      updateEventStep: (step: EventStepType) => {
        setCurrentEvent((prev) => ({ ...prev, step }));
      },
      updateEventStepLatest: () => {
        const skipBooking =
          currentEvent.step === 'time' &&
          (currentEvent.visibility === 'public' ||
            currentEvent.variant === 'edit');

        setCurrentEvent((prev) => ({
          ...prev,
          step: EventStepTypes[
            EventStepTypes.indexOf(currentEvent.step) - (skipBooking ? 2 : 1)
          ],
        }));

        trackEventFlowGoToPreviousStep(currentEvent);
      },
    }),
    [currentEvent]
  );

  return (
    <EventUpsertContext.Provider value={api}>
      {children}
    </EventUpsertContext.Provider>
  );
};

const updateNestedObject = (obj: any, path: string[], value: any): any => {
  if (path.length === 0) return value;

  const [firstKey, ...remainingKeys] = path;
  if (remainingKeys.length === 0) {
    return { ...obj, [firstKey]: value };
  }

  if (typeof obj[firstKey] === 'undefined' || obj[firstKey] === null) {
    obj[firstKey] = {};
  }

  return {
    ...obj,
    [firstKey]: updateNestedObject(obj[firstKey], remainingKeys, value),
  };
};
