export const HoldbarLogoBlack = () => (
  <svg
    width="124"
    height="22"
    viewBox="0 0 124 22"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.3203 0.746696V19.7048C20.3203 20.1211 19.9819 20.4515 19.5706 20.4515H15.6897C15.2717 20.4515 14.94 20.1145 14.94 19.7048V15.5881C14.94 15.1784 14.6017 14.8414 14.1904 14.8414L6.14982 14.815C5.73187 14.815 5.39353 15.152 5.39353 15.5617V19.7048C5.39353 20.1211 5.05519 20.4515 4.64388 20.4515H0.749654C0.331705 20.4515 0 20.1145 0 19.7048V0.746696C0 0.337004 0.33834 0 0.749654 0H4.63724C5.05519 0 5.3869 0.337004 5.3869 0.746696V8.68943C5.3869 9.10573 5.72524 9.43612 6.13655 9.43612H14.1771C14.595 9.43612 14.9267 9.09912 14.9267 8.68943V0.746696C14.9267 0.330396 15.2651 0 15.6764 0H19.5574C19.9753 0 20.307 0.337004 20.307 0.746696H20.3203Z"
      fill="currentColor"
    />
    <path
      d="M66.8659 0.746696V19.7048C66.8659 20.1211 66.5276 20.4515 66.1163 20.4515H63.1309C62.786 20.4515 62.4874 20.2203 62.4012 19.8899L62.3547 19.7247C62.2154 19.2026 61.5785 18.9846 61.1606 19.3348C59.8338 20.4317 58.0824 21 55.9263 21C51.9989 21 48.8809 18.5088 48.8809 12.8524C48.8809 6.64758 52.3571 3.64097 57.1271 3.64097C58.3743 3.64097 59.5087 3.85242 60.4906 4.19604C60.9748 4.36784 61.4724 3.99119 61.4724 3.48238V0.746696C61.4724 0.330396 61.8107 0 62.2221 0H66.103C66.521 0 66.8527 0.337004 66.8527 0.746696H66.8659ZM61.479 11.6564V9.56828C61.479 9.24449 61.2734 8.96696 60.9748 8.85463C60.2053 8.57709 59.303 8.45154 58.3013 8.45154C55.9329 8.45154 54.2412 9.93833 54.2412 12.6872C54.2412 14.9802 55.5083 16.2093 57.4521 16.2093C59.7542 16.2093 61.479 14.6233 61.479 11.6498V11.6564Z"
      fill="currentColor"
    />
    <path
      d="M87.4304 11.4648C87.4304 18.2511 83.0452 20.9075 77.0414 20.9075C74.7261 20.9075 72.0061 20.5903 70.0424 20.174C69.6908 20.1013 69.4453 19.7974 69.4453 19.4405V0.746696C69.4453 0.330396 69.7837 0 70.195 0H74.0759C74.4939 0 74.8256 0.337004 74.8256 0.746696V4.07048C74.8256 4.65859 75.4824 5.02863 75.9733 4.70485C77.1674 3.92511 78.6137 3.51542 80.2788 3.51542C84.173 3.51542 87.4171 5.84141 87.4171 11.4714L87.4304 11.4648ZM82.0435 11.8546C82.0435 9.56167 80.71 8.29956 78.6999 8.29956C76.6898 8.29956 74.8057 9.81938 74.8057 12.6938V15.4758C74.8057 15.8722 75.1175 16.196 75.5089 16.2225C76.0529 16.2621 76.5637 16.2753 77.0414 16.2489C79.9935 16.2489 82.0369 14.8612 82.0369 11.8546H82.0435Z"
      fill="currentColor"
    />
    <path
      d="M106.43 17.3393V19.8438C106.43 20.1808 106.205 20.4913 105.873 20.5706C104.964 20.7953 104.062 20.9076 103.087 20.9076C101.76 20.9076 100.805 20.4913 100.161 19.857C99.9025 19.5993 99.5111 19.5464 99.1993 19.738C98.0848 20.4253 96.3997 20.9407 94.2237 20.9407C90.9465 20.9407 88.5449 19.2292 88.5449 15.7336C88.5449 11.9803 90.9465 10.3349 94.7478 10.3349C95.9818 10.3349 97.0034 10.4869 97.8128 10.7512C98.4364 10.9495 98.987 10.3482 98.7482 9.74684C98.1976 8.379 96.9901 8.07503 95.3316 8.07503C93.5736 8.07503 92.1671 8.42525 90.9597 8.89442C90.4688 9.08605 89.9447 8.72261 89.9447 8.19397V5.22041C89.9447 4.90323 90.1371 4.62569 90.4356 4.51336C91.8354 4.00455 93.9716 3.54199 96.8575 3.54199C101.661 3.54199 104.454 5.41864 104.454 11.4979V15.575C104.454 16.2887 104.779 16.6125 105.429 16.6125C105.502 16.6125 105.568 16.6125 105.641 16.6059C106.072 16.5728 106.437 16.9164 106.437 17.346L106.43 17.3393ZM99.06 15.4098C99.06 14.2138 97.8592 13.5993 96.2007 13.5993C94.5422 13.5993 93.5072 14.2138 93.5072 15.3768C93.5072 16.5398 94.5488 17.1874 96.2007 17.1874C97.8526 17.1874 99.06 16.6059 99.06 15.4098Z"
      fill="currentColor"
    />
    <path
      d="M119.122 4.32143V8.69588C119.122 9.09897 118.797 9.42936 118.392 9.44258C114.531 9.5417 113.383 10.9822 113.383 13.632V19.7047C113.383 20.121 113.045 20.4514 112.634 20.4514H108.753C108.335 20.4514 108.003 20.1144 108.003 19.7047V4.81042C108.003 4.39412 108.341 4.06372 108.753 4.06372H112.634C113.051 4.06372 113.383 4.40073 113.383 4.81042C113.383 5.48443 114.199 5.80822 114.677 5.32584C115.692 4.295 116.986 3.70029 118.312 3.56813C118.75 3.52187 119.128 3.8787 119.128 4.32143H119.122Z"
      fill="currentColor"
    />
    <path
      d="M46.5242 20.4515H42.6432C42.2253 20.4515 41.8936 20.1145 41.8936 19.7048V0.746696C41.8936 0.330396 42.2319 0 42.6432 0H46.5242C46.9421 0 47.2738 0.337004 47.2738 0.746696V19.7048C47.2738 20.1211 46.9355 20.4515 46.5242 20.4515Z"
      fill="currentColor"
    />
    <path
      d="M21.9854 12.337C21.9854 6.5154 26.2378 3.50879 31.1073 3.50879C35.9767 3.50879 40.2292 6.5154 40.2292 12.337C40.2292 18.1586 36.043 20.9669 31.1073 20.9669C26.1715 20.9669 21.9854 18.152 21.9854 12.337ZM34.8356 12.2379C34.8356 9.65416 33.1174 8.25989 31.1006 8.25989C29.0838 8.25989 27.3324 9.64756 27.3324 12.2379C27.3324 14.8282 29.0507 16.1828 31.1006 16.1828C33.1506 16.1828 34.8356 14.8546 34.8356 12.2379Z"
      fill="currentColor"
    />
  </svg>
);
