import { useProfile } from '../useProfile';
import { useSelectedLanguages } from './use-selected-languages';

export const useStorefrontLanguages = () => {
  const {
    company: { data: company },
  } = useProfile();

  return useSelectedLanguages(company?.languages);
};
