import { Box, BoxProps } from '@mui/material';
import React from 'react';

// This component provides the padding-aspect-ratio hack, but unfortunately contains a lot of `any` types, which means typing gets destroyed when using these
interface Props extends BoxProps<any, any> {
  aspectRatio: number;
}

export const AspectRatioBox = React.forwardRef<any, Props>(
  ({ aspectRatio, ...boxProps }, ref) => {
    return (
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          paddingBottom: `${100 / aspectRatio}%`,
        }}
      >
        <Box
          {...boxProps}
          sx={{
            ...boxProps.sx,
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          }}
          ref={ref}
        ></Box>
      </Box>
    );
  }
);
