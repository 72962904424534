import { useReducer } from 'react';

export type State = {
  isSaved: boolean;
  isError: boolean;
  isSaving: boolean;
};

type Action =
  | { type: 'saveStart' }
  | { type: 'saveSuccess' }
  | { type: 'saveError' }
  | { type: 'reset' };

const initialState: State = { isSaving: false, isSaved: false, isError: false };

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'saveStart':
      return { ...state, isSaving: true };
    case 'saveSuccess':
      return { ...initialState, isSaved: true };
    case 'saveError':
      return { ...initialState, isError: true };
    case 'reset':
      return initialState;
    default:
      throw new Error('Unhandled action type');
  }
}

// Custom hook for managing save state
export const useEditParticipantsReducer = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return { state, dispatch };
};
