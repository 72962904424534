import styled from '@emotion/styled';
import { Button, lightTheme, Text } from '@holdbar-com/pixel';
import { DeleteRounded } from '@mui/icons-material';
import { Divider, Popover, Stack } from '@mui/material';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { useExperienceTags } from '../../../../Hooks/useExperienceTags';
import { useTranslate } from '../../../../Hooks/useTranslate';
import { ExperienceTagDialogContent } from './dialog-content';
import { ExperienceTagDialogActions } from './form';
import { ExperienceTagViewProps, renderTagName } from './shared';

export const ExperienceTagsListView = ({
  setView,
  setSelectedId,
}: ExperienceTagViewProps) => {
  const {
    tags: { data: tags, isLoading },
    deleteTag,
  } = useExperienceTags();
  const { i18n } = useTranslation();
  const { t } = useTranslate('experience.tags.dialog.actions');

  const [tagToDelete, setTagToDelete] = useState<string | undefined>();
  const [isDeleting, setIsDeleting] = useState(false);
  const deleteRef = useRef<Record<string, HTMLButtonElement>>({});

  const handleDelete = (id: string) => {
    setIsDeleting(true);
    setTagToDelete(undefined);
    deleteTag
      .mutateAsync(id)
      .then(() => {
        toast.success(t('tagDeleted', 'experience.tags.dialog.toast'));
      })
      .catch((error) => {
        toast.error(t('generic', 'utils.errors'));
        console.error(error);
      })
      .finally(() => setIsDeleting(false));
  };

  if (isLoading) return null;

  return (
    <ExperienceTagDialogContent>
      {!tags || !tags.length ? (
        <Text>{t('noTagsAdded', 'experience.tags.section')}</Text>
      ) : (
        <>
          <Stack gap={1} divider={<Divider />}>
            {tags.map((tag) => (
              <Stack
                key={tag.id}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                gap={2}
              >
                <Text>{renderTagName(tag.name, i18n.language)}</Text>
                <Stack direction="row" gap={1}>
                  <Button
                    variant="secondary"
                    size="medium"
                    onClick={() => setSelectedId(tag.id)}
                  >
                    {t('editTag')}
                  </Button>
                  <StyledButton
                    ref={(ref) => ref && (deleteRef.current[tag.id] = ref)}
                    variant="secondary"
                    size="medium"
                    onClick={() => {
                      setTagToDelete(tag.id);
                    }}
                  >
                    <DeleteRounded />
                  </StyledButton>
                </Stack>
              </Stack>
            ))}
          </Stack>
          {tagToDelete && (
            <Popover
              open={!!tagToDelete}
              anchorEl={deleteRef.current?.[tagToDelete]}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              onClose={() => setTagToDelete(undefined)}
            >
              <Stack gap={2} padding={2} maxWidth={250}>
                <Stack>
                  <Text variant="medium">{t('delete.title')}</Text>
                  <Text
                    color={lightTheme.palette.neutral.n400}
                    fontSize="xsmall"
                  >
                    {t('delete.description')}
                  </Text>
                </Stack>
                <Stack gap={1} direction="row">
                  <Button
                    variant="primary"
                    size="medium"
                    onClick={() => handleDelete(tagToDelete)}
                    style={{
                      background: lightTheme.palette.error.e300,
                    }}
                    disabled={isDeleting}
                  >
                    {t('delete', 'utils.generic')}
                  </Button>
                  <Button
                    variant="secondary"
                    size="medium"
                    onClick={() => setTagToDelete(undefined)}
                    disabled={isDeleting}
                  >
                    {t('cancel')}
                  </Button>
                </Stack>
              </Stack>
            </Popover>
          )}
        </>
      )}

      <ExperienceTagDialogActions
        actions={[
          {
            id: 'create',
            label: t('createTag'),
            variant: 'primary',
            type: 'button',
            onClick: () => setView('create'),
          },
        ]}
      />
    </ExperienceTagDialogContent>
  );
};

const StyledButton = styled(Button)`
  aspect-ratio: 1;
  span {
    display: inline-flex;
    align-items: center;
  }
`;
