import { Stack } from '@mui/material';

import useResponsive from '../../../../Hooks/layout/useResponsive';
import { GiftCardsSearchFormInput } from './GiftCardsSearchFormInput';
import { GiftCardsSearchFormSelect } from './GiftCardsSearchFormSelect';

export const GiftCardsSearchForm = ({
  searchState,
  setSearchState,
}: {
  searchState: {
    query: string;
    status: string;
  };
  setSearchState: (state: { query: string; status: string }) => void;
}) => {
  const { isSm } = useResponsive();

  return (
    <Stack gap={2} maxWidth={isSm ? '100%' : 400}>
      <GiftCardsSearchFormInput
        query={searchState.query}
        setQuery={(query) => setSearchState({ ...searchState, query })}
      />
      <GiftCardsSearchFormSelect
        status={searchState.status}
        setStatus={(status) => setSearchState({ ...searchState, status })}
      />
    </Stack>
  );
};
