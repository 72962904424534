import { Text } from '@holdbar-com/pixel';
import {
  capitalize,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Skeleton,
  styled,
} from '@mui/material';
import { useEffect, useMemo } from 'react';
import { Controller, ControllerProps, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useGetLocations } from '../../Hooks/data/useLocations';
import { useErrors } from '../../Pages/SyiPage/useErrors';
import { ILocationView } from '../../types/api/location';

type LocationsSelectProps = {
  companyId: string;
  control: ControllerProps['control'];
};

export const LocationsSelect = ({
  companyId,
  control,
}: LocationsSelectProps) => {
  const { t } = useTranslation('');
  const { clearError } = useErrors();
  const { setValue, watch } = useFormContext();
  const {
    locations: { data: locations, isLoading },
  } = useGetLocations(companyId);

  // This logic is temporary for backwards compatibility
  const watchedLocations = watch('locationIds');

  const formattedAddresses = useMemo(() => {
    if (!locations || !watchedLocations) {
      return {
        multiple: [],
        selectedOptionKey: 'multiple',
      };
    }

    const selectedAddresses = locations
      .filter((location) => watchedLocations.includes(location.locationId))
      .map(createFormattedAddresses);

    return {
      multiple: selectedAddresses,
      selectedOptionKey: 'multiple',
    };
  }, [locations, watchedLocations]);

  useEffect(() => {
    setValue('addresses', formattedAddresses);
  }, [formattedAddresses, setValue]);

  const locationMap = useMemo(() => {
    return new Map(locations?.map((loc) => [loc.locationId, loc.locationName]));
  }, [locations]);

  if (!locations && isLoading) {
    return <Skeleton variant="rounded" height={56} width="60%" />;
  }

  if (!locations) {
    return null;
  }

  const renderValue = (selected: string[]) => {
    return selected
      .map((locationId) => capitalize(locationMap.get(locationId) || ''))
      .join(', ');
  };

  const label = t('experience.create.practicalities.addresses.locations');

  return (
    <FormControl required sx={{ maxWidth: 480, minWidth: 320 }}>
      <InputLabel id="location-select-label">{label}</InputLabel>
      <Controller
        name="locationIds"
        control={control}
        render={({ field: { onChange, value } }) => {
          const controlledValue = Array.isArray(value) ? value : [];
          return (
            <Select
              labelId="location-select-label"
              multiple
              value={controlledValue}
              input={<OutlinedInput label={label} />}
              onChange={(event) => {
                clearError('locationIds');
                onChange(event.target.value);
              }}
              renderValue={(selected) => renderValue(selected)}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
              }}
            >
              {locations.map((location) => (
                <MenuItem
                  key={location.locationId}
                  value={location.locationId}
                  disableRipple
                >
                  <Checkbox
                    checked={controlledValue.includes(location.locationId)}
                  />
                  <StyledText fontSize="small">
                    {location.locationName}
                  </StyledText>
                </MenuItem>
              ))}
            </Select>
          );
        }}
      />
    </FormControl>
  );
};

const StyledText = styled(Text)`
  &::first-letter {
    text-transform: uppercase;
  }
  text-transform: lowercase;
`;

const createFormattedAddresses = (location: ILocationView) => {
  const parts = [
    location.address.line1,
    location.address.postalCode,
    location.address.city,
  ]
    .filter(Boolean)
    .map((part) => part?.trim());

  return {
    value: parts.length > 0 ? parts.join(', ') : location.locationName,
  };
};
