import { FC } from 'react';

import { useGroupedEvents } from '../hooks/useGroupedEvents';
import { GroupedRow } from './GroupedRow';
import { GroupedRowSkeleton } from './GroupedRowSkeleton';

type Props = {
  dates: Date[];
};

export const GroupedRows: FC<Props> = ({ dates }) => {
  const groups = useGroupedEvents();

  if (!groups)
    return (
      <>
        <GroupedRowSkeleton numberOfColumns={dates.length + 1} />
        <GroupedRowSkeleton numberOfColumns={dates.length + 1} />
        <GroupedRowSkeleton numberOfColumns={dates.length + 1} />
      </>
    );

  return (
    <>
      {Object.values(groups).map(({ events, experience }) => (
        <GroupedRow
          key={experience.id}
          dates={dates}
          events={events}
          experience={experience}
        />
      ))}
    </>
  );
};
