import { toISODateTime } from '@holdbar-com/utils-date';
import { differenceInMinutes, format, set } from 'date-fns';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TimeInput } from '../../../../../../../Components/inputs/time-input';
import { trackEventFlowEndTimeOpened } from '../../../../../../../tracking/events/flow/time/form/trackEventFlowEndTimeOpened';
import { trackEventFlowEndTimeSelected } from '../../../../../../../tracking/events/flow/time/form/trackEventFlowEndTimeSelected';
import { useEventUpsert } from '../../../../domain/event_upsert_context';
import { parseTime } from './event_upsert_time_form';
import { EventUpsertTimeFormTypes } from './use_time_form_validation';

export const EventUpsertTimeFormEndTimeSelect = () => {
  const { t } = useTranslation();
  const { currentEvent, updateEventField } = useEventUpsert();
  const {
    control,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext<
    Pick<EventUpsertTimeFormTypes, 'endDateTime' | 'endDateTimeDisabled'>
  >();

  const selectedStartDateTime = new Date(currentEvent.startDateTime);
  const selectedEndDateTime = new Date(currentEvent.endDateTime);

  const handleSelectChange = (time: string | undefined) => {
    try {
      if (!time) return;

      const [hours, minutes] = parseTime(time);
      const updatedEndDateTime = set(selectedEndDateTime, { hours, minutes });

      const totalDiffInMinutes = differenceInMinutes(
        updatedEndDateTime,
        selectedStartDateTime
      );

      setValue('endDateTime', toISODateTime(updatedEndDateTime), {
        shouldValidate: true,
      });
      updateEventField('endDateTime', toISODateTime(updatedEndDateTime));
      updateEventField('startEndTimeDiff', totalDiffInMinutes);

      trackEventFlowEndTimeSelected(updatedEndDateTime);
    } catch (error) {
      console.error('Error updating end time: ', error);
    }
  };

  const hasBookings = currentEvent.bookings && currentEvent.bookings.length > 0;
  const endDateTimeDisabled = watch('endDateTimeDisabled');

  return (
    <Controller
      name="endDateTime"
      control={control}
      render={({ field }) => (
        <TimeInput
          {...field}
          label={t('eventUpsert.flow.time.form.endTime.label')}
          value={
            field.value ? format(new Date(field.value), 'HH:mm') : undefined
          }
          sx={{ flex: 2 }}
          slotProps={{
            textField: {
              error: !!errors.endDateTime,
              required: true,
              id: 'time-end-time-select',
              InputLabelProps: { shrink: true },
            },
          }}
          minTime={selectedStartDateTime}
          onChange={handleSelectChange}
          onOpen={trackEventFlowEndTimeOpened}
          disabled={hasBookings || endDateTimeDisabled}
        />
      )}
    />
  );
};
