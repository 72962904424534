import {
  Box,
  FormControlLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  Stack,
  StackProps,
  TextField,
  Typography,
} from '@mui/material';
import { formatISO } from 'date-fns';
import { useController, UseFormReturn } from 'react-hook-form';

import { useTranslate } from '../../Hooks/useTranslate';
import { DateInput } from '../inputs/date-input';

interface IFrequencyUntilSelectProps {
  typeFormKey: string;
  valueFormKey: string;
  control?: UseFormReturn['control'];
  onChange?: (key: 'until' | 'untilType', value: string | undefined) => void;
}

export const FrequencyUntilSelect = ({
  typeFormKey,
  valueFormKey,
  control,
  onChange,
  ...props
}: Omit<StackProps, 'onChange'> & IFrequencyUntilSelectProps) => {
  const { t } = useTranslate(
    'events.create.details.recurring.frequency.customOptions'
  );

  const { field: untilType } = useController({
    control,
    name: typeFormKey,
    defaultValue: 'never',
  });
  const { field: until } = useController({
    control,
    name: valueFormKey,
    defaultValue: '',
  });

  const handleChange =
    (key: 'type' | 'value') => (value: string | undefined) => {
      if (key === 'type') {
        untilType.onChange(value);
        onChange?.('untilType', value);
      }
      if (key === 'value') {
        until.onChange(value);
        onChange?.('until', value);
      }
    };

  const toISODateTime = (date: Date) => {
    const _t = formatISO(date).split('+')[0];
    return _t.substring(0, _t.length - 3);
  };

  return (
    <Stack
      direction={'row'}
      spacing={2}
      minHeight={48}
      alignItems={'flex-start'}
      {...props}
    >
      <Typography mt={2} variant={'h5'} sx={{ minWidth: 96 }}>
        {t('ends.title')}
      </Typography>
      <RadioGroup
        value={untilType.value}
        onChange={(evt, value) => handleChange('type')(value)}
      >
        <FormControlLabel
          value={'never'}
          control={<Radio />}
          label={t('ends.options.never')}
        />
        <Box display={'flex'} alignItems={'center'}>
          <FormControlLabel
            value={'date'}
            control={<Radio />}
            label={t('ends.options.until')}
            sx={{ mr: 3 }}
          />
          <DateInput
            value={untilType.value === 'date' ? until.value : null}
            minDate={new Date()}
            onChange={(val: Date | null) =>
              handleChange('value')(val ? toISODateTime(val) : '')
            }
            disabled={untilType.value !== 'date'}
          />
        </Box>
        <Box display={'flex'} alignItems={'center'}>
          <FormControlLabel
            value={'occurrence'}
            control={<Radio />}
            label={t('ends.options.after')}
            sx={{ mr: 3 }}
          />
          <TextField
            value={untilType.value === 'occurrence' ? until.value : null}
            type={'number'}
            margin={'dense'}
            size={'small'}
            disabled={untilType.value !== 'occurrence'}
            sx={{
              width: 164,
              opacity: untilType.value !== 'occurrence' ? 0.5 : 1,
            }}
            onChange={(evt) => handleChange('value')(evt.target.value)}
            inputProps={{
              min: 0,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {t('ends.options.occurrences')}
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </RadioGroup>
    </Stack>
  );
};
