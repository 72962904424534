import { setDefaultOptions } from 'date-fns';
import { da } from 'date-fns/locale';
import * as locales from 'date-fns/locale';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { languageOptions } from '../Utils/config';
import { useLocalStorage } from './useLocalStorage';

const localesMap: Record<string, Locale> = locales;

export const useLocale = () => {
  const [_locale, setLocale] = useState(da);

  const { i18n } = useTranslation();

  const [preferredLanguage] = useLocalStorage(
    'preferredLanguage',
    i18n.language
  );

  useEffect(() => {
    const { locale = 'da' } =
      (languageOptions(i18n.language.split('-')[0]) as {
        locale: string;
      }) ?? {};
    if (locale && localesMap[locale]) {
      setLocale(() => localesMap[locale]);
      setDefaultOptions({ locale: localesMap[locale] });
    }
  }, [i18n.language]);

  useEffect(() => {
    if (preferredLanguage) {
      i18n.changeLanguage(preferredLanguage);
    }
  }, [i18n, preferredLanguage]);

  return {
    _locale,
  };
};
