import { Experience } from '@holdbar-com/utils-types';

import { ampli } from '../../../../Ampli';
import { TEvent } from '../../../../Utils/eventHelpers';
import { eventProperties } from './eventProperties';

export const trackExperienceActivated = (
  events: TEvent[],
  experience?: Experience
) => {
  if (!experience) return;

  const properties = eventProperties(experience, events);

  ampli.experienceActivated(properties);
};
