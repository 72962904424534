import { Stack } from '@mui/material';
import { PropsWithChildren } from 'react';

import useResponsive from '../../../../Hooks/layout/useResponsive';

export const ExperienceTagDialogContent = ({ children }: PropsWithChildren) => {
  const { isSm } = useResponsive();

  return (
    <Stack
      padding={isSm ? 2 : 4}
      paddingLeft={isSm ? 2 : 6}
      paddingTop={0}
      direction="column"
      sx={{ '&> *': { flexGrow: 1 } }}
    >
      {children}
    </Stack>
  );
};
