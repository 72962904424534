import { TextField } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { useTranslate } from '../../../../Hooks/useTranslate';

export const InternalNote = () => {
  const { t } = useTranslate('dialogs.createBooking');

  const { register } = useFormContext();

  return (
    <TextField
      {...register('internalNote')}
      label={t('internalNote', 'utils.generic')}
      fullWidth
      multiline
      minRows={5}
    />
  );
};
