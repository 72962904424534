import { useRef, useState } from 'react';
import { useInterval } from 'usehooks-ts';

import {
  getStorefrontUrl,
  GetStorefrontUrlResponse,
} from '../../../Api/ai-onboarding';

const FETCH_INTERVAL_IN_MS = 2_000;
const MAX_DURATION_IN_MS = 60_000;

const MAX_ATTEMPTS = MAX_DURATION_IN_MS / FETCH_INTERVAL_IN_MS;

export const useGeneratingPreviewState = (
  id?: string
): GetStorefrontUrlResponse => {
  const attempts = useRef(0);

  const [state, setState] = useState<GetStorefrontUrlResponse>({
    status: 'initiated',
  });

  const delayInMs = state.status === 'initiated' ? FETCH_INTERVAL_IN_MS : null;

  useInterval(async () => {
    try {
      if (attempts.current >= MAX_ATTEMPTS) {
        setState({ status: 'failed' });
        return;
      }

      if (!id) return;

      setState(await getStorefrontUrl(id));

      attempts.current += 1;
    } catch {
      setState({ status: 'failed' });
    }
  }, delayInMs);

  return state;
};
