import React from 'react';

import fallbackImage from './placeholder.svg';

interface UseImageProps {
  source?: string;
  loadNow?: boolean;
}

type useImageState = 'loading' | 'success' | 'error';

export const useLazyImage = ({ source, loadNow = true }: UseImageProps) => {
  const [state, setState] = React.useState<useImageState>('loading');

  React.useEffect(() => {
    if (!loadNow || !source) {
      return;
    }

    const main = new Image();

    main.onload = () => {
      if (main.width === 80 && main.height === 80) {
        // This is most likely the eBayImg placeholder image - go to fallback image
        setState('error');
      } else {
        setState('success');
      }
    };

    main.onerror = () => {
      setState('error');
    };

    main.src = source;
  }, [source, loadNow]);

  return { source: state === 'success' ? source : fallbackImage, state };
};
