import { lightTheme, Text } from '@holdbar-com/pixel';
import { InfoOutlined } from '@mui/icons-material';
import { Stack, styled } from '@mui/material';

import { useTranslate } from '../../../../../Hooks/useTranslate';
import { useBookingFlowSectionContext } from '../../domain/use_booking_flow_section_context';

export const FlowTypeInfo = () => {
  const { t } = useTranslate('storefront.bookingflow');

  const { selectedFlowType } = useBookingFlowSectionContext();

  return (
    <StyledInfoContainer>
      <InfoOutlined sx={{ color: lightTheme.palette.neutral.n200 }} />
      <Text fontSize={'small'}>
        {t(`flowType.${selectedFlowType}.description1`)}{' '}
        <span style={{ fontWeight: '600' }}>
          {t(`flowType.${selectedFlowType}.description2`)}
        </span>
      </Text>
    </StyledInfoContainer>
  );
};

const StyledInfoContainer = styled(Stack)`
  max-width: 780px;
  border: 1px solid ${lightTheme.palette.neutral.n100};
  border-radius: 30px;
  padding: 16px 24px;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`;
