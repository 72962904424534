import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect } from 'react';

import { ampli } from '../Ampli';
import { useSearch } from './useSearch';
import { useAuth } from './useAuth';

export const useGlobalShortcuts = () => {
  const { canAccess } = useAuth();
  const { openSearch } = useSearch();

  const { featureBookingSearch } = useFlags();

  useEffect(() => {
    const shortcuts = [
      {
        key: 'k',
        scope: 'booking',
        handleShortcut: () => {
          ampli.searchOpen({
            location: window.location.pathname,
            trigger: 'shortcut',
          });
          openSearch();
        },
      },
    ];

    const handleKeyDown = (e: KeyboardEvent) => {
      const modifierKeyPressed = e.metaKey || e.ctrlKey;

      shortcuts.forEach((shortcut) => {
        if (
          shortcut.key === e.key &&
          modifierKeyPressed &&
          canAccess(shortcut.scope)
        ) {
          e.preventDefault();
          e.stopPropagation();
          shortcut.handleShortcut();
        }
      });
    };

    // Event listener for keyboard shortcuts
    if (featureBookingSearch) {
      document.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [canAccess, openSearch, featureBookingSearch]);
};
