import { Button } from '@holdbar-com/pixel';
import { AddRounded } from '@mui/icons-material';
import { Box, Stack } from '@mui/material';
import React, { ChangeEvent, Fragment, ReactNode } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';

export const ArrayContainer = ({
  renderItem,
  max = 10,
  onAdd,
  controllerKey,
  emptyState,
  addButtonProps,
  defaultValue,
  children,
}: {
  onAdd?: (val: any) => void;
  defaultValue:
    | { [k: string]: string | number }
    | string
    | ((index: number) => any);
  max?: number;
  children?: ReactNode;
  emptyState?: UseControllerProps['defaultValue'];
  addButtonProps: {
    label: string;
    variant?: React.ComponentProps<typeof Button>['variant'];
  };
  controllerKey: string;
  renderItem: (
    el: any,
    i: number,
    props: {
      onChange: (evt: ChangeEvent<HTMLInputElement>) => void;
      onDeleteItem: () => void;
    }
  ) => ReactNode;
}) => {
  const { label, variant } = addButtonProps;

  const {
    field: { value, onChange },
  } = useController({ name: controllerKey, defaultValue: emptyState });

  const handleAddVariant = () => {
    const newValue =
      defaultValue === 'latest'
        ? value?.[value.length - 1] ?? {}
        : typeof defaultValue === 'function'
          ? defaultValue((value?.length ?? 0) + 1)
          : defaultValue;
    onChange([...(value ?? []), newValue]);
    onAdd?.(newValue);
  };

  const handleDeleteVariant = (index: number) => () => {
    const _filtered = [
      ...(value ?? []).filter((_: never, i: number) => i !== index),
    ];
    onChange(_filtered.length > 0 ? _filtered : []);
  };

  return (
    <Box>
      <Stack spacing={3}>
        {value?.map((el: any, i: number) => (
          <Fragment key={el.id}>
            {renderItem(el, i, {
              onDeleteItem: handleDeleteVariant(i),
              onChange: (evt) => onChange(evt.target.value),
            })}
          </Fragment>
        ))}
      </Stack>
      {(value?.length ?? 0) < max && (
        <Button
          variant={variant ?? 'secondary'}
          size={'medium'}
          leftIcon={<AddRounded fontSize="small" sx={{ mr: 0.6 }} />}
          onClick={handleAddVariant}
          style={{ marginTop: 16 }}
        >
          {label}
        </Button>
      )}
      {children}
    </Box>
  );
};
