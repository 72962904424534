import { SearchParamSerializer } from './search-param-serializer';

export class BooleanSerializer implements SearchParamSerializer<boolean> {
  serialize(value: boolean) {
    return value ? 'true' : '';
  }

  deserialize(value: string) {
    return value === 'true';
  }
}
