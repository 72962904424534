import { IEvent, TEvent, TEventFilter } from '../Utils/eventHelpers';
import axios, { IShallowSuccessResponse } from './index';

type Options = {
  from?: Date;
  to?: Date;
};

export const getEvents = async (options?: Options) => {
  if (typeof process.env.REACT_APP_API_EVENTS_URL === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_EVENTS_URL is not defined'
    );
  }

  const { data } = await axios.get<TEvent[]>(
    `${process.env.REACT_APP_API_EVENTS_URL}`,
    {
      params: options,
    }
  );

  return data;
};

export const getEvent = async (id: string) => {
  if (typeof process.env.REACT_APP_API_EVENTS_URL === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_EVENTS_URL is not defined'
    );
  }

  const { data } = await axios.get<TEvent>(
    `${process.env.REACT_APP_API_EVENTS_URL}/${id}`
  );

  return data;
};

export const getEventsInExperience = async (
  experienceId: string,
  offset: number,
  limit: number,
  eventFilter?: TEventFilter
) => {
  if (typeof process.env.REACT_APP_API_EVENTS_URL === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_EVENTS_URL is not defined'
    );
  }

  const { data, headers } = await axios.get<TEvent[]>(
    `${process.env.REACT_APP_API_EVENTS_URL}/experience/${experienceId}`,
    {
      params: {
        limit,
        offset,
        filter: eventFilter?.state,
        locationIds: eventFilter?.locationId,
        states:
          eventFilter?.status === 'anyStatus' ? undefined : eventFilter?.status,
      },
    }
  );

  return { events: data, totalCount: parseInt(headers['x-total-count'], 10) };
};

export const updateEvent = async (
  payload: Record<string, any>,
  id?: string
) => {
  if (!id) {
    throw new Error('No id');
  }
  if (typeof process.env.REACT_APP_API_EVENTS_URL === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_EVENTS_URL is not defined'
    );
  }

  const response = await axios.put<{ id: string }>(
    `${process.env.REACT_APP_API_EVENTS_URL}/${id}`,
    payload
  );

  return response.data;
};

export const addExcludedDate = async (dateTime: string, id?: string) => {
  if (!id) {
    throw new Error('No id');
  }
  if (typeof process.env.REACT_APP_API_EVENTS_URL === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_EVENTS_URL is not defined'
    );
  }
  await axios.put<IShallowSuccessResponse>(
    `${process.env.REACT_APP_API_EVENTS_URL}/${id}/exclude`,
    { dateTime }
  );
};

export const manifestVirtualEvent = async (
  id: string,
  parentId: string,
  payload: unknown
) => {
  if (!id || !parentId) {
    throw new Error('No ids');
  }
  if (typeof process.env.REACT_APP_API_EVENTS_URL === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_EVENTS_URL is not defined'
    );
  }
  await axios.put<IShallowSuccessResponse>(
    `${process.env.REACT_APP_API_EVENTS_URL}/${parentId}/manifest/${id}`,
    payload
  );
};

export const deleteEvent = async (id: string) => {
  if (typeof process.env.REACT_APP_API_EVENTS_URL === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_EVENTS_URL is not defined'
    );
  }

  const { data } = await axios.delete<IShallowSuccessResponse>(
    `${process.env.REACT_APP_API_EVENTS_URL}/${id}`
  );

  return data;
};

export const cancelEvent = async (
  payload: Record<string, any>,
  id?: string
) => {
  if (!id) {
    throw new Error('No id');
  }
  if (typeof process.env.REACT_APP_API_EVENTS_URL === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_EVENTS_URL is not defined'
    );
  }

  const { data } = await axios.post<{
    event: IEvent;
    message?: string;
  }>(`${process.env.REACT_APP_API_EVENTS_URL}/${id}/cancel`, payload);

  return data;
};
