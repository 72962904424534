import { lightTheme, Text } from '@holdbar-com/pixel';
import { Box, Divider, Skeleton, Stack, styled } from '@mui/material';
import { setHours, setMinutes } from 'date-fns';
import { format } from 'date-fns/esm';
import { Fragment } from 'react';

import { TEventWithTitle } from '../../../../../Utils/eventHelpers';
import { useCalendar } from '../../../context';
import { ListEmpty } from '../../../shared';
import { ListItem } from '../ListItem';
import { ListContainer, LoadingEventItem } from './shared';

export const CalendarDayListUngrouped = () => {
  return (
    <ListContainer>
      <List />
    </ListContainer>
  );
};

const List = () => {
  const { events, isLoading } = useCalendar();

  const [groupedEvents, hours] = groupEventsByTime(events);

  const groups = hours as number[];

  if (isLoading || !groups) return <LoadingList />;

  if (!groups.length) return <ListEmpty />;

  return (
    <Stack divider={<Divider />} position="relative">
      {groups.map((group, i) => {
        const eventsInGroup = groupedEvents[group];

        return (
          <Fragment key={group}>
            <Stack direction="row">
              <StyledBox>
                <Text
                  variant="medium"
                  fontSize="tiny"
                  color={lightTheme.palette.neutral.n300}
                >
                  {format(setMinutes(setHours(new Date(), group), 0), 'HH:mm')}
                </Text>
              </StyledBox>
              {eventsInGroup ? (
                <Stack gap={1} flexGrow={1} padding={1} overflow="hidden">
                  {eventsInGroup.map((event) => (
                    <ListItem key={event.id} event={event} />
                  ))}
                </Stack>
              ) : (
                <Box height={48} />
              )}
            </Stack>
          </Fragment>
        );
      })}
    </Stack>
  );
};

const StyledBox = styled(Box)({
  padding: 8,
  width: 56,
  textAlign: 'center',
  borderRightColor: lightTheme.palette.neutral.n100,
  borderRightWidth: 1,
  borderRightStyle: 'solid',
});

const groupEventsByTime = (
  events?: TEventWithTitle[]
): [{ [hour: number]: TEventWithTitle[] }, number[]] => {
  if (!events) return [{}, []];

  const groupedEvents = events.reduce(
    (timeGroups, event) => {
      // Get starting hour from ISO string, and remove leading 0
      const startHour = Number(event.startDateTime.split('T')[1].split(':')[0]);

      if (timeGroups[startHour]) {
        return {
          ...timeGroups,
          [startHour]: [...timeGroups[startHour], event],
        };
      }

      return {
        ...timeGroups,
        [startHour]: [event],
      };
    },
    {} as Record<number, Array<TEventWithTitle>>
  );

  const hoursInGroup = Object.keys(groupedEvents) as unknown[] as number[];

  const minHour = hoursInGroup.length ? Math.min(...hoursInGroup) : undefined;
  const maxHour = hoursInGroup.length ? Math.max(...hoursInGroup) : undefined;

  const hours = Array.from(
    { length: (maxHour as number) - (minHour as number) + 1 },
    (_, i) => i + (minHour as number)
  );

  return [groupedEvents, hours];
};

const LoadingList = () => {
  return (
    <Stack divider={<Divider />}>
      <LoadingGroupItem />
      <LoadingGroupItem />
      <LoadingGroupItem />
    </Stack>
  );
};

const LoadingGroupItem = () => {
  return (
    <Stack direction="row">
      <StyledBox display="flex" justifyContent="center">
        <Skeleton height={13} width={25} />
      </StyledBox>
      <Stack gap={1} flexGrow={1} padding={1}>
        <LoadingEventItem />
        <LoadingEventItem />
        <LoadingEventItem />
      </Stack>
    </Stack>
  );
};
