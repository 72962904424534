import { ampli } from '../../../Ampli';

export const trackOverviewRequestReportCompleted = (
  report_type: string,
  userId?: string
) => {
  const properties = { report_type };

  if (userId) ampli.identify(userId, properties);
  ampli.overviewRequestReportCompleted(properties);
};
