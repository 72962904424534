import create from 'zustand';
import { persist } from 'zustand/middleware';

export const connectStore = create(
  persist<{
    chooseAccountForInvitation: (org: string, token: string) => void;
    removeInvitation: (token: string) => void;
    invitations: Record<
      string,
      {
        org: string;
      }
    >;
  }>(
    (set) => ({
      invitations: {},
      chooseAccountForInvitation: (org: string, token: string) =>
        set((state) => ({
          invitations: {
            ...state.invitations,
            [token]: {
              org,
            },
          },
        })),
      removeInvitation: (token: string) =>
        set((state) => {
          delete state.invitations[token];
          return state;
        }),
    }),
    {
      name: 'connect-store',
      getStorage: () => sessionStorage,
    }
  )
);
