import NiceModal from '@ebay/nice-modal-react';
import { Button, Text } from '@holdbar-com/pixel';
import { AddRounded } from '@mui/icons-material';
import { Box } from '@mui/material';
import { toast } from 'react-toastify';

import { IInvitation } from '../../Api/Invitation';
import { UsersList } from '../../Components/UsersList/UsersList';
import { useInvitations } from '../../Hooks/data/useInvitations';
import { useTranslate } from '../../Hooks/useTranslate';
import { InviteUsersDialog } from '../../Modals/InviteUsersDialog';

export const SettingsUsersSection = () => {
  const { t } = useTranslate('settings.team');
  const { invitations, invite } = useInvitations();

  const handleClickInvite = async () => {
    const usersToBeAdded =
      await NiceModal.show<IInvitation[]>(InviteUsersDialog);

    toast.loading(
      t('loading', 'toast.inviteUser', { count: usersToBeAdded.length }),
      {
        toastId: 'invite-users-loading',
      }
    );

    const responses = await Promise.all(
      usersToBeAdded.map(async (i) => {
        try {
          await invite(i);
          return {
            error: false,
          };
        } catch (err) {
          return {
            error: true,
            message: (err as Error).message,
            email: i.claims.email,
          };
        }
      })
    ).finally(() => {
      toast.dismiss('invite-users-loading');
      invitations.refetch();
    });

    const allErrors = responses.every((x) => x.error);

    if (responses.some((x) => x.error) && !allErrors) {
      const failed = responses.filter((x) => x.error);
      toast.warn(
        t('failed', 'toast.inviteUser', {
          count: failed.length,
          users: failed.map((x) => `${x.email}: ${x.message}`).join('\n'),
        }),
        {
          toastId: 'invite-users-failed',
          autoClose: 5000,
        }
      );
    } else if (allErrors) {
      toast.error(
        t('failedAll', 'toast.inviteUser', {
          count: responses.length,
          users: responses.map((x) => `${x.email}: ${x.message}`).join('\n'),
        }),
        {
          toastId: 'invite-users-failed',
          autoClose: 5000,
        }
      );
    } else {
      toast.success(
        t('success', 'toast.inviteUser', { count: responses.length }),
        {
          toastId: 'invite-users-success',
          autoClose: 5000,
        }
      );
    }
  };

  return (
    <>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
        mb={2}
      >
        <Text fontSize={'large'} variant={'medium'}>
          {t('title')}
        </Text>
        <Button
          size='small'
          variant='primary'
          leftIcon={<AddRounded fontSize='small' sx={{ mr: 1 }} />}
          onClick={handleClickInvite}
        >
          {t('buttonLabel')}
        </Button>
      </Box>
      <UsersList />
    </>
  );
};
