import { add, isAfter, isBefore, sub } from 'date-fns';
import { useMemo } from 'react';

import { trackCalendarDateNavigation } from '../../../../tracking/calendar/trackCalendarDateNavigation';
import { CalendarView, useCalendar } from '../../context';

export const useDateNavigation = (interval: CalendarView) => {
  const { minimumDate, maximumDate, selectedDate, setSelectedDate } =
    useCalendar();

  return useMemo(() => {
    const prevDate = getPrevDate(selectedDate, interval);
    const nextDate = getNextDate(selectedDate, interval);

    const isPrevDisabled = isBefore(prevDate, minimumDate);
    const isNextDisabled = isAfter(nextDate, maximumDate);

    const handlePrevDate = () => {
      if (isPrevDisabled) return;

      trackCalendarDateNavigation('prev', interval, prevDate);
      setSelectedDate(prevDate);
    };

    const handleNextDate = () => {
      if (isNextDisabled) return;

      trackCalendarDateNavigation('next', interval, nextDate);
      setSelectedDate(nextDate);
    };

    return { handlePrevDate, handleNextDate, isPrevDisabled, isNextDisabled };
  }, [interval, maximumDate, minimumDate, selectedDate, setSelectedDate]);
};

const getPrevDate = (date: Date, view: CalendarView) => {
  const duration = viewToDuration(view);

  return sub(date, duration);
};

const getNextDate = (date: Date, view: CalendarView) => {
  const duration = viewToDuration(view);

  return add(date, duration);
};

const viewToDuration = (view: CalendarView) => {
  if (view === 'day') return { days: 1 };
  if (view === 'week') return { weeks: 1 };
  if (view === 'month') return { months: 1 };

  return {};
};
