import {
  BoxProps,
  Button,
  Divider,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { ReactNode } from 'react';
import {
  useController,
  UseControllerReturn,
  useFormContext,
} from 'react-hook-form';

import {
  OptionGroup,
  OptionGroupSelectedSection,
} from '../../Components/OptionGroup/OptionGroup';
import { useExperience } from '../../Hooks/useExperience';
import { useProfile } from '../../Hooks/useProfile';
import { useTranslate } from '../../Hooks/useTranslate';
import { SyiSection } from '../../Pages/SyiPage/SyiSection';
import { useErrors } from '../../Pages/SyiPage/useErrors';
import { minimumParticipantsOptions } from '../ExperienceSyiSections/ExperienceSyiSectionPracticalities';
import { LocationSelect } from './LocationSelect';

const seatsAvailableOptions = [
  {
    key: 'experienceDefault',
    label: 'Ja, det er korrekt',
  },
  {
    key: 'custom',
    label: 'Nej, lad mig definere',
  },
];

type TOptionGroupObj = Record<string, string> & {
  selectedOptionKey: string;
};

const replaceValue = (str: string, value: string) =>
  str.replaceAll('{{value}}', value as string);

const getPrefillLabel =
  (options: any[]) => (optionKey: string, value: string | number) => {
    const label =
      options?.find((el) => el.key === optionKey)?.prefillLabel ?? '';
    return replaceValue(label, value as string);
  };
const getDefinedValueOrDefault = (
  obj: TOptionGroupObj,
  defaultValue: number | string
) => {
  if (obj?.hasOwnProperty(obj?.selectedOptionKey)) {
    return obj[obj.selectedOptionKey];
  }
  return defaultValue as string;
};

const FormGroupWithPrefill = ({
  formKey,
  defaultValue,
  label,
  render,
  prefillOptions,
  options,
  ...props
}: Omit<BoxProps, 'defaultValue'> & {
  render: (field: UseControllerReturn<any, any>['field']) => ReactNode;
  label?: string;
  options: any[];
  prefillOptions?: typeof minimumParticipantsOptions;
  formKey: string;
  defaultValue?: any;
}) => {
  const { setValue } = useFormContext();

  const { field: selected } = useController({
    name: `${formKey}.selectedOptionKey`,
    defaultValue: 'experienceDefault',
  });
  const { field: value } = useController({
    name: `${formKey}.value`,
    defaultValue:
      typeof defaultValue === 'string' || typeof defaultValue === 'number'
        ? defaultValue
        : defaultValue?.value,
  });

  const handleOptionKeyChangeToExperienceDefault =
    (formKey: string, value: string | number) => (optionKey: string) => {
      if (optionKey === 'experienceDefault' && value !== undefined) {
        setValue(formKey, value);
      }
    };

  return (
    <SyiSection
      title={
        label
          ? replaceValue(label, defaultValue)
          : getPrefillLabel(prefillOptions!)(
              defaultValue?.selectedOptionKey,
              getDefinedValueOrDefault(defaultValue, 0)
            )
      }
      {...props}
    >
      <OptionGroup
        name={formKey}
        options={options}
        field={selected}
        onChangeValue={handleOptionKeyChangeToExperienceDefault(
          `${formKey}.value`,
          value.value
        )}
      >
        {render(value)}
      </OptionGroup>
    </SyiSection>
  );
};

export const EventSyiSectionPracticalities = () => {
  const { t } = useTranslate('events.create.practicalities');
  const { company } = useProfile();

  const { control } = useFormContext();
  const { getError } = useErrors();

  const { field: experienceId } = useController({ name: 'experienceId' });

  const {
    experience: { data: experience },
  } = useExperience(experienceId.value);

  const _seatsAvailableOptions = seatsAvailableOptions.map((el) => ({
    ...el,
    label: t(`seatCount.options.${el.key}.label`),
  }));

  return (
    <>
      <Stack direction={'row'} justifyContent={'space-between'}>
        <Typography variant={'h4'}>{t('title')}</Typography>
      </Stack>

      {experience?.seats?.seatCount && (
        <Stack mt={2} divider={<Divider />} spacing={4}>
          <FormGroupWithPrefill
            render={(field) => (
              <OptionGroupSelectedSection optionKey={'custom'} mt={2}>
                <TextField
                  label={t('seatCount.options.custom.inputLabel')}
                  type={'number'}
                  inputProps={{
                    min: 0,
                  }}
                  sx={{ minWidth: 320 }}
                  {...field}
                  onChange={(v) => {
                    field.onChange(parseInt(v.target.value));
                  }}
                />
              </OptionGroupSelectedSection>
            )}
            formKey={'seatCount'}
            label={t('seatCount.title')}
            options={_seatsAvailableOptions}
            defaultValue={experience?.seats?.seatCount}
          />

          <SyiSection
            title={t('addresses.title')}
            error={getError('locationId')}
          >
            <LocationSelect
              experienceId={experience.id ?? ''}
              control={control}
            />
          </SyiSection>
        </Stack>
      )}
    </>
  );
};
