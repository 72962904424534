import { Box, Typography } from '@mui/material';

export const TextBlock = ({
  title,
  description,
  ...props
}: {
  title: string;
  description: string;
}) => {
  return (
    <Box color={'white'} maxWidth={440} {...props}>
      <Typography color={'inherit'} fontSize={'3.375em'} variant={'h1'}>
        {title}
      </Typography>
      <Typography color={'inherit'} mt={4} variant={'h4'}>
        {description}
      </Typography>
    </Box>
  );
};
