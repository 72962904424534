import { Button, Link, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';

export const OnBoardingStartSection = () => {
  const { t } = useTranslation();

  const { handleNext } = useOutletContext<{ handleNext: () => void }>();

  return (
    <>
      <Typography variant={'h4'} fontWeight={500}>
        {t('onboarding.start.boxTitle')}
      </Typography>
      <Typography mt={2} mb={2} variant={'h2'}>
        {t('onboarding.start.boxSubtitle')}
      </Typography>
      <Typography variant={'h5'}>
        {t('onboarding.start.boxDescription')}
      </Typography>
      <Button
        sx={{ mt: 5, mb: 5 }}
        variant={'contained'}
        size={'large'}
        onClick={handleNext}
      >
        {t('onboarding.start.boxButtonLabel')}
      </Button>
    </>
  );
};
