import {
  StockPhotosDownloadImageRequest,
  StockPhotosSearchResponse,
} from '@holdbar-com/utils-types';

import axios from './index';

export const getStockPhotos = async (
  type: 'experience',
  experience: {
    experienceName?: Record<string, string | undefined>;
    experienceDescription?: Record<string, string | undefined>;
  }
): Promise<StockPhotosSearchResponse> => {
  if (typeof process.env.REACT_APP_INTERNAL_API_GATEWAY === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_INTERNAL_API_GATEWAY is not defined'
    );
  }

  const { data } = await axios.post(
    `${process.env.REACT_APP_INTERNAL_API_GATEWAY}/v1/stock-photos/search`,
    {
      type,
      experience: {
        name: experience.experienceName,
        description: experience.experienceDescription,
      },
    }
  );

  return data;
};

export const downloadStockPhotoImage = async (
  provider: 'shutterstock',
  assetId: string,
  searchId?: string
): Promise<Blob> => {
  if (typeof process.env.REACT_APP_INTERNAL_API_GATEWAY === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_INTERNAL_API_GATEWAY is not defined'
    );
  }

  const payload: StockPhotosDownloadImageRequest = {
    provider,
    assetId,
    searchId,
  };

  const { data } = await axios.post(
    `${process.env.REACT_APP_INTERNAL_API_GATEWAY}/v1/stock-photos/download-image`,
    payload,
    {
      responseType: 'blob',
    }
  );

  return data;
};
