import { MediaItem } from '@holdbar-com/utils-types';
import {
  Box,
  BoxProps,
  CircularProgress,
  Grid,
  styled,
  SvgIcon,
} from '@mui/material';
import { ComponentPropsWithoutRef } from 'react';
import { useDropzone } from 'react-dropzone';

import { useUploader } from '../../Hooks/useUploader';
import { TFileTypes, TModelTypes } from '../../types';

const Styled = styled(Box)({
  overflow: 'hidden',
  position: 'relative',
  border: '1px dashed rgba(100,100,100,0.15)',
  textAlign: 'center',
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'rgba(100,100,100,.05)',
  ['&:hover']: {
    backgroundColor: 'rgba(100,100,100,.15)',
  },
});

const StyledOverlay = styled(Box)({
  width: '100%',
  height: '100%',
  position: 'absolute',
  background: 'rgba(250,250,250,0.9)',
  zIndex: 5,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  color: 'common.main',
});

const StyledProgress = styled('div')(({ progress }: { progress: number }) => ({
  width: `${progress}%`,
  height: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
  backgroundColor: 'rgba(0, 127, 255, 0.1)',
  transition: 'width 160ms',
}));

const LoadingOverlay = ({
  progress,
  children,
}: { progress: number } & ComponentPropsWithoutRef<typeof StyledOverlay>) => {
  return (
    <StyledOverlay>
      <StyledProgress progress={progress} />
      <Box sx={{ zIndex: 5 }}>{children}</Box>
    </StyledOverlay>
  );
};

interface IDropzoneProps {
  urls?: string[];
  modelType: TModelTypes;
  id?: string;
  fileType: TFileTypes;
  Icon?: typeof SvgIcon;
  onSuccess?: (
    args: Omit<MediaItem, 'id' | 'provider'>,
    key: TFileTypes,
    file: File
  ) => Promise<void>;
  onPrepared?: (
    args: Omit<MediaItem, 'id' | 'provider'> & { localUrl: string },
    key: TFileTypes,
    uploadFunc: () => Promise<void>
  ) => void;
  containerProps?: BoxProps;
  maxFiles?: number;
}

export const Dropzone = ({
  children,
  maxFiles = 40,
  modelType,
  urls,
  id,
  onPrepared,
  fileType,
  containerProps = {},
}: ComponentPropsWithoutRef<typeof Box> & IDropzoneProps) => {
  const { loading, uploadProgress, onDrop, tempUrls } = useUploader({
    fileType,
    modelType,
    id,
    onPrepared,
  });

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles,
  });

  const items = tempUrls ?? urls ?? null;

  return (
    <Styled
      {...getRootProps()}
      {...containerProps}
      sx={{
        ...(containerProps?.sx ?? {}),
      }}
    >
      {Boolean(loading) && (
        <LoadingOverlay progress={uploadProgress}>
          <CircularProgress size={40} />
        </LoadingOverlay>
      )}

      <input {...getInputProps()} />

      {items ? (
        <Grid
          container
          columns={maxFiles > 1 ? 4 : 1}
          spacing={maxFiles > 1 ? 1 : 0}
          height="100%"
        >
          {items.map((el, i) => (
            <Box
              component={'img'}
              alt={'Preview'}
              src={el}
              width={'100%'}
              style={{ objectFit: 'cover' }}
              key={i}
            />
          ))}
        </Grid>
      ) : (
        children
      )}
    </Styled>
  );
};
