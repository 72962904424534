import { useCalendar } from '../../../context';
import { CalendarDayListGrouped } from './Grouped';
import { CalendarDayListUngrouped } from './Ungrouped';

export const CalendarDayList = () => {
  const { groupByExperience } = useCalendar();

  return groupByExperience ? (
    <CalendarDayListGrouped />
  ) : (
    <CalendarDayListUngrouped />
  );
};
