import { lightTheme } from '@holdbar-com/pixel';
import {
  Skeleton,
  styled,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';

import { useGetEvent } from '../../../../../../../../Hooks/events/useGetEvent';
import { useTranslate } from '../../../../../../../../Hooks/useTranslate';
import { useBookingDetailsContext } from '../../../../../domain/use_booking_details_context';
import { StyledTable } from '../../BookingDetailsSummaryTable';
import { paymentRows } from '../domain/use_booking_details_payment';
import { BookingDetailsSummaryReceiptError } from './BookingDetailsSummaryReceiptError';

export const BookingDetailsSummaryPaymentTable = () => {
  const { t } = useTranslate('dialogs.booking');

  const { booking, receipt } = useBookingDetailsContext();
  const {
    event: { data: event },
  } = useGetEvent(booking?.eventId ?? '');

  if (!booking) return null;

  if (!receipt.isLoading && receipt.error) {
    return <BookingDetailsSummaryReceiptError />;
  }

  return (
    <StyledTable>
      <TableBody>
        {paymentRows.map((row) => {
          const key = row.key;
          const value = receipt.isLoading ? (
            <Skeleton width={80} />
          ) : (
            row.value(booking, t, receipt.data, event)
          );
          if (!value) return null;

          return (
            <StyledTableRow
              key={key}
              sx={
                row.showDivider
                  ? {
                      marginBottom: 1,
                      paddingBottom: 1,
                      borderBottom: '1px solid',
                      borderColor: lightTheme.palette.neutral.n100,
                    }
                  : {}
              }
            >
              <TableCell
                component="th"
                scope="row"
                sx={row.highlight ? { fontWeight: 600 } : {}}
              >
                {t(key)}
              </TableCell>
              <StyledTableCell sx={row.highlight ? { fontWeight: 600 } : {}}>
                {value}
              </StyledTableCell>
            </StyledTableRow>
          );
        })}
      </TableBody>
    </StyledTable>
  );
};

const StyledTableRow = styled(TableRow)({
  display: 'flex',
  justifyContent: 'space-between',
  gap: 16,
});

const StyledTableCell = styled(TableCell)({
  textAlign: 'right',
  display: 'flex',
  justifyContent: 'flex-end',
  flexGrow: 1,
});
