import { Text } from '@holdbar-com/pixel';
import { renderDate } from '@holdbar-com/utils-date';
import { Divider, Stack } from '@mui/material';

import { useCalendar } from '../context';
import { CalendarMobileDateNavigation } from '../desktop/date-navigation/CalendarMobileDateNavigation';
import { DaySelector } from './DaySelector';
import { Filters } from './filters/Filters';
import { CalendarMobileList } from './List';

export const CalendarMobileView = () => {
  const { selectedDate } = useCalendar();

  return (
    <Stack gap={2} marginTop={4}>
      <Stack direction="row" justifyContent="space-between">
        <CalendarMobileDateNavigation />
        <Filters />
      </Stack>
      <DaySelector />
      <Divider />
      <Stack gap={2} marginTop={2}>
        <Text variant="medium" fontSize="small">
          {renderDate(selectedDate.toISOString())}
        </Text>
        <CalendarMobileList />
      </Stack>
    </Stack>
  );
};
