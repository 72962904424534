import { Button } from '@holdbar-com/pixel';
import { ExperienceTag } from '@holdbar-com/utils-types';
import { Stack } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';

import { TranslatableGroup } from '../../../../Components/TranslatableGroup/translatable-group';
import { useStorefrontLanguages } from '../../../../Hooks/translatable/use-storefront-languages';
import { useTranslate } from '../../../../Hooks/useTranslate';
import { languageOptions } from '../../../../Utils/config';

export const ExperienceTagForm = ({
  onSubmit,
  defaultValues,
  actions,
}: {
  onSubmit: (data: ExperienceTag['name']) => void;
  defaultValues?: ExperienceTag['name'];
  actions: FormAction[];
}) => {
  const { t } = useTranslate('experience.tags.dialog.form');

  const formMethods = useForm<{ name: ExperienceTag['name'] }>({
    defaultValues: { name: defaultValues ?? {} },
  });

  const {
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
  } = formMethods;

  const storefrontLanguages = useStorefrontLanguages();

  const errorMessage = (
    [
      errors.name?.message,
      ...Object.values(errors.name ?? {}).map((a) => a?.message),
    ] as Array<string | undefined>
  ).filter(Boolean)?.[0];

  return (
    <FormProvider {...formMethods}>
      <form
        onSubmit={handleSubmit((data) => {
          if (!Object.entries(data.name)?.length) {
            return setError('name', { message: t('error.noInput') });
          }
          for (const [language, value] of Object.entries(data.name)) {
            if (!value) {
              return setError(`name.${language}`, {
                message: t('error.missingTranslation', {
                  language: languageOptions(language).label,
                }),
              });
            }
          }

          onSubmit(data.name);
        })}
      >
        <TranslatableGroup
          mt={0}
          langs={storefrontLanguages ?? []}
          tip={t(`helpText`)}
          id={'name'}
          title={t(`title`)}
          input={{
            type: 'input',
            maxLength: 50,
            placeholder: t(`placeholder`),
          }}
          clearError={() => clearErrors('name')}
          error={errorMessage}
        />
        <ExperienceTagDialogActions actions={actions} />
      </form>
    </FormProvider>
  );
};

type FormAction = {
  id: string;
  variant: React.ComponentProps<typeof Button>['variant'];
  type?: React.ComponentProps<typeof Button>['type'];
  label: string;
  onClick?: () => void;
  disabled?: boolean;
};

export const ExperienceTagDialogActions = ({
  actions,
}: {
  actions: FormAction[];
}) => {
  if (!actions) return null;

  return (
    <Stack direction="row" gap={1} justifyContent="flex-end" marginTop={2}>
      {actions.map(
        ({
          id,
          type = 'button',
          variant,
          onClick,
          label,
          disabled = false,
        }) => (
          <Button
            key={id}
            name={id}
            size="medium"
            type={type}
            variant={variant}
            onClick={onClick}
            disabled={disabled}
          >
            {label}
          </Button>
        )
      )}
    </Stack>
  );
};
