import NiceModal, { useModal } from '@ebay/nice-modal-react';
import styled from '@emotion/styled';
import { Button, lightTheme, Text } from '@holdbar-com/pixel';
import { ArrowBackRounded } from '@mui/icons-material';
import { Dialog, Stack } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Loading } from '../../../Components/Loading/Loading';
import useResponsive from '../../../Hooks/layout/useResponsive';
import { useProfile } from '../../../Hooks/useProfile';
import { useTranslate } from '../../../Hooks/useTranslate';

export const ExperiencePreviewDialog = NiceModal.create(
  ({ experienceId }: { experienceId: string }) => {
    const { t } = useTranslate('onboarding.ai.experiencePreview.dialog');
    const [isLoaded, setIsLoaded] = useState(false);
    const modal = useModal();
    const { isSm, isMd } = useResponsive();

    const handleClose = () => {
      modal.resolve();
      modal.hide();
    };

    const navigate = useNavigate();
    const { companyProfileUrl } = useProfile();
    const companyUrl = companyProfileUrl();

    // If company URL is not ready yet, the iframe
    // will show the details page for the experience
    if (!companyUrl) return null;
    const previewUrl = `${companyUrl}/experience/${experienceId}?preview=true`;

    return (
      <Dialog
        open={modal.visible}
        onClose={handleClose}
        fullScreen={true}
        fullWidth
      >
        <Stack flexGrow={1} sx={{ background: 'white' }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            gap={3}
            sx={{
              paddingY: { xs: 2, md: 3.5 },
              paddingX: { xs: 2, md: 5 },
              // The Storefront has a white background so this border is added
              // to highlight where the iframe begins
              border: `1px solid ${lightTheme.palette.neutral.n100}`,
            }}
          >
            <Stack direction="row" alignItems="center" gap={2}>
              <StyledBackButton
                variant="text"
                size="small"
                onClick={handleClose}
              >
                <ArrowBackRounded />
              </StyledBackButton>
              {!isSm && (
                <Text variant="medium" fontSize={isMd ? 'medium' : 'large'}>
                  {t('goBackLabel')}
                </Text>
              )}
            </Stack>
            <Stack direction="row" gap={2}>
              <Button
                variant="secondary"
                size={isMd ? 'medium' : 'large'}
                onClick={() => {
                  navigate(`/experience/${experienceId}/edit`);
                  handleClose();
                }}
              >
                {t('editLabel')}
              </Button>
              <Button
                variant="primary"
                size={isMd ? 'medium' : 'large'}
                onClick={() => {
                  navigate(`/experience/${experienceId}`);
                  handleClose();
                }}
              >
                {t('acceptLabel')}
              </Button>
            </Stack>
          </Stack>
          <Stack flexGrow={1} position="relative">
            <iframe
              onLoad={() => setIsLoaded(true)}
              style={{
                height: '100%',
                border: 'none',
              }}
              src={previewUrl}
            />
            {!isLoaded && <Loading isLoading={true} />}
          </Stack>
        </Stack>
      </Dialog>
    );
  }
);

const StyledBackButton = styled(Button)({
  height: 'unset',
  padding: 0,
  span: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
