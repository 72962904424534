import { useQuery, useQueryClient } from 'react-query';

import * as api from '../../Api';
import { TEvent, transformEvents } from '../../Utils/eventHelpers';

export const getEventQueryKey = (id: string) => ['events', id];

export const useGetEvent = (id: string) => {
  const queryClient = useQueryClient();
  const EventQueryKey = getEventQueryKey(id);

  const event = useQuery<TEvent>(
    EventQueryKey,
    async () => {
      await queryClient.cancelQueries(EventQueryKey);
      const fetched = await api.getEvent(id);
      return transformEvents([fetched])[0];
    },
    { enabled: Boolean(id) }
  );

  return { event };
};
