import { Stack } from '@mui/material';
import { FC } from 'react';

import { MonthSelector } from '../../shared/MonthSelector';
import { TodayButton } from '../../shared/TodayButton';
import { DateNavigationButtons } from './DateNavigationButtons';

const MOBILE_NAVIGATION_INTERVAL = 'week';

export const CalendarMobileDateNavigation: FC = () => {
  return (
    <Stack gap={1} mt={2}>
      <MonthSelector interval={MOBILE_NAVIGATION_INTERVAL} />
      <Stack direction="row" gap={3}>
        <TodayButton />
        <DateNavigationButtons interval={MOBILE_NAVIGATION_INTERVAL} />
      </Stack>
    </Stack>
  );
};
