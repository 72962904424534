import { TFunction } from 'i18next';
import { useMemo } from 'react';
import * as yup from 'yup';

export const useNotificationsResendModalFormValidation = (t: TFunction) =>
  useMemo(
    () =>
      yup.object().shape({
        name: yup.string().required(),
        address: yup
          .string()
          .email(t('invalidEmail', 'auth.errors'))
          .required(t('required', 'utils.errors')),
      }),
    [t]
  );

export type NotificationsResendModalFormType = yup.InferType<
  ReturnType<typeof useNotificationsResendModalFormValidation>
>;
