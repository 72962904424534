import { useFlags } from 'launchdarkly-react-client-sdk';

import { DaysInput } from '../../../../Components/DaysInput/DaysInput';
import FeatureItem from '../../../../Components/FeatureItem/FeatureItem';
import FeatureInput from '../inputs/FeatureInput';

export const AllowGuestsCancelBookingFeature = () => {
  const flags = useFlags();
  if (!flags.featureGuestsCancelSetting) {
    return null;
  }

  return (
    <FeatureItem feature={'allowGuestsCancelBooking'}>
      <FeatureInput name="untilDays" as={DaysInput} required />
    </FeatureItem>
  );
};
