import { Divider, Stack } from '@mui/material';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { useEventUpsert } from '../../../../domain/event_upsert_context';
import { isIntervalSelected } from '../../../../domain/types';
import { EventUpsertTimeFormIntervalField } from './EventUpsertTimeFormIntervalField';

export const EventUpsertTimeFormIntervalList = () => {
  const { currentEvent, updateEventField } = useEventUpsert();
  const { setValue, trigger } = useFormContext();

  const intervals = useMemo(() => {
    if (isIntervalSelected(currentEvent.intervals)) {
      return currentEvent.intervals.value;
    }
    return [];
  }, [currentEvent.intervals]);

  const handleDeleteInterval = async (index: number) => {
    const updatedIntervals = intervals.filter((_, idx) => idx !== index);

    updateEventField('intervals', {
      selectedOptionKey: updatedIntervals.length > 0 ? 'yes' : 'no',
      value: updatedIntervals,
    });

    setValue(`intervals.value`, updatedIntervals);
    await trigger(`intervals`);
  };

  if (intervals.length === 0 || !isIntervalSelected(currentEvent.intervals)) {
    return null;
  }

  return (
    <Stack gap={3}>
      <Divider />
      {intervals.map((interval, index) => (
        <EventUpsertTimeFormIntervalField
          key={index}
          index={index}
          fromTime={interval.fromTime}
          toTime={interval.toTime}
          onDelete={handleDeleteInterval}
        />
      ))}
    </Stack>
  );
};
