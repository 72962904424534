import { Skeleton, Text } from '@holdbar-com/pixel';
import { Experience } from '@holdbar-com/utils-types';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
} from '@mui/material';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Await } from 'react-router';

import { getLocalized } from '../../../Hooks/useBookings';
import { useTranslate } from '../../../Hooks/useTranslate';

const shareOptions = ['all', 'selection', 'none'] as const;
type ShareOption = (typeof shareOptions)[number];

export type SelectExperiencesInputs = {
  share: ShareOption;
  experiences: string[];
};

export const SelectExperiences = ({
  experiences,
}: {
  experiences: Promise<Experience[]>;
}) => {
  const { watch } = useFormContext<SelectExperiencesInputs>();
  const selectedShareOption = watch('share');

  return (
    <Stack gap={3}>
      <ShareSelection />
      {selectedShareOption === 'selection' && (
        <React.Suspense
          fallback={
            <>
              <Skeleton variant={'rectangular'} height={32} width={'100%'} />
              <Skeleton variant={'rectangular'} height={32} width={'100%'} />
            </>
          }
        >
          <Await resolve={experiences}>
            {(experiences: Experience[]) => (
              <ExperienceSelection experiences={experiences} />
            )}
          </Await>
        </React.Suspense>
      )}
    </Stack>
  );
};

const ShareSelection = () => {
  const { t } = useTranslate('connect.dialog.selectShare');
  const { control } = useFormContext<SelectExperiencesInputs>();

  return (
    <FormControl>
      <FormLabel id="share">
        <Text fontSize="small" variant="medium">
          {t('label')}
        </Text>
      </FormLabel>
      <Controller
        control={control}
        name="share"
        rules={{ required: true }}
        render={({ field }) => (
          <RadioGroup {...field}>
            {shareOptions.map((option) => (
              <FormControlLabel
                key={option}
                value={option}
                label={t(`optionLabel.${option}`)}
                control={<Radio />}
              />
            ))}
          </RadioGroup>
        )}
      />
    </FormControl>
  );
};

const ExperienceSelection = ({
  experiences,
}: {
  experiences: Experience[];
}) => {
  const { t, i18n } = useTranslate('connect.dialog.selectExperiences');
  const { control } = useFormContext<SelectExperiencesInputs>();

  if (!experiences || !experiences.length)
    return <ExperienceListError message={t('noExperiences')} />;

  return (
    <FormControl>
      <FormLabel id="share">
        <Text fontSize="small" variant="medium">
          {t('label')}
        </Text>
      </FormLabel>
      <Controller
        control={control}
        name="experiences"
        rules={{ required: true, shouldUnregister: true }}
        render={({ field: { value, onChange, ...field } }) => (
          <FormGroup {...field}>
            {experiences.map(({ id, headline }) => (
              <FormControlLabel
                key={id}
                value={id}
                label={getLocalized(headline, i18n.language)}
                control={
                  <Checkbox
                    defaultChecked={value?.includes(id)}
                    onChange={(_, checked) => {
                      if (checked) {
                        onChange([...value, id]);
                      } else {
                        onChange(
                          value.filter((experienceId) => experienceId !== id)
                        );
                      }
                    }}
                  />
                }
              />
            ))}
          </FormGroup>
        )}
      />
    </FormControl>
  );
};

const ExperienceListError = ({ message }: { message: string }) => {
  return <Text fontSize="small">{message}</Text>;
};
