import { Button } from '@holdbar-com/pixel';
import { Experience } from '@holdbar-com/utils-types';
import { Stack, TextField } from '@mui/material';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useNavigation } from 'react-router-dom';

import { useTranslate } from '../../../Hooks/useTranslate';
import { emailRegex } from '../../../Utils/helpers';
import {
  SelectExperiences,
  SelectExperiencesInputs,
} from '../components/select-experiences';

export type AddConnectionFormInputs = {
  email: string;
} & SelectExperiencesInputs;

export const AddConnectionForm = ({
  onSubmit,
  experiences,
}: {
  onSubmit: (values: FieldValues) => void;
  experiences: Promise<Experience[]>;
}) => {
  const { t } = useTranslate('connect.dialog.addConnection');
  const { state } = useNavigation();

  const formMethods = useForm<AddConnectionFormInputs>({
    defaultValues: { email: '', share: 'all', experiences: [] },
  });

  const {
    handleSubmit,
    watch,
    register,
    formState: {
      errors: { email: emailError },
    },
  } = formMethods;

  const watchedEmail = watch('email');
  const watchedShare = watch('share');
  const watchedExperienceSelection = watch('experiences');

  const isSelectionRequired =
    watchedShare === 'selection' && !watchedExperienceSelection?.length;
  const isSubmitDisabled =
    !watchedEmail || isSelectionRequired || state === 'submitting';

  return (
    <FormProvider {...formMethods}>
      <Stack
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        gap={4}
      >
        <Stack direction="row" gap={1} marginTop={4}>
          <TextField
            {...register('email', {
              required: {
                value: true,
                message: t('validation.emailRequired', 'signup.form'),
              },
              pattern: {
                value: emailRegex,
                message: t('validation.emailInvalid', 'signup.form'),
              },
            })}
            size="medium"
            fullWidth
            type="email"
            label={t('label.email', 'signup.form')}
            placeholder={t('placeholder.email')}
            error={!!emailError}
            helperText={emailError?.message}
            InputProps={{ sx: { maxHeight: 48 } }}
            InputLabelProps={{ shrink: true }}
          />
          <Button
            type="submit"
            variant="primary"
            size="large"
            disabled={isSubmitDisabled}
          >
            {t('submitLabel')}
          </Button>
        </Stack>
        <SelectExperiences experiences={experiences} />
      </Stack>
    </FormProvider>
  );
};
