import { yupResolver } from "@hookform/resolvers/yup";
import { Stack } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";

import { SectionHeadline } from "../../../../Components/SectionHeadline/SectionHeadline";
import { useProfile } from "../../../../Hooks/useProfile";
import { useTranslate } from "../../../../Hooks/useTranslate";
import {
  ProfileFormData,
  useProfileFormValidation,
} from '../domain/use-profile-form-validation';
import { ProfileForm } from "./form/ProfileForm";
import { SettingsProfileSectionAdvanced } from './SettingsProfileSectionAdvanced';

export const SettingsProfileSection = () => {
  const { t } = useTranslate('settings.profile');
  const {
    me: { data: me },
  } = useProfile();

  const profileFormValidation = useProfileFormValidation();
  const form = useForm<ProfileFormData>({
    resolver: yupResolver(profileFormValidation),
    defaultValues: me as ProfileFormData,
  });

  return (
    <FormProvider {...form}>
      <Stack gap={5} mb={{ xs: 4, lg: form.formState.isDirty ? 6 : 0 }}>
        <SectionHeadline title={t('title')} description={t('description')} />
        <ProfileForm />
        <SettingsProfileSectionAdvanced />
      </Stack>
    </FormProvider>
  );
};
