import NiceModal from '@ebay/nice-modal-react';
import { useContext, useEffect, useRef } from 'react';

type Shortcut = {
  key: string;
  action: () => void;
};

export const useShortcuts = (shortcuts: Shortcut[]) => {
  const modals = useContext(NiceModal.NiceModalContext);

  const shortcutsRef = useRef(shortcuts);
  useEffect(() => {
    shortcutsRef.current = shortcuts;
  }, [shortcuts]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      const modifierKeyPressed = e.metaKey || e.ctrlKey;
      if (modifierKeyPressed) return;

      const modalOpen = Object.keys(modals).some((key) => modals[key].visible);
      if (modalOpen) return;

      const activeElement = document.activeElement;
      if (
        activeElement?.tagName === 'INPUT' ||
        activeElement?.tagName === 'TEXTAREA'
      )
        return;

      (document.activeElement as any)?.blur();

      const keyPressed = e.key;

      const shortcut = shortcutsRef.current.find(
        (shortcut) => shortcut.key === keyPressed
      );

      shortcut?.action();
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [shortcutsRef, modals]);
};
