import { Stack } from '@mui/material';
import { FC } from 'react';

import { useBookings } from '../../../../../../Hooks/useBookings';
import { PageBreadcrumbItem } from '../page-breadcrumb-item';
import { PageBreadcrumbBookingNavigation } from './PageBreadcrumbBookingNavigation';

type Props = {
  bookingId: string | undefined;
};

export const PageBreadcrumbBreadcrumbsBooking: FC<Props> = ({ bookingId }) => {
  const {
    booking: { data: booking },
  } = useBookings(undefined, bookingId);

  return (
    <Stack direction="row" gap={2} alignItems="center">
      <PageBreadcrumbItem
        title={booking?.customer.name}
        href={`/booking/${bookingId}`}
      />
      {booking && (
        <PageBreadcrumbBookingNavigation
          eventId={booking.eventId}
          bookingId={booking.id}
        />
      )}
    </Stack>
  );
};
