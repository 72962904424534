import { lightTheme, Text } from '@holdbar-com/pixel';
import { Card, Divider, Stack, styled } from '@mui/material';
import { ReactNode } from 'react';

type InfoBoxProps = {
  title: string;
  actions?: ReactNode;
  children: ReactNode;
};

export const InfoBox = ({ title, actions, children }: InfoBoxProps) => {
  return (
    <StyledInfoBox>
      <StyledHeader>
        <StyledText variant="medium">{title}</StyledText>
        {actions}
      </StyledHeader>
      <Divider />
      <Stack
        px={4}
        py={3}
        fontSize={lightTheme.typography.small.normal.fontSize}
      >
        {children}
      </Stack>
    </StyledInfoBox>
  );
};

const StyledInfoBox = styled(Card)({
  width: '100%',
  padding: 0,
  borderRadius: 8,
});

const StyledHeader = styled(Stack)({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '16px 16px 16px 32px',
});

const StyledText = styled(Text)({
  userSelect: 'none',
});
