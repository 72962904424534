import { Button, Text } from '@holdbar-com/pixel';
import { Box, Stack } from '@mui/material';
import { FC } from 'react';

import { Loading } from '../../../Components/Loading/Loading';
import { useTranslate } from '../../../Hooks/useTranslate';

type Props = {};

export const TicketCheckingIn: FC<Props> = () => {
  const { t } = useTranslate('dialogs.booking.checkIn');

  return (
    <Stack justifyContent="space-between" flex={1} gap={3}>
      <Stack gap={2} alignItems="center" marginY={3}>
        <Box position="relative" width={60} height={60}>
          <Loading isLoading={true} />
        </Box>
        <Text fontSize="large" variant="medium">
          {t('pending')}
        </Text>
      </Stack>

      <Button size={'large'} variant={'primary'} disabled>
        {t('confirm')}
      </Button>
    </Stack>
  );
};
