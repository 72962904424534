export function encodeConnectionPath(companyId: string, receiverEmail: string) {
  return btoa(`${companyId}-${receiverEmail}`);
}

export function decodeConnectionPath(connectionPath: string) {
  const string = atob(connectionPath);
  const [companyId, ...receiverEmail] = string.split('-');
  return {
    companyId,
    receiverEmail: receiverEmail.join('-'),
  };
}
