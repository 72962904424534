import { Skeleton, Stack } from '@mui/material';

import { InfoBox } from '../../../../../../../Components/InfoBox/InfoBox';
import { useTranslate } from '../../../../../../../Hooks/useTranslate';
import { useBookingDetailsContext } from '../../../../domain/use_booking_details_context';
import { customerRows } from '../../domain/use_booking_details_summary';
import useResponsive from '../../../../../../../Hooks/layout/useResponsive';
import { BookingDetailsSummaryCustomerMobile } from './BookingDetailsSummaryCustomerMobile';

export const BookingDetailsSummaryCustomer = () => {
  const { t } = useTranslate('dialogs.booking');
  const { isSm } = useResponsive();

  const { booking, receipt } = useBookingDetailsContext();

  if (isSm) {
    return <BookingDetailsSummaryCustomerMobile />;
  }

  return (
    <InfoBox title={t('sections.customer')}>
      <Stack gap={2}>
        {booking
          ? customerRows.map((row) => row.value(booking, t, receipt))
          : customerRows.map((row) => <Skeleton key={row.key} width="60%" />)}
      </Stack>
    </InfoBox>
  );
};
