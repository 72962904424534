import { Experience } from '@holdbar-com/utils-types';
import randomBytes from 'randombytes';
import { FieldValues } from 'react-hook-form';
import {
  ActionFunctionArgs,
  defer,
  LoaderFunction,
  redirect,
  useLoaderData,
  useNavigate,
} from 'react-router';
import { useSubmit } from 'react-router-dom';
import { toast } from 'react-toastify';

import { createConnection, getExperiences } from '../../../Api';
import useResponsive from '../../../Hooks/layout/useResponsive';
import { useTranslate } from '../../../Hooks/useTranslate';
import { t } from '../../../i18n/config';
import { DialogWrapper } from '../components/dialog-wrapper';
import {
  AddConnectionForm,
  AddConnectionFormInputs,
} from './add-connection-form';

type LoaderData = {
  experiences: Promise<Experience[]>;
};

export const loader: LoaderFunction<LoaderData> = async () => {
  // Intentionally not awaited. Returned as a promise to defer loading to after render.
  const experiences = getExperiences();
  return defer({ experiences });
};

export async function action({ request }: ActionFunctionArgs) {
  const loadingToastId = randomBytes(16).toString('hex');
  toast.loading(t('connect.dialog.addConnection.toast.loading'), {
    toastId: loadingToastId,
  });

  try {
    const { email, share, experiences } =
      (await request.json()) as AddConnectionFormInputs;

    await createConnection({
      email: email.trim(),
      sharedExperiences: share === 'selection' ? experiences : share,
    });

    toast.dismiss(loadingToastId);
    toast.success(t('connect.dialog.addConnection.toast.success'), {
      autoClose: 5000,
    });

    return redirect('../');
  } catch (error) {
    console.error(error);
    toast.dismiss(loadingToastId);
    toast.error(t('connect.dialog.addConnection.toast.error'), { delay: 500 });
    return {
      success: false,
    };
  }
}

export const AddConnectionDialog = () => {
  const { t } = useTranslate('connect.dialog.addConnection');
  const { isSm } = useResponsive();
  const navigate = useNavigate();
  const submit = useSubmit();
  const { experiences } = useLoaderData() as LoaderData;

  const handleClose = () => {
    navigate('../');
  };

  const onSubmit = (data: FieldValues) => {
    submit(data, {
      method: 'post',
      action: '',
      encType: 'application/json',
    });
  };

  return (
    <DialogWrapper
      fullWidth
      maxWidth="sm"
      fullScreen={isSm}
      open={true}
      onClose={handleClose}
      title={t('title')}
    >
      <AddConnectionForm onSubmit={onSubmit} experiences={experiences} />
    </DialogWrapper>
  );
};
