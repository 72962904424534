import { ExperienceTag } from '@holdbar-com/utils-types';
import { useState } from 'react';
import { toast } from 'react-toastify';

import { useExperienceTags } from '../../../../Hooks/useExperienceTags';
import { useTranslate } from '../../../../Hooks/useTranslate';
import { ExperienceTagDialogContent } from './dialog-content';
import { ExperienceTagForm } from './form';
import { ExperienceTagViewProps } from './shared';

export const UpdateExperienceTagView = ({
  selectedId,
  setSelectedId,
}: ExperienceTagViewProps) => {
  const { t } = useTranslate('experience.tags.dialog.actions');
  const [isUpdating, setIsUpdating] = useState(false);

  const {
    tag: { data: tag },
    updateTag,
  } = useExperienceTags(selectedId);

  if (!tag) return null;

  const handleSubmit = (data: ExperienceTag['name']) => {
    setIsUpdating(true);
    const payload = { ...tag, name: data };

    updateTag
      .mutateAsync(payload)
      .then(() => {
        setSelectedId(undefined);
      })
      .catch((error) => {
        toast.error(t('generic', 'utils.errors'));
        console.error(error);
      })
      .finally(() => setIsUpdating(false));
  };

  return (
    <ExperienceTagDialogContent>
      <ExperienceTagForm
        onSubmit={handleSubmit}
        defaultValues={tag.name}
        actions={[
          {
            id: 'cancel',
            label: t('cancel'),
            variant: 'secondary',
            type: 'button',
            onClick: () => setSelectedId(undefined),
            disabled: isUpdating,
          },
          {
            id: 'update',
            label: t('updateTag'),
            variant: 'primary',
            type: 'submit',
            disabled: isUpdating,
          },
        ]}
      />
    </ExperienceTagDialogContent>
  );
};
