import { TextField } from '@mui/material';
import { useEffect, useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { PreviewFormData } from './use-preview-form-validation';

export const PreviewFormWebsiteField = () => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);

  const {
    control,
    formState: { errors },
  } = useFormContext<Pick<PreviewFormData, 'website'>>();

  useEffect(() => {
    inputRef?.current?.focus();
  }, []);

  return (
    <Controller
      name="website"
      control={control}
      render={({ field }) => (
        <TextField
          {...field}
          label={t('auth.preview.previewForm.website')}
          inputRef={inputRef}
          helperText={errors.website?.message}
          error={!!errors.website}
        />
      )}
    />
  );
};
