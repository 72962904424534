import { Box, Stack, TextFieldProps } from '@mui/material';
import * as Sentry from "@sentry/react";
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, UseControllerProps, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Outlet, useMatch, useNavigate } from 'react-router-dom';

import * as api from '../../Api';
import { Logo } from '../../Components/Logo/Logo';
import { useProfile } from '../../Hooks/useProfile';
import type { TImage } from '../../Utils/types';
import { createId } from '../SyiPage/SyiPage';
import { ContentBlock } from './ContentBlock';
import { TextBlock } from './TextBlock';

export type TInput = {
  key: string;
  name: string;
  props?: TextFieldProps;
  helperText?: string;
  hasHelperText: boolean;
  rules?: UseControllerProps['rules'];
};

const _steps = [
  {
    key: 'start',
    title: 'Velkommen til Holdbar',
    description:
      'Vi har lavet en lille opsætningsguide så du let og hurtigt kan komme i gang med Holdbar.',
  },
  {
    key: 'profile',
    title: 'Min profil',
    description:
      'På Holdbar får du og dit team alle en personlig profil, med navn, profilbillede, hvilke sprog du/I taler og evt. en lille beskrivelse af hvem du/I er.',
  },
  {
    key: 'company',
    title: 'Min virksomhed',
    description:
      'Her opretter du din virksomhed, med din virksomheds logo samt virksomhedsoplysninger så Holdbar kan udbetale penge til dig og din virksomhed.',
  },
  {
    key: 'locale',
  },
  {
    key: 'team',
    title: 'Mit team',
    description:
      'Her inviterer du dit team til Holdbar. De vil modtage en invitation på deres mail hvorfra de vil blive guidet ind på Holdbar.\n\nSkriv email på den person du vil invitere og definer hvilken rolle personen skal have.\n\nDu kan invitere lige så mange du ønsker. Du kan også tilføje flere brugere senere under indstillinger.',
  },
  {
    key: 'success',
    title: 'Færdig',
    description:
      'Det var det! Din profil, din virksomhed og dit team er nu klar til at bruge Holdbar.',
  },
];

export const mapUsersToRoles = (users: any[]) => {
  return users.reduce((acc, { role, ...props }) => {
    const type = `${role}s`;
    return {
      ...acc,
      [type]: [...(acc?.[type] ?? []), { ...props }],
    };
  }, {});
};

export const normalizeWebsite = (website: string) => {
  return website.startsWith('http') ? website : `https://${website}`;
};

export const OnBoardingPage = () => {
  const { t } = useTranslation();

  const uploadFuncRef = useRef<
    { exec: Function; cleanUp: Function; modelType: 'company' | 'me' }[] | null
  >(null);
  const contentRef = useRef<null | HTMLElement>();
  const [wasInvited, setWasInvited] = useState<boolean | null>(null);

  const {
    params: { step },
  } = useMatch('welcome/:step') ?? { params: {} };
  const navigate = useNavigate();

  const { updateCompany, firstLogin, updateMe, userinfo } = useProfile();

  const {
    register: registerMe,
    reset: resetMe,
    getValues: getMeValues,
    setValue: setMeValue,
    formState: formStateMe,
    handleSubmit: onSubmitMe,
    control: meControl,
    ...methods
  } = useForm({ reValidateMode: 'onSubmit' });
  const {
    register: registerCompany,
    getValues: getCompanyValues,
    setValue: setCompanyValue,
    formState: formStateCompany,
    handleSubmit: onSubmitCompany,
    control: companyControl,
  } = useForm();

  const [currentStep, setCurrentStep] = useState(0);

  useEffect(() => {
    if (wasInvited === null && userinfo.data) {
      setWasInvited(Boolean(userinfo.data?.org));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userinfo.data]);

  const steps = useMemo(() => {
    return _steps.filter((el) =>
      wasInvited ? !['company', 'team', 'locale'].includes(el.key) : true
    );
  }, [wasInvited]);

  const progress = useMemo(() => {
    return Math.round(((currentStep + 1) / steps.length) * 100);
  }, [steps, currentStep]);

  useEffect(() => {
    userinfo.refetch();
  }, []);

  useEffect(() => {
    if (step) {
      const foundIndex = steps.findIndex((el) => el.key === step);
      setCurrentStep(foundIndex);
    }
  }, [step, steps]);

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current?.scrollTo(0, 0);
    }
  }, [currentStep]);

  useEffect(() => {
    if (userinfo.data?.email) {
      setMeValue('email', userinfo.data?.email, {
        shouldValidate: true,
        shouldDirty: false,
      });
      setMeValue('id', userinfo.data?.sub, {
        shouldValidate: true,
        shouldDirty: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userinfo.data]);

  const handleNext = async () => {
    if (currentStep + 1 < steps.length) {
      setCurrentStep((p) => p + 1);
      return navigate(`${steps[currentStep + 1].key}`, { replace: true });
    }
  };

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep((p) => p - 1);
      navigate(`${steps[currentStep - 1].key}`, { replace: true });
    }
  };

  const handleSubmitCompany = async (users: any[]) => {
    const companyFormData = getCompanyValues();
    delete companyFormData['admins'];
    delete companyFormData['guides'];

    const { website, domain, ...companyData } = companyFormData;

    const payload = {
      ...companyData,
      website: normalizeWebsite(website),
      domains: domain ? [domain] : null,
      ...mapUsersToRoles(users),
      users: [userinfo.data?.sub],
    };
    await updateCompany.mutateAsync(payload);

    try {
      await api.createDomain({ type: 'subdomain', domain });
    } catch (err) {
      Sentry.captureException(err);
    }
  };

  const handleSubmit = async (users: any[]) => {
    const mePayload = getMeValues();
    const proms: Promise<unknown>[] = [
      updateMe.mutateAsync(mePayload),
      Promise.all([
        ...(uploadFuncRef.current?.map((el) => {
          return el.exec();
        }) ?? []),
      ]),
    ];
    if (wasInvited) {
      proms.push(firstLogin.mutateAsync(userinfo.data?.sub ?? ''));
    } else {
      proms.push(handleSubmitCompany(users));
    }
    return Promise.all(proms).then(() => handleNext());
  };

  const handleUploadPrepared =
    (modelType: 'company' | 'me') =>
    (
      { key, url, localUrl }: { [k: string]: string },
      fileType: 'logo' | 'profile',
      uploadFunc: () => Promise<void>,
      cleanUp: () => void
    ) => {
      const setter = modelType === 'company' ? setCompanyValue : setMeValue;
      const id = createId();
      setter(
        'pictures',
        { [fileType]: { url, localUrl, key, id } },
        {
          shouldDirty: true,
        }
      );
      uploadFuncRef.current = [
        ...((uploadFuncRef.current as any) ?? []),
        {
          exec: uploadFunc,
          id,
          cleanUp: () => {
            setter('pictures', { [fileType]: null });
            cleanUp();
          },
        },
      ];
    };

  const handleDeleteImage =
    (prop: 'logo' | 'profile', modelType: 'company' | 'me') =>
    async ({ id }: TImage) => {
      uploadFuncRef.current =
        uploadFuncRef.current?.filter((el: any) => {
          if (el.id === id) {
            el.cleanUp();
          }
        }) ?? [];
    };

  return (
    <Box
      sx={{
        height: '100vh',
        width: '100vw',
        background:
          'linear-gradient(292.46deg, #391874 4.58%, #F42C05 172.49%)',
      }}
      p={{ xs: 2, md: 10 }}
      display={'flex'}
    >
      <Logo
        variant={'white'}
        height={48}
        position={'absolute'}
        left={80}
        top={80}
      />

      <Stack
        direction={'row'}
        width={'100%'}
        justifyContent={'center'}
        alignItems={'center'}
        spacing={16}
      >
        <TextBlock
          title={t(`onboarding.${steps[currentStep]?.key}.leftTitle`)}
          description={t(
            `onboarding.${steps[currentStep]?.key}.leftDescription`
          )}
        />
        <ContentBlock ref={contentRef} progress={progress}>
          <FormProvider
            {...{
              ...methods,
              control: meControl,
              handleSubmit: onSubmitMe,
              getValues: getMeValues,
              setValue: setMeValue,
              reset: resetMe,
              register: registerMe,
              formState: formStateMe,
            }}
          >
            <Outlet
              context={{
                currentStep,
                companyControl,
                formStateMe,
                formStateCompany,
                registerMe,
                registerCompany,
                getMeValues,
                getCompanyValues,
                onSubmitMe,
                onSubmitCompany,
                wasInvited,
                handleSubmit,
                handleBack,
                handleNext,
                handleDeleteImage,
                handleUploadPrepared,
              }}
            />
          </FormProvider>
        </ContentBlock>
      </Stack>
    </Box>
  );
};
