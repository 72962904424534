import { differenceInDays, startOfDay } from 'date-fns';

import { ampli } from '../../Ampli';
import { CalendarView } from '../../features/calendar/context';

export const trackCalendarDateNavigation = (
  direction: 'prev' | 'next',
  view: CalendarView,
  newDate: Date
) => {
  const eventProperties = {
    view,
    days_from_current_date: differenceInDays(
      startOfDay(newDate),
      startOfDay(new Date())
    ),
  };

  if (direction === 'prev') {
    return ampli.goBackCalendarViewChanged(eventProperties);
  }

  if (direction === 'next') {
    return ampli.goForwardCalendarViewChanged(eventProperties);
  }
};
