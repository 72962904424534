import { lightTheme, Text } from '@holdbar-com/pixel';
import { AutoAwesomeOutlined } from '@mui/icons-material';
import { List, ListItem, Stack, TextField } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { useTranslate } from '../../../Hooks/useTranslate';
import { trackQuickSetupWebsiteFieldOpened } from '../../../tracking/quick-setup/step-events';
import { OnboardingFormInputs } from '../onboarding-flow-form';
import { ErrorText } from './shared';
import { OnboardingStepLayout } from './step-layout';

const urlRegex =
  /[-a-zA-Z0-9@:%.+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%+.~#?&//=]*)/gi;

export const StartStep = () => {
  const { t } = useTranslate('onboarding.dialog.step.start.input');

  const {
    register,
    formState: {
      errors: { domain: domainError },
    },
  } = useFormContext<OnboardingFormInputs>();

  return (
    <OnboardingStepLayout
      titleIcon={<AutoAwesomeOutlined />}
      customDescription={<CustomStartStepDescription />}
      boxContent={
        <Stack
          gap={1}
          paddingY={6}
          paddingX={7}
          sx={{
            background: { md: lightTheme.palette.neutral.n50 },
            paddingY: { md: 6 },
            paddingX: { md: 7 },
          }}
        >
          <Text variant="medium" fontSize="small">
            {t('pretitle')}
          </Text>
          <TextField
            {...register('domain', {
              required: {
                value: true,
                message: t('requiredMessage'),
              },
              pattern: {
                value: urlRegex,
                message: t('invalidPatternMessage'),
              },
            })}
            label={t('label')}
            placeholder={t('placeholder')}
            InputLabelProps={{ shrink: true }}
            error={!!domainError}
            onFocus={trackQuickSetupWebsiteFieldOpened}
            required
            sx={{ marginTop: 2 }}
          />
          {domainError && <ErrorText>{domainError.message}</ErrorText>}
        </Stack>
      }
    />
  );
};

const CustomStartStepDescription = () => {
  const { t } = useTranslate(`onboarding.dialog.step.start.description`);

  return (
    <Stack gap={2}>
      <Text>{t('text')}</Text>
      <Stack gap={1}>
        <Text>{t('list.title')}</Text>
        <List sx={{ listStyleType: 'disc', pl: 3, py: 0 }}>
          {Array(3)
            .fill(undefined)
            .map((_, i) => (
              <ListItem key={i} sx={{ display: 'list-item', pl: 0, pt: 0 }}>
                <Text>{t(`list.${i}.text`)}</Text>{' '}
              </ListItem>
            ))}
        </List>
      </Stack>
      <Text>{t('list.description')}</Text>
    </Stack>
  );
};
