import { Text } from '@holdbar-com/pixel';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Skeleton,
  styled,
} from '@mui/material';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetLocationsByExperienceId } from '../../Hooks/data/useLocations';
import { ILocationView } from '../../types/api/location';

type LocationSelectProps = {
  experienceId: string;
  locationId?: string;
  isRequired?: boolean;
  isDisabled?: boolean;
  errorMessage?: string;
  name?: string;
  onChange?: (location: ILocationView) => void;
  onOpen?: () => void;
};

export const LocationSelect = ({
  experienceId,
  locationId,
  isRequired,
  isDisabled,
  errorMessage,
  name,
  onChange,
  onOpen,
}: LocationSelectProps) => {
  const { t } = useTranslation();
  const {
    locations: { data: locations, isLoading, isError },
  } = useGetLocationsByExperienceId(experienceId);

  const locationSelected = locations?.find(
    (location) => location.locationId === locationId
  );

  const locationsSorted = useMemo(() => {
    if (!locations) return [];
    return [...locations].sort((a, b) =>
      a.locationName.localeCompare(b.locationName)
    );
  }, [locations]);

  const label = useMemo(() => {
    return locations?.length
      ? t('components.locationSelect.label.location')
      : t('components.locationSelect.label.noLocationsFound');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [experienceId, locations?.length]);

  useEffect(() => {
    if (locationSelected) {
      onChange?.(locationSelected);
    } else if (locations?.length === 1 && onChange) {
      // Automatically select the location if there's only one in the list
      const singleLocation = locations[0];
      onChange(singleLocation);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locations]);

  const handleChange = useCallback(
    (event: SelectChangeEvent<string>) => {
      const location = locations?.find(
        (loc) => loc.locationId === event.target.value
      );

      if (location) {
        onChange?.(location);
      }
    },
    [locations, onChange]
  );

  if (isLoading && !locations) {
    return <Skeleton height={56} width="100%" variant="rounded" />;
  }

  if (isError) {
    return null;
  }

  return (
    <FormControl
      fullWidth
      error={!!errorMessage}
      disabled={isDisabled ?? !locations?.length}
    >
      <InputLabel id="location-select-label" required={isRequired}>
        {label}
      </InputLabel>
      <Select
        name={name}
        label={label}
        value={locationSelected?.locationId ?? ''}
        onChange={handleChange}
        onOpen={onOpen}
        renderValue={() => (
          <StyledText fontSize="small">
            {locationSelected?.locationName ?? ''}
          </StyledText>
        )}
        error={!!errorMessage}
        required={isRequired}
        disabled={isDisabled ?? !locations?.length}
        aria-label={label}
        aria-required={isRequired}
        aria-disabled={isDisabled}
        labelId="location-select-label"
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 600,
            },
          },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
      >
        {locationsSorted.map((location) => (
          <MenuItem key={location.locationId} value={location.locationId}>
            <StyledText fontSize="small">{location.locationName}</StyledText>
          </MenuItem>
        ))}
      </Select>
      {errorMessage && <FormHelperText error>{errorMessage}</FormHelperText>}
    </FormControl>
  );
};

const StyledText = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: break-spaces;
`;
