import { styled, Tab, Tabs, TabsProps } from '@mui/material';

const StyledTabs = styled(Tabs)`
  min-height: 24px;
  border: none;
  font-size: 24px;
  & .MuiTab-root {
    min-height: 12px;
    line-height: 1;
    font-size: 0.75em;
    min-width: 56px;
    border-radius: 100px;
    padding: 4px 4px;
    &.Mui-selected {
      color: ${({ theme }) => theme.palette.primary.main};
      z-index: 2;
    }
  }
  & .MuiTabs-indicator {
    border: 0px solid ${({ theme }) => theme.palette.primary.main};
    background-color: rgba(228, 228, 228, 0.5);
    z-index: 0;
    border-radius: 100px;
  }
`;

export const LanguageTabs = ({ children, ...props }: TabsProps) => {
  return <StyledTabs {...props}>{children}</StyledTabs>;
};

export const LanguageTabItem = styled(Tab)(() => ({}));
