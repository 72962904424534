import { Experience } from '@holdbar-com/utils-types';

import { ampli } from '../../../../Ampli';
import { TEvent } from '../../../../Utils/eventHelpers';
import { eventProperties } from '../card/eventProperties';

export const trackExperienceLocationFilterOpened = (
  events: TEvent[],
  selected_location_id?: string,
  experience?: Experience
) => {
  if (!experience) return;
  const properties = eventProperties(experience, events);

  ampli.experienceLocationFilterOpened({
    ...properties,
    selected_location_id,
  });
};
