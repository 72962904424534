import { useMemo } from 'react';
import { TFunction } from 'react-i18next';
import { InferType, object } from 'yup';

interface Values {
  [key: string]: string;
}

export const useCancelEventFormValidation = (
  t: TFunction,
  languages: string[]
) =>
  useMemo(() => {
    return object().shape({
      cancelNote: localizedSchema(languages),
    });
  }, [t, languages]);

export type CancelEventFormData = InferType<
  ReturnType<typeof useCancelEventFormValidation>
>;

const localizedSchema = (languages?: string[]) => {
  return object().test({
    name: 'localized',
    test: (value, ctx: any) => {
      if (!value || !languages) {
        return true;
      }

      const values: Values = Object.keys(value)
        .filter((key) => languages.includes(key))
        .reduce((cur, key) => {
          return { ...cur, [key]: value[key] };
        }, {});

      const hasValue = Object.values(values).some((val) => val !== '');

      if (!hasValue) {
        return true;
      }

      for (const language of languages) {
        if (values[language] === '' || !values[language]) {
          return ctx.createError({ message: `requiredLanguage` });
        }
      }
      return true;
    },
  });
};
