import { Text } from '@holdbar-com/pixel';
import { MenuItem, TextField } from '@mui/material';
import countries from 'iso-3166-1';
import { useMemo } from 'react';
import { ControllerProps, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ILocationView } from '../../../../types/api/location';

type LocationFormCountrySelectProps = {
  control: ControllerProps<ILocationView>['control'];
};

export const LocationFormCountrySelect = ({
  control,
}: LocationFormCountrySelectProps) => {
  const { i18n, t } = useTranslation('');

  const { field } = useController({
    name: 'address.country',
    control,
  });

  const countryNames = useMemo(
    () =>
      new Intl.DisplayNames(i18n.language, {
        type: 'region',
      }),
    [i18n.language]
  );

  const countryOptions = useMemo(
    () => getCountryOptions(i18n.language, countryNames),
    [i18n.language, countryNames]
  );

  return (
    <TextField
      select
      label={t('dialogs.location.form.country')}
      helperText={control?._formState.errors.address?.country?.message}
      error={!!control?._formState.errors.address?.country}
      {...field}
    >
      {countryOptions.map((country) => (
        <MenuItem key={country.code} value={country.code}>
          <Text fontSize="small">{country.name}</Text>
        </MenuItem>
      ))}
    </TextField>
  );
};

function getCountryOptions(
  locale: string,
  names: Intl.DisplayNames
): Array<CountryOption> {
  // Returns a sorted list of the country names in the current language
  return countries
    .all()
    .map(
      (country: { alpha2: string }): CountryOption => ({
        code: country.alpha2,
        name: names.of(country.alpha2) ?? '',
      })
    )
    .sort((a: CountryOption, b: CountryOption) =>
      a.name.localeCompare(b.name, locale)
    );
}

type CountryOption = {
  code: string;
  name: string;
};
