import { lightTheme, Text } from '@holdbar-com/pixel';
import { Stack, styled, TooltipProps } from '@mui/material';

import useResponsive from '../../Hooks/layout/useResponsive';
import { Tooltip } from './tooltip';

type TCommandTooltipProps = {
  title: string;
  shortcut: string;
  hideTitle?: boolean;
  hideCommandKey?: boolean;
  children: React.ReactElement;
};

export const ShortcutTooltip = ({
  title,
  shortcut,
  hideTitle = false,
  hideCommandKey = false,
  children,
  ...props
}: TCommandTooltipProps & TooltipProps) => {
  const isMac = navigator.userAgent.toUpperCase().indexOf('MACINTOSH') >= 0;
  const { isMd } = useResponsive();
  const shortcutKey = hideCommandKey ? '' : isMac ? '⌘' : '^';

  if (isMd) return children;

  return (
    <Tooltip
      title={
        <Stack direction="row" gap={0.6}>
          {!hideTitle && (
            <Text
              fontSize="tiny"
              variant="medium"
              color={'white'}
              style={{
                display: 'flex',
                alignItems: 'center',
                lineHeight: 'unset',
              }}
            >
              {title}
            </Text>
          )}
          <StyledShortcut fontSize="tiny" variant="medium" color={'white'}>
            {shortcutKey}
            {shortcut.toUpperCase()}
          </StyledShortcut>
        </Stack>
      }
      {...props}
    >
      {children}
    </Tooltip>
  );
};

const StyledShortcut = styled(Text)`
  line-height: 1.1;
  background-color: ${lightTheme.palette.neutral.n400};
  padding: 1px 3px;
  border-radius: 4px;
`;
