import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Location, useLocation, useNavigate } from 'react-router-dom';

type HistoryContextType = {
  history: Location[];
  goBack: (fallback: string) => void;
};

export const HistoryContext = createContext<HistoryContextType | null>(null);

export const useHistory = () => {
  const context = useContext(HistoryContext);

  if (!context) {
    throw new Error('useHistory must be used within a HistoryContextProvider');
  }

  return context;
};

export const HistoryContextProvider = ({ children }: PropsWithChildren) => {
  const [history, setHistory] = useState<Location[]>([]);
  const [isMounted, setIsMounted] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const goBack = (fallback: string) => {
    if (history.length > 2 && location !== history[history.length - 2]) {
      navigate(-1);
      setHistory((currentHistory) => currentHistory.slice(0, -1));
    } else {
      navigate(fallback, { replace: true });
    }
  };

  useEffect(() => {
    setIsMounted(true);
    if (isMounted && !history.find((state) => state.key === location.key)) {
      setHistory((currentHistory) => [...currentHistory, location]);
    }
    return () => setIsMounted(false);
  }, [history, location, isMounted]);

  const onPop = useCallback(
    (e: PopStateEvent) => {
      const index = history.findIndex((state) => state.key === e.state.key);

      if (index >= 0) {
        setHistory((currentHistory) => currentHistory.slice(0, index));
      }
    },
    [history]
  );

  useEffect(() => {
    window.addEventListener('popstate', onPop);
    return () => window.removeEventListener('popstate', onPop);
  }, [onPop]);

  return (
    <HistoryContext.Provider value={{ history, goBack }}>
      {children}
    </HistoryContext.Provider>
  );
};
