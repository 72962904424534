import axios from './index';

export type Metrics = {
  [date: string]: { key: string; value: number }[];
};

export type MetricsV2 = {
  metrics: Metrics;
  metadata: {
    dataUpdateTimeUtc: string;
    nextDataUpdateTimeUtc: string;
  };
};

export const getMetrics = async (
  from: string,
  to: string,
  experienceId: string | null,
  v2?: boolean
) => {
  if (typeof process.env.REACT_APP_API_TRACKING_URL === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_TRACKING_URL is not defined'
    );
  }

  const { data } = await axios.get<Metrics | MetricsV2>(
    `${process.env.REACT_APP_API_TRACKING_URL}/metrics`,
    {
      params: {
        from,
        to,
        experienceId,
      },
      headers: {
        ...(v2 ? { 'api-version': 'v2' } : {}),
      },
    }
  );

  return v2 ? (data as MetricsV2) : (data as Metrics);
};

export const getMetricsForExperience = async (
  experienceId: string,
  from: string,
  to: string
) => {
  if (typeof process.env.REACT_APP_API_TRACKING_URL === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_TRACKING_URL is not defined'
    );
  }

  const { data } = await axios.get<{
    [date: string]: { key: string; value: number }[];
  }>(
    `${process.env.REACT_APP_API_TRACKING_URL}/metrics/experience/${experienceId}`,
    {
      params: {
        from,
        to,
      },
    }
  );

  return data;
};
