import { Button, Text } from '@holdbar-com/pixel';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  BoxProps,
  CircularProgress,
  Stack,
  TextField,
} from '@mui/material';
import { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ILocationView } from '../../../../types/api/location';
import { LocationFormCountrySelect } from './LocationFormCountrySelect';
import { useGetLocationFormSchema } from './use_location_form_validation';
import { LocationFormVariant } from './use_location_form_values';

type LocationFormProps = {
  variant: LocationFormVariant;
  initialValues: ILocationView;
  isLoading: boolean;
  onSubmit: (data: ILocationView) => void;
} & BoxProps;

export const LocationForm = ({
  variant,
  initialValues,
  isLoading,
  onSubmit,
  ...otherProps
}: LocationFormProps) => {
  const { t } = useTranslation('');
  const locationNameRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    locationNameRef.current?.focus();
  }, []);

  const isCreate = LocationFormVariant.create === variant;

  const schema = useGetLocationFormSchema(t);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ILocationView>({
    resolver: yupResolver(schema) as any,
    defaultValues: initialValues,
  });

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} {...otherProps}>
      <Stack gap={4}>
        <Stack gap={2}>
          <Text fontSize="large" variant="medium">
            {isCreate
              ? t('dialogs.location.addLocation')
              : t('dialogs.location.editLocation')}
          </Text>
          <TextField
            inputRef={locationNameRef}
            label={t('dialogs.location.form.locationName')}
            helperText={errors.locationName?.message}
            error={!!errors.locationName}
            {...register('locationName')}
          />
        </Stack>
        <Stack gap={2}>
          <Text fontSize="small" variant="medium">
            {t('dialogs.location.practicalInfo')}
          </Text>
          <TextField
            label={t('dialogs.location.form.line1')}
            helperText={errors.address?.line1?.message}
            error={!!errors.address?.line1}
            {...register('address.line1')}
          />
          <TextField
            label={t('dialogs.location.form.line2')}
            helperText={errors.address?.line2?.message}
            error={!!errors.address?.line2}
            {...register('address.line2')}
          />
          <Stack direction="row" gap={2}>
            <TextField
              label={t('dialogs.location.form.city')}
              fullWidth
              helperText={errors.address?.city?.message}
              error={!!errors.address?.city}
              {...register('address.city')}
            />
            <TextField
              label={t('dialogs.location.form.zipCode')}
              fullWidth
              helperText={errors.address?.postalCode?.message}
              error={!!errors.address?.postalCode}
              {...register('address.postalCode')}
            />
          </Stack>
          <TextField
            label={t('dialogs.location.form.state')}
            helperText={errors.address?.state?.message}
            error={!!errors.address?.state}
            {...register('address.state')}
          />
          <LocationFormCountrySelect control={control} />
        </Stack>
        <Button
          type="submit"
          size="medium"
          variant="primary"
          disabled={isLoading}
        >
          {isLoading ? (
            <CircularProgress size="1em" color="inherit" />
          ) : isCreate ? (
            t('dialogs.location.form.actions.addLocation')
          ) : (
            t('dialogs.location.form.actions.editLocation')
          )}
        </Button>
      </Stack>
    </Box>
  );
};
