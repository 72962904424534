import { lightTheme } from '@holdbar-com/pixel';
import { Tooltip as MUITooltip, TooltipProps } from '@mui/material';
import { PropsWithChildren } from 'react';

export const Tooltip = ({
  children,
  ...props
}: PropsWithChildren & TooltipProps) => {
  return (
    <MUITooltip
      placement="top"
      arrow
      componentsProps={{
        tooltip: {
          sx: {
            textAlign: 'center',
            py: 0.6,
            pl: 0.8,
            pr: 0.7,
            backgroundColor: lightTheme.palette.neutral.n500,
          },
        },
        arrow: {
          sx: {
            color: lightTheme.palette.neutral.n500,
          },
        },
      }}
      {...props}
    >
      {children}
    </MUITooltip>
  );
};
