import { FC, useEffect } from 'react';
import ReactConfetti from 'react-confetti';

const REDIRECT_DELAY_IN_MS = 3_000;

type Props = {
  storefrontUrl: string;
};

export const GeneratingPreviewSuccess: FC<Props> = ({ storefrontUrl }) => {
  useEffect(() => {
    // Redirect to the new storefront after some delay
    const timeoutId = setTimeout(() => {
      window.location.href = storefrontUrl;
    }, REDIRECT_DELAY_IN_MS);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [storefrontUrl]);

  return <ReactConfetti />;
};
