import { ArrowBackRounded, ArrowForwardRounded } from '@mui/icons-material';
import { Button, Divider, Stack } from '@mui/material';
import { useController, useFormState } from 'react-hook-form';
import { useOutletContext } from 'react-router-dom';

import { LanguageChips } from '../../Components/LanguageChips/LanguageChips';
import { useTranslate } from '../../Hooks/useTranslate';
import { currencyOptions } from '../../Utils/config';
import { SyiSection } from '../SyiPage/SyiSection';

export const OnBoardingLocaleSection = () => {
  const { t } = useTranslate('onboarding.locale');

  const { handleBack, handleNext, onSubmitCompany, companyControl } =
    useOutletContext<any>();

  const { errors } = useFormState({ control: companyControl });

  const { field: languages } = useController({
    name: 'languages',
    control: companyControl,
    rules: { required: true },
  });
  const { field: currency } = useController({
    name: 'defaultCurrency',
    control: companyControl,
    rules: { required: true },
  });

  return (
    <>
      <SyiSection
        title={t('languagesTitle')}
        error={errors['languages'] ? t('required', 'utils.errors') : ''}
      >
        <LanguageChips
          onChange={languages.onChange}
          languages={languages.value}
          multiple={true}
        />
      </SyiSection>

      <Divider sx={{ mt: 3, mb: 3 }} />

      <SyiSection
        title={t('currencyTitle')}
        error={errors['defaultCurrency'] ? t('required', 'utils.errors') : ''}
      >
        <LanguageChips
          onChange={(keys) => currency.onChange(keys[0])}
          languageOptions={currencyOptions}
          shouldTranslate={false}
          languages={[currency.value]}
          multiple={false}
        />
      </SyiSection>

      <Stack
        direction={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
        spacing={1}
        mt={4}
      >
        <Button
          onClick={handleBack}
          startIcon={<ArrowBackRounded />}
          variant={'outlined'}
          size={'large'}
          color={'secondary'}
        >
          {t('back', 'buttons')}
        </Button>
        <Button
          size={'large'}
          variant={'contained'}
          onClick={onSubmitCompany(handleNext)}
          startIcon={<ArrowForwardRounded />}
        >
          {t('next', 'buttons')}
        </Button>
      </Stack>
    </>
  );
};
