import { Stack } from '@mui/material';

import { EventUpsertTimeFormEndDateField } from './EventUpsertTimeFormEndDateField';
import { EventUpsertTimeFormEndTimeSelect } from './EventUpsertTimeFormEndTimeSelect';
import { EventUpsertTimeFormEndTimeToggle } from './EventUpsertTimeFormEndTimeToggle';
import { EventUpsertTimeFormStartDateField } from './EventUpsertTimeFormStartDateField';
import { EventUpsertTimeFormStartTimeSelect } from './EventUpsertTimeFormStartTimeSelect';

export const EventUpsertTimeFormDateSection = () => {
  return (
    <Stack gap={3}>
      <Stack direction="row" gap={2}>
        <EventUpsertTimeFormStartDateField />
        <EventUpsertTimeFormStartTimeSelect />
      </Stack>
      <Stack gap={1}>
        <Stack direction="row" gap={2}>
          <EventUpsertTimeFormEndDateField />
          <EventUpsertTimeFormEndTimeSelect />
        </Stack>
        <EventUpsertTimeFormEndTimeToggle />
      </Stack>
    </Stack>
  );
};
