import {
  Frequency,
  RRule,
  RRuleSet,
  rrulestr,
  Weekday,
  WeekdayStr,
} from 'rrule';

type RRuleOptions = {
  freq: Frequency;
  interval: number;
  dtstart: Date;
  until: Date;
  byweekday?: Weekday[];
  bymonthday?: number;
};

export const createRRuleString = ({
  freq,
  interval,
  dtstart,
  until,
  byweekday,
  bymonthday,
}: RRuleOptions): string => {
  const utcDtstart = new Date(
    Date.UTC(
      dtstart.getFullYear(),
      dtstart.getMonth(),
      dtstart.getDate(),
      dtstart.getHours(),
      dtstart.getMinutes(),
      dtstart.getSeconds()
    )
  );
  const utcUntil = new Date(
    Date.UTC(
      until.getFullYear(),
      until.getMonth(),
      until.getDate(),
      until.getHours(),
      until.getMinutes(),
      until.getSeconds()
    )
  );

  const rruleSet = new RRuleSet();

  rruleSet.rrule(
    new RRule({
      freq,
      interval,
      dtstart: utcDtstart,
      until: utcUntil,
      byweekday,
      bymonthday,
      byhour: 0,
      byminute: 0,
      bysecond: 0,
    })
  );

  return rruleSet.toString();
};

type UpdateRRuleOptions = {
  freq?: Frequency;
  interval?: number;
  dtstart?: Date;
  until?: Date;
  byweekday?: WeekdayStr[];
};

export const updateRRuleString = (
  rruleStr: string,
  newOptions: UpdateRRuleOptions
): string => {
  const rrule = rrulestr(rruleStr);
  const options = rrule.origOptions;

  if (newOptions.freq !== undefined) options.freq = newOptions.freq;
  if (newOptions.interval !== undefined) options.interval = newOptions.interval;

  if (newOptions.dtstart !== undefined) {
    options.dtstart = new Date(
      Date.UTC(
        newOptions.dtstart.getFullYear(),
        newOptions.dtstart.getMonth(),
        newOptions.dtstart.getDate(),
        newOptions.dtstart.getHours(),
        newOptions.dtstart.getMinutes(),
        newOptions.dtstart.getSeconds()
      )
    );
  }

  if (newOptions.until !== undefined) {
    options.until = new Date(
      Date.UTC(
        newOptions.until.getFullYear(),
        newOptions.until.getMonth(),
        newOptions.until.getDate(),
        newOptions.until.getHours(),
        newOptions.until.getMinutes(),
        newOptions.until.getSeconds()
      )
    );
  }

  if (newOptions.byweekday !== undefined)
    options.byweekday = newOptions.byweekday.map((day) => RRule[day]);

  return new RRule(options).toString();
};

export const getIntervalFromRRuleString = (rruleString: string): number => {
  const intervalRegex = /INTERVAL=(\d+)/;
  const match = rruleString.match(intervalRegex);

  if (match && match[1]) {
    return parseInt(match[1], 10);
  }

  return -1;
};

export const getFrequencyFromRRuleString = (
  rruleString: string
): Frequency | undefined => {
  const freqRegex = /FREQ=([A-Z]+)/;
  const match = rruleString.match(freqRegex);

  if (match && match[1]) {
    switch (match[1]) {
      case 'DAILY':
        return RRule.DAILY;
      case 'WEEKLY':
        return RRule.WEEKLY;
      case 'MONTHLY':
        return RRule.MONTHLY;
      default:
        throw new Error('Invalid frequency in RRule string');
    }
  }

  return undefined;
};

export const getByweekdayFromRRuleString = (
  rruleString: string
): Weekday[] | undefined => {
  const byDayRegex = /BYDAY=([A-Z,]+)/;
  const match = rruleString.match(byDayRegex);

  if (!match || !match[1]) {
    return undefined;
  }

  const weekdayStrs: string[] = match[1].split(',');
  const weekdays: Weekday[] = weekdayStrs.map((day) => {
    switch (day) {
      case 'MO':
        return RRule.MO;
      case 'TU':
        return RRule.TU;
      case 'WE':
        return RRule.WE;
      case 'TH':
        return RRule.TH;
      case 'FR':
        return RRule.FR;
      case 'SA':
        return RRule.SA;
      case 'SU':
        return RRule.SU;
      default:
        throw new Error('Invalid day in RRule string');
    }
  });

  return weekdays;
};
