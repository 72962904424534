import { ReactNode, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

export const AuthPage = ({
  children,
  title,
}: {
  children: ReactNode;
  title?: string;
}) => {
  useEffect(() => {
    document.title = title ? `${title} - Holdbar` : 'Holdbar';
  }, [title]);

  const form = useForm({ reValidateMode: 'onChange' });

  return <FormProvider {...form}>{children}</FormProvider>;
};
