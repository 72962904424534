import { ExperienceTag } from '@holdbar-com/utils-types';
import { Dispatch, SetStateAction } from 'react';

import { getLocalized } from '../../../../Hooks/useBookings';
import { CreateExperienceTagView } from './create';
import { ExperienceTagsListView } from './list';
import { UpdateExperienceTagView } from './update';

export const viewComponents = {
  create: CreateExperienceTagView,
  list: ExperienceTagsListView,
  update: UpdateExperienceTagView,
} as Record<string, (arg0: ExperienceTagViewProps) => JSX.Element>;

export type DialogView = keyof typeof viewComponents;

export type ExperienceTagViewProps = {
  setView: Dispatch<SetStateAction<DialogView>>;
  selectedId: ExperienceTag['id'] | undefined;
  setSelectedId: Dispatch<SetStateAction<ExperienceTag['id'] | undefined>>;
};

export const renderTagName = (
  name: ExperienceTag['name'],
  language: string
) => {
  return getLocalized(name, language) ?? Object.values(name)[0];
};
