import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { Button, Popover, styled } from '@mui/material';
import { DateCalendar } from '@mui/x-date-pickers';
import { differenceInDays, startOfDay } from 'date-fns';
import { FC, useRef, useState } from 'react';

import { ampli } from '../../../Ampli';
import { CalendarView, useCalendar } from '../context';
import { MonthSelectorLabel } from './MonthSelectorLabel';

type Props = {
  interval: CalendarView;
};

export const MonthSelector: FC<Props> = ({ interval }) => {
  const { selectedDate, setSelectedDate, minimumDate, maximumDate } =
    useCalendar();

  const anchor = useRef<HTMLButtonElement | null>(null);

  const [pickerOpen, setPickerOpen] = useState(false);

  const openDatePicker = () => {
    setPickerOpen((prev) => {
      // Only track if it isn't already open
      if (!prev) {
        ampli.calendarViewDatePickerOpened();
      }

      return true;
    });
  };

  return (
    <>
      <Button
        endIcon={<KeyboardArrowDownRoundedIcon fontSize="medium" />}
        style={{ width: 'fit-content', padding: 0, height: 'auto' }}
        onClick={openDatePicker}
        ref={anchor}
      >
        <MonthSelectorLabel interval={interval} />
      </Button>

      <Popover
        open={pickerOpen}
        anchorEl={anchor.current}
        onClose={() => setPickerOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        marginThreshold={0}
        style={{
          width: '100%',
          maxWidth: '320px',
        }}
      >
        <StyledBox>
          <DateCalendar
            minDate={minimumDate}
            maxDate={maximumDate}
            value={selectedDate}
            onChange={(date) => {
              const newDate = date ?? selectedDate;
              setSelectedDate(newDate);

              ampli.calendarViewPickerDateSelected({
                days_from_current_date: differenceInDays(
                  startOfDay(newDate),
                  startOfDay(new Date())
                ),
              });
            }}
          />
        </StyledBox>
      </Popover>
    </>
  );
};

const StyledBox = styled('div')({
  width: '100%',

  '> div': {
    width: '100%',
  },
});
