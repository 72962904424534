import { lightTheme, Text } from '@holdbar-com/pixel';
import { MenuItem, Select, Stack } from '@mui/material';

import { useTranslate } from '../../../../Hooks/useTranslate';

export const GiftCardsSearchFormSelect = ({
  status,
  setStatus,
}: {
  status: string;
  setStatus: (status: string) => void;
}) => {
  const { t } = useTranslate('giftcards.search');
  const states = ['all', 'active', 'expired', 'spent', 'cancelled'];

  return (
    <Stack direction={'row'} gap={1} alignItems={'center'}>
      <Text fontSize={'small'}>{t('show', 'utils.generic')}:</Text>
      <Select
        size={'small'}
        variant={'standard'}
        sx={{
          fontWeight: 600,
          fontSize: lightTheme.typography.small.normal.fontSize,
          background: 'none',
        }}
        SelectDisplayProps={{ style: { paddingBottom: '0px' } }}
        disableUnderline
        value={status}
        onChange={(e) => setStatus(e.target.value)}
      >
        {states.map((state) => (
          <MenuItem key={state} value={state}>
            {t(state, 'giftcards.statusOptions')}
          </MenuItem>
        ))}
      </Select>
    </Stack>
  );
};
