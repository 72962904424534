import { Text } from '@holdbar-com/pixel';
import { CloseRounded } from '@mui/icons-material';
import { Box, Stack } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { useTranslate } from '../../../../Hooks/useTranslate';
import { ICancelEventForm, ICancellationNote } from '../../domain/types';
import { CancelEventActions } from './CancelEventActions';
import { CancelEventTranslatableGroupField } from './CancelEventTranslatableGroupField';

export const CancelEventForm = ({
  onCancelEvent,
  onClose,
  booked,
  prevCancellationNote,
  prevCancellationRefundChoice,
  languages,
  selectedLanguages,
  isBookingsLoading,
  mappedNotesForLanguages,
}: {
  onCancelEvent: (data: ICancelEventForm) => void;
  onClose: () => void;
  booked: boolean;
  prevCancellationNote?: ICancellationNote[];
  prevCancellationRefundChoice?: boolean;
  languages: string[];
  selectedLanguages: {
    key: string;
    label: string;
  }[];
  isBookingsLoading: boolean;
  mappedNotesForLanguages: (string | undefined)[];
}) => {
  const { t } = useTranslate('dialogs.cancelEvent');

  const { handleSubmit } = useFormContext<ICancelEventForm>();

  return (
    <form
      onSubmit={handleSubmit((data, e) =>
        onCancelEvent({ cancelNote: data.cancelNote, event: e })
      )}
    >
      <Stack gap={3} p={4}>
        <CloseRounded
          onClick={onClose}
          sx={{
            cursor: 'pointer',
          }}
        />
        <Stack gap={2}>
          <Text fontSize={'xlarge'} variant="medium">
            {t('title')}
          </Text>
          <Text>
            {booked ? t('description') : t('descriptionWithoutBookings')}
          </Text>
          <Box mt={languages.length > 1 ? 6 : 1}>
            <CancelEventTranslatableGroupField
              selectedLanguages={selectedLanguages}
              mappedNotesForLanguages={mappedNotesForLanguages}
              prevCancellationNote={prevCancellationNote}
              isBookingsLoading={isBookingsLoading}
            />
          </Box>
        </Stack>
        <CancelEventActions
          booked={booked}
          isBookingsLoading={isBookingsLoading}
          prevCancellationRefundChoice={prevCancellationRefundChoice}
        />
      </Stack>
    </form>
  );
};
