import { Divider, Popover, PopoverActions } from '@mui/material';
import { useCallback, useRef } from 'react';

import { PopoverEventDetails } from './event-details/PopoverEventDetails';
import { useEventPopover } from './event-popover-context';
import { PopoverEventActions } from './PopoverEventActions';
import { PopoverHeader } from './PopoverHeader';

export const CalendarEventPopover = () => {
  const { popoverPosition, handleClosePopover } = useEventPopover();
  const actionsRef = useRef<PopoverActions>(null);

  const id = 'calendar-event-popover';

  const updatePopoverPosition = useCallback(() => {
    if (actionsRef.current) {
      actionsRef.current.updatePosition();
    }
  }, [actionsRef]);

  return (
    <Popover
      id={id}
      open={!!popoverPosition}
      onClose={handleClosePopover}
      action={actionsRef}
      anchorReference="anchorPosition"
      anchorPosition={popoverPosition}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      PaperProps={{
        sx: {
          p: 0,
          borderRadius: '16px',
          border: '1px solid #E0E0E0',
          width: 850,
          maxHeight: 'calc(100vh-8rem)',
        },
      }}
    >
      <PopoverHeader />
      <Divider />
      <PopoverEventDetails updatePopoverPosition={updatePopoverPosition} />
      <Divider />
      <PopoverEventActions />
    </Popover>
  );
};
