import NiceModal from '@ebay/nice-modal-react';

import { useTranslate } from '../Hooks/useTranslate';
import { ConfirmDialog } from '../Modals/ConfirmDialog';

export const useErrorRetry = () => {
  const { t } = useTranslate('dialogs.errorRetry');

  const showErrorRetryDialog = async (
    title?: string,
    description?: string,
    confirmLabel?: string
  ) => {
    return NiceModal.show(ConfirmDialog, {
      title: title ?? t('title'),
      description: description ?? t('description'),
      confirmLabel: confirmLabel ?? t('confirmLabel'),
    }).then(() => {
      return true;
    });
  };
  return { showErrorRetryDialog };
};
