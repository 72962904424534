import { DraggableSyntheticListeners } from '@dnd-kit/core';
import {
  CloseRounded,
  DeleteOutlineOutlined,
  MoreHoriz,
} from '@mui/icons-material';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import {
  Box,
  CircularProgress,
  IconButton,
  Stack,
  SxProps,
} from '@mui/material';
import React, { useEffect, useState } from 'react';

import { AspectRatioBox } from './AspectRatioBox';
import { DraggableVideoPreview } from './DraggableVideoPreview/DraggableVideoPreview';
import { useLazyImage } from './useLazyImage';

interface Props {
  source: string;
  isMoving?: boolean;
  isLocal?: boolean;
  onDelete?: () => void;
  id?: string;
  large?: boolean;
  sx?: SxProps;
  type?: 'image' | 'video';
  mimeType?: string;
  listeners?: DraggableSyntheticListeners;
  attributes?: {
    role: string;
    tabIndex: number;
    'aria-pressed': boolean | undefined;
    'aria-roledescription': string;
    'aria-describedby': string;
  };
}

export const MediaItem = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      source: originalSource,
      onDelete,
      large = false,
      listeners,
      attributes,
      isMoving,
      isLocal,
      sx,
      type,
      mimeType,
    },
    ref
  ) => {
    useEffect(() => {
      if (isMoving && showOptions) {
        setShowOptions(false);
      }
    }, [isMoving]);

    const { source, state } = useLazyImage({ source: originalSource });

    const [showOptions, setShowOptions] = useState(false);

    return (
      <Box>
        <Box
          ref={ref}
          sx={{
            ...sx,
            padding: 0,
            position: 'relative',
            borderRadius: '12px',
            border: '1px solid #E5E5E5',
          }}
        >
          {type === 'video' ? (
            <AspectRatioBox
              aspectRatio={165 / 200}
              sx={{ width: '100%', borderRadius: '12px' }}
            >
              <DraggableVideoPreview
                styles={videoJSStyles}
                options={{
                  autoplay: true,
                  loop: true,
                  muted: true,
                  sources: [
                    {
                      src: originalSource,
                      type: isLocal ? mimeType : 'application/x-mpegURL',
                    },
                  ],
                }}
              />
            </AspectRatioBox>
          ) : (
            <AspectRatioBox
              aspectRatio={165 / 200}
              sx={{
                display: 'block',
                backgroundColor: 'common.white',
                backgroundImage: `url("${source}")`,
                backgroundSize: 'cover',
                backgroundPosition: 'center center',
                width: '100%',
                borderRadius: '12px',
              }}
            />
          )}
          {state === 'loading' && (
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <CircularProgress
                variant="indeterminate"
                color="secondary"
                size={large ? 80 : 50}
                thickness={large ? 5 : 4.6}
              />
            </Box>
          )}

          {state !== 'loading' && (
            <>
              {!!listeners && (
                <IconButton
                  sx={{
                    backgroundColor: 'action.active',
                    color: 'common.white',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate3d(-50%, -50%, 0)',
                    touchAction: 'none',
                  }}
                  {...attributes}
                  {...listeners}
                  size="large"
                  className="move"
                >
                  <OpenWithIcon />
                </IconButton>
              )}
            </>
          )}
        </Box>
        <Stack
          visibility={isMoving ? 'hidden' : 'initial'}
          spacing={1}
          direction={'row'}
          justifyContent={'center'}
          alignItems={'center'}
          mt={1}
        >
          {showOptions && (
            <>
              <IconButton onClick={onDelete}>
                <DeleteOutlineOutlined />
              </IconButton>
            </>
          )}

          <IconButton onClick={() => setShowOptions((p) => !p)}>
            {showOptions ? <CloseRounded fontSize={'small'} /> : <MoreHoriz />}
          </IconButton>
        </Stack>
      </Box>
    );
  }
);

MediaItem.displayName = 'MediaItem';

const videoJSStyles = {
  parent: {
    cursor: 'pointer',
    'background-color': '#F6F6F6',
    width: '100%',
    height: '100%',
    outline: 'none',
    'border-radius': '12px',
  },
  video: {
    'object-fit': 'cover',
    'border-radius': '12px',
  },
};
