import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

interface ResponsiveProps {
  isXs: boolean;
  isSm: boolean;
  isMd: boolean;
  isLg: boolean;
  isXl: boolean;
}

const useResponsive = (): ResponsiveProps => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));
  const isMd = useMediaQuery(theme.breakpoints.down('md'));
  const isLg = useMediaQuery(theme.breakpoints.down('lg'));
  const isXl = useMediaQuery(theme.breakpoints.down('xl'));

  return { isXs, isSm, isMd, isLg, isXl };
};

export default useResponsive;
