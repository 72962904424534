import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Dialog } from '@mui/material';

import { useGetEvent } from '../../Hooks/events/useGetEvent';
import useResponsive from '../../Hooks/layout/useResponsive';
import { TCreateBookingPayload } from '../../Hooks/useBookings';
import { IExperience } from '../../Hooks/useExperience';
import { useTranslate } from '../../Hooks/useTranslate';
import { DialogHeader } from './dialog-header';
import { CreateBookingForm } from './form/create-booking-form';

export const CreateBookingDialog = NiceModal.create<{
  eventId: string;
  experience: IExperience | undefined;
  defaultValues?: Partial<
    Pick<TCreateBookingPayload, 'customer' | 'internalNote' | 'shouldNotify'>
  >;
}>(({ eventId, experience }) => {
  const { t } = useTranslate('dialogs.createBooking');
  const { isSm } = useResponsive();
  const modal = useModal();

  const { event } = useGetEvent(eventId);

  const handleClose = async (shouldRemove = true) => {
    modal.hide();
    if (shouldRemove) {
      modal.remove();
    }
  };

  if (!event.data || !experience) return null;

  return (
    <Dialog
      sx={{ justifyContent: 'flex-end' }}
      fullWidth
      maxWidth={'md'}
      fullScreen={isSm}
      open={modal.visible}
      onClose={() => handleClose(false)}
    >
      <DialogHeader title={t('title')} handleClose={handleClose} />
      <CreateBookingForm
        event={event.data}
        experience={experience}
        handleClose={handleClose}
      />
    </Dialog>
  );
});
