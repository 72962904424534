import { Experience } from '@holdbar-com/utils-types';

import { countVariants } from '../../../../features/bookings/booking_details/ui/booking_details_summary/domain/use_booking_details_summary';
import { EventType } from '../../../../features/events/event_upsert/domain/types';
import { BookingFormData } from '../../../../features/events/event_upsert/ui/booking/ui/booking_form/use_booking_form_validation';
import { getLocalized } from '../../../../Hooks/useBookings';

export const eventProperties = (
  booking: BookingFormData,
  event: EventType,
  experiences?: Experience[]
) => {
  const { language, guests } = booking;

  const {
    id: event_id,
    visibility,
    assignedGuides,
    seatCount,
    experienceId: experience_id,
    languages: language_list,
    addresses: location,
  } = event;

  const experience_name =
    getLocalized(
      experiences?.find((experience) => experience.id === experience_id)
        ?.headline,
      'en'
    ) ?? '';

  return {
    event_capacity: seatCount?.value ?? 0,
    event_id,
    experience_id,
    experience_name,
    is_private: visibility === 'private',
    language_list,
    location: location?.value ?? '',
    number_of_guides: assignedGuides.length,
    language,
    number_of_guests: countVariants(guests),
    flow_type: event.variant,
  };
};
