import { useMediaQuery } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

import { theme } from '../../Utils/theme';
import { PreviewLayoutDesktop } from './preview-layout-desktop';
import { PreviewLayoutMobile } from './preview-layout-mobile';

type Props = PropsWithChildren;

export const PreviewLayout: FC<Props> = (props) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const Layout = isMobile ? PreviewLayoutMobile : PreviewLayoutDesktop;

  return <Layout {...props} />;
};
