import NiceModal from '@ebay/nice-modal-react';
import { Button, Text } from '@holdbar-com/pixel';
import { LaunchRounded } from '@mui/icons-material';
import { Skeleton, Stack } from '@mui/material';
import { useState } from 'react';
import { useIntercom } from 'react-use-intercom';

import { ampli, OverviewPageViewedProperties } from '../Ampli';
import { Page } from '../Components/Page/Page';
import { INTERCOM_SETUP_TOURS, useOnBoarding } from '../Hooks/useOnBoarding';
import { useProfile } from '../Hooks/useProfile';
import { useTranslate } from '../Hooks/useTranslate';
import { RequestReportDialog } from '../Modals/request-report/request-report-dialog';
import { DashboardOverviewSection } from '../Sections/DashboardSections/DashboardOverviewSection';
import { useStripeIntegration } from '../Sections/SettingsSections/Integrations/domain/integrations/use-stripe-integration';

export const DashboardPage = () => {
  const { t } = useTranslate('dashboard');
  const [overviewTrackingData, setOverviewTrackingData] =
    useState<OverviewPageViewedProperties>();

  const { startTour } = useIntercom();

  const {
    companyProfileUrl,
    onboardingCompleted,
    company: { data: company },
  } = useProfile();

  const {
    onBoarding: { data: onBoarding },
  } = useOnBoarding();

  const stripeIntegration = useStripeIntegration();

  const handleStripeClick = () => {
    ampli.overviewStripeLinkClicked();

    if (!isStepComplete('payment', onBoarding?.items)) {
      return stripeIntegration.onInstall?.(stripeIntegration.status)();
    }

    window.open('https://dashboard.stripe.com/dashboard', '_blank');
  };

  const handlePageClick = () => {
    ampli.overviewMyHoldbarPageClicked();

    if (!isStepComplete('experience', onBoarding?.items)) {
      return startTour(INTERCOM_SETUP_TOURS.experience);
    }

    window.open(companyProfileUrl(), '_blank');
  };

  const title = (company &&
    (onboardingCompleted ? t('title') : t('title', 'onboardingSetup'))) ?? (
    <Skeleton width={200} />
  );

  return (
    <Page sx={{ pr: { xs: 0, md: '16px' } }} maxWidth={1050}>
      <Stack direction={'row'} justifyContent={'space-between'} width={'100%'}>
        <Text fontSize={'h5'}>{title}</Text>

        <Stack
          sx={{ display: { xs: 'none', md: 'flex' } }}
          direction={'row'}
          gap={2}
        >
          {onboardingCompleted && (
            <Button
              variant="secondary"
              size="medium"
              onClick={() => {
                ampli.overviewRequestReportStarted(overviewTrackingData);
                NiceModal.show(RequestReportDialog);
              }}
            >
              {t('requestReport', 'buttons')}
            </Button>
          )}
          <Button
            rightIcon={<LaunchRounded fontSize="small" sx={{ ml: 1 }} />}
            variant="secondary"
            size="medium"
            onClick={handleStripeClick}
          >
            {t('buttons.goToStripe')}
          </Button>
          <Button
            rightIcon={<LaunchRounded fontSize="small" sx={{ ml: 1 }} />}
            variant="primary"
            size="medium"
            onClick={handlePageClick}
          >
            {t('buttons.goToHoldbar')}
          </Button>
        </Stack>
      </Stack>
      <DashboardOverviewSection
        setOverviewTrackingData={setOverviewTrackingData}
      />
    </Page>
  );
};

const isStepComplete = (step: string, items?: any) => {
  return items?.find((item: any) => item.key === step)?.completed;
};
