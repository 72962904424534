import axios from './index';

export type AuditLog = {
  createdAt: string;
  createdBy: string;
  message: string;
  metadata: Record<string, string>;
  type: string;
};

export const getAudits = async (
  object: string,
  objectId: string,
  page?: number,
  limit?: number
) => {
  if (typeof process.env.REACT_APP_INTERNAL_API_GATEWAY === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_INTERNAL_API_GATEWAY is not defined'
    );
  }

  const params = new URLSearchParams({
    object,
    objectId,
    ...(page && { page: page.toString() }),
    ...(limit && { limit: limit.toString() }),
  });

  const { data } = await axios.get<{ items: AuditLog[] }>(
    `${process.env.REACT_APP_INTERNAL_API_GATEWAY}/v1/audits?${params.toString()}`
  );

  return data;
};
