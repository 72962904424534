import NiceModal from '@ebay/nice-modal-react';
import { useModal } from '@ebay/nice-modal-react';
import { Text } from '@holdbar-com/pixel';
import { CloseRounded } from '@mui/icons-material';
import { Dialog, Stack, styled } from '@mui/material';
import { useEffect, useState } from 'react';

import { TabBar } from '../../../../../Components/TabBar/TabBar';
import { useTranslate } from '../../../../../Hooks/useTranslate';
import { FlowTypeEnum } from '../../domain/types';

export const FlowTypePreviewsDialog = NiceModal.create<{
  initialType: FlowTypeEnum;
}>(({ initialType }) => {
  const { t } = useTranslate('storefront.bookingflow');
  const modal = useModal();

  const [flowType, setFlowType] = useState<FlowTypeEnum>(initialType);

  useEffect(() => {
    setFlowType(initialType);
  }, [initialType]);

  const handleClose = () => {
    setFlowType(initialType);
    modal.reject();
    modal.hide();
  };

  return (
    <Dialog
      open={modal.visible}
      fullWidth
      onClose={handleClose}
      sx={{
        '.MuiPaper-root': {
          maxWidth: '1096px',
        },
      }}
    >
      <Stack p={6} gap={4} alignItems={'center'}>
        <Stack
          width={'100%'}
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Text variant={'medium'} fontSize={'xlarge'}>
            {t('flowType.dialog.title')}
          </Text>
          <CloseRounded
            onClick={handleClose}
            style={{ cursor: 'pointer' }}
            fontSize="large"
          />
        </Stack>
        <TabBar
          items={Object.values(FlowTypeEnum).map((type) => ({
            key: type,
            label: t(`flowType.${type}.title`),
          }))}
          selected={Object.values(FlowTypeEnum).indexOf(flowType)}
          onChange={(key) => setFlowType(Object.values(FlowTypeEnum)[key])}
        />
        <StyledVideo>
          <video
            autoPlay
            loop
            controls={false}
            key={flowType}
            style={{
              borderRadius: '16px',
              clipPath: 'inset(1px 1px)',
            }}
          >
            <source
              src={`https://26131226.fs1.hubspotusercontent-eu1.net/hubfs/26131226/Videos/preview-flowtype-${flowType}.mp4`}
              type="video/mp4"
            />
          </video>
        </StyledVideo>
      </Stack>
    </Dialog>
  );
});

const StyledVideo = styled(Stack)`
  border-radius: 16px;
  border: 1px solid #e0e0e0;
  width: 100%;
  height: 100%;
  max-width: 1000px;
  min-height: 646px;
`;
