import { Experience } from '@holdbar-com/utils-types';

import { ampli } from '../../Ampli';
import { TEventWithTitle } from '../../Utils/eventHelpers';
import { eventProperties, EventSourceType } from './eventProperties';

export const trackEventCancellationStarted = (
  event: TEventWithTitle,
  experience: Experience | undefined,
  source: EventSourceType
) => {
  ampli.eventCancellationStarted(eventProperties(event, experience, source));
};
