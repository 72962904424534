import { useMutation, useQueryClient } from 'react-query';

import * as api from '../../Api';
import { TEvent } from '../../Utils/eventHelpers';
import { getEventsQueryKey } from './useGetEvents';

export const useDeleteEvent = () => {
  const queryClient = useQueryClient();

  const EventsQueryKey = getEventsQueryKey();

  const deleteEvent = useMutation((id: string) => api.deleteEvent(id), {
    onMutate: async (id) => {
      await queryClient.cancelQueries(EventsQueryKey, { exact: true });

      const previousEvents = queryClient.getQueryData<TEvent[]>(EventsQueryKey);

      if (previousEvents) {
        queryClient.setQueryData<TEvent[]>(EventsQueryKey, (prev) => {
          return prev?.filter((el) => el.id !== id) ?? [];
        });
      }

      return {
        previousEvents,
        experienceId: previousEvents?.find((el) => el.id === id)?.experienceId,
      };
    },
    onError: async (err, variables, context) => {
      if (context?.previousEvents) {
        queryClient.setQueryData<TEvent[]>(
          EventsQueryKey,
          context.previousEvents
        );
      }
    },
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries(EventsQueryKey);
    },
  });

  return { deleteEvent };
};
