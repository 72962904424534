export class delay {
  time: number;
  timeout: NodeJS.Timeout | null;
  close: ((reason?: any) => void) | null;

  constructor(time: number) {
    this.time = time;
    this.timeout = null;
    this.close = null;
  }

  getPromise() {
    return new Promise((resolve, reject) => {
      this.close = reject;
      this.timeout = setTimeout(() => {
        resolve(null);
      }, this.time);
    });
  }
  cancel() {
    this.timeout && clearTimeout(this.timeout);
    this.close && this.close('unmounted');
    return { isCanceled: true };
  }
}
