import { Experience } from '@holdbar-com/utils-types';

import { ampli } from '../../Ampli';
import { TEventWithTitle } from '../../Utils/eventHelpers';
import { eventProperties } from './eventProperties';

export const trackEventDetailsPopupViewed = (
  event: TEventWithTitle,
  experience: Experience | undefined
) => {
  ampli.eventDetailsPopupViewed(eventProperties(event, experience, 'Calendar'));
};
