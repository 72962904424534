import { Stack } from '@mui/material';

import { IntegrationLinksDropdownExperience } from './IntegrationLinksDropdownExperience';
import { IntegrationLinksDropdownLanguage } from './IntegrationLinksDropdownLanguage';

interface IntegrationLinksDropdownsProps {
  languages?: string[];
}

export const IntegrationLinksDropdowns = ({
  languages,
}: IntegrationLinksDropdownsProps) => {
  return (
    <Stack gap={2} direction={'row'}>
      <IntegrationLinksDropdownExperience />
      <IntegrationLinksDropdownLanguage languages={languages} />
    </Stack>
  );
};
