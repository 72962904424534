import NiceModal from '@ebay/nice-modal-react';
import { lightTheme, Text } from '@holdbar-com/pixel';
import { renderDateTime } from '@holdbar-com/utils-date';
import { capitalize, styled } from '@mui/material';

import { formatMoney } from '../../../../../../../../Components/VariantSelect/VariantSelect';
import { GiftCardsListDialog } from '../../../../../../../giftcards/ui/giftcards_list/GiftCardsListDialog';
import { TPaymentRow } from '../../../../../domain/types';
import { BookingDetailsSummaryPaymentCreateLink } from '../ui/BookingDetailsSummaryPaymentCreateLink';
import { BookingDetailsSummaryPaymentOpenLink } from '../ui/BookingDetailsSummaryPaymentOpenLink';

export const paymentRows: TPaymentRow[] = [
  {
    key: 'paymentState',
    value: (_, t, receipt) => {
      if (!receipt?.status) return t!('unpaid', 'utils.statusOptions');

      // Handle "free" bookings
      if (receipt.status === 'new') return t!('paid', 'utils.statusOptions');

      return t!(receipt?.status, 'utils.statusOptions');
    },
  },
  {
    key: 'paymentLink',
    value: (el, _t, _receipt, event) => {
      if (!event) {
        return null;
      }

      if (el.paymentId && !el.transaction && el.status !== 'cancelled') {
        return <BookingDetailsSummaryPaymentOpenLink />;
      }
      if (
        el.channel === 'manual' &&
        !el.paymentId &&
        !el.transaction &&
        el.status !== 'cancelled'
      ) {
        return <BookingDetailsSummaryPaymentCreateLink />;
      }
      return null;
    },
  },
  {
    key: 'paymentMethod',
    value: (_, t, receipt) => {
      if (!receipt?.status) return undefined;

      const paymentMethod =
        receipt?.transaction.payment?.method === 'paypal'
          ? 'PayPal'
          : receipt?.transaction.payment?.brand
            ? `${capitalize(receipt?.transaction.payment?.brand)} (Holdbar Pay)`
            : receipt?.transaction.voucher?.code && (
                <StyledGiftCardLink
                  style={{ fontSize: 'inherit' }}
                  onClick={() =>
                    NiceModal.show(GiftCardsListDialog, {
                      voucherId: receipt?.transaction.voucher?.id,
                    })
                  }
                >
                  {t!('giftCard', 'utils.statusOptions', {
                    code: receipt?.transaction.voucher?.code,
                  })}
                </StyledGiftCardLink>
              );

      return paymentMethod;
    },
  },
  {
    key: 'refunded',
    value: (el) => {
      if (!el?.refund?.date) return undefined;

      return renderDateTime(el.refund.date);
    },
  },
  {
    key: 'receiptId',
    value: (_, t, receipt) => {
      if (!receipt?.id) return undefined;

      return receipt?.id;
    },
    showDivider: true,
  },
  {
    key: 'subtotal',
    value: (_, t, receipt) => {
      if (!receipt || !receipt?.transaction.tax?.totalExcludingTax)
        return undefined;

      return formatMoney(
        t!,
        true
      )({
        value: receipt.transaction.tax.totalExcludingTax,
        nativeCurrency: receipt.transaction.currency,
      });
    },
  },
  {
    key: 'VAT',
    value: (_, t, receipt) => {
      if (!receipt || !receipt?.transaction.tax?.amount) return undefined;

      return formatMoney(
        t!,
        true
      )({
        value: receipt.transaction.tax.amount,
        nativeCurrency: receipt.transaction.currency,
      });
    },
  },
  {
    key: 'totalAmount',
    value: (_, t, receipt) => {
      if (!receipt) return undefined;

      return formatMoney(
        t!,
        true
      )({
        value: receipt.transaction.totalAmount,
        nativeCurrency: receipt.transaction.currency,
      });
    },
  },
  {
    key: 'discount',
    value: (_, t, receipt) => {
      const discount = receipt?.transaction?.discount;

      if (!discount) return undefined;

      return `${discount.code} ${discount.percentage ? discount.rate + '%' : ''} ${formatMoney(
        t!,
        true
      )({
        value: -discount.totalDiscountedAmount,
        nativeCurrency: receipt.transaction.currency,
      })}`;
    },
  },
  {
    key: 'totalPaid',
    value: (_, t, receipt) => {
      if (!receipt) return undefined;

      return formatMoney(
        t!,
        true
      )({
        value:
          receipt.transaction.totalAmount -
          (receipt.transaction.discount?.totalDiscountedAmount ?? 0),
        nativeCurrency: receipt?.transaction.currency,
      });
    },
    highlight: true,
  },
];

const StyledGiftCardLink = styled(Text)`
  cursor: pointer;
  color: ${lightTheme.palette.action.a300};

  &:hover {
    text-decoration: underline;
  }
`;
