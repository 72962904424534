import { InfoBox } from '../../../../../../../Components/InfoBox/InfoBox';
import useResponsive from '../../../../../../../Hooks/layout/useResponsive';
import { useTranslate } from '../../../../../../../Hooks/useTranslate';
import { bookingRows } from '../../domain/use_booking_details_summary';
import { BookingDetailsSummaryTable } from '../BookingDetailsSummaryTable';
import { BookingDetailsSummaryBookingMobile } from './BookingDetailsSummaryBookingMobile';

export const BookingDetailsSummaryBooking = () => {
  const { t } = useTranslate('dialogs.booking');
  const { isSm } = useResponsive();

  if (isSm) {
    return <BookingDetailsSummaryBookingMobile />;
  }

  return (
    <InfoBox title={t('sections.booking')}>
      <BookingDetailsSummaryTable rows={bookingRows} />
    </InfoBox>
  );
};
