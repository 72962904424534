export default function Connected({ size = 14 }: { size?: number }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.66668 9.16697C6.95298 9.54972 7.31825 9.86642 7.73771 10.0956C8.15717 10.3248 8.62102 10.461 9.09778 10.4952C9.57454 10.5293 10.0531 10.4605 10.5009 10.2935C10.9487 10.1264 11.3554 9.86502 11.6933 9.52697L13.6933 7.52697C14.3005 6.8983 14.6365 6.05629 14.6289 5.1823C14.6213 4.30831 14.2708 3.47227 13.6527 2.85424C13.0347 2.23621 12.1987 1.88565 11.3247 1.87806C10.4507 1.87046 9.60869 2.20644 8.98001 2.81364L7.83334 3.95364M9.33334 7.83364C9.04704 7.45088 8.68177 7.13418 8.26231 6.90501C7.84285 6.67584 7.37901 6.53956 6.90224 6.50541C6.42548 6.47127 5.94695 6.54006 5.49911 6.70711C5.05128 6.87417 4.6446 7.13559 4.30668 7.47364L2.30668 9.47364C1.69948 10.1023 1.3635 10.9443 1.3711 11.8183C1.37869 12.6923 1.72926 13.5283 2.34728 14.1464C2.96531 14.7644 3.80135 15.115 4.67534 15.1226C5.54933 15.1301 6.39134 14.7942 7.02001 14.187L8.16001 13.047"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
