import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useIntercom } from 'react-use-intercom';

import { useAuth } from '../Hooks/useAuth';

type ErrorSignOutDialogProps = {
  setShowErrorDialog: (isOpen: boolean) => void;
};

export const ErrorSignOutDialog = ({
  setShowErrorDialog,
}: ErrorSignOutDialogProps) => {
  const { t } = useTranslation();
  const { signOut } = useAuth();
  const { show } = useIntercom();

  const handleSignOut = () => {
    setShowErrorDialog(false);
    signOut();
  };

  return (
    <Dialog open>
      <DialogTitle>{t('dialogs.errorDialog.errorTitle')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('dialogs.errorDialog.errorSubtitle')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={show}>
          {t('dialogs.errorDialog.contactSupport')}
        </Button>
        <Button onClick={handleSignOut} variant="contained">
          {t('utils.primaryNavigation.logout')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
