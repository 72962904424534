import { useTranslation } from 'react-i18next';

export type LocalizedString = Record<string, string>;

export type LocalizerFunction = (
  value: string | LocalizedString | undefined | null,
  defaultValue?: string
) => string;

export const useLocalizedStringFormatter = (): LocalizerFunction => {
  const { i18n } = useTranslation();

  return (value, defaultValue = '') => {
    if (!value) return defaultValue;

    if (typeof value === 'string') {
      return value || defaultValue;
    }

    return value[i18n.language] || Object.values(value)[0] || defaultValue;
  };
};
