import { Text } from '@holdbar-com/pixel';
import {
  Box,
  Skeleton,
  Stack,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';
import { i18n } from 'i18next';
import { useContext } from 'react';

import { InfoBox } from '../../../Components/InfoBox/InfoBox';
import useResponsive from '../../../Hooks/layout/useResponsive';
import { getLocalized, TBooking } from '../../../Hooks/useBookings';
import { IExperience } from '../../../Hooks/useExperience';
import { useTranslate } from '../../../Hooks/useTranslate';
import { EventDetailsContext } from './EventDetailsView';
import { Accordion, StyledTable } from './shared';

export const BookingsSummary = () => {
  const { t, i18n } = useTranslate('events.detailsPage.bookingsSummary');

  const { isMd } = useResponsive();

  const { event, experience, bookingsForEvent, isLoading } =
    useContext(EventDetailsContext);

  const eventTickets = getTicketTypes(i18n, bookingsForEvent, experience);

  const ticketTypes = eventTickets
    ? Object.entries(eventTickets?.variants)
        .map(([name, count]) => `${count} x ${name}`)
        .join(', ')
    : undefined;

  const addons = eventTickets
    ? Object.entries(eventTickets?.addons)
        .map(([name, count]) => `${count} x ${name}`)
        .join(', ')
    : undefined;

  const customerInformationCount = bookingsForEvent
    ?.map((booking) => booking.customDataInputs)
    .filter(Boolean).length;

  const rows = [
    {
      key: 'guests',
      value: event
        ? `${event?.slots.booked} / ${event?.slots.total}`
        : undefined,
    },
    {
      key: 'ticketTypes',
      value: ticketTypes,
    },
    { key: 'addons', value: addons },
    customerInformationCount && {
      key: 'guestInformation',
      value: t('guestInformationText', {
        guestCount: customerInformationCount,
      }),
    },
  ];

  if (isMd)
    return (
      <Stack>
        <Accordion title={t('title')}>
          <Stack gap={2} pb={3}>
            {rows.map((row) => {
              if (!row || !row?.value) return null;

              return (
                <Stack key={row.key}>
                  <Text variant="medium"> {t(row.key)}</Text>
                  <Text>{row.value}</Text>
                </Stack>
              );
            })}
          </Stack>
        </Accordion>
      </Stack>
    );

  return (
    <Box flexGrow={2} display="flex" width={'40%'}>
      <InfoBox title={t('title')}>
        <StyledTable>
          <TableBody>
            {rows.map((row) => {
              if (!row) return;

              const value = isLoading ? (
                <Skeleton height={19.6} width={50} />
              ) : (
                row.value
              );

              if (!value) return;

              return (
                <TableRow key={row.key}>
                  <TableCell
                    component="th"
                    scope="row"
                    size="small"
                    padding="none"
                  >
                    {t(row.key)}
                  </TableCell>
                  <TableCell size="small" padding="none">
                    {value}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </StyledTable>
      </InfoBox>
    </Box>
  );
};

export const getTicketTypes = (
  i18n: i18n,
  bookingsForEvent?: TBooking[],
  experience?: IExperience
) => {
  if (!bookingsForEvent || !experience) return undefined;

  return bookingsForEvent
    ?.filter((booking) => ['active', 'unpaid'].includes(booking.status))
    ?.map((booking) => booking.items)
    .reduce(
      (types, items) => {
        const ticketsForBooking = Object.entries(items ?? {}).reduce(
          (tickets, [id, count]) => {
            const variantId = id.split('/').pop();
            const isAddon = id.split('/')[0] === 'addon';

            if (!experience?.price?.variants) {
              return {
                ...tickets,
                variants: {
                  ...tickets.variants,
                  ['Standard']: (tickets.variants['Standard'] ?? 0) + count,
                },
              };
            }

            let n;

            if (isAddon) {
              const parentVariant = experience.price.variants.find((v) => {
                return v.addons?.find((a) => a.id === variantId);
              });

              n = parentVariant?.addons?.find((a) => a.id === variantId)?.name;
            } else {
              n = experience?.price?.variants.find(
                (v) => v.id === variantId
              )?.name;
            }

            const name = getLocalized(n, i18n.language) ?? 'Standard';

            if (isAddon) {
              return {
                ...tickets,
                addons: {
                  ...tickets.addons,
                  [name]: (tickets.addons[name] ?? 0) + count,
                },
              };
            }

            return {
              ...tickets,
              variants: {
                ...tickets.variants,
                [name]: (tickets.variants[name] ?? 0) + count,
              },
            };
          },
          { addons: {}, variants: {} } as {
            variants: Record<string, number>;
            addons: Record<string, number>;
          }
        );

        let newTypes = types;

        Object.entries(ticketsForBooking.variants).forEach(
          ([name, count]) =>
            (newTypes = {
              ...newTypes,
              variants: {
                ...newTypes.variants,
                [name]: (newTypes.variants[name] ?? 0) + count,
              },
            })
        );

        Object.entries(ticketsForBooking.addons).forEach(
          ([name, count]) =>
            (newTypes = {
              ...newTypes,
              addons: {
                ...newTypes.addons,
                [name]: (newTypes.addons[name] ?? 0) + count,
              },
            })
        );

        return newTypes;
      },
      { addons: {}, variants: {} } as {
        variants: Record<string, number>;
        addons: Record<string, number>;
      }
    );
};
