import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Button, lightTheme, Text } from '@holdbar-com/pixel';
import { CloseRounded } from '@mui/icons-material';
import { Box, Dialog, Stack } from '@mui/material';

import { Markdown } from '../../../../Components/Markdown/Markdown';
import useResponsive from '../../../../Hooks/layout/useResponsive';
import { useTranslate } from '../../../../Hooks/useTranslate';
import { Opportunity } from '../domain/types';

interface OpportunityDialogProps {
  opportunity: Opportunity;
  onInterest: () => void;
}

export const OpportunityDialog = NiceModal.create<OpportunityDialogProps>(
  ({ opportunity, onInterest }) => {
    const { t } = useTranslate('opportunities');
    const { isSm } = useResponsive();
    const modal = useModal();

    const handleClose = () => {
      modal.reject();
      modal.hide();
    };

    return (
      <Dialog
        open={modal.visible}
        onClose={handleClose}
        fullWidth
        maxWidth={'lg'}
        fullScreen={isSm}
      >
        <Stack gap={3} p={4}>
          <CloseRounded onClick={handleClose} sx={{ cursor: 'pointer' }} />
          <Stack direction={isSm ? 'column' : 'row'} gap={4} flexGrow={1}>
            <Box
              component="img"
              src={opportunity.icon}
              sx={{
                width: 80,
                height: 80,
              }}
            />
            <Stack gap={1}>
              <Stack direction="row" gap={1} alignItems="center">
                <Text fontSize="xlarge" variant="medium">
                  {opportunity.title}
                </Text>
                {opportunity.isFree && (
                  <Text
                    fontSize="small"
                    color={lightTheme.palette.neutral.n300}
                  >
                    {t('free', 'utils.generic')}
                  </Text>
                )}
              </Stack>
              <Markdown>{t(`${opportunity.id}.extendedDescription`)}</Markdown>
            </Stack>
          </Stack>
          <Stack direction="row" justifyContent="end">
            <Button
              variant="primary"
              size="medium"
              type="submit"
              onClick={() => {
                onInterest();
                handleClose();
              }}
            >
              {t('actions.interested')}
            </Button>
          </Stack>
        </Stack>
      </Dialog>
    );
  }
);
