import { createContext, useContext } from 'react';

export type PageBreadcrumbState = {
  isLast: boolean;
  isFirst: boolean;
};

export const PageBreadcrumbContext = createContext<PageBreadcrumbState | null>(
  null
);

export const usePageBreadcrumbContext = () => {
  const context = useContext(PageBreadcrumbContext);
  if (!context) {
    return {
      isLast: false,
      isFirst: false,
    };
  }

  return context;
};
