import { Text } from '@holdbar-com/pixel';
import { Stack } from '@mui/material';
import { useState } from 'react';

import { Page } from '../../../Components/Page/Page';
import { useTranslate } from '../../../Hooks/useTranslate';
import { GiftCardsList } from './giftcards_list/GiftCardsList';
import { GiftCardsSearchForm } from './giftcards_search_form/GiftCardsSearchForm';

export const GiftCardsView = () => {
  const { t } = useTranslate('giftcards');

  const [searchState, setSearchState] = useState({
    query: '',
    status: 'all',
  });

  return (
    <Page maxWidth={1200} pb={10}>
      <Stack gap={3}>
        <Text fontSize={'h5'}>{t('title')}</Text>
        <GiftCardsSearchForm
          searchState={searchState}
          setSearchState={setSearchState}
        />
        <GiftCardsList searchState={searchState} />
      </Stack>
    </Page>
  );
};
