import { useModal } from '@ebay/nice-modal-react';
import {
  createContext,
  Dispatch,
  ReactNode,
  RefObject,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

import { ampli } from '../../Ampli';
import { TBookingResult, useSearch } from '../../Hooks/useSearch';

type SearchDialogContextProps = {
  input: string;
  setInput: Dispatch<SetStateAction<string>>;
  handleClose: () => void;
  selectedIndex: number | undefined;
  setSelectedIndex: Dispatch<SetStateAction<number | undefined>>;
  usingArrowKeys: boolean;
  listItemsRef: RefObject<HTMLUListElement>;
  goToBooking: () => void;
  query: string;
  searchResults: TBookingResult[];
  isFetching: boolean;
};

export const SearchDialogContext = createContext<
  SearchDialogContextProps | undefined
>(undefined);

export const useSearchDialog = () => {
  const context = useContext(SearchDialogContext);
  if (!context) {
    throw new Error(
      'useSearchDialog must be used within a SearchDialogContextProvider'
    );
  }
  return context;
};

export const SearchDialogContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { query, setQuery, searchResults, isFetching } = useSearch();
  const modal = useModal();
  const navigate = useNavigate();

  const [selectedIndex, setSelectedIndex] = useState<number | undefined>(
    undefined
  );
  const [usingArrowKeys, setUsingArrowKeys] = useState(false);
  const [input, setInput] = useState('');

  const listItemsRef = useRef<HTMLUListElement>(null);

  // Clear search input after fading out modal
  const handleClose = useCallback(() => {
    modal.hide();
    setTimeout(() => {
      setInput('');
      setQuery('');
      setSelectedIndex(undefined);
    }, 100);
  }, [modal, setQuery]);

  const goToBooking = useCallback(() => {
    ampli.searchSelectResult({
      selectedIndex: selectedIndex ?? 0,
      results: searchResults.length,
      location: window.location.pathname,
    });
    handleClose();
  }, [handleClose, searchResults, selectedIndex]);

  // Select first search result when list updates
  useEffect(() => {
    if (searchResults.length) {
      setSelectedIndex(0);
    } else {
      setSelectedIndex(undefined);
    }
  }, [searchResults]);

  // Handle keyboard events
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (searchResults.length === 0) return;

      const shiftPressed = e.shiftKey;

      const isTab = e.key === 'Tab';

      const keyup = e.key === 'ArrowUp' || (shiftPressed && isTab);
      const keydown = e.key === 'ArrowDown' || (!shiftPressed && isTab);

      if (keyup || keydown) {
        e.preventDefault();
        setUsingArrowKeys(true);

        if (!listItemsRef.current) return;
        const items = listItemsRef.current.children;
        if (items.length === 0) return;

        const currentIndex = selectedIndex ?? 0;
        let nextIndex = currentIndex;

        if (keyup) {
          nextIndex = currentIndex > 0 ? currentIndex - 1 : 0;
        } else if (keydown) {
          const maxIndex = items.length - 1;
          nextIndex = currentIndex < maxIndex ? currentIndex + 1 : maxIndex;
        }

        setSelectedIndex(nextIndex);
        const selectedItem = items[nextIndex];
        selectedItem.scrollIntoView({ block: 'nearest' });
      }

      if (e.key === 'Enter' && selectedIndex !== undefined) {
        e.preventDefault();
        const id = searchResults[selectedIndex].id;
        goToBooking();
        navigate(`/booking/${id}`);
      }
    };

    const handleMouseMove = () => {
      if (usingArrowKeys) setUsingArrowKeys(false);
    };

    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('mousemove', handleMouseMove);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, [selectedIndex, searchResults, usingArrowKeys, navigate, goToBooking]);

  // Debounce updating query
  useEffect(() => {
    const debounce = setTimeout(() => {
      setQuery(input);
    }, 250);
    return () => clearTimeout(debounce);
  }, [input, setQuery]);

  return (
    <SearchDialogContext.Provider
      value={{
        input,
        setInput,
        handleClose,
        selectedIndex,
        setSelectedIndex,
        usingArrowKeys,
        listItemsRef,
        goToBooking,
        query,
        searchResults,
        isFetching,
      }}
    >
      {children}
    </SearchDialogContext.Provider>
  );
};
