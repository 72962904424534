import { FC, Fragment } from 'react';

import { CalendarGrid } from '../../calendar-grid/CalendarGrid';
import { GridExperienceCell } from '../../calendar-grid/GridExperienceCell';
import { useGroupedEvents } from '../../hooks/useGroupedEvents';
import { CalendarDateHeader } from './CalendarDateHeader';
import { EventList } from './EventList';

type Props = {
  dates: Date[];
};

export const CalendarWeekListGrouped: FC<Props> = ({ dates }) => {
  const groups = useGroupedEvents();
  if (!groups) return null;

  return (
    <CalendarGrid $columns={8}>
      <div>{/* Skip the first grid */}</div>
      {dates.map((date) => (
        <CalendarDateHeader key={date.toISOString()} date={date} />
      ))}

      {Object.values(groups).map(({ events, experience }) => (
        <Fragment key={experience.id}>
          <GridExperienceCell experience={experience} />
          {dates.map((date) => (
            <EventList
              key={date.toISOString()}
              date={date}
              events={events}
              grouped
            />
          ))}
        </Fragment>
      ))}
    </CalendarGrid>
  );
};
