import { Button } from '@holdbar-com/pixel';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import useResponsive from '../../../../Hooks/layout/useResponsive';

export const VatFormActions = ({ onClose }: { onClose: () => void }) => {
  const { t } = useTranslation();
  const { isMd } = useResponsive();

  return (
    <Stack direction={isMd ? 'column' : 'row'} gap={2} justifyContent={'right'}>
      <Button size="medium" variant="secondary" onClick={onClose}>
        {t('utils.generic.cancel')}
      </Button>
      <Button size="medium" variant="primary" type="submit">
        {t('buttons.create')}
      </Button>
    </Stack>
  );
};
