import { useGetEvent } from '../../../Hooks/events/useGetEvent';
import { ICancellationNote } from './types';

export const useCancelNotes = (eventId: string) => {
  const {
    event: { data: eventData },
  } = useGetEvent(eventId);

  const prevCancellationNote: ICancellationNote[] | undefined =
    eventData?.cancellationDetails?.cancellationNote;
  const prevCancellationRefundChoice: boolean | undefined =
    eventData?.cancellationDetails?.shouldRefund;

  const getCancelNotes = (
    languages: string[],
    cancelNote: ICancellationNote[] | string | undefined
  ) => {
    if (languages.length === 0 && cancelNote) {
      return [
        {
          [eventData?.languages[0] ?? 'da']:
            cancelNote['undefined' as keyof typeof cancelNote],
        },
      ] as ICancellationNote[];
    }

    if (!cancelNote || typeof cancelNote === 'string') {
      return [];
    }

    const notes = Object.entries(cancelNote)
      .filter(
        ([key]) =>
          languages.includes(key) && cancelNote[key as keyof typeof cancelNote]
      )
      .map(([key, value]) => ({ [key]: value }));

    if (notes.length === 0) {
      return [];
    }

    return notes;
  };

  return {
    prevCancellationNote,
    prevCancellationRefundChoice,
    getCancelNotes,
  };
};
