import {
  EconomicConfiguration,
  VatRegistration,
} from '@holdbar-com/utils-types';
import { Skeleton } from '@mui/material';
import { FC } from 'react';

import { SERIALIZED_PREFIX, SERIALIZED_SYMBOL } from '../../../Api';
import { useCountryVatRates } from '../../../Hooks/useCountryVatRates';
import { EconomicDetailsTableRow } from './economic-details-table-row';

type Props = {
  configuration: EconomicConfiguration;
  vatRegistrations: VatRegistration[];
};

export const EconomicDetailsVatCodes: FC<Props> = ({
  configuration,
  vatRegistrations,
}) => {
  const { data: vatRatesByCountry, isLoading, isError } = useCountryVatRates();

  const loadingVatCodes = isError || isLoading || !vatRatesByCountry;

  const countryVatCodes = Array.from(
    new Set(
      vatRegistrations
        .flatMap(
          (vatRegistration) =>
            vatRatesByCountry?.[vatRegistration.country]?.rates ?? []
        )
        .flatMap(({ reduced, superReduced, standard }) =>
          [standard, superReduced ?? []]
            .concat(
              // The VAT response type is pretty hard to work with,
              // because we would have to check the type value before being able to use `rate`
              // so we just cast it to any or fallback to nothing
              reduced.flatMap(
                (reduce) => (Object.values(reduce)[0] as any)?.rate ?? []
              )
            )
            .flat()
        )
    )
  ).sort();

  if (!countryVatCodes.length) {
    return <Skeleton width={100} />;
  }

  return (
    <>
      {countryVatCodes.map((vatRate) => (
        <EconomicDetailsTableRow
          key={vatRate}
          title={`${vatRate * 100}%`}
          value={
            !loadingVatCodes
              ? configuration.vatCodes?.[
                  `${SERIALIZED_PREFIX}${vatRate.toString().replaceAll('.', SERIALIZED_SYMBOL)}`
                ]
              : undefined
          }
        />
      ))}
    </>
  );
};
