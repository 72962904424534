import { useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IntercomContextValues } from 'react-use-intercom';
import { ObjectSchema } from 'yup';

import { ampli } from '../Ampli';
import * as api from '../Api';
import { OnBoardingData, OnBoardingResult } from '../Api/OnBoarding';
import { useAuthStore } from '../Store/useAuthStore';

type Step = {
  key: string;
  onClick?: (
    trackEvent: IntercomContextValues['trackEvent'],
    startTour: IntercomContextValues['startTour']
  ) => void;
  progress: number;
  metadata?: {
    status?: string;
    existingAccount?: boolean;
  };
  depends?: string[];
  subjectId?: string;
};

type Item = {
  key: string;
  steps: Step[];
  completed?: boolean;
};

export const INTERCOM_SETUP_TOURS = {
  experience: 546237,
  event: 546601,
  implementation: 546605,
};

const handleSetupExperience = (
  trackEvent: IntercomContextValues['trackEvent'],
  startTour: IntercomContextValues['startTour']
) => {
  trackEvent('onboarding-setup-experience');
  startTour(INTERCOM_SETUP_TOURS.experience);
  ampli.onboardingListExperienceClicked();
};

const handleSetupEvent = (
  trackEvent: IntercomContextValues['trackEvent'],
  startTour: IntercomContextValues['startTour']
) => {
  trackEvent('onboarding-setup-event');
  startTour(INTERCOM_SETUP_TOURS.event);
  ampli.onboardingListEventClicked();
};

const handleSetupPayment = (
  trackEvent: IntercomContextValues['trackEvent']
) => {
  trackEvent('onboarding-setup-payment');
  ampli.onboardingListPaymentClicked();
};

const handleSetupImplementation = (
  trackEvent: IntercomContextValues['trackEvent'],
  startTour: IntercomContextValues['startTour']
) => {
  trackEvent('onboarding-setup-implementation');
  startTour(INTERCOM_SETUP_TOURS.implementation);
  ampli.onboardingListImplementationClicked();
};

export const defaultItems: Item[] = [
  {
    key: 'experience',
    steps: [
      {
        key: 'create',
        onClick: handleSetupExperience,
        progress: 0,
      },
    ],
  },
  {
    key: 'event',
    steps: [
      {
        key: 'create',
        onClick: handleSetupEvent,
        progress: 0,
        depends: ['experience'],
      },
    ],
  },
  {
    key: 'payment',
    steps: [
      {
        key: 'setup',
        onClick: handleSetupPayment,
        progress: 0,
      },
    ],
  },
  {
    key: 'implementation',
    steps: [
      {
        key: 'setup',
        onClick: handleSetupImplementation,
        progress: 0,
      },
    ],
  },
];

export const useOnBoarding = () => {
  const queryClient = useQueryClient();
  const { auth } = useAuthStore();

  const QueryKey = ['onBoarding'];

  const onBoarding = useQuery<{
    items: Item[];
    onboarding: OnBoardingResult;
    response: OnBoardingResult;
  }>(
    QueryKey,
    async () => {
      await queryClient.cancelQueries(QueryKey);
      const saved = await api.getOnBoarding();
      const data = {
        items: defaultItems
          .map((el) => {
            return saved[el.key]
              ? {
                ...el,
                steps:
                  el.steps?.map((step) => {
                    const {
                      progress = 0,
                      metadata,
                      subjectId,
                    } = saved[el.key].find((f) => f.key === step.key) ?? {};
                    return {
                      ...step,
                      subjectId,
                      progress,
                      ...(metadata && { metadata }),
                    };
                  }) ?? [],
              }
              : el;
          })
          .map((m) => {
            return {
              ...m,
              completed: !m.steps?.some((st) => st.progress !== 100),
            };
          }),
        onboarding: defaultItems.reduce((data, item) => {
          data[item.key] =
            saved[item.key] ??
            (item.steps.map((step) => ({
              key: step.key,
              progress: 0,
            })) as OnBoardingData[]);
          return data;
        }, {} as OnBoardingResult),
        response: saved,
      };
      return data;
    },
    {
      enabled: Boolean(auth),
      retry: false,
      refetchOnWindowFocus: 'always',
    }
  );

  const missingSteps = useMemo(() => {
    if (onBoarding.data?.items) {
      return onBoarding.data.items
        .filter((f) => !f.completed)
        .map(({ key }) => ({ key }));
    }
    return defaultItems.map(({ key }) => ({ key }));
  }, [onBoarding]);

  const updateOnBoardingStep = useMutation(
    ({
      type,
      payload,
    }: {
      type: string;
      payload: { key: string; progress: number };
    }) => {
      const existingOnboardingData = queryClient.getQueryData<{
        items: Item[];
        onboarding: OnBoardingResult;
      }>(QueryKey);
      if (
        existingOnboardingData?.items?.some(
          (item) =>
            item.key === type &&
            item.steps.some(
              (step) => step.key === payload.key && step.progress !== 100
            )
        )
      ) {
        return api.updateOnBoardingStep(type, payload);
      }
      return Promise.resolve();
    },
    {
      /*onMutate: async ({type, payload}) => {

                await queryClient.cancelQueries(QueryKey);

                const previous = queryClient.getQueryData<{ items: Item[], onboarding: OnBoardingResult}>(QueryKey);

                queryClient.setQueryData<{ items: Item[], onboarding: OnBoardingResult}>(QueryKey, (existingData) => {
                    const newData = {
                        items: [],
                        onboarding: {}
                    } as { items: Item[], onboarding: OnBoardingResult }

                    for(const item of existingData?.items ?? []) {
                        if (item.key === type) {
                            newData.items.push({
                                ...item,
                                steps: item.steps.map(step => {
                                    return step.key === payload.key && step.progress !== 100 ? {
                                        ...step,
                                        ...payload
                                    } : {...step}
                                })
                            })
                        } else {
                            newData.items.push({...item})
                        }
                    }

                    for(const key of Object.keys(existingData?.onboarding ?? {})) {
                        if (key === type) {
                            newData.onboarding[key] = (existingData?.onboarding[key] ?? []).map(item => {
                                return item.key === payload.key && item.progress !== 100 ? {
                                    ...item,
                                    ...payload
                                } : {...item}
                            })
                        } else {
                            newData.onboarding[key] = [...(existingData?.onboarding[key] ?? [])]
                        }
                    }

                    console.log("Updating onboarding:", newData)
                    return newData
                })

                return { previous };
            },*/
      onError: (err, variables, context: any) => {
        if (context?.previous) {
          queryClient.setQueryData(QueryKey, context.previous);
        }
      },
      onSettled: async () => {
        queryClient.invalidateQueries(QueryKey);
      },
    }
  );

  const updateStep = async (
    payload: { id?: string },
    schema: ObjectSchema<any>,
    type: string,
    stepKey: string
  ) => {
    const errorCount = await schema
      .validate(payload, { abortEarly: false, strict: true })
      .then(() => 0)
      .catch((err) => {
        console.log(err.errors);
        return err.errors?.length ?? 0;
      });
    const fieldsCount = Object.keys(schema.fields).length;
    updateOnBoardingStep.mutate({
      type,
      payload: {
        key: stepKey,
        progress:
          errorCount > 0
            ? Math.round(((fieldsCount - errorCount) / fieldsCount) * 100)
            : 100,
        ...(payload.id && {
          subjectId: payload.id,
        }),
      },
    });
  };

  return {
    onBoarding,
    updateStep,
    updateOnBoardingStep,
    missingSteps,
  };
};
