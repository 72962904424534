import { useTranslate } from '../../../../Hooks/useTranslate';
import { EconomicAccountAutocomplete } from './economic-account-autocomplete';
import { EconomicConfigurationAccountsByExperience } from './economic-configuration-accounts-by-experience';
import { EconomicConfigurationAccountsSection } from './economic-configuration-accounts-section';

export const EconomicConfigurationAccounts = () => {
  const { t } = useTranslate('settings.integrations.economic.configuration');

  return (
    <>
      <EconomicConfigurationAccountsSection
        title={t('sales.title')}
        description={t('sales.description')}
        appendedNode={<EconomicConfigurationAccountsByExperience />}
      >
        <EconomicAccountAutocomplete
          label={t('accounts.salesWithVat')}
          path="salesWithVat"
        />
        <EconomicAccountAutocomplete
          label={t('accounts.salesWithoutVat')}
          path="salesWithoutVat"
        />
      </EconomicConfigurationAccountsSection>

      <EconomicConfigurationAccountsSection
        title={t('costs.title')}
        description={t('costs.description')}
      >
        <EconomicAccountAutocomplete
          label={t('accounts.holdbarFee')}
          path="feeHoldbar"
        />
        <EconomicAccountAutocomplete
          label={t('accounts.stripeFee')}
          path="gateways.stripe.fee"
        />
        <EconomicAccountAutocomplete
          label={t('accounts.paypalFee')}
          path="gateways.paypal.fee"
        />
      </EconomicConfigurationAccountsSection>

      <EconomicConfigurationAccountsSection
        title={t('liabilities.title')}
        description={t('liabilities.description')}
      >
        <EconomicAccountAutocomplete
          label={t('accounts.bookings')}
          path="liabilityGuest"
        />
        <EconomicAccountAutocomplete
          label={t('accounts.giftCards')}
          path={'liabilityGiftCard'}
        />
      </EconomicConfigurationAccountsSection>

      <EconomicConfigurationAccountsSection
        title={t('receivables.title')}
        description={t('receivables.description')}
      >
        <EconomicAccountAutocomplete
          label={t('accounts.stripeRefunds')}
          path="gateways.stripe.asset"
        />
        <EconomicAccountAutocomplete
          label={t('accounts.paypalRefunds')}
          path="gateways.paypal.asset"
        />
      </EconomicConfigurationAccountsSection>
    </>
  );
};
