import { differenceInCalendarDays } from 'date-fns';

import { OverviewPageViewedProperties } from '../../Ampli';
import { AccumulatedMetricsItem } from '../../Sections/DashboardSections/useMetrics';

export const eventProperties = (
  accumulatedMetricsItems: AccumulatedMetricsItem[],
  currency: string,
  fromDate: Date,
  toDate: Date
) => {
  const daysToStart = differenceInCalendarDays(fromDate, new Date());
  const daysToEnd = differenceInCalendarDays(toDate, new Date());

  const days_from_current_date = Math.min(
    Math.abs(daysToStart),
    Math.abs(daysToEnd)
  );

  return {
    currency,
    days_from_current_date,
    total_gift_cards_sold: accumulatedMetricsItems.find(
      (item) => item.key === 'vouchersSold'
    )?.value,
    total_number_customers: accumulatedMetricsItems.find(
      (item) => item.key === 'customers'
    )?.value,
    total_number_guests: accumulatedMetricsItems.find(
      (item) => item.key === 'guests'
    )?.value,
    total_sales: accumulatedMetricsItems.find((item) => item.key === 'revenue')
      ?.value,
    total_views_experiences: accumulatedMetricsItems.find(
      (item) => item.key === 'impressions'
    )?.value,
  };
};

export const transformToUserProperties = (
  properties: OverviewPageViewedProperties
) => ({
  total_number_guests: properties.total_number_guests,
  total_sales: properties.total_sales,
});
