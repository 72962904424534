import { useMemo } from 'react';

import { useExperience } from '../../../Hooks/useExperience';
import { useTranslate } from '../../../Hooks/useTranslate';
import { useCalendar } from '../context';
import { Filter } from './view/Filter';

export const ExperiencesFilter = () => {
  const {
    experiences: { data, isLoading },
  } = useExperience();
  const { experienceFilters, setExperienceFilters } = useCalendar();

  const { t } = useTranslate('utils.generic');

  const options = useMemo(() => {
    if (!data) return [];

    return data
      .filter(({ status }) => status === 'active')
      .map(({ id, headline }) => ({
        id: id ?? headline,
        name: headline,
      }));
  }, [data]);

  return (
    <Filter
      inputId="experiences-filter"
      label={t('experiences')}
      options={options}
      checkedIds={experienceFilters}
      onChange={setExperienceFilters}
      isLoading={isLoading}
      sortOptions={(a, b) => a.name.localeCompare(b.name)}
    />
  );
};
