import { Recipient } from '../features/bookings/booking_details/ui/booking_details_summary/ui/notifications/domain/types';
import axios from './index';

export const getTemplates = async () => {
  if (typeof process.env.REACT_APP_API_NOTIFICATIONS_URL === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_NOTIFICATIONS_URL is not defined'
    );
  }

  const { data } = await axios.get<{ groupKey: string; templateId: string }[]>(
    `${process.env.REACT_APP_API_NOTIFICATIONS_URL}/templates/editable`
  );

  return data;
};

export const getSettings = async () => {
  if (typeof process.env.REACT_APP_API_NOTIFICATIONS_URL === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_NOTIFICATIONS_URL is not defined'
    );
  }

  const { data } = await axios.get<{ [k: string]: boolean }>(
    `${process.env.REACT_APP_API_NOTIFICATIONS_URL}/settings`
  );

  return data;
};

export const updateSettings = async (payload?: { [k: string]: boolean }) => {
  if (typeof process.env.REACT_APP_API_NOTIFICATIONS_URL === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_NOTIFICATIONS_URL is not defined'
    );
  }

  if (!payload) {
    throw new Error('Missing payload');
  }

  const { data } = await axios.put(
    `${process.env.REACT_APP_API_NOTIFICATIONS_URL}/settings`,
    payload
  );

  return data;
};

export const getNotificationLog = async (notificationId: string) => {
  if (typeof process.env.REACT_APP_API_NOTIFICATIONS_URL === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_NOTIFICATIONS_URL is not defined'
    );
  }

  const { data } = await axios.get(
    `${process.env.REACT_APP_API_NOTIFICATIONS_URL}/logs/${notificationId}`
  );

  return data;
};

export const resendNotification = async (
  notificationId: string,
  bookingRecipient: Recipient,
  newRecipient?: Recipient
) => {
  if (typeof process.env.REACT_APP_API_NOTIFICATIONS_URL === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_NOTIFICATIONS_URL is not defined'
    );
  }

  await axios.post(`${process.env.REACT_APP_API_NOTIFICATIONS_URL}/resend`, {
    notificationId,
    recipient: bookingRecipient,
    ...(newRecipient && { newRecipient }),
  });
};

export const sendSlackNotification = async (
  message: string,
  channel:
    | '#customer-report-requests'
    | '#growth-opportunity-requests'
    | '#product-integration-requests'
) => {
  if (typeof process.env.REACT_APP_API_NOTIFICATIONS_URL === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_NOTIFICATIONS_URL is not defined'
    );
  }

  await axios.post(
    `${process.env.REACT_APP_API_NOTIFICATIONS_URL}/notifications/slack`,
    {
      message,
      channel,
    }
  );
};
