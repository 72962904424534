import { QueryKey, useQuery } from 'react-query';

import { queryClient } from '../..';
import * as api from '../../Api';
import { TEvent, transformEvents } from '../../Utils/eventHelpers';

type Options = {
  from?: Date;
  to?: Date;
};

const EVENTS_QUERY_KEY = 'events';

export const getEventsQueryKey = (options?: Options) =>
  options
    ? [
        EVENTS_QUERY_KEY,
        {
          from: options?.from?.toISOString(),
          to: options?.to?.toISOString(),
        },
      ]
    : [EVENTS_QUERY_KEY];

export const useGetEvents = (options?: Options) => {
  const EventsQueryKey = getEventsQueryKey(options);

  const events = useQuery<TEvent[]>(
    EventsQueryKey,
    fetchEvents(EventsQueryKey, options)
  );

  return { events };
};

export const usePrefetchEvents = (options?: Options) => {
  const EventsQueryKey = getEventsQueryKey(options);

  queryClient.prefetchQuery({
    queryKey: EventsQueryKey,
    queryFn: fetchEvents(EventsQueryKey, options),
  });
};

const fetchEvents = (cacheKey: QueryKey, options?: Options) => async () => {
  await queryClient.cancelQueries(cacheKey, { exact: true });
  const events = await api.getEvents(options);
  const transformed = transformEvents(events);
  transformed.forEach((el) => queryClient.setQueryData(['events', el.id], el));
  return transformed;
};
