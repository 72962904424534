import styled from '@emotion/styled';
import { PropsWithChildren } from 'react';

export const InvitePage = ({ children }: PropsWithChildren) => {
  return <StyledBackground>{children}</StyledBackground>;
};

const StyledBackground = styled.div({
  backgroundColor: '#FBFBFF',
  height: '100vh',
  width: '100vw',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});
