import { CalendarView, isCalendarView } from '../../context';
import { SearchParamSerializer } from './search-param-serializer';

const DEFAULT_CALENDAR_VIEW: CalendarView = 'day';

export class SelectViewSerializer
  implements SearchParamSerializer<CalendarView>
{
  serialize(value: CalendarView) {
    return value;
  }

  deserialize(value: string) {
    return isCalendarView(value) ? value : DEFAULT_CALENDAR_VIEW;
  }
}
