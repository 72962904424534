import { BookingStatus } from '@holdbar-com/utils-types';
import { useMemo } from 'react';

import { useGetBookingsForEvent } from '../../../Hooks/data/useBookings';
import { languagesOptionValues } from '../../../Utils/config';

const CANCELLABLE_BOOKING_STATUSES: BookingStatus[] = [
  'active',
  'unpaid',
  'checked-in',
];

export const useCancelEventLanguages = (eventId: string) => {
  const {
    bookingsForEvent: { data: bookingsForEvent, isLoading: isBookingsLoading },
  } = useGetBookingsForEvent(eventId);

  const languages = Array.from(
    new Set(
      (bookingsForEvent ?? [])
        ?.filter(
          ({ language, status }) =>
            Boolean(language) && CANCELLABLE_BOOKING_STATUSES.includes(status)
        )
        .map(({ language }) => language as string)
    )
  );

  const selectedLanguages = useMemo(() => {
    return mapSelectedLanguages(languages, languagesOptionValues);
  }, [languages]);

  return {
    languages,
    isBookingsLoading,
    selectedLanguages,
  };
};

const mapSelectedLanguages = (
  selected: string[],
  langs: { [key: string]: { emoji: string } }
) => {
  return selected.map((key) => {
    const k = key === 'se' ? 'sv' : key;
    const label = langs[k]?.emoji;
    return { key: k, label: label ?? '' };
  });
};
