import { MenuItem } from '@mui/material';
import { formatDistanceStrict, hoursToMilliseconds } from 'date-fns';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCallback } from 'react';

import { ampli } from '../../../../Ampli';
import FeatureItem from '../../../../Components/FeatureItem/FeatureItem';
import FeatureSelect from '../inputs/FeatureSelect';

const FEATURE_ID = 'bookingReminder';

const durationOptionsAsHours = [1, 2, 6, 24, 48];

export const BookingReminderFeature = () => {
  const trackToggling = useCallback((enabled: boolean) => {
    if (enabled) {
      ampli.bookingReminderToggledOn();
    } else {
      ampli.bookingReminderToggledOff();
    }
  }, []);

  const flags = useFlags();
  if (!flags.featureBookingReminderSetting) {
    return null;
  }

  return (
    <FeatureItem feature={FEATURE_ID} onToggle={trackToggling}>
      <FeatureSelect
        name="hoursBeforeEvent"
        onOpen={() => {
          ampli.bookingReminderTbeDropdownFilterOpened();
        }}
        onChange={(event) => {
          const hours = Number(event.target.value);
          ampli.bookingReminderTbeDropdownFilterApplied({
            booking_reminder_tbe: hours,
          });
        }}
      >
        {durationOptionsAsHours.map((hours) => (
          <MenuItem key={hours} value={hours}>
            {formatDistanceStrict(0, hoursToMilliseconds(hours))}
          </MenuItem>
        ))}
      </FeatureSelect>
    </FeatureItem>
  );
};
