import { FC } from 'react';

import { useCalendar } from '../../context';
import { CalendarMonthListGrouped } from './Grouped';
import { CalendarMonthListUngrouped } from './Ungrouped';

export const CalendarMonthList: FC = () => {
  const { groupByExperience } = useCalendar();

  if (groupByExperience) {
    return <CalendarMonthListGrouped />;
  }

  return <CalendarMonthListUngrouped />;
};
