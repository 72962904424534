import { formatISO } from 'date-fns';
import isEmpty from 'lodash.isempty';
import isObject from 'lodash.isobject';
import merge from 'lodash.merge';
import { FieldErrors } from 'react-hook-form/dist/types/errors';

import { languageOptions } from './config';
import { DEFAULT_FORM_SEPARATOR } from './constants';

export const isEmptyObject = (obj: object) =>
  typeof obj === 'object' &&
  typeof obj !== null &&
  !Array.isArray(obj) &&
  Object.keys(obj).length === 0;

export const toISODate = (date: Date) => {
  return formatISO(date).split('T')[0];
};

export const extractByPrefix = (obj: any, prefix: string) => {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    return key.startsWith(prefix)
      ? {
          ...acc,
          [key.split(prefix)[1]]: value,
        }
      : acc;
  }, {});
};

export const cleanByPrefix = (obj: any, prefix: string) => {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    return key.startsWith(prefix)
      ? acc
      : {
          ...acc,
          [key]: value,
        };
  }, {});
};

export const getHoldbarCut = (value: number, takerate: number = 5) =>
  Math.round((value / 100) * takerate);

export const renderLocation = (props?: {
  city: string;
  zipCode: string;
  address: string;
}) => {
  const { address, city, zipCode } = props ?? {};
  return address && city && zipCode ? `${address}, ${zipCode} ${city}` : '';
};

const getNestedKey = (obj: FieldErrors, key: string) => {
  if (isEmpty(obj)) return null;
  let temp: { [key: string]: unknown } = obj as any;
  for (const p of key.split('.')) {
    temp = temp?.[p] ?? (temp as any);
  }
  return temp;
};

const unpackBySeparator = (
  separatedKey: string,
  value: string,
  obj: any = {},
  sep = '__'
) => {
  const keys = separatedKey.split(sep);
  const output: Record<string, any> = {};
  let ref = output;
  for (let i = 0; i < keys.length - 1; i++) {
    ref[keys[i]] = {};
    ref = ref[keys[i]];
  }
  ref[keys[keys.length - 1]] = value;
  return output;
};

export const flattenObject = (obj: Record<string, any>) => {
  const toReturn: Record<string, any> = {};
  for (const i in obj) {
    if (!obj.hasOwnProperty(i)) continue;
    if (isObject(obj[i]) && !Array.isArray(obj[i])) {
      const flatObject = flattenObject(obj[i]);
      for (const x in flatObject) {
        if (!flatObject.hasOwnProperty(x)) continue;

        toReturn[i + DEFAULT_FORM_SEPARATOR + x] = flatObject[x];
      }
    } else {
      toReturn[i] = obj[i];
    }
  }
  return toReturn;
};

export const pad = (val: number) => (val < 10 ? `0${val}` : `${val}`);

const transformFormState = (state: Record<string, string>) => {
  return Object.entries(state).reduce(
    (acc, [key, value]) => {
      if (key.includes(DEFAULT_FORM_SEPARATOR)) {
        return merge(acc, unpackBySeparator(key, value));
      }
      return { ...acc, [key]: value };
    },
    {} as Record<string, any>
  );
};

const getQueryParams = (query: string) => {
  if (!query) return {};
  return query
    .substring(1)
    .split('&')
    .reduce((acc, pair) => {
      const [key, value] = pair.split('=');
      return {
        ...acc,
        [key.trim()]: value.trim(),
      };
    }, {});
};

export const renderLanguages = (langCodes: string[]) => {
  return langCodes
    .map((code) => languageOptions(code)?.emoji)
    .filter((f) => f)
    .join(' ');
};

export const wait = (ms: number) => {
  return new Promise((res) => setTimeout(res, ms));
};

export const validUrl = (url: string) => {
  const input = document.createElement('input');
  input.type = 'url';
  if (url && !url.startsWith('https://') && !url.startsWith('http://')) {
    url = `https://${url}`;
  }
  input.value = url;
  return input.checkValidity();
};

export const validEmail = (email: string) => {
  const input = document.createElement('input');
  input.type = 'email';
  input.value = email;
  return input.checkValidity();
};

export const getFormattedPrice = (
  price: number,
  currency: string,
  locale?: string,
  digits?: number
) => {
  const formattedPrice = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    maximumFractionDigits: digits ?? 0,
  }).format(price);

  return formattedPrice;
};

export const sum = (arr: number[]): number => arr.reduce((a, b) => a + b, 0);

export const emailRegex =
  // eslint-disable-next-line no-control-regex
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
