import { lightTheme, Text } from '@holdbar-com/pixel';
import { styled } from '@mui/material';
import { KeyboardEvent } from 'react';

type BookingDetailsActionsPopoverItemProps = {
  label: string;
  onClick: () => void;
  disabled?: boolean;
};

function noOp() {}

export const BookingDetailsActionsPopoverItem = ({
  label,
  onClick,
  disabled,
}: BookingDetailsActionsPopoverItemProps) => {
  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      onClick();
    }
  };

  return (
    <StyledText
      tabIndex={0}
      fontSize="small"
      onClick={disabled ? noOp : onClick}
      onKeyDown={disabled ? noOp : handleKeyDown}
      disabled={disabled}
    >
      {label}
    </StyledText>
  );
};

const StyledText = styled(Text)<{ disabled?: boolean }>(
  {
    display: 'block',
    padding: 8,
    width: 200,
    cursor: 'pointer',
    ':hover': {
      backgroundColor: lightTheme.palette.neutral.n50,
    },
  },
  (props) => ({
    color: props.disabled ? lightTheme.palette.neutral.n300 : 'inherit',
  })
);
