import { Stack, styled } from '@mui/material';

import { SectionHeadline } from '../../../../Components/SectionHeadline/SectionHeadline';
import { useTranslate } from '../../../../Hooks/useTranslate';
import { opportunities } from '../domain/opportunities';
import { OpportunityCard } from './opportunity-card';

export const GrowthOpportunitiesSection = () => {
  const { t } = useTranslate('opportunities');

  return (
    <Stack gap={2}>
      <SectionHeadline title={t('title')} description={t('description')} />
      <StyledGrid>
        {opportunities.map((opportunity) => (
          <OpportunityCard key={opportunity.id} opportunity={opportunity} />
        ))}
      </StyledGrid>
    </Stack>
  );
};

const StyledGrid = styled('div')`
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr 1fr;
  padding-top: 8px;
`;
