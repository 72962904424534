import { TFunction } from 'i18next';
import { useMemo } from 'react';
import * as yup from 'yup';

import { REPORT_TYPES } from '../request-report-dialog';

export const useRequestReportFormValidation = (t: TFunction) =>
  useMemo(
    () =>
      yup.object().shape({
        type: yup
          .string()
          .required(t('required', 'utils.errors'))
          .oneOf(REPORT_TYPES, t('required', 'utils.errors')),
        startDate: yup.date().required(t('required', 'utils.errors')),
        endDate: yup.date().required(t('required', 'utils.errors')),
      }),
    [t]
  );

export type RequestReportDialogFormType = yup.InferType<
  ReturnType<typeof useRequestReportFormValidation>
>;
