import { Stack } from '@mui/material';

import { AllFilters } from '../filters/AllFilters';
import { CalendarViewSelector } from './CalendarViewSelector';
import { GroupByExperienceToggle } from './GroupByExperienceToggle';

export const CalendarDesktopFilters = () => {
  return (
    <Stack gap={4}>
      <Stack direction="row" alignItems="center" gap={4}>
        <CalendarViewSelector />
        <GroupByExperienceToggle />
      </Stack>
      <Stack direction="row" gap={1}>
        <AllFilters />
      </Stack>
    </Stack>
  );
};
