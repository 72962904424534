import { CalendarContextProvider } from '../features/calendar/context';
import { EventPopoverContextProvider } from '../features/calendar/desktop/event-popover/event-popover-context';
import { CalendarDesktopView } from '../features/calendar/desktop/View';
import { CalendarMobileView } from '../features/calendar/mobile/View';
import useResponsive from '../Hooks/layout/useResponsive';

export const CalendarPage = () => {
  const { isSm } = useResponsive();

  return (
    <CalendarContextProvider>
      {isSm ? (
        <CalendarMobileView />
      ) : (
        <EventPopoverContextProvider>
          <CalendarDesktopView />
        </EventPopoverContextProvider>
      )}
    </CalendarContextProvider>
  );
};
