import { useTranslate } from '../../../Hooks/useTranslate';
import { BookingStatus, useCalendar } from '../context';
import { Filter } from './view/Filter';

const states: Array<{ id: BookingStatus; translationKey: string }> = [
  {
    id: 'fully-booked',
    translationKey: 'fullyBooked',
  },
  {
    id: 'booked',
    translationKey: 'booked',
  },
  {
    id: 'no-bookings',
    translationKey: 'noBookings',
  },
];

export const BookingStatusFilter = () => {
  const { t } = useTranslate('');

  const { bookingStatusFilters, setBookingStatusFilters } = useCalendar();

  const options = states.map((state) => ({
    id: state.id,
    name: t(state.translationKey, 'utils.statusOptions'),
  }));

  return (
    <Filter
      inputId="calendar-booking-status-filter"
      label={t('bookingStatus', 'utils.generic')}
      options={options}
      checkedIds={bookingStatusFilters}
      onChange={(ids) => setBookingStatusFilters(ids as BookingStatus[])}
    />
  );
};
