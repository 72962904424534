import NiceModal from '@ebay/nice-modal-react';

import { useTranslate } from '../../../../../Hooks/useTranslate';
import { IcalDialog } from '../../../../../Modals/IcalDialog';
import { IntegrationObject } from '../use-integrations';

export const useIcalIntegration = (): IntegrationObject => {
  const { t } = useTranslate('settings.integrations');

  const handleCreateIcalFeed = (status: string) => async () => {
    if (status === 'connect') {
      NiceModal.show(IcalDialog, { onClick: async () => {} });
    }
  };

  return {
    id: 'ical',
    title: t('calendars.ical.title'),
    description: t('calendars.ical.description'),
    extendedDescription: t('calendars.ical.extendedDescription'),
    status: 'connect',
    icon: '/integrations/calendars/ical.png',
    onInstall: handleCreateIcalFeed,
    buttonLabel: (status: string) => t(`calendars.status.${status}`),
  };
};
