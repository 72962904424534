import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { CloseRounded } from '@mui/icons-material';
import { Box, Dialog, IconButton } from '@mui/material';
import { FC, useRef } from 'react';

import { Loading } from '../../Components/Loading/Loading';
import useResponsive from '../../Hooks/layout/useResponsive';
import { trackBookingCheckInCancelled } from '../../tracking/bookings/details';
import { TOPBAR_MIN_HEIGHT } from '../../Utils/theme';
import { useQRScanner } from './domain/use-qr-scanner';
import { TicketError } from './views/ticket-error';
import { TicketPopover } from './views/ticket-popover';

export const TicketScannerDialog = NiceModal.create(() => {
  const { isMd } = useResponsive();
  const modal = useModal();

  const onClose = () => {
    modal.hide();
    trackBookingCheckInCancelled('qr_code');
  };

  return (
    <Dialog
      sx={{ justifyContent: 'flex-end' }}
      fullWidth
      maxWidth={'md'}
      fullScreen={isMd}
      open={modal.visible}
      PaperProps={{
        sx: {
          background: 'linear-gradient(to bottom, white 90%, #eeeeee 100%)',
          display: 'flex',
          bottom: 0,
          left: 0,
          right: 0,
          top: TOPBAR_MIN_HEIGHT,
          height: 'auto',
          position: 'absolute',
        },
      }}
      onClose={onClose}
    >
      <IconButton
        onClick={onClose}
        sx={{ position: 'absolute', top: 16, right: 16, zIndex: 101 }}
      >
        <CloseRounded htmlColor="black" />
      </IconButton>
      <TicketScannerDialogContent />
    </Dialog>
  );
});

const TicketScannerDialogContent: FC = () => {
  const { status, scanResult, reset } = useQRScanner();
  const containerRef = useRef<HTMLDivElement>(null);

  if (status === 'denied') {
    return (
      <Box marginTop={4}>
        <TicketError error="camera-permission-denied" />
      </Box>
    );
  }

  if (status === 'no-camera') {
    return (
      <Box marginTop={4}>
        <TicketError error="no-camera" />
      </Box>
    );
  }

  return (
    <Box
      ref={containerRef}
      sx={{
        position: 'relative',
        flex: 1,
        overflow: 'hidden',
      }}
    >
      <video
        id="camera"
        preload="none"
        style={{
          position: 'absolute',
          bottom: 0,
          zIndex: 0,
          height: '100%',
          width: '100%',
          objectFit: 'cover',
        }}
      />
      {status === 'pending' && <Loading isLoading={true} />}
      <TicketPopover
        qrCodeValue={scanResult}
        containerRef={containerRef}
        onClose={() => {
          reset();
        }}
      />
    </Box>
  );
};
