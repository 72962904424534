import { useReducer } from 'react';

interface FieldValue {
  text: string;
  lengthHelperText?: string;
  lastAutoTranslation?: string;
  seen: boolean;
}

export interface FieldState {
  /** This is the first language in the list. The one other languages will be translated from. */
  primaryLanguage: string;
  /** This is the language which input field is currently shown. */
  activeLanguage: string;
  /** This is used to track whether the primary language was changed and translations should be requested */
  didPrimaryLanguageChange: boolean;
  /** This holds all the text field values in state along with some meta data. Key is language code. */
  values: { [key: string]: FieldValue };
  /** Tracks whether translations are in progress. */
  isTranslating: boolean;
}

type Action =
  | {
      type: 'CHANGE_LANGUAGE';
      payload: {
        language: string;
      };
    }
  | {
      type: 'CHANGE_VALUE';
      payload: {
        language: string;
        value: string;
        maxLength?: number;
      };
    }
  | {
      type: 'TOGGLE_IS_TRANSLATING';
    }
  | {
      type: 'SET_AUTO_TRANSLATION';
      payload: {
        language: string;
        text: string;
      };
    };

function fieldStateReducer(state: FieldState, action: Action): FieldState {
  switch (action.type) {
    case 'CHANGE_LANGUAGE':
      return {
        ...state,
        activeLanguage: action.payload.language,
        values: {
          ...state.values,
          [action.payload.language]: {
            ...state.values[action.payload.language],
            seen: true,
          },
        },
      };
    case 'CHANGE_VALUE': {
      const isPrimary = action.payload.language === state.primaryLanguage;
      return {
        ...state,
        didPrimaryLanguageChange: isPrimary
          ? true
          : state.didPrimaryLanguageChange,
        values: {
          ...state.values,
          [action.payload.language]: {
            ...state.values[action.payload.language],
            text: action.payload.value,
            lengthHelperText: getTextInputLengthHelperText(
              action.payload.maxLength,
              action.payload.value
            ),
          },
        },
      };
    }
    case 'TOGGLE_IS_TRANSLATING':
      return {
        ...state,
        didPrimaryLanguageChange: state.isTranslating
          ? false
          : state.didPrimaryLanguageChange,
        isTranslating: !state.isTranslating,
      };
    case 'SET_AUTO_TRANSLATION':
      return {
        ...state,
        values: {
          ...state.values,
          [action.payload.language]: {
            ...state.values[action.payload.language],
            text: action.payload.text,
            lastAutoTranslation: action.payload.text,
            seen: state.activeLanguage === action.payload.language,
          },
        },
      };
    default:
      return state;
  }
}

export function useFieldStateReducer({
  translatableLanguages,
  userLanguage,
  maxLength,
  initialValues,
}: {
  translatableLanguages: string[];
  userLanguage: string;
  maxLength?: number;
  initialValues: any;
}) {
  const [state, dispatch] = useReducer(
    fieldStateReducer,
    {
      translatableLanguages,
      userLanguage,
    },
    (params) => {
      const primaryLanguage = params.translatableLanguages.includes(
        params.userLanguage
      )
        ? userLanguage
        : params.translatableLanguages[0];

      const initial = initialValues();

      return {
        activeLanguage: primaryLanguage,
        primaryLanguage,
        values: translatableLanguages.reduce(
          (previous, language) => ({
            ...previous,
            [language]: getDefaultFieldValue(maxLength, initial[language]),
          }),
          {}
        ),
        isTranslating: false,
        didPrimaryLanguageChange: false,
      };
    }
  );

  return {
    state,
    changeLanguage: (language: string) =>
      dispatch({
        type: 'CHANGE_LANGUAGE',
        payload: {
          language,
        },
      }),
    changeValue: (language: string, value: string) =>
      dispatch({
        type: 'CHANGE_VALUE',
        payload: {
          language,
          value,
          maxLength,
        },
      }),
    startTranslating: () => dispatch({ type: 'TOGGLE_IS_TRANSLATING' }),
    endTranslating: () => dispatch({ type: 'TOGGLE_IS_TRANSLATING' }),
    setAutoTranslation: (language: string, text: string) =>
      dispatch({
        type: 'SET_AUTO_TRANSLATION',
        payload: {
          language,
          text,
        },
      }),
  };
}

export function getDefaultFieldValue(
  maxLength?: number,
  text?: string
): FieldValue {
  return {
    text: text ?? '',
    lengthHelperText: getTextInputLengthHelperText(maxLength, text),
    seen: true,
  };
}

function getTextInputLengthHelperText(maxLength?: number, value?: string) {
  if (!maxLength) {
    return undefined;
  }

  const length = value?.length ?? 0;
  return `${length}/${maxLength}`;
}
