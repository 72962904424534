import { Box, BoxProps } from '@mui/material';

import { LOGO, LOGO_BLACK, LOGO_WHITE } from '../../Utils/constants';

export const Logo = ({
  height = 24,
  variant = 'default',
  ...props
}: BoxProps & {
  variant?: 'default' | 'white' | 'purple';
}) => {
  return (
    <Box
      component={'img'}
      src={
        variant === 'default'
          ? LOGO_BLACK
          : variant === 'white'
            ? LOGO_WHITE
            : LOGO
      }
      height={height}
      alt={'Velkommen til Holdbar'}
      {...props}
    />
  );
};
