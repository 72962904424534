import { Box, TextField } from '@mui/material';
import {
  intervalToDuration,
  secondsInDay,
  secondsInHour,
  secondsInMinute,
} from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export type TimeSpanPickerProps = {
  initialValueSeconds: number;
  onChange: (totalSeconds: number) => void;
};

export const TimeSpanPicker = ({
  initialValueSeconds,
  onChange,
}: TimeSpanPickerProps) => {
  const duration = intervalToDuration({
    start: 0,
    end: (initialValueSeconds || 0) * 1000,
  });

  const [timeSpan, setTimeSpan] = useState({
    days: duration.days ?? 0,
    hours: duration.hours ?? 0,
    minutes: duration.minutes ?? 0,
  });

  const { t } = useTranslation();

  useEffect(() => {
    const daysSeconds = timeSpan.days * secondsInDay;
    const hoursSeconds = timeSpan.hours * secondsInHour;
    const minutesSeconds = timeSpan.minutes * secondsInMinute;
    const totalSeconds = daysSeconds + hoursSeconds + minutesSeconds;
    onChange(totalSeconds);
  }, [timeSpan]);

  return (
    <Box display="flex" alignItems="center" gap={2} p={2}>
      <TextField
        label={t('utils.generic.days')}
        type="number"
        value={timeSpan.days.toString()}
        fullWidth
        onChange={(e) =>
          setTimeSpan({
            ...timeSpan,
            days: parseInt(e.target.value, 10),
          })
        }
        InputProps={{ inputProps: { min: 0 } }}
      />
      <TextField
        label={t('utils.generic.hours')}
        type="number"
        value={timeSpan.hours.toString()}
        fullWidth
        onChange={(e) =>
          setTimeSpan({
            ...timeSpan,
            hours: parseInt(e.target.value, 10),
          })
        }
        InputProps={{ inputProps: { min: 0, max: 23 } }}
      />
      <TextField
        label={t('utils.generic.minutes')}
        type="number"
        value={timeSpan.minutes.toString()}
        fullWidth
        onChange={(e) =>
          setTimeSpan({
            ...timeSpan,
            minutes: parseInt(e.target.value, 10),
          })
        }
        InputProps={{ inputProps: { min: 0, max: 59 } }}
      />
    </Box>
  );
};
