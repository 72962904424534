import { Box } from '@mui/material';
import { ComponentPropsWithoutRef } from 'react';

import { useAuth } from '../../Hooks/useAuth';

export const EnableForScope = ({
  children,
  scopes,
}: ComponentPropsWithoutRef<any> & { scopes: string[] }) => {
  const { canAccess } = useAuth();
  if (scopes.some((scope) => canAccess(scope))) {
    return <>{children}</>;
  }
  return <Box sx={{ pointerEvents: 'none' }}>{children}</Box>;
};

export const ShowForScope = ({
  children,
  scopes,
}: ComponentPropsWithoutRef<any> & { scopes: string[] }) => {
  const { canAccess } = useAuth();
  if (scopes.some((scope) => canAccess(scope))) {
    return <>{children}</>;
  }
  return <></>;
};
