import { Controller } from 'react-hook-form';

import { LanguageChips } from '../../../../../Components/LanguageChips/LanguageChips';
import { useTranslate } from '../../../../../Hooks/useTranslate';
import { SyiSection } from '../../../../../Pages/SyiPage/SyiSection';
import { currencyOptions } from '../../../../../Utils/config';

export const CompanyFormCurrency = () => {
  const { t } = useTranslate('settings.company');

  return (
    <SyiSection title={t('defaultCurrency.title')}>
      <Controller
        name={'defaultCurrency'}
        render={({ field }) => (
          <LanguageChips
            onChange={(keys) => {
              field.onChange(keys[0]);
            }}
            shouldTranslate={false}
            languageOptions={currencyOptions}
            languages={field.value}
            multiple={false}
          />
        )}
      />
    </SyiSection>
  );
};
