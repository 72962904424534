import { useQuery, useQueryClient } from 'react-query';

import { getEconomicVatAccounts } from '../../../Api';
import { useProfile } from '../../../Hooks/useProfile';
import { EconomicVatAccountsQueryKey } from './economic-query-keys';

export const useEconomicVatAccounts = () => {
  const { company } = useProfile();
  const queryClient = useQueryClient();

  return useQuery(
    EconomicVatAccountsQueryKey,
    async () => {
      await queryClient.cancelQueries(EconomicVatAccountsQueryKey);
      return await getEconomicVatAccounts();
    },
    {
      enabled: !!company.data?.id,
    }
  );
};
