import { Experience } from '@holdbar-com/utils-types';

import { ampli } from '../../../../Ampli';
import { TEvent } from '../../../../Utils/eventHelpers';
import { eventProperties } from './eventProperties';

export const trackExperienceEventCancellationCompleted = (
  event_id: string,
  is_cancelled_with_refund: boolean,
  text_content: string,
  events: TEvent[],
  experience?: Experience
) => {
  if (!experience) return;

  const properties = eventProperties(experience, events, event_id);

  ampli.experienceEventCancellationCompleted({
    ...properties,
    is_cancelled_with_refund,
    text_content,
  });
};
