import { Outlet } from 'react-router-dom';

import { PageTabs } from '../Components/Page/PageTabs';
import { SimpleSkeleton } from '../Components/SimpleSkeleton/SimpleSkeleton';
import { useProfile } from '../Hooks/useProfile';
import { useTranslate } from '../Hooks/useTranslate';
import { trackStorefrontImplementationViewed } from '../tracking/storefront/implementation/trackStorefrontImplementationViewed';

export const StorefrontPage = () => {
  const { t } = useTranslate('storefront');

  const { company } = useProfile();

  const tabs = [
    { key: 'customize', scopes: ['company.write'] },
    { key: 'features', scopes: ['company.write'] },
    {
      key: 'bookingflow',
      scopes: ['company.write'],
      trackingFn: trackStorefrontImplementationViewed,
    },
    { key: 'language', scopes: ['company.write'] },
  ];

  return (
    <PageTabs tabs={tabs} title={t('title')}>
      {!company.data ? <SimpleSkeleton /> : <Outlet />}
    </PageTabs>
  );
};
