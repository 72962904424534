import { MenuItem, Skeleton, TextField } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

import { useCountryVatRates } from "../../../../../../Hooks/useCountryVatRates";
import { useTranslate } from "../../../../../../Hooks/useTranslate";
import { StyledTextField } from "../../../../shared/StyledTextField";
import { filteredVatRates, getAllVatRates } from "../../../domain/helpers";

type CompanyFormVatRegistrationCategoryProps = {
  fixedWidth?: boolean;
};

export const CompanyFormVatRegistrationCategory = ({
  fixedWidth,
}: CompanyFormVatRegistrationCategoryProps) => {
  const { t } = useTranslate('');

  const { data: countryVatRates } = useCountryVatRates();

  const {
    watch,
    formState: { errors },
  } = useFormContext();

  const selectedVatCountry = watch('vatCompliance.vatRegistration.country');

  const Field = fixedWidth ? StyledTextField : TextField;

  if (!countryVatRates)
    return (
      <Skeleton
        variant="rectangular"
        width={fixedWidth ? 320 : '100%'}
        height={56}
      />
    );

  return (
    <Controller
      name={'vatCompliance.vatRegistration.defaultVatCategory'}
      defaultValue={''}
      render={({ field }) => (
        <Field
          {...field}
          fullWidth={!fixedWidth}
          value={field.value || ''}
          error={
            !!(errors.vatCompliance as any)?.vatRegistration?.defaultVatCategory
          }
          helperText={
            (errors.vatCompliance as any)?.vatRegistration?.defaultVatCategory
              ?.message || ''
          }
          select
          required
          label={t('defaultRate', 'settings.company.vatSettings')}
          disabled={!selectedVatCountry}
        >
          {getAllVatRates(countryVatRates, selectedVatCountry).map((rate) => {
            const [name, value] = Object.entries(rate)[0];
            const isExempt = value?.exempt;
            const isHidden = filteredVatRates.includes(name);

            return (
              <MenuItem
                key={name}
                value={name}
                style={{ display: isHidden ? 'none' : 'block' }}
              >
                {t(name, 'vat.categories')} (
                {isExempt
                  ? t('vatExempt', 'utils.generic')
                  : `${((value?.rate ?? value) * 100).toFixed()}%`}
                )
              </MenuItem>
            );
          })}
        </Field>
      )}
    />
  );
};
