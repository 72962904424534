import styled from '@emotion/styled';
import { FC } from 'react';

import { useTranslate } from '../../../../Hooks/useTranslate';

type Props = {
  options: Array<{ id: string; name: string }>;
  selected: Array<string>;
};

export const FilterValue: FC<Props> = ({ options, selected }) => {
  const { t } = useTranslate('');

  const allLabel = t('all', 'utils.tables.filters');

  const filtered = options.filter(({ id }) => selected.includes(id));

  const allSelected =
    filtered.length === 0 || filtered.length === options.length;
  if (allSelected) return <>{allLabel}</>;

  if (filtered.length === 1) return <Capitalize>{filtered[0].name}</Capitalize>;

  return (
    <>
      {filtered.length} {t('selected', 'utils.generic')}
    </>
  );
};

const Capitalize = styled('span')`
  text-transform: capitalize;
`;
