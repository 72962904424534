import { lightTheme } from '@holdbar-com/pixel';
import { ChevronRightRounded } from '@mui/icons-material';
import { Stack, styled } from '@mui/material';
import React, { FC, PropsWithChildren } from 'react';

import { PageBreadcrumbContext } from '../../domain/use_page_breadcrumb_context';

export const PageBreadcrumbBreadcrumbs: FC<PropsWithChildren> = ({
  children,
}) => {
  const lastIndex = React.Children.count(children) - 1;

  return (
    <StyledBreadcrumbs
      sx={{
        display: {
          xs: 'none',
          lg: 'flex',
        },
      }}
    >
      {React.Children.map(children, (child, idx) => {
        const isFirst = idx === 0;
        const isLast = idx === lastIndex;

        return (
          <PageBreadcrumbContext.Provider
            value={{
              isFirst,
              isLast,
            }}
          >
            {!isFirst && (
              <ChevronRightRounded
                fontSize="small"
                htmlColor={lightTheme.palette.neutral.n300}
              />
            )}
            {child}
          </PageBreadcrumbContext.Provider>
        );
      })}
    </StyledBreadcrumbs>
  );
};

const StyledBreadcrumbs = styled(Stack)`
  min-height: 55px;
  flex-direction: row;
  gap: 4px;
  padding: 16px 24px;
  background-color: #ffffff;
  align-items: center;
`;
