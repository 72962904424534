import { lightTheme, Text } from '@holdbar-com/pixel';
import {
  ChatBubbleOutlineOutlined,
  FlagOutlined,
  LockOpenOutlined,
  LockOutlined,
  PersonRounded,
  PlaceOutlined,
} from '@mui/icons-material';
import { Box, Chip, Stack, styled } from '@mui/material';
import { secondsInDay } from 'date-fns';
import { useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';

import { useGetBookingsForEvent } from '../../../../../Hooks/data/useBookings';
import { useExperience } from '../../../../../Hooks/useExperience';
import { useTranslate } from '../../../../../Hooks/useTranslate';
import { TUser } from '../../../../../Hooks/useUsers';
import { trackEventDetailsViewed } from '../../../../../tracking/events/trackEventDetailsViewed';
import { languageOptions } from '../../../../../Utils/config';
import { getTicketTypes } from '../../../../events/event_details/BookingsSummary';
import { useEventPopover } from '../event-popover-context';
import { GuestListAccordion } from './GuestListAccordion';

export const PopoverEventDetails = ({
  updatePopoverPosition,
}: {
  updatePopoverPosition: () => void;
}) => {
  const { t, i18n } = useTranslate('calendar.popover');

  const { selectedEvent } = useEventPopover();

  const {
    bookingsForEvent: { data: bookingsForEvent },
  } = useGetBookingsForEvent(selectedEvent?.id ?? '');

  const {
    experience: { data: experience },
  } = useExperience(selectedEvent?.experienceId);

  const eventTickets = useMemo(
    () => getTicketTypes(i18n, bookingsForEvent, experience),
    [bookingsForEvent, experience, i18n]
  );

  const hasBookings = useMemo(
    () => Boolean(eventTickets && Object.entries(eventTickets.variants).length),
    [eventTickets]
  );

  const closedForBookings = useMemo(
    () =>
      selectedEvent &&
      !selectedEvent.states.statusIsCancelled &&
      !selectedEvent.states.isBeforeCutoff,
    [selectedEvent]
  );

  useEffect(() => {
    updatePopoverPosition();
  }, [updatePopoverPosition, eventTickets]);

  const isPrivateEvent = selectedEvent?.visibility === 'private';

  const rows = useMemo(
    () => [
      {
        key: 'address',
        icon: PlaceOutlined,
        content: <Text>{selectedEvent?.location ?? t('noAddress')}</Text>,
      },
      {
        key: 'guests',
        icon: PersonRounded,
        content: (
          <Stack>
            <Stack direction="row" alignItems="center" gap={1}>
              <Text textTransform="lowercase">
                {selectedEvent?.slots.booked} / {selectedEvent?.slots.total}{' '}
                {t('guests', 'utils.generic')}
              </Text>
              <StyledChip
                color={
                  (selectedEvent?.slots.booked ?? 0) <
                    (selectedEvent?.slots.total ?? 0)
                    ? 'success'
                    : 'error'
                }
              />
            </Stack>
            {eventTickets && hasBookings && (
              <Stack>
                <Stack>
                  {Object.entries(eventTickets!.variants).map(
                    ([name, count]) => (
                      <Text
                        key={name}
                        fontSize="medium"
                        color={lightTheme.palette.neutral.n300}
                      >
                        {count} x {name}
                      </Text>
                    )
                  )}
                </Stack>
                <GuestListAccordion
                  bookingsForEvent={bookingsForEvent}
                  experience={experience}
                  updatePopoverPosition={updatePopoverPosition}
                />
              </Stack>
            )}
          </Stack>
        ),
      },
      {
        key: 'openForBooking',
        icon:
          isPrivateEvent || closedForBookings ? LockOutlined : LockOpenOutlined,
        content: (
          <Stack>
            <Text>
              {isPrivateEvent
                ? t('private', 'events.detailsPage.card.visibility')
                : closedForBookings
                  ? t('closedForBookings')
                  : t('openForBookings')}
            </Text>
            {Boolean(experience?.cutoffTimeSeconds) && (
              <Text color={lightTheme.palette.neutral.n300}>
                {t('closeBookingsBeforeDays', {
                  days: (experience?.cutoffTimeSeconds ?? 0) / secondsInDay, // TODO: Should we change the translation here?
                })}
              </Text>
            )}
          </Stack>
        ),
      },
      {
        key: 'guides',
        icon: FlagOutlined,
        content: renderGuides(selectedEvent?.guides ?? [], t('noGuide')),
      },
      {
        key: 'languages',
        icon: ChatBubbleOutlineOutlined,
        content:
          selectedEvent && selectedEvent.languages.length > 0
            ? selectedEvent.languages
              .map((language) => languageOptions(language).label)
              .join(', ')
            : null,
      },
    ],
    [
      selectedEvent,
      t,
      eventTickets,
      hasBookings,
      bookingsForEvent,
      experience,
      updatePopoverPosition,
      isPrivateEvent,
      closedForBookings,
    ]
  );

  const handleClick = () => {
    trackEventDetailsViewed('Event Details Popup');
  };

  return (
    <StyledStack>
      {rows.map((row) => {
        if (!row.content) return null;

        const Icon = row.icon;
        return (
          <StyledElementStack key={row.key}>
            <Icon />
            {row.content}
          </StyledElementStack>
        );
      })}
      <StyledBox>
        <StyledLink to={`/event/${selectedEvent?.id}`} onClick={handleClick}>
          {t('eventDetailsLink')}
        </StyledLink>
      </StyledBox>
    </StyledStack>
  );
};

const StyledStack = styled(Stack)({
  gap: 16,
  padding: '32px 32px 48px 48px',
});

const StyledElementStack = styled(Stack)({
  flexDirection: 'row',
  gap: 32,
});

const StyledBox = styled(Box)({
  marginTop: 8,
});

const StyledLink = styled(Link)`
  color: ${lightTheme.palette.action.a300};
  &:hover {
    text-decoration: underline;
  }
`;

const StyledChip = styled(Chip)({
  height: 10,
  width: 10,
  borderRadius: '100%',
});

const renderGuides = (guides: TUser[], noGuidesText: string) => {
  if (!guides.length) return noGuidesText;

  return guides.map(({ name, email }) => name ?? email).join(', ');
};
