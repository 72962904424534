import { useTranslation } from 'react-i18next';

import { Link } from '../../../Components/Link/Link';

export const GeneratingPreviewError = () => {
  const { t } = useTranslation();

  return (
    <>
      <div>{t('auth.preview.previewForm.error')}</div>
      <Link href={'/preview'}>{t('dialogs.errorRetry.confirmLabel')}</Link>
    </>
  );
};
