import { Text } from '@holdbar-com/pixel';
import { Skeleton, Stack } from '@mui/material';

import { useTranslate } from '../../../../../../../Hooks/useTranslate';
import { useBookingDetailsContext } from '../../../../domain/use_booking_details_context';
import { bookingRows } from '../../domain/use_booking_details_summary';

export const BookingDetailsSummaryBookingMobile = () => {
  const { t, i18n } = useTranslate('dialogs.booking');

  const { booking, eventMovedFrom, eventMovedTo } = useBookingDetailsContext();

  return (
    <Stack gap={2}>
      <Text variant="medium">{t('sections.booking')}</Text>
      {bookingRows.map((row, index) => {
        if (
          booking &&
          !row.value(booking, t, {
            language: i18n.language ?? 'da',
            eventMovedFrom,
            eventMovedTo,
          })
        ) {
          return null;
        }

        return (
          <Stack key={index} gap={0.5}>
            <Text fontSize="small" variant="medium">
              {t(row.key)}
            </Text>
            <Text fontSize="small">
              {booking ? (
                row.value(booking, t, {
                  language: i18n.language ?? 'da',
                  eventMovedFrom,
                  eventMovedTo,
                })
              ) : (
                <Skeleton key={row.key} width="50%" />
              )}
            </Text>
          </Stack>
        );
      })}
    </Stack>
  );
};
