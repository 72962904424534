import { TextField } from '@mui/material';
import { useEffect } from 'react';
import { FieldError, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useExperience } from '../../../../../../../Hooks/useExperience';
import { trackEventFlowSeatsSelected } from '../../../../../../../tracking/events/flow/details/form/trackEventFlowSeatsSelected';
import { trackEventFlowSeatsSelectionOpened } from '../../../../../../../tracking/events/flow/details/form/trackEventFlowSeatsSelectionOpened';
import { useEventUpsert } from '../../../../domain/event_upsert_context';

export const EventUpsertDetailsFormSeatsField = () => {
  const { t } = useTranslation();
  const { currentEvent, updateEventField } = useEventUpsert();

  const {
    setValue,
    register,
    watch,
    formState: { errors },
  } = useFormContext();

  const selectedExperienceId = watch('experienceId');
  const selectedValue = watch('seatCount.value');
  const error = errors['seatCount'] as (FieldError & any) | undefined;

  const {
    experience: { data: experience, isLoading },
  } = useExperience(selectedExperienceId);

  useEffect(() => {
    if (!isLoading && experience && Object.keys(experience).length) {
      const defaultSeatCount = {
        selectedOptionKey: 'experienceDefault',
        value: 0,
      };

      const seatCountValue =
        currentEvent?.seatCount?.selectedOptionKey === 'custom'
          ? currentEvent?.seatCount
          : experience?.seats
            ? {
                selectedOptionKey: 'experienceDefault',
                value: experience.seats.seatCount,
              }
            : defaultSeatCount;

      setValue('seatCount', seatCountValue);
      updateEventField('seatCount', seatCountValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [experience]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = Number(event.target.value);

    setValue(
      'seatCount',
      {
        selectedOptionKey: 'custom',
        value: inputValue,
      },
      { shouldValidate: true }
    );
    updateEventField('seatCount', {
      selectedOptionKey: 'custom',
      value: inputValue,
    });
    trackEventFlowSeatsSelected(inputValue);
  };

  return (
    <TextField
      {...register('seatCount.value')}
      label={t('eventUpsert.flow.details.form.seats.label')}
      onChange={handleChange}
      onFocus={trackEventFlowSeatsSelectionOpened}
      value={selectedValue ?? ''}
      error={!!error}
      helperText={error?.value?.message ?? ''}
      required
      type="number"
      InputProps={{ inputProps: { min: 0 } }}
    />
  );
};
