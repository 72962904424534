import styled from '@emotion/styled';
import { Text } from '@holdbar-com/pixel';
import { Radio, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { trackEventFlowPrivateStatusSelected } from '../../../../../../tracking/events/flow/details/form/trackEventFlowPrivateStatusSelected';
import { useEventUpsert } from '../../../domain/event_upsert_context';

export const EventUpsertDetailsVisibility = () => {
  const { t } = useTranslation();
  const { currentEvent, updateEventField } = useEventUpsert();

  const isPublic = currentEvent.visibility === 'public';
  const isPrivate = currentEvent.visibility === 'private';

  const onSelect = (visibility: 'public' | 'private') => {
    updateEventField('visibility', visibility);
  };

  return (
    <Stack gap={2}>
      <Stack
        direction="row"
        alignItems="center"
        width="fit-content"
        gap={1}
        sx={{ cursor: 'pointer' }}
        onClick={() => onSelect('public')}
      >
        <StyledRadio checked={isPublic} />
        <Text fontSize="small">
          {t('eventUpsert.flow.details.form.visibility.public')}
        </Text>
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        width="fit-content"
        gap={1}
        sx={{ cursor: 'pointer' }}
        onClick={() => {
          onSelect('private');
          trackEventFlowPrivateStatusSelected();
        }}
      >
        <StyledRadio checked={isPrivate} />
        <Text fontSize="small">
          {t('eventUpsert.flow.details.form.visibility.private')}
        </Text>
      </Stack>
    </Stack>
  );
};

const StyledRadio = styled(Radio)`
  padding: 0 !important;
  margin: 0 !important;
`;
