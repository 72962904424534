import { Button, lightTheme, Text, TextProps } from '@holdbar-com/pixel';
import { AddRounded } from '@mui/icons-material';
import { Box, Stack } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ReactNode } from 'react';

import { ShowForScope } from '../Components/AllowForScope/AllowForScope';
import { Page } from '../Components/Page/Page';
import useResponsive from '../Hooks/layout/useResponsive';

interface ICrudPage {
  title: string;
  titleProps?: TextProps;
  description?: string;
  createButtonLabel?: string;
  createEventButtonLabel?: string;
  filters?: ReactNode;
  children?: ReactNode;
  contextMenu?: ReactNode;
  baseScope: string;
  handleCreate?: () => void;
  handleCreateEvent?: () => void;
}

export const CrudPage = ({
  title,
  titleProps,
  description,
  filters,
  createButtonLabel = 'Opret',
  createEventButtonLabel = 'Opret begivenhed',
  baseScope,
  children,
  contextMenu,
  handleCreate,
  handleCreateEvent,
}: ICrudPage) => {
  const flags = useFlags();
  const { isMd } = useResponsive();

  return (
    <Page
      sx={{
        maxWidth: '1400px',
        pr: { xs: 0, md: 2 },
        pb: { xs: 0, md: 10 },
      }}
    >
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'flex-start'}
      >
        <Stack>
          <Text fontSize={'h5'} {...titleProps}>
            {title}
          </Text>
          {description && (
            <Text fontSize="xsmall" color={lightTheme.palette.neutral.n300}>
              {description}
            </Text>
          )}
        </Stack>
        {handleCreate && (
          <ShowForScope scopes={[`${baseScope}.write`]}>
            <Stack direction={'row'} spacing={2} alignItems={'center'}>
              <Stack direction={'row'} spacing={1}>
                {filters}
              </Stack>
              {handleCreateEvent && flags.featureEventV2Create && !isMd && (
                <Button
                  onClick={handleCreateEvent}
                  variant="secondary"
                  size="medium"
                  leftIcon={<StyledAddIcon />}
                >
                  {createEventButtonLabel}
                </Button>
              )}
              {!isMd && (
                <Button
                  onClick={handleCreate}
                  variant="primary"
                  size="medium"
                  leftIcon={<StyledAddIcon />}
                  data-intercom-target="create-button-action"
                >
                  {createButtonLabel}
                </Button>
              )}
            </Stack>
          </ShowForScope>
        )}
        {isMd && contextMenu && contextMenu}
      </Stack>
      <Box sx={{ marginTop: 3 }}>{children}</Box>
    </Page>
  );
};

const StyledAddIcon = () => <AddRounded fontSize="small" sx={{ mr: 1 }} />;
