import styled from '@emotion/styled';
import { Text } from '@holdbar-com/pixel';
import {
  KeyboardArrowDownRounded,
  KeyboardArrowUpRounded,
} from '@mui/icons-material';
import { Box, Button, capitalize, Stack, TextField } from '@mui/material';
import { FC, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import { IExperience } from '../../../../Hooks/useExperience';
import { useTranslate } from '../../../../Hooks/useTranslate';
import { CreateBookingFormInputs } from '../create-booking-form';

type CustomDataInputsProps = {
  experience: IExperience;
  guestCount: number;
};

export const CustomDataInputs: FC<CustomDataInputsProps> = ({
  experience,
  guestCount,
}) => {
  const { t } = useTranslate('dialogs.createBooking');
  const [showInputs, setShowInputs] = useState(false);

  if (!guestCount) return null;

  const inputs = experience?.customData?.yes;

  if (!inputs?.length) return null;

  return (
    <Box position="relative">
      <StyledButton
        type="button"
        onClick={() => setShowInputs((show) => !show)}
        endIcon={
          showInputs ? <KeyboardArrowUpRounded /> : <KeyboardArrowDownRounded />
        }
      >
        {t('actions.showCustomInputs')}
      </StyledButton>
      {showInputs && (
        <Stack
          gap={4}
          marginTop={2}
          maxHeight={!showInputs ? 150 : 'unset'}
          overflow="hidden"
        >
          {Array.from({ length: guestCount }).map((_, guestIndex) => (
            <Stack key={`guest-${guestIndex}`} gap={2}>
              <Text>{`${capitalize(t('guest', 'utils.generic'))} ${guestIndex + 1}`}</Text>
              {inputs?.map((input, inputIndex) => (
                <CustomDataInput
                  key={`${guestIndex}-input-${inputIndex}`}
                  guestIndex={guestIndex}
                  inputIndex={inputIndex}
                  inputName={input.value}
                />
              ))}
            </Stack>
          ))}
        </Stack>
      )}
    </Box>
  );
};

const StyledButton = styled(Button)({
  fontWeight: 400,
  height: 'unset',
  marginTop: 16,
  padding: 0,
  display: 'flex',
  alignItems: 'center',
  ':hover': {
    background: 'unset',
  },
});

type CustomDataInputProps = {
  guestIndex: number;
  inputIndex: number;
  inputName: string;
};

const CustomDataInput: FC<CustomDataInputProps> = ({
  guestIndex,
  inputIndex,
  inputName,
}) => {
  const { control, register } = useFormContext<CreateBookingFormInputs>();

  const { field: customFieldInput } = useController({
    name: `customDataInputs.${guestIndex}.inputs.${inputIndex}`,
    control,
    defaultValue: { name: inputName, value: '' },
  });

  return (
    <TextField
      {...register(`${customFieldInput.name}.value`)}
      label={inputName}
      fullWidth
    />
  );
};
