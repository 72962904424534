import { Box, BoxProps, Stack, Typography } from '@mui/material';

import { CloseButton } from '../Components/CloseButton/CloseButton';

export const Header = ({
  children,
  title,
  id,
  onClose,
  ...props
}: BoxProps & { title?: string; id?: string; onClose: () => void }) => {
  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      alignItems={'flex-start'}
      p={0}
      {...props}
    >
      <Stack direction={'row'} spacing={0}>
        <CloseButton
          sx={{
            '&.MuiIconButton-root:hover': {
              background: 'transparent',
            },
            border: 0,
            pl: 0,
            pb: '21px',
            color: '#464646',
          }}
          onClick={onClose}
        />
      </Stack>

      <Box>
        <Typography fontSize={{ xs: '24px', md: '32px' }} variant={'h3'}>
          {title}
        </Typography>
        {id && (
          <Typography variant={'body2'}>
            <strong>ID</strong>: {id}
          </Typography>
        )}
      </Box>
    </Box>
  );
};
