import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { CopyAllRounded, DoneRounded } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  Divider,
  Grid,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import copy from 'copy-to-clipboard';
import isEmpty from 'lodash.isempty';
import React, { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm, useFormState, useWatch } from 'react-hook-form';

import { CustomChip } from '../Components/CustomChip/CustomChip';
import { LanguageChips } from '../Components/LanguageChips/LanguageChips';
import { Markdown } from '../Components/Markdown/Markdown';
import {
  OptionGroup,
  OptionGroupSelectedSection,
} from '../Components/OptionGroup/OptionGroup';
import { SimpleSkeleton } from '../Components/SimpleSkeleton/SimpleSkeleton';
import { useCalendarFeed } from '../Hooks/useCalendarFeed';
import { useTranslate } from '../Hooks/useTranslate';
import { useUsers } from '../Hooks/useUsers';
import { SyiSection } from '../Pages/SyiPage/SyiSection';
import { Header } from './Header';

export const onlyEventsWithBookingsOptions = [
  {
    key: true,
    label: 'Nej, kun begivenheder der indeholder bookings',
  },
  {
    key: false,
    label: 'Ja, alle begivenheder skal synkroniseres',
  },
];

export const filterGuidesOptions = [
  {
    key: false,
    label: 'Nej, alt skal med over',
  },
  {
    key: true,
    label: 'Ja, lad mig vælge hvilke guides skal med',
  },
];

const languageOptions = [
  {
    key: false,
    label: 'Nej, de skal bare være på engelsk',
  },
  {
    key: true,
    label: 'Ja, lad mig definere hvilket sprog',
  },
];

type Option<T> = {
  selectedOptionKey: boolean;
  value: T;
};

type FormValues = {
  onlyEventsWithBookings: Option<never>;
  filterGuides: Option<string[]>;
  language: Option<string | null>;
};

const getUrl = (accessKey?: string) => {
  if (!accessKey) {
    return undefined;
  }
  return `${process.env.REACT_APP_API_CALENDAR_FEEDS_URL ?? ''}/${accessKey}`;
};

export const IcalDialog = NiceModal.create(
  ({ onClick }: { onClick: () => Promise<void> }) => {
    const { t } = useTranslate('dialogs.ical');

    const [page, setPage] = useState(-1);

    const [hasLoadedInitially, setHasLoadedInitially] = useState(false);
    const [copied, setCopied] = useState(false);
    const [loading, setLoading] = useState(false);

    const modal = useModal();

    const { feed, updateFeed, deleteFeed, accessKey } = useCalendarFeed();
    const {
      users: { data: users },
    } = useUsers();

    const { control, setValue, handleSubmit, formState, ...methods } =
      useForm<FormValues>({
        defaultValues: {
          onlyEventsWithBookings: {
            selectedOptionKey: false,
          },
          filterGuides: {
            selectedOptionKey: false,
            value: [],
          },
          language: {
            selectedOptionKey: false,
            value: null,
          },
        },
      });

    const selectedGuides = useWatch({ name: 'filterGuides.value', control });
    const languages = useWatch({ name: 'language.value', control });

    useEffect(() => {
      if (!hasLoadedInitially && !feed.isLoading) {
        if (!isEmpty(feed.data)) {
          for (const [key, value] of Object.entries(feed.data)) {
            const isEnabled =
              value === null || value === undefined
                ? false
                : typeof value === 'boolean'
                  ? value
                  : true;
            setValue(
              key as keyof FormValues,
              {
                selectedOptionKey: isEnabled,
                value: Array.isArray(value) ? [...value] : (value as any),
              },
              { shouldTouch: true }
            );
          }
          setHasLoadedInitially(true);
          return;
        }
        setPage((p) => (p === -1 ? 0 : p));
      }
    }, [feed, hasLoadedInitially]);

    useEffect(() => {
      if (hasLoadedInitially) {
        setPage(2);
      }
    }, [hasLoadedInitially]);

    const handleClose = () => {
      modal.reject();
      modal.remove();
    };

    const handleCopyLink = () => {
      setCopied(true);
      copy(getUrl(accessKey) as string);
      setTimeout(() => setCopied(false), 2500);
    };

    const handleToggleGuide = useCallback(
      (id: string) => () => {
        if (selectedGuides?.some((v) => v === id)) {
          setValue(
            'filterGuides.value',
            [...(selectedGuides?.filter((f) => f !== id) ?? [])],
            { shouldDirty: true }
          );
          return;
        }
        setValue('filterGuides.value', [...(selectedGuides ?? []), id], {
          shouldDirty: true,
        });
      },
      [selectedGuides]
    );

    const onSubmit = async (values: FormValues) => {
      setLoading(true);
      try {
        const {
          onlyEventsWithBookings: { selectedOptionKey: onlyEventsWithBookings },
          filterGuides,
          language,
        } = values;

        const payload = {
          language: language.value ?? 'en',
          ...(filterGuides.selectedOptionKey &&
            filterGuides.value.length > 0 && {
              filterGuides: [...filterGuides.value],
            }),
          onlyEventsWithBookings,
        };
        await new Promise((res) => setTimeout(res, 1200));
        updateFeed.mutate(payload);

        setPage(2);
      } finally {
        setLoading(false);
      }
    };

    const handleChangeLanguage = (values: string[]) => {
      const [newLanguage] = values;
      if (newLanguage) {
        setValue('language.value', newLanguage, { shouldDirty: true });
      }
    };

    const handleCancelEdit = () => {
      setHasLoadedInitially(false);
      methods.reset();
    };

    const handleDelete = async () => {
      if (window.confirm('Er du sikker på at slette?')) {
        setLoading(true);
        try {
          await deleteFeed.mutateAsync();
          handleCancelEdit();
          setPage(0);
        } finally {
          setLoading(false);
        }
      }
    };

    return (
      <Dialog
        open={modal.visible}
        onClose={handleClose}
        PaperProps={{ sx: { maxWidth: 800 } }}
      >
        <FormProvider
          {...{ control, setValue, formState, handleSubmit, ...methods }}
        >
          <Box pl={4}>
            <Header title={t('title')} onClose={handleClose} />

            <Stack pt={3} pb={4} pr={4} direction={'row'} spacing={3}>
              <Box maxWidth={500}>
                {page === -1 && <SimpleSkeleton />}

                {page === 0 && (
                  <>
                    <Typography variant={'h4'} fontSize={'1.2em'} mb={2}>
                      {t('subtitle')}
                    </Typography>
                    <Typography whiteSpace={'pre-wrap'}>
                      <Markdown>{t('description')}</Markdown>
                    </Typography>
                    <Stack
                      mt={4}
                      direction={'row'}
                      spacing={2}
                      alignItems={'center'}
                    >
                      <Button
                        variant={'outlined'}
                        color={'secondary'}
                        size={'large'}
                        disabled={loading}
                        onClick={handleClose}
                      >
                        {t('actions.secondary')}
                      </Button>
                      <Button
                        variant={'contained'}
                        size={'large'}
                        disabled={loading}
                        onClick={() => setPage(1)}
                      >
                        {loading ? (
                          <CircularProgress
                            color={'inherit'}
                            size={20}
                            sx={{ m: 0 }}
                          />
                        ) : (
                          t('actions.primary')
                        )}
                      </Button>
                    </Stack>
                  </>
                )}

                {page === 1 && (
                  <Box component={'form'} onSubmit={handleSubmit(onSubmit)}>
                    <Stack divider={<Divider />} spacing={3}>
                      <SyiSection
                        title={
                          'Skal alle Holdbar begivenheder vises i din kalender?'
                        }
                      >
                        <OptionGroup
                          name={'onlyEventsWithBookings.selectedOptionKey'}
                          options={onlyEventsWithBookingsOptions}
                        />
                      </SyiSection>
                      <SyiSection
                        title={
                          'Er det kun nogle bestemte guides begivenheder der skal synkroniseres?'
                        }
                      >
                        <OptionGroup
                          name={'filterGuides.selectedOptionKey'}
                          options={filterGuidesOptions}
                        >
                          <OptionGroupSelectedSection optionKey={true} mt={2}>
                            <Grid container gap={1}>
                              {users?.map((el) => {
                                return (
                                  <Grid
                                    item
                                    component={CustomChip}
                                    avatar={
                                      <Avatar
                                        alt={el.name}
                                        src={el.pictures?.profile?.url}
                                      />
                                    }
                                    label={el.name}
                                    onClick={handleToggleGuide(el.id!)}
                                    selected={selectedGuides?.includes(el.id!)}
                                    deleteIcon={<DoneRounded />}
                                    sx={{ height: 48, borderRadius: 100 }}
                                  />
                                );
                              })}
                            </Grid>
                          </OptionGroupSelectedSection>
                        </OptionGroup>
                      </SyiSection>
                      <SyiSection
                        title={'Skal sproget i begivenhederne oversættes?'}
                      >
                        <OptionGroup
                          name={'language.selectedOptionKey'}
                          options={languageOptions}
                        >
                          <OptionGroupSelectedSection optionKey={true} mt={2}>
                            <LanguageChips
                              onChange={handleChangeLanguage}
                              languages={[languages ?? '']}
                              multiple={false}
                            />
                          </OptionGroupSelectedSection>
                        </OptionGroup>
                      </SyiSection>
                    </Stack>
                    <Stack
                      mt={4}
                      direction={'row'}
                      spacing={2}
                      alignItems={'center'}
                    >
                      {!hasLoadedInitially && (
                        <Button
                          variant={'outlined'}
                          color={'secondary'}
                          size={'large'}
                          disabled={loading}
                          onClick={() => setPage((p) => p - 1)}
                        >
                          {t('back', 'utils.generic')}
                        </Button>
                      )}
                      {hasLoadedInitially && (
                        <Button
                          variant={'outlined'}
                          color={'secondary'}
                          size={'large'}
                          disabled={loading}
                          onClick={handleCancelEdit}
                        >
                          {t('back', 'utils.generic')}
                        </Button>
                      )}
                      <Button
                        type={'submit'}
                        sx={{ mt: 3 }}
                        variant={'contained'}
                        size={'large'}
                        disabled={loading}
                      >
                        {loading ? (
                          <CircularProgress
                            color={'inherit'}
                            size={20}
                            sx={{ m: 0 }}
                          />
                        ) : (
                          t(
                            hasLoadedInitially ? 'update' : 'next',
                            'utils.generic'
                          )
                        )}
                      </Button>
                    </Stack>
                  </Box>
                )}

                {page === 2 && (
                  <>
                    <Typography variant={'h4'} fontSize={'1.2em'} mb={2}>
                      🎉 Dit iCal feed er klar til synkronisering 🎉
                    </Typography>

                    <TextField
                      helperText={
                        'Kopier linket og følg vores guide til opsætning herunder'
                      }
                      label={'iCal link'}
                      required
                      fullWidth
                      multiline
                      disabled={true}
                      InputProps={{
                        endAdornment: (
                          <Tooltip
                            open={copied}
                            title={'Kopieret til udklipsholderen'}
                          >
                            <IconButton onClick={handleCopyLink} sx={{ ml: 2 }}>
                              <CopyAllRounded />
                            </IconButton>
                          </Tooltip>
                        ),
                      }}
                      value={getUrl(accessKey) ?? 'Genererer link...'}
                    />

                    <Box mt={8}>
                      <Typography fontWeight={600}>Brug for hjælp?</Typography>
                      <Typography mt={1}>
                        Følg vores guide holdbar.com/guides/connect-ical-feed
                        for at opsætte forbindelse til din egen kalender.
                      </Typography>
                    </Box>

                    <Stack
                      mt={4}
                      direction={'row'}
                      alignItems={'center'}
                      justifyContent={'space-between'}
                    >
                      <Stack direction={'row'} spacing={2}>
                        <Button
                          variant={'outlined'}
                          color={'secondary'}
                          size={'large'}
                          disabled={loading}
                          onClick={() => setPage((p) => p - 1)}
                        >
                          {t('edit', 'utils.generic')}
                        </Button>
                        {!hasLoadedInitially && (
                          <Button
                            sx={{ mt: 3 }}
                            variant={'contained'}
                            size={'large'}
                            disabled={loading}
                            onClick={handleClose}
                          >
                            {loading ? (
                              <CircularProgress
                                color={'inherit'}
                                size={20}
                                sx={{ m: 0 }}
                              />
                            ) : (
                              t('complete', 'utils.generic')
                            )}
                          </Button>
                        )}
                      </Stack>
                      <Button
                        variant={'outlined'}
                        color={'error'}
                        size={'large'}
                        onClick={handleDelete}
                        disabled={loading}
                      >
                        {t('delete', 'utils.generic')}
                      </Button>
                    </Stack>
                  </>
                )}
              </Box>
              <Box
                component={'img'}
                src={'/integrations/calendars/ical-illustration.svg'}
                height={'100%'}
                minWidth={280}
                minHeight={260}
              />
            </Stack>
          </Box>
        </FormProvider>
      </Dialog>
    );
  }
);
