import NiceModal from '@ebay/nice-modal-react';

import { useExperience } from '../../../../Hooks/useExperience';
import { CreateBookingDialog } from '../../../../Modals/create-booking/create-booking-dialog';
import { EventSourceType } from '../../../../tracking/events/eventProperties';
import { trackBookingAddedStarted } from '../../../../tracking/events/trackEventBookingAddedStarted';
import { TEventWithTitle } from '../../../../Utils/eventHelpers';
import { isCompleted } from './shared';

export const useAddBookingAction = (
  source: EventSourceType,
  event?: TEventWithTitle
) => {
  const {
    experience: { data: experience },
  } = useExperience(event?.experienceId);

  const showAddBookingDialog = async () => {
    trackBookingAddedStarted(event!, experience, source);

    const [callback] = await NiceModal.show<[() => Promise<string>, string]>(
      CreateBookingDialog,
      {
        eventId: event?.id,
        experience,
      }
    );

    const newEventId = await callback();
    return newEventId;
  };

  const isDisabled =
    isCompleted(event) ||
    event?.status === 'cancelled' ||
    event?.slots.total === event?.slots.booked;

  return { showAddBookingDialog, isDisabled };
};
