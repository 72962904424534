import { TFunction } from 'i18next';
import * as yup from 'yup';

export const usePreviewFormValidation = (t: TFunction) =>
  yup.object({
    website: yup.string().required(t('utils.errors.required')),
  });

export type PreviewFormData = yup.InferType<
  ReturnType<typeof usePreviewFormValidation>
>;
