import { useCalendar } from '../context';
import { CalendarDayList } from './day/List';
import { CalendarMonthList } from './month/CalendarMonthList';
import { CalendarWeekList } from './week/List/CalendarWeekList';

export const CalendarDesktopList = () => {
  const { selectedView } = useCalendar();

  if (selectedView === 'week') {
    return <CalendarWeekList />;
  }

  if (selectedView === 'month') {
    return <CalendarMonthList />;
  }

  return <CalendarDayList />;
};
