import { lightTheme, Text } from "@holdbar-com/pixel";
import { InputAdornment, Skeleton, TextField } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

import { useCountryVatRates } from "../../../../../../Hooks/useCountryVatRates";
import { useTranslate } from "../../../../../../Hooks/useTranslate";
import { StyledTextField } from "../../../../shared/StyledTextField";
import { getDisplayVatNumber, getFullVatNumber } from "../../../domain/helpers";

type CompanyFormVatRegistrationNumberProps = {
  fixedWidth?: boolean;
};

export const CompanyFormVatRegistrationNumber = ({
  fixedWidth,
}: CompanyFormVatRegistrationNumberProps) => {
  const { t } = useTranslate('');

  const { data: countryVatRates } = useCountryVatRates();

  const {
    watch,
    formState: { errors },
  } = useFormContext();

  const selectedVatCountry = watch('vatCompliance.vatRegistration.country');

  const Field = fixedWidth ? StyledTextField : TextField;

  if (!countryVatRates)
    return (
      <Skeleton
        variant="rectangular"
        width={fixedWidth ? 320 : '100%'}
        height={56}
      />
    );

  return (
    <Controller
      name={'vatCompliance.vatRegistration.vatNumber'}
      defaultValue={''}
      render={({ field }) => {
        const { prefix, suffix, length } =
          countryVatRates[selectedVatCountry]?.vatNumberRules?.length === 1
            ? countryVatRates[selectedVatCountry]?.vatNumberRules[0]
            : { prefix: '', suffix: '', length: undefined };
        return (
          <Field
            {...field}
            fullWidth={!fixedWidth}
            error={!!(errors.vatCompliance as any)?.vatRegistration?.vatNumber}
            helperText={
              (errors.vatCompliance as any)?.vatRegistration?.vatNumber
                ?.message || ''
            }
            required
            label={t('cvrNr', 'settings.company')}
            inputProps={{
              minLength: length,
              maxLength: length,
            }}
            value={getDisplayVatNumber(field.value, prefix, suffix)}
            onChange={(e) => {
              field.onChange(getFullVatNumber(e.target.value, prefix, suffix));
            }}
            InputProps={{
              startAdornment: prefix && (
                <InputAdornment position="start">
                  <Text
                    fontSize={'small'}
                    color={lightTheme.palette.neutral.n400}
                  >
                    {prefix}
                  </Text>
                </InputAdornment>
              ),
              endAdornment: suffix && (
                <InputAdornment position="end">
                  <Text
                    fontSize={'small'}
                    color={lightTheme.palette.neutral.n400}
                  >
                    {suffix}
                  </Text>
                </InputAdornment>
              ),
            }}
          />
        );
      }}
    />
  );
};
