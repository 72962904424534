import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Text } from '@holdbar-com/pixel';
import { yupResolver } from '@hookform/resolvers/yup';
import { CloseRounded } from '@mui/icons-material';
import { Dialog, Stack } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';

import useResponsive from '../../Hooks/layout/useResponsive';
import { useTranslate } from '../../Hooks/useTranslate';
import { RequestReportForm } from './form/request-report-form';
import { useRequestReportFormValidation } from './form/use-request-report-form-validation';

export const REPORT_TYPES = ['bookings', 'giftcards', 'customer-debt'];

export const RequestReportDialog = NiceModal.create(() => {
  const { t } = useTranslate('dialogs.requestReport');
  const { isSm } = useResponsive();
  const modal = useModal();

  const formValidationSchema = useRequestReportFormValidation(t);
  const form = useForm({
    resolver: yupResolver(formValidationSchema),
  });

  const handleClose = () => {
    modal.reject();
    modal.hide();
    form.reset();
  };

  return (
    <FormProvider {...form}>
      <Dialog
        open={modal.visible}
        onClose={handleClose}
        fullWidth
        maxWidth={'sm'}
        fullScreen={isSm}
      >
        <Stack gap={3} p={4}>
          <CloseRounded onClick={handleClose} sx={{ cursor: 'pointer' }} />
          <Stack gap={6}>
            <Stack gap={1}>
              <Text fontSize={'xlarge'} variant="medium">
                {t('requestReport', 'buttons')}
              </Text>
              <Text>{t('description')}</Text>
            </Stack>
            <RequestReportForm />
          </Stack>
        </Stack>
      </Dialog>
    </FormProvider>
  );
});
