import { lightTheme } from '@holdbar-com/pixel';
import { AutoAwesome } from '@mui/icons-material';
import {
  Badge,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link, useMatch } from 'react-router-dom';

import { useSuggestions } from '../../Hooks/data/useSuggestions';

export const SuggestionsMenuItem = () => {
  const suggestions = useSuggestions();
  const { t } = useTranslation();

  const {
    params: { id: locationKey },
  } = useMatch(':id/*') ?? { params: {} };

  return (
    <Link to={`/suggestions`}>
      <ListItem
        sx={{
          p: 0,
          borderRadius: 1,
          '&:hover svg': { color: 'black' },
        }}
      >
        <ListItemButton
          sx={{
            py: 1,
            px: 2,
            borderRadius: 1,
            '&:hover': {
              color: 'black',
              backgroundColor: lightTheme.palette.neutral.n100,
            },
            '&.Mui-selected': {
              backgroundColor: lightTheme.palette.neutral.n50,
              border: `1px solid ${lightTheme.palette.neutral.n50}`,
              borderRadius: 1,
            },
          }}
          selected={locationKey === 'suggestions'}
        >
          <ListItemIcon
            sx={{
              minWidth: '30px',
              '& svg': { color: 'inherit', fontSize: '20px' },
            }}
          >
            <AutoAwesome />
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{
              fontSize: lightTheme.typography.small.normal.fontSize,
            }}
            primary={t('suggestions.title')}
          />
          <Badge
            badgeContent={suggestions.data?.length}
            sx={{
              '& .MuiBadge-badge': {
                color: lightTheme.palette.contrast.white,
                backgroundColor: lightTheme.palette.action.a300,
              },
            }}
          />
        </ListItemButton>
      </ListItem>
    </Link>
  );
};
