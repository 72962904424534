import type { TDiscount } from '../Hooks/useDiscount';
import axios, { IShallowSuccessResponse } from './index';

export const getDiscounts = async () => {
  if (typeof process.env.REACT_APP_API_DISCOUNTS_URL === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_DISCOUNTS_URL is not defined'
    );
  }

  const { data } = await axios.get<TDiscount[]>(
    `${process.env.REACT_APP_API_DISCOUNTS_URL}`
  );

  return data;
};

export const getDiscount = async (id: string) => {
  if (typeof process.env.REACT_APP_API_DISCOUNTS_URL === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_DISCOUNTS_URL is not defined'
    );
  }

  if (!id) {
    throw Error('Missing discount id');
  }

  const { data } = await axios.get<TDiscount>(
    `${process.env.REACT_APP_API_DISCOUNTS_URL}/${id}`
  );

  return data;
};

export const updateDiscount = async (
  id: string,
  payload: Record<string, any>
) => {
  if (typeof process.env.REACT_APP_API_DISCOUNTS_URL === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_DISCOUNTS_URL is not defined'
    );
  }

  const { data } = await axios.put<IShallowSuccessResponse>(
    `${process.env.REACT_APP_API_DISCOUNTS_URL}/${id}`,
    payload
  );

  return data;
};

export const deleteDiscount = async (id: string) => {
  if (typeof process.env.REACT_APP_API_DISCOUNTS_URL === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_DISCOUNTS_URL is not defined'
    );
  }

  const { data } = await axios.delete<IShallowSuccessResponse>(
    `${process.env.REACT_APP_API_DISCOUNTS_URL}/${id}`
  );

  return data;
};
