import { useQuery, useQueryClient } from "react-query";

import * as api from '../Api';

export const useCountryVatRates = () => {
  const queryClient = useQueryClient();

  const CountryVatRatesQueryKey = ['countryVatRates'];

  const countryVatRates = useQuery(CountryVatRatesQueryKey, async () => {
    await queryClient.cancelQueries(CountryVatRatesQueryKey);
    return await api.getCountryVatRates();
  });

  return countryVatRates;
};
