import { Link } from '../../../../../../../../Components/Link/Link';
import { useProfile } from '../../../../../../../../Hooks/useProfile';
import { useTranslate } from '../../../../../../../../Hooks/useTranslate';
import { useBookingDetailsContext } from '../../../../../domain/use_booking_details_context';

export const BookingDetailsSummaryPaymentOpenLink = () => {
  const { t } = useTranslate('dialogs.booking');

  const { booking } = useBookingDetailsContext();

  const { companyProfileUrl } = useProfile();

  return (
    <Link
      href={`${companyProfileUrl()}/payment/${booking!.paymentId}`}
      target={'_blank'}
      style={{ fontSize: 'inherit' }}
    >
      {t('goToPayment')}
    </Link>
  );
};
