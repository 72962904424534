import { Stack } from "@mui/material";
import { useFlags } from "launchdarkly-react-client-sdk";

import { BookingDetailsSummaryBooking } from "./booking/BookingDetailsSummaryBooking";
import { BookingDetailsSummaryPayment } from "./payment/BookingDetailsSummaryPayment";
import { BookingDetailsSummaryNotifications } from "./notifications/BookingDetailsSummaryNotifications";
import { BookingDetailsSummaryCustomer } from "./customer/BookingDetailsSummaryCustomer";
import { BookingDetailsSummaryNotes } from "./notes/BookingDetailsSummaryNotes";
import useResponsive from "../../../../../../Hooks/layout/useResponsive";
import { BookingDetailsSummaryMobileDivider } from "./BookingDetailsSummaryMobileDivider";

export const BookingDetailsSummaryMobile = () => {
  const { isSm } = useResponsive();
  const { featureNotificationsLog } = useFlags();

  return (
    <Stack gap={isSm ? 4 : 2} divider={<BookingDetailsSummaryMobileDivider />}>
      <BookingDetailsSummaryBooking />
      <BookingDetailsSummaryPayment />
      <BookingDetailsSummaryCustomer />
      <BookingDetailsSummaryNotes />
      {featureNotificationsLog && <BookingDetailsSummaryNotifications />}
    </Stack>
  );
};
