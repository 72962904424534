import { Button, lightTheme } from '@holdbar-com/pixel';
import { ExpandMoreRounded, FileDownloadOutlined } from '@mui/icons-material';
import { Stack, styled } from '@mui/material';
import { Popover } from '@radix-ui/themes';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useState } from 'react';

import { StyledPopoverContent } from '../../../../../../../../Components/Popover/radix_popover_styles';
import { BookingReceiptMeta } from '../../../../../../../../Hooks/useReceipt';
import { useTranslate } from '../../../../../../../../Hooks/useTranslate';
import { useBookingDetailsContext } from '../../../../../domain/use_booking_details_context';

export const BookingDetailsSummaryPaymentActions = () => {
  const { t } = useTranslate('dialogs.booking.payment.accounting');
  const { featureDownloadBookingPdfs } = useFlags();

  const {
    receipt: { data: receipt },
  } = useBookingDetailsContext();

  const [isExpanded, setIsExpanded] = useState(false);

  if (!receipt || !featureDownloadBookingPdfs) return null;

  const accountingReceipts = Object.entries(
    (receipt.metadata as BookingReceiptMeta)?.accounting || {}
  );

  const handleDownload = () => {
    accountingReceipts.forEach(([, value]) => {
      window.open(value, '_blank');
    });
  };

  return (
    <Stack direction={'row'}>
      <Button
        size="medium"
        variant="secondary"
        onClick={handleDownload}
        leftIcon={<FileDownloadOutlined fontSize="small" sx={{ mr: 1 }} />}
        style={{
          borderRadius: '8px 0 0 8px',
          lineHeight: 'unset',
        }}
      >
        {t('download')}
      </Button>
      <Popover.Root open={isExpanded} onOpenChange={setIsExpanded}>
        <Popover.Trigger>
          <Button
            size="medium"
            variant="secondary"
            onClick={() => setIsExpanded(!isExpanded)}
            style={{
              lineHeight: 'unset',
              borderRadius: '0 8px 8px 0',
              padding: '10px',
            }}
          >
            <ExpandMoreRounded fontSize="small" />
          </Button>
        </Popover.Trigger>
        <StyledPopoverContent sideOffset={5} align="end">
          <Stack gap={1} width="100%" py={1}>
            {accountingReceipts.map(([key, value]) => (
              <StyledLink key={key} href={value} download="Test.pdf">
                {t(key)}
              </StyledLink>
            ))}
          </Stack>
        </StyledPopoverContent>
      </Popover.Root>
    </Stack>
  );
};

const StyledLink = styled('a')({
  display: 'block',
  fontSize: 14,
  padding: 8,
  width: 200,
  cursor: 'pointer',
  ':hover': {
    backgroundColor: lightTheme.palette.neutral.n50,
  },
});
