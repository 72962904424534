import { EconomicConfiguration } from '@holdbar-com/utils-types';
import { Stack, TableBody } from '@mui/material';
import { FC } from 'react';

import { InfoBox } from '../../../Components/InfoBox/InfoBox';
import { useProfile } from '../../../Hooks/useProfile';
import { useTranslate } from '../../../Hooks/useTranslate';
import { StyledTable } from '../../bookings/booking_details/ui/booking_details_summary/ui/BookingDetailsSummaryTable';
import { useEconomicJournals } from '../data/use-economic-journals';
import { EconomicDetailsTableDivider } from './economic-details-table-divider';
import { EconomicDetailsTableRow } from './economic-details-table-row';
import { EconomicDetailsTableRowAccount } from './economic-details-table-row-account';
import { EconomicDetailsVatCodes } from './economic-details-vat-codes';

type Props = {
  configuration: EconomicConfiguration;
};

export const EconomicDetails: FC<Props> = ({ configuration }) => {
  const { t } = useTranslate('settings.integrations.economic.configuration');

  const { company } = useProfile();

  const journals = useEconomicJournals();

  const vatRegistrations = company.data?.vatCompliance.vatRegistrations;

  return (
    <Stack gap={3} width={'100%'}>
      <InfoBox title={t('details', 'settings.integrations.economic.details')}>
        <StyledTable>
          <TableBody>
            <EconomicDetailsTableRow
              title={'Journal'}
              value={
                journals.data?.find(
                  (journal) => journal.journalNumber === configuration.journal
                )?.name ??
                /* the first journal is used if nothing is selected */
                journals.data?.[0]?.name
              }
            />
            {vatRegistrations && vatRegistrations.length > 0 && (
              <>
                <EconomicDetailsTableDivider />
                <EconomicDetailsTableRow
                  title={t('vatCodes.title')}
                  value={null}
                />
                <EconomicDetailsVatCodes
                  configuration={configuration}
                  vatRegistrations={vatRegistrations}
                />
              </>
            )}
          </TableBody>
        </StyledTable>
      </InfoBox>
      <InfoBox title={t('accounts', 'settings.integrations.economic.details')}>
        <StyledTable>
          <TableBody>
            <EconomicDetailsTableRow title={t('sales.title')} value={null} />
            <EconomicDetailsTableRowAccount
              title={t('accounts.salesWithVat')}
              accountNumber={configuration.accounts.salesWithVat}
            />
            <EconomicDetailsTableRowAccount
              title={t('accounts.salesWithoutVat')}
              accountNumber={configuration.accounts.salesWithoutVat}
            />
            <EconomicDetailsTableDivider />
            <EconomicDetailsTableRow title={t('costs.title')} value={null} />
            <EconomicDetailsTableRowAccount
              title={t('accounts.holdbarFee')}
              accountNumber={configuration.accounts.feeHoldbar}
            />
            <EconomicDetailsTableRowAccount
              title={t('accounts.stripeFee')}
              accountNumber={configuration.accounts.gateways.stripe.fee}
            />
            <EconomicDetailsTableRowAccount
              title={t('accounts.paypalFee')}
              accountNumber={configuration.accounts.gateways.paypal.fee}
            />
            <EconomicDetailsTableDivider />
            <EconomicDetailsTableRow
              title={t('liabilities.title')}
              value={null}
            />
            <EconomicDetailsTableRowAccount
              title={t('accounts.bookings')}
              accountNumber={configuration.accounts.liabilityGuest}
            />
            <EconomicDetailsTableRowAccount
              title={t('accounts.giftCards')}
              accountNumber={configuration.accounts.liabilityGiftCard}
            />
            <EconomicDetailsTableDivider />
            <EconomicDetailsTableRow
              title={t('receivables.title')}
              value={null}
            />
            <EconomicDetailsTableRowAccount
              title={t('accounts.stripeRefunds')}
              accountNumber={configuration.accounts.gateways.stripe.asset}
            />
            <EconomicDetailsTableRowAccount
              title={t('accounts.paypalRefunds')}
              accountNumber={configuration.accounts.gateways.paypal.asset}
            />
          </TableBody>
        </StyledTable>
      </InfoBox>
    </Stack>
  );
};
