import { FormControl, InputLabel, Select, SelectProps } from '@mui/material';
import { useId } from 'react';
import { useController } from 'react-hook-form';

import { useProfile } from '../../../../Hooks/useProfile';
import { useTranslate } from '../../../../Hooks/useTranslate';
import { useFeatureSetting } from '../context/feature_setting_context';

export type TFeatureSelect = SelectProps & {
  name: string;
};

const FeatureSelect = ({ name, ...props }: TFeatureSelect) => {
  const { control, save, feature } = useFeatureSetting();

  const { t } = useTranslate(`settings.features.${feature}.${name}`);

  const {
    company: { data: company },
  } = useProfile();

  const isFeatureEnabled = company?.features?.[feature]?.enabled;
  const required = isFeatureEnabled && props.required;

  const featureParams = company?.features?.[feature]?.params as Record<
    string,
    string | number
  >;
  const defaultValue = props.defaultValue || featureParams?.[name];

  const { field, formState } = useController({
    name: `params.${name}`,
    defaultValue: defaultValue ?? '',
    rules: { required },
    control,
  });

  const id = useId();

  return (
    <FormControl fullWidth>
      <InputLabel id={`${id}-label`}>{t('label')}</InputLabel>
      <Select
        labelId={`${id}-label`}
        id={id}
        label={t('label')}
        error={Boolean((formState as any)?.errors?.params?.[name])}
        {...field}
        {...props}
        onChange={(event, child) => {
          field.onChange(event);
          props.onChange?.(event, child);
          save();
        }}
      />
    </FormControl>
  );
};

export default FeatureSelect;
