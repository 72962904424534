import NiceModal from '@ebay/nice-modal-react';
import styled from '@emotion/styled';
import { Button, lightTheme, Text } from '@holdbar-com/pixel';
import {
  ArrowBackRounded,
  MoreVert,
  SvgIconComponent,
} from '@mui/icons-material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import PauseCircleOutlineOutlinedIcon from '@mui/icons-material/PauseCircleOutlineOutlined';
import PlayCircleFilledWhiteOutlinedIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined';
import {
  IconButton,
  MenuItemProps,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Popover } from '@radix-ui/themes';
import { AxiosError } from 'axios';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ComponentPropsWithoutRef, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { ShowForScope } from '../Components/AllowForScope/AllowForScope';
import { EventsList } from '../Components/EventsList/EventsList';
import { ExperienceCard } from '../Components/ExperienceCard/ExperienceCard';
import { PageBreadcrumb } from '../Components/Page/page_breadcrumb';
import { PageBreadcrumbBreadcrumbsExperience } from '../Components/Page/page_breadcrumb/ui/page_breadcrumb_breadcrumbs/experience/PageBreadcrumbBreadcrumbsExperience';
import { PageBreadcrumbBreadcrumbsExperienceDetails } from '../Components/Page/page_breadcrumb/ui/page_breadcrumb_breadcrumbs/experience/PageBreadcrumbBreadcrumbsExperienceDetails';
import { PageBreadcrumbBreadcrumbs } from '../Components/Page/page_breadcrumb/ui/page_breadcrumb_breadcrumbs/PageBreadcrumbBreadcrumbs';
import { StyledPopoverContent } from '../Components/Popover/radix_popover_styles';
import { useExperienceEvents } from '../Hooks/events/useExperienceEvents';
import { IExperience, useExperience } from '../Hooks/useExperience';
import { useFireOnce } from '../Hooks/useFireOnce';
import { useProfile } from '../Hooks/useProfile';
import { useTranslate } from '../Hooks/useTranslate';
import { ConfirmDialog } from '../Modals/ConfirmDialog';
import { trackEventFlowOpened } from '../tracking/events/flow/trackEventFlowOpened';
import { trackExperienceActivated } from '../tracking/experiences/details/card/trackExperienceActivated';
import { trackExperienceDeactivated } from '../tracking/experiences/details/card/trackExperienceDeactivated';
import { trackExperienceDetailsPageOpened } from '../tracking/experiences/details/card/trackExperienceDetailsPageOpened';
import { trackExperienceEventCreationStarted } from '../tracking/experiences/details/card/trackExperienceEventCreationStarted';
import { trackExperienceThreeDotsOpened } from '../tracking/experiences/details/card/trackExperienceThreeDotsOpened';
import { trackSeeExperienceOpened } from '../tracking/experiences/details/card/trackSeeExperienceOpened';
import { ErrorPage } from './error-page';
import { eventSyiSteps } from './SyiPage/config';

const options: {
  key: string;
  label: string;
  icon: SvgIconComponent;
  props?: Pick<MenuItemProps, 'color'>;
}[] = [
  {
    key: 'edit',
    label: 'Rediger',
    icon: EditOutlinedIcon,
  },
];

export const ExperienceDetailsPage = ({
  experienceId,
  children,
}: ComponentPropsWithoutRef<any> & { experienceId: string }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const flags = useFlags();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const { i18n } = useTranslation();
  const { t } = useTranslate('experience.card');

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const fireOnce = useFireOnce();

  const {
    experience: { data: experience, isError, error },
    deleteExperience,
    updateExperience,
  } = useExperience(experienceId);
  const eventsForExperience = useExperienceEvents(
    experience?.ownerExperienceId
  );
  const { companyProfileUrl } = useProfile();

  useEffect(() => {
    const language = i18n.language;
    const primaryHeadline =
      experience?.headline?.[language] ??
      experience?.headline?.[experience.languages[0]];
    document.title = primaryHeadline
      ? `${primaryHeadline} - Holdbar`
      : 'Holdbar';
  }, [experience?.headline, i18n.language]);

  useEffect(() => {
    if (!experience || !eventsForExperience.eventsForExperience.data) return;

    const { events } = eventsForExperience.eventsForExperience.data ?? {
      events: [],
    };
    fireOnce(() => trackExperienceDetailsPageOpened(experience, events));
  }, [experience, eventsForExperience.eventsForExperience.data, fireOnce]);

  const handleBack = () => navigate('/experiences');

  const toggleStatusTo = (newStatus: IExperience['status']) => {
    if (!experience) return;

    updateExperience.mutateAsync({
      ...experience,
      status: newStatus,
    });
  };

  const handleSelect = (key: string) => () => {
    setIsPopoverOpen(false);

    if (key === 'edit')
      navigate('edit/description', {
        state: { returnUrl: `/experience/${experienceId}` },
      });
    if (key === 'activate') {
      toggleStatusTo('active');
      trackExperienceActivated(
        eventsForExperience.eventsForExperience.data?.events ?? [],
        experience
      );
    }

    if (key === 'deactivate') {
      toggleStatusTo('inactive');
      trackExperienceDeactivated(
        eventsForExperience.eventsForExperience.data?.events ?? [],
        experience
      );
    }

    if (key === 'archive') toggleStatusTo('archived');
    if (key === 'delete') {
      if (
        eventsForExperience?.eventsForExperience?.data?.events
          ?.filter((f) => new Date(f.startDateTime) > new Date())
          ?.some((el) => el.slots.booked !== 0)
      ) {
        return NiceModal.show(ConfirmDialog, {
          allowCancel: false,
          headline: t('headline', 'dialogs.deleteExperienceWithBookings'),
          title: t('title', 'dialogs.deleteExperienceWithBookings'),
        });
      }

      NiceModal.show(ConfirmDialog, {
        headline: t('headline', 'dialogs.confirmDelete', {
          type: t('thisExperience', 'utils.generic'),
        }),
        title: t('title', 'dialogs.confirmDelete'),
      }).then(() => {
        deleteExperience.mutate(experienceId);
        navigate('/experiences', { replace: true });
      });
    }
  };

  const handleCreateEvent = () => {
    trackExperienceEventCreationStarted(
      eventsForExperience.eventsForExperience.data?.events ?? [],
      experience
    );

    if (flags.featureEventV2Create) {
      navigate(`/event/create`, {
        state: { experienceId, returnUrl: `/experience/${experienceId}` },
      });
      trackEventFlowOpened(`/experience/${experienceId}`, 'create');
    } else {
      navigate(`/event/create/${eventSyiSteps[0].key}`, {
        state: { experienceId, returnUrl: `/experience/${experienceId}` },
      });
    }
  };

  const handleCompleteDraft = () => {
    navigate('edit', {
      state: { returnUrl: location.pathname, validateOnMount: true },
    });
  };

  const handleGoToExperience = () => {
    window.open(`${companyProfileUrl()}/experience/${experienceId}`, '_blank');

    trackSeeExperienceOpened(
      eventsForExperience.eventsForExperience.data?.events ?? [],
      experience
    );
  };

  const _options: typeof options = useMemo(() => {
    const primary =
      experience?.status === 'active'
        ? {
            key: 'deactivate',
            icon: PauseCircleOutlineOutlinedIcon,
          }
        : {
            key: 'activate',
            icon: PlayCircleFilledWhiteOutlinedIcon,
          };
    return [primary, ...options].map((el) => ({
      ...el,
      label: t(`options.${el.key}`),
    }));
  }, [experience]);

  const experienceStatus = useMemo(
    () => experience?.status ?? 'draft',
    [experience?.status]
  );

  if (isError)
    return <ErrorPage error={error as AxiosError} resource="experience" />;

  return (
    <>
      <PageBreadcrumbBreadcrumbs>
        <PageBreadcrumbBreadcrumbsExperience />
        <PageBreadcrumbBreadcrumbsExperienceDetails
          experienceId={experienceId}
        />
      </PageBreadcrumbBreadcrumbs>
      <PageBreadcrumb>
        <Stack gap={2} direction={'row'} width={'100%'} mb={2}>
          <StyledBackArrowRounded onClick={handleBack} />
          {!isMobile && (
            <Stack alignItems={'center'} mt={1}>
              <Text fontSize="large" variant="medium">
                {t('detailsPageTitle', 'experience')}
              </Text>
            </Stack>
          )}
        </Stack>
        <ExperienceCard
          status={experienceStatus}
          title={
            experience?.headline?.[i18n.language] ??
            experience?.headline?.[experience.languages[0]] ??
            ''
          }
          languages={experience?.languages}
          showUpcomingEventsCount={true}
          coverImage={experience?.media?.primaryImage?.url}
          rating={{ average: 4, count: 34 }}
          createdDate={experience?.dates?.created}
          fullWidth
          imageProps={{ width: { xs: 124, md: 320 } }}
          id={experienceId}
          visibility={experience?.visibility}
        >
          {isMobile ? null : (
            <>
              <ShowForScope scopes={['experience.write']}>
                <Popover.Root
                  open={isPopoverOpen}
                  onOpenChange={setIsPopoverOpen}
                >
                  <Popover.Trigger data-intercom-target="experience-actions">
                    <IconButton
                      onClick={() => {
                        trackExperienceThreeDotsOpened(
                          eventsForExperience.eventsForExperience.data
                            ?.events ?? [],
                          experience
                        );
                      }}
                    >
                      <MoreVert />
                    </IconButton>
                  </Popover.Trigger>
                  <StyledPopoverContent sideOffset={5} align="end">
                    <Stack gap={1} width="100%" py={1}>
                      {_options.map((el, i) => {
                        const Icon = el.icon as any;
                        return (
                          <Stack
                            tabIndex={0}
                            direction="row"
                            alignItems="center"
                            p={1}
                            gap={1}
                            width={200}
                            key={el.key}
                            onClick={handleSelect(el.key)}
                            onKeyDown={(evt: any) => {
                              if (evt.key === 'Enter') {
                                handleSelect(el.key)();
                              }
                            }}
                            sx={{
                              cursor: 'pointer',
                              ':hover': {
                                backgroundColor: lightTheme.palette.neutral.n50,
                              },
                            }}
                          >
                            <Icon fontSize="small" {...el.props} />
                            <Typography fontSize="small">{el.label}</Typography>
                          </Stack>
                        );
                      })}
                    </Stack>
                  </StyledPopoverContent>
                </Popover.Root>
              </ShowForScope>

              {(experience?.status === 'active' ||
                experience?.status === 'inactive') && (
                <Stack
                  spacing={2}
                  direction={'row'}
                  p={1}
                  whiteSpace={'nowrap'}
                >
                  {eventsForExperience && experience?.status === 'active' && (
                    <Button
                      size="small"
                      variant="secondary"
                      onClick={handleGoToExperience}
                    >
                      {t('actions.secondary')}
                    </Button>
                  )}
                  <ShowForScope scopes={['event.write']}>
                    <Button
                      size="small"
                      variant="primary"
                      onClick={handleCreateEvent}
                      data-intercom-target="experience-create-new-event"
                    >
                      {t('actions.primary')}
                    </Button>
                  </ShowForScope>
                </Stack>
              )}

              {experience?.status === 'draft' && (
                <Button
                  size="medium"
                  variant="primary"
                  onClick={handleCompleteDraft}
                >
                  {t('actions.finishDraft')}
                </Button>
              )}
            </>
          )}
        </ExperienceCard>

        {experience?.status !== 'draft' && (
          <EventsList
            experienceId={experienceId}
            handleCreate={handleCreateEvent}
            experienceStatus={experienceStatus}
            mt={6}
            width={'100%'}
          />
        )}
      </PageBreadcrumb>
    </>
  );
};

const StyledBackArrowRounded = styled(ArrowBackRounded)({
  height: '33px',
  width: '33px',
  padding: '4px',
  cursor: 'pointer',
  borderRadius: '50%',
  marginTop: '3.5px',
  transition: 'background-color 0.1s',
  '&:hover': {
    backgroundColor: lightTheme.palette.neutral.n100,
  },
  '&:active': {
    backgroundColor: lightTheme.palette.neutral.n200,
  },
});
