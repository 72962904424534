import { Text } from '@holdbar-com/pixel';
import { Stack } from '@mui/material';

import { useTranslate } from '../../../../../Hooks/useTranslate';
import { ButtonStyleSelect } from './button-style-select';
import { FontFamilySelect } from './font-family-select';
import { PrimaryColorSelect } from './primary-color-select';

export const StyleForm = () => {
  const { t } = useTranslate('storefront.customize');

  return (
    <Stack gap={3}>
      <FontFamilySelect />
      <PrimaryColorSelect />
      <Stack gap={1}>
        <Text fontSize="small">{t('buttonStyle.label')}</Text>
        <ButtonStyleSelect />
      </Stack>
    </Stack>
  );
};
