import { Text, lightTheme } from "@holdbar-com/pixel";
import { Stack } from "@mui/material";

import { useTranslate } from "../../../../../../../../Hooks/useTranslate";
import { Timeline, TimelineProps } from "../../../../../../../../Components/Timeline/Timeline";

type BookingDetailsSummaryNotificationsMobileProps = TimelineProps;

export const BookingDetailsSummaryNotificationsMobile = ({ activities }: BookingDetailsSummaryNotificationsMobileProps) => {
  const { t } = useTranslate('dialogs.booking');

  return (
    <Stack gap={2} fontSize={lightTheme.typography.small.normal.fontSize}>
      <Text variant="medium">
        {t('sections.notifications')}
      </Text>
      <Timeline activities={activities} />
    </Stack>
  );
};
