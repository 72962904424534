import { Checkbox, FormControlLabel, Stack } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { useTranslate } from '../../../../Hooks/useTranslate';
import { CreateBookingFormInputs } from '../create-booking-form';

export const Options = () => {
  const { t } = useTranslate('dialogs.createBooking');

  const { register } = useFormContext<CreateBookingFormInputs>();

  return (
    <Stack>
      <FormControlLabel
        {...register('shouldSendNotification')}
        label={t('actions.sendNotification')}
        control={<Checkbox defaultChecked={true} />}
      />
      <FormControlLabel
        {...register('shouldCreatePaymentLink')}
        label={t('actions.generatePaymentLink')}
        control={<Checkbox />}
      />
    </Stack>
  );
};
