import { useFlags } from 'launchdarkly-react-client-sdk';
import { Outlet } from 'react-router-dom';

import { PageTabs } from '../Components/Page/PageTabs';
import { SimpleSkeleton } from '../Components/SimpleSkeleton/SimpleSkeleton';
import { useProfile } from '../Hooks/useProfile';
import { useTerms } from '../Hooks/useTerms';
import { useTranslate } from '../Hooks/useTranslate';

export const SettingsPage = () => {
  const { t } = useTranslate('settings');

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { terms } = useTerms();
  const { me, company } = useProfile();
  const flags = useFlags();

  const tabs = [
    { key: 'profile', scopes: [], allowedOnMobile: true },
    { key: 'company', scopes: ['organization.write'] },
    { key: 'users', scopes: ['organization.write'] },
    flags.featureConnectedAccounts && {
      key: 'connect',
      scopes: ['admin'],
      allowedOnMobile: true,
    },
    {
      key: 'integrations',
      scopes: ['organization.write'],
      allowedOnMobile: true,
    },
    { key: 'notifications', scopes: ['organization.write'] },
    { key: 'terms', scopes: ['organization.write'] },
  ].filter(Boolean);

  return (
    <PageTabs title={t('title')} tabs={tabs}>
      {!(me.data && company.data) ? <SimpleSkeleton /> : <Outlet />}
    </PageTabs>
  );
};
