import axios from './index';

export const getSearchResults = async (query: string) => {
  if (typeof process.env.REACT_APP_INTERNAL_API_GATEWAY === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_INTERNAL_API_GATEWAY is not defined'
    );
  }

  const { data } = await axios.get(
    process.env.REACT_APP_INTERNAL_API_GATEWAY + '/v1/search',
    {
      params: { term: query },
    }
  );

  return data;
};
