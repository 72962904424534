import NiceModal from '@ebay/nice-modal-react';
import { AddRounded, ArrowForwardRounded } from '@mui/icons-material';
import { Button, CircularProgress, Stack, Typography } from '@mui/material';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';

import { ProgressButton } from '../../Components/ProgressButton/ProgressButton';
import { Tip } from '../../Components/Tip/Tip';
import { UserItem } from '../../Components/UserItem/UserItem';
import { useRoles } from '../../Hooks/data/useRoles';
import { InputDialog } from '../../Modals/InputDialog';

export const OnBoardingTeamSection = () => {
  const { t } = useTranslation();

  const { handleSubmit, getMeValues } = useOutletContext<any>();

  const [_users, setUsers] = useState<any[]>(() => {
    const { email = '', name = '', id } = getMeValues();
    return [{ email, role: 'admin', name, id }];
  });

  const { roles } = useRoles();

  const myEmail = useMemo(() => {
    return getMeValues()?.email;
  }, []);

  const handleDelete = (index: number) => () =>
    setUsers((p) => p.filter((_, i) => i !== index));

  const handleUpdate = (index: number) => (key: 'email' | 'role', value: any) =>
    setUsers((p) =>
      p.map((el, i) => {
        return i === index ? { ...el, [key]: value } : el;
      })
    );

  const _handleSubmit = () => {
    return handleSubmit([..._users]);
  };

  const handleCreate = async () => {
    try {
      const { email, role, name } = await NiceModal.show<{
        email: string;
        role: string;
        name: string;
      }>(InputDialog, {
        title: 'Opret bruger',
        inputs: [
          {
            key: 'name',
            name: t('utils.generic.name'),
            props: {
              fullWidth: true,
            },
            rules: {
              required: true,
            },
          },
          {
            key: 'email',
            name: t('utils.generic.email'),
            props: {
              fullWidth: true,
            },
            rules: {
              required: true,
            },
          },
          {
            key: 'role',
            name: t('utils.generic.role'),
            props: {
              fullWidth: true,
              options: roles.data!.map(({ name, id }) => ({
                label: name,
                key: id,
              })),
              type: 'select',
            },
            rules: {
              required: true,
            },
          },
        ],
      });
      setUsers((p) => [...p, { email, role, name }]);
    } catch (err) {
      return;
    }
  };

  return (
    <>
      <Typography mb={2} variant={'h4'}>
        {t('onboarding.team.boxSubtitle')}
      </Typography>
      <Tip label={t('onboarding.team.boxSubtitle')} />
      <Stack p={4} pl={0} pr={8} spacing={2}>
        {_users.map((el, i) => {
          return (
            <UserItem
              name={el.name}
              email={el.email}
              role={el.role}
              isCurrent={el.email === myEmail}
              id={`user-item-${i}`}
              key={`user-item-${i}`}
              onUpdate={handleUpdate(i)}
              onDelete={el.email === myEmail ? undefined : handleDelete(i)}
            />
          );
        })}
        <Stack spacing={4} alignItems={'start'} mt={2}>
          <Button
            variant={'outlined'}
            color={'secondary'}
            startIcon={<AddRounded />}
            disabled={roles.isLoading}
            onClick={handleCreate}
          >
            {t('onboarding.team.addUserButtonLabel')}
          </Button>
          <Stack direction={'row'} alignItems={'center'} spacing={1} mt={4}>
            <ProgressButton
              size={'large'}
              variant={'contained'}
              onClick={_handleSubmit}
              label={t('buttons.next')}
              startIcon={
                roles.isLoading ? (
                  <CircularProgress color={'inherit'} size={'1em'} />
                ) : (
                  <ArrowForwardRounded />
                )
              }
              disabled={roles.isLoading}
              sx={{
                minWidth: '100%',
              }}
            />
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};
