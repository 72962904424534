import { ampli } from '../../Ampli';
import { CalendarView } from '../../features/calendar/context';

export const trackCalendarViewGroupUpdated = (
  grouped: boolean,
  view: CalendarView
) => {
  const eventProperties = {
    view,
  };

  if (grouped) {
    ampli.calendarViewGroupSelected(eventProperties);
  } else {
    ampli.calendarViewGroupDeselected(eventProperties);
  }
};
