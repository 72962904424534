import styled from '@emotion/styled';
import CheckIcon from '@mui/icons-material/Check';
import { Box, BoxProps, Link, Typography } from '@mui/material';
import { ComponentPropsWithoutRef, forwardRef } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkBreaks from 'remark-breaks';

type MarkdownProps = {
  checkmark?: boolean;
};

export const Markdown = forwardRef(
  (
    {
      children,
      checkmark = false,
      ...props
    }: Omit<BoxProps, 'children'> &
      ComponentPropsWithoutRef<any> &
      MarkdownProps,
    ref
  ) => {
    return (
      <StyledBox ref={ref} {...props}>
        <ReactMarkdown
          remarkPlugins={[remarkBreaks]}
          components={{
            ul: ({ node, ordered, ...props }) => (
              <ul
                style={{
                  paddingInlineStart: checkmark ? '0px' : '20px',
                  marginBlockStart: '0px',
                }}
                {...props}
              />
            ),
            ol: ({ node, ordered, ...props }) => (
              <ol
                style={{ paddingInlineStart: '20px', marginBlockStart: '0px' }}
                {...props}
              />
            ),
            li: ({ node, ordered, ...props }) =>
              checkmark && !ordered ? (
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="flex-start"
                  gap={2}
                >
                  <Box display="flex" flexShrink={0} pt="2px">
                    <CheckIcon />
                  </Box>
                  <li
                    style={{ lineHeight: 2, listStyleType: 'none' }}
                    {...props}
                  />
                </Box>
              ) : (
                <li style={{ lineHeight: 2 }} {...props} />
              ),
            p: (props) => <Typography {...props} />,
            h2: (props) => <Typography {...props} variant={'h5'} />,
            a: (props) => (
              <Link
                {...props}
                target={'_blank'}
                sx={{ fontWeight: 500, wordBreak: 'break-word' }}
              />
            ),
          }}
        >
          {children}
        </ReactMarkdown>
      </StyledBox>
    );
  }
);

Markdown.displayName = 'Markdown';

const StyledBox = styled(Box)`
  zoom: 0.8;

  & > p + p {
    margin-top: 16px;
  }

  li > * {
    line-height: 2;
  }
`;
