import { Experience } from '@holdbar-com/utils-types';
import { differenceInCalendarDays, parseISO } from 'date-fns';

import { ExperiencesPageViewedProperties } from '../../../Ampli';
import { getLocalized } from '../../../Hooks/useBookings';

export const eventProperties = (experiences: Experience[]) => {
  const {
    list_of_experiences,
    list_of_experience_ids,
    date_since_most_recent_xp_creation,
    date_since_oldest_xp_creation,
    number_of_active_experiences,
    number_of_inactive_experiences,
    language_set,
  } = experiences.reduce(
    (acc, experience) => {
      acc.list_of_experiences.push(
        getLocalized(experience.headline, 'en') ?? ''
      );
      acc.list_of_experience_ids.push(experience.id);

      const creationDate = parseISO(experience.dates.created);
      if (creationDate > acc.date_since_most_recent_xp_creation) {
        acc.date_since_most_recent_xp_creation = creationDate;
      }
      if (creationDate < acc.date_since_oldest_xp_creation) {
        acc.date_since_oldest_xp_creation = creationDate;
      }

      if (experience.status === 'active') {
        acc.number_of_active_experiences += 1;
      } else if (experience.status === 'inactive') {
        acc.number_of_inactive_experiences += 1;
      }

      experience.languages.forEach((lang) => acc.language_set.add(lang));

      return acc;
    },
    {
      list_of_experiences: [] as string[],
      list_of_experience_ids: [] as string[],
      date_since_most_recent_xp_creation: new Date(0),
      date_since_oldest_xp_creation: new Date(),
      number_of_active_experiences: 0,
      number_of_inactive_experiences: 0,
      language_set: new Set<string>(),
    }
  );

  const days_since_most_recent_xp_creation =
    experiences.length > 0
      ? differenceInCalendarDays(new Date(), date_since_most_recent_xp_creation)
      : undefined;

  const days_since_oldest_xp_creation =
    experiences.length > 0
      ? differenceInCalendarDays(new Date(), date_since_oldest_xp_creation)
      : undefined;

  const language_list = Array.from(language_set);

  return {
    list_of_experiences,
    list_of_experience_ids,
    number_of_experiences: experiences.length,
    days_since_most_recent_xp_creation,
    days_since_oldest_xp_creation,
    number_of_active_experiences,
    number_of_inactive_experiences,
    language_list,
  };
};

export const transformToUserProperties = (
  properties: ExperiencesPageViewedProperties
) => ({
  '# Experiences Listed': properties.number_of_experiences,
  '# Days Since Last XP Created': properties.days_since_most_recent_xp_creation,
  '# Days Since First XP Created': properties.days_since_oldest_xp_creation,
  '# Active Experiences Listed': properties.number_of_active_experiences,
  '# Inactive Experiences Listed': properties.number_of_inactive_experiences,
});
