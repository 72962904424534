import { Experience } from '@holdbar-com/utils-types';

import { ampli } from '../../../../Ampli';
import { TEvent } from '../../../../Utils/eventHelpers';
import { eventProperties } from '../card/eventProperties';

export const trackExperienceEventStatusFilterOpened = (
  events: TEvent[],
  status_selected?: string,
  experience?: Experience
) => {
  if (!experience) return;
  const properties = eventProperties(experience, events);

  ampli.experienceEventStatusFilterOpened({
    ...properties,
    status_selected,
  });
};
