import { Opportunity } from './types';

export const opportunities: Opportunity[] = [
  {
    id: 'tracking',
    title: 'Tracking',
    icon: '/opportunities/tracking.png',
  },
  {
    id: 'metaAds',
    title: 'Meta Ads',
    icon: '/opportunities/metaAds.png',
  },
  {
    id: 'googleAds',
    title: 'Google Ads',
    icon: '/opportunities/googleAds.png',
  },
  {
    id: 'googleMyBusiness',
    title: 'Google My Business',
    icon: '/opportunities/googleMyBusiness.png',
  },
  // {
  //   id: 'reviewManagement',
  //   title: 'Review Management',
  //   icon: '/opportunities/review.png',
  // },
  {
    id: 'review',
    title: 'Review',
    icon: '/opportunities/review.png',
    isFree: true,
  },
];
