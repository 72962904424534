import { Text } from '@holdbar-com/pixel';
import { Box, Divider, Stack } from '@mui/material';

import { InfoBox } from '../../../Components/InfoBox/InfoBox';
import useResponsive from '../../../Hooks/layout/useResponsive';

export function ListCard({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}) {
  const { isSm } = useResponsive();

  if (isSm) {
    return (
      <Stack divider={<Divider />} gap={1}>
        <Text fontSize="medium" variant="medium">
          {title}
        </Text>
        {children}
      </Stack>
    );
  }

  return <InfoBox title={title}>{children}</InfoBox>;
}
