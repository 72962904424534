import { Stack } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

import { useTranslate } from "../../../../../Hooks/useTranslate";
import { StyledTextField } from "../../../shared/StyledTextField";
import { ProfileFormData } from "../../domain/use-profile-form-validation";

export const ProfileFormDetails = () => {
  const { t } = useTranslate('settings.profile');

  const {
    formState: { errors },
  } = useFormContext<ProfileFormData>();

  return (
    <Stack gap={2}>
      <Controller
        name={'name'}
        render={({ field }) => (
          <StyledTextField
            {...field}
            label={t('fullName')}
            error={!!errors.name}
            helperText={errors.name?.message}
          />
        )}
      />
      <Controller
        name={'email'}
        render={({ field }) => (
          <StyledTextField {...field} label={t('email')} disabled={true} />
        )}
      />
      <Controller
        name={'phone'}
        render={({ field }) => (
          <StyledTextField
            {...field}
            label={t('phone')}
            error={!!errors.phone}
            helperText={errors.phone?.message}
          />
        )}
      />
    </Stack>
  );
};
