import { differenceInCalendarDays } from 'date-fns';

import { ampli } from '../../../Ampli';

export const trackOverviewSecondPickerDateSelected = (date: Date | null) => {
  const days_from_current_date = differenceInCalendarDays(
    date ?? new Date(),
    new Date()
  );

  ampli.overviewReportSecondDateSelected({ days_from_current_date });
};
