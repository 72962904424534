import { useQuery, useQueryClient } from 'react-query';

import { getEconomicJournals } from '../../../Api';
import { useProfile } from '../../../Hooks/useProfile';
import { EconomicJournalsQueryKey } from './economic-query-keys';

export const useEconomicJournals = () => {
  const { company } = useProfile();
  const queryClient = useQueryClient();

  return useQuery(
    EconomicJournalsQueryKey,
    async () => {
      await queryClient.cancelQueries(EconomicJournalsQueryKey);
      const journals = await getEconomicJournals();
      return journals.items;
    },
    {
      enabled: !!company.data?.id,
    }
  );
};
