import { TokenPayload } from '../Api/Profiles';
import { TEvent } from '../Utils/eventHelpers';
import { useProfile } from './useProfile';

type Props = {
  event?: TEvent;
};

type ReturnType = {
  hasAccessToExperience: boolean;
  eventExperienceId?: string;
  hasWriteAccess?: boolean;
};

/**
 * Checks access related to an event
 */
export const useEventAccess = ({ event }: Props): ReturnType => {
  const {
    userinfo: { data: userinfo },
  } = useProfile();

  const { hasAccessToExperience, eventExperienceId } = checkEventAccess(
    event,
    userinfo
  );

  const hasWriteAccess =
    userinfo?.scope?.includes('event.write') &&
    userinfo.companyId === event?.org;

  return {
    hasAccessToExperience,
    eventExperienceId,
    hasWriteAccess,
  };
};

const checkEventAccess = (
  event?: TEvent,
  userinfo?: TokenPayload
): {
  hasAccessToExperience: boolean;
  eventExperienceId?: string;
} => {
  if (!event) {
    return {
      hasAccessToExperience: false,
    };
  }

  if (event.org === userinfo?.companyId) {
    return {
      hasAccessToExperience: true,
      eventExperienceId: event.experienceId,
    };
  }

  const connection = userinfo?.connections.find(
    (x) => x.companyId === event.org
  );

  if (!connection) {
    return {
      hasAccessToExperience: false,
      eventExperienceId: event.experienceId,
    };
  }

  const hasAccessToExperience =
    connection.sharedExperiences === 'all' ||
    connection.sharedExperiences.includes(event.experienceId);

  return {
    hasAccessToExperience,
    eventExperienceId: `${event.experienceId}:${userinfo?.companyId}`,
  };
};
