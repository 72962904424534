import { lightTheme } from '@holdbar-com/pixel';
import { Check, CloseRounded } from '@mui/icons-material';
import { Box, Stack, styled, TextField } from '@mui/material';
import { useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { useBookings } from '../../../../../../../Hooks/useBookings';
import { useTranslate } from '../../../../../../../Hooks/useTranslate';
import {
  trackBookingInternalNoteCompleted,
  trackBookingInternalNoteStarted,
} from '../../../../../../../tracking/bookings/details';
import { useBookingDetailsContext } from '../../../../domain/use_booking_details_context';
import { useBookingAccess } from '../../../../../../../Hooks/useBookingAccess';

export const BookingDetailsSummaryNotesForm = () => {
  const { t } = useTranslate('dialogs.booking');

  const {
    booking,
    receipt: { data: receipt },
  } = useBookingDetailsContext();

  const { updateBooking } = useBookings(undefined, booking?.id);
  const { hasWriteAccess } = useBookingAccess({ booking });

  const form = useForm();
  const { getValues, setValue, reset, handleSubmit, ...methods } = form;

  useEffect(() => {
    if (booking?.internalNote) {
      setValue('internalNote', booking.internalNote, { shouldTouch: true });
    } else {
      setValue('internalNote', '', { shouldTouch: true });
    }
  }, [booking]);

  const handleTrackStarted = () => {
    if (!booking) return;
    trackBookingInternalNoteStarted(booking, receipt);
  };

  const handleUpdateNote = () => {
    return new Promise((res, rej) => {
      handleSubmit(
        async () => {
          if (!booking) return;

          const { internalNote } = getValues();

          updateBooking.mutate({ id: booking.id, internalNote });
          setTimeout(() => {
            trackBookingInternalNoteCompleted(booking, internalNote, receipt);

            reset();
            toast.success(t('toastSuccess'));
            res('Success');
          }, 500);
        },
        (err) => {
          toast.error(t('toastError'));
          rej(err);
        }
      )();
    });
  };

  const handleReset = () => {
    reset({
      internalNote: booking?.internalNote,
    });
  };

  return (
    <FormProvider {...form}>
      <StyledBox>
        <Controller
          name={'internalNote'}
          control={methods.control}
          render={({ field, fieldState }) => (
            <Stack direction="row">
              <TextField
                {...field}
                variant="standard"
                aria-label="internal note"
                placeholder={`${t('internalNote', 'utils.generic')}...`}
                InputProps={{
                  disableUnderline: true,
                  sx: { fontSize: lightTheme.typography.small.normal.fontSize },
                }}
                minRows={3}
                fullWidth
                multiline
                disabled={!hasWriteAccess}
                onFocus={handleTrackStarted}
              />
              {fieldState.isDirty && (
                <Stack gap={2}>
                  <CloseRounded
                    color={'error'}
                    onClick={handleReset}
                    sx={noteButtonStyles}
                  />
                  <Check
                    color={'success'}
                    onClick={handleUpdateNote}
                    sx={noteButtonStyles}
                  />
                </Stack>
              )}
            </Stack>
          )}
        />
      </StyledBox>
    </FormProvider>
  );
};

const noteButtonStyles = {
  cursor: 'pointer',
  borderRadius: '50%',
  padding: '2px',
  transition: 'background-color 0.1s',
  '&:hover': {
    backgroundColor: lightTheme.palette.neutral.n50,
  },
  '&:active': {
    backgroundColor: lightTheme.palette.neutral.n100,
  },
};

const StyledBox = styled(Box)({
  backgroundColor: '#FFFFFF',
  border: '1px solid',
  borderColor: lightTheme.palette.neutral.n100,
  borderRadius: 4,
  padding: 4,
});
