import { useCallback, useRef } from 'react';

export const useFireOnce = () => {
  const fired = useRef(false);

  return useCallback((fn: () => unknown) => {
    if (fired.current) return;

    fired.current = true;
    fn();
  }, []);
};
