import { Dispatch, SetStateAction, useCallback } from 'react';

import {
  SearchParamOptions,
  useSearchParam,
} from '../../Hooks/events/useSearchParam';
import { useProfile } from '../../Hooks/useProfile';
import {
  GetSerializerType,
  SearchParams,
  serializers,
} from './search-params/calendar-search-params';
import { SearchParamSerializer } from './search-params/serialization/search-param-serializer';

export const SAVED_CALENDAR_KEY = 'calendar';

export function useCalendarSearchParam<
  ParamName extends SearchParams,
  T = GetSerializerType<ParamName>,
>(
  name: ParamName,
  options?: SearchParamOptions<ParamName, T>
): [T, Dispatch<SetStateAction<T>>] {
  const { updatePreferences } = useProfile();

  const saveToProfile = useCallback(
    <ParamName extends SearchParams, T = GetSerializerType<ParamName>>(
      name: ParamName,
      values: T
    ) => {
      const serializer = serializers[name] as SearchParamSerializer<T>;
      const serializedValue = serializer.serialize(values);
      updatePreferences(
        SAVED_CALENDAR_KEY,
        (previous: Record<string, string> | undefined) => {
          return {
            ...previous,
            [name]: serializedValue,
          };
        }
      );
    },
    [updatePreferences]
  );

  return useSearchParam<ParamName, T>(name, {
    serializer: serializers[name] as SearchParamSerializer<T>,
    onChange: saveToProfile,
    ...options,
  });
}
