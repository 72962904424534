import { useMemo } from 'react';
import * as yup from 'yup';

import { useTranslate } from '../../../../Hooks/useTranslate';
import { localizedSchema } from '../../../../Pages/SyiPage/config';

export const useCompanyFormValidation = () => {
  const { t } = useTranslate('');

  return useMemo(
    () =>
      yup.object().shape({
        name: yup.string(),
        website: yup
          .string()
          .transform((value) => {
            if (value && !value.startsWith('http')) {
              return `https://${value}`;
            }
            return value;
          })
          .url(t('validUrl', 'settings.company.errors')),
        cvrNr: yup.string(),
        companyEmail: yup.string(),
        companyPhone: yup.string(),
        defaultCurrency: yup.string().required(t('required', 'utils.errors')),
        description: localizedSchema(),
        domains: yup
          .array()
          .of(yup.string().required(t('required', 'utils.errors')))
          .required(t('required', 'utils.errors')),
        location: yup.object().shape({
          country: yup.string(),
          city: yup.string(),
          zipCode: yup.string(),
          address: yup.string(),
        }),
        vatCompliance: yup.object().shape({
          isVatRegistered: yup
            .boolean()
            .required(t('required', 'utils.errors')),
          vatRegistration: yup.object().when('isVatRegistered', {
            is: true,
            then: yup.object().shape({
              vatNumber: yup.string().required(t('required', 'utils.errors')),
              country: yup.string().required(t('required', 'utils.errors')),
              defaultVatCategory: yup
                .string()
                .required(t('required', 'utils.errors')),
            }),
            otherwise: yup.object().nullable(),
          }),
        }),
      }),
    [t]
  );
};

export type CompanyFormData = yup.InferType<
  ReturnType<typeof useCompanyFormValidation>
>;
