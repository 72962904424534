import axios from './index';

type GenerateStorefrontRequest = {
  companyUrl: string;
  locale: string;
} & (GenerateFullRequest | GeneratePartialRequest);

type GenerateFullRequest = {
  generate: {
    company: true;
    experience: true;
    events: true;
  };
};

type GeneratePartialRequest = {
  generate: {
    company: false;
    experience: true;
    events: boolean;
  };
  ids: {
    companyId: string;
  };
};

type GenerateStorefrontResponse = {
  id: string;
};

export const generateStorefront = async (
  request: GenerateStorefrontRequest
): Promise<GenerateStorefrontResponse> => {
  if (typeof process.env.REACT_APP_INTERNAL_API_GATEWAY === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_INTERNAL_API_GATEWAY is not defined'
    );
  }

  const { data } = await axios.post(
    `${process.env.REACT_APP_INTERNAL_API_GATEWAY}/v1/ai-onboarding/generate-storefront`,
    request
  );

  return data;
};

export type GetStorefrontUrlResponse =
  | {
      status: 'initiated' | 'failed';
    }
  | {
      status: 'succeeded';
      storefrontUrl: string;
      companyId?: string;
      experienceId?: string;
    };

export const getStorefrontUrl = async (
  id: string
): Promise<GetStorefrontUrlResponse> => {
  if (typeof process.env.REACT_APP_INTERNAL_API_GATEWAY === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_INTERNAL_API_GATEWAY is not defined'
    );
  }

  const { data } = await axios.get(
    `${process.env.REACT_APP_INTERNAL_API_GATEWAY}/v1/ai-onboarding/get-generated-storefront/${id}`
  );

  return data;
};
