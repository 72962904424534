import { Button, LinkButton, Text } from '@holdbar-com/pixel';
import { Experience } from '@holdbar-com/utils-types';
import { Stack } from '@mui/material';
import randomBytes from 'randombytes';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import {
  ActionFunctionArgs,
  defer,
  LoaderFunction,
  redirect,
  useHref,
  useLoaderData,
  useNavigate,
  useNavigation,
  useRouteLoaderData,
} from 'react-router';
import { useLinkClickHandler, useSubmit } from 'react-router-dom';
import { toast } from 'react-toastify';

import { getExperiences, updateConnectionItem } from '../../../Api';
import useResponsive from '../../../Hooks/layout/useResponsive';
import { useTranslate } from '../../../Hooks/useTranslate';
import { t } from '../../../i18n/config';
import { DialogWrapper } from '../components/dialog-wrapper';
import {
  SelectExperiences,
  SelectExperiencesInputs,
} from '../components/select-experiences';
import { decodeConnectionPath } from '../list/connection-path-tools';
import { ConnectionDetailsLoaderData } from './connection-details-page';

type LoaderData = {
  sharedExperiences: 'all' | 'none' | 'selection';
  experiences: Promise<Experience[]>;
};

export const loader: LoaderFunction<LoaderData> = async () => {
  const experiences = getExperiences({
    type: 'owned',
  });

  return defer({
    experiences: experiences,
  });
};

export async function action({ request, params }: ActionFunctionArgs) {
  const loadingToastId = randomBytes(16).toString('hex');
  toast.loading(t('connect.dialog.edit.toast.loading'), {
    toastId: loadingToastId,
  });

  try {
    const json = (await request.json()) as EditDialogFormInputs;

    toast.dismiss(loadingToastId);
    const { companyId, receiverEmail } = decodeConnectionPath(params.id!);

    await updateConnectionItem({
      companyId,
      receiverEmail,
      sharedExperiences:
        json.share === 'selection' ? json.experiences : json.share,
    });

    toast.success(t('connect.dialog.edit.toast.success'), {
      autoClose: 5000,
    });

    return redirect('..');
  } catch (error) {
    console.error(error);
    toast.dismiss(loadingToastId);
    toast.error(t('connect.dialog.edit.toast.error'), { delay: 500 });
    return {
      success: false,
    };
  }
}

type EditDialogFormInputs = SelectExperiencesInputs;

export function EditConnectionDialog() {
  const { t } = useTranslate('connect.dialog.edit');
  const { isSm } = useResponsive();
  const { experiences } = useLoaderData() as LoaderData;
  const { youShare } = useRouteLoaderData(
    'connection-details'
  ) as ConnectionDetailsLoaderData;

  const sharedExperiences = youShare === null ? 'none' : youShare;

  const closeHref = useHref(`..`);
  const closeLinkClick = useLinkClickHandler(closeHref);
  const submit = useSubmit();
  const { location, state } = useNavigation();

  const navigate = useNavigate();

  const formMethods = useForm<EditDialogFormInputs>({
    defaultValues: {
      share: Array.isArray(sharedExperiences) ? 'selection' : sharedExperiences,
      experiences: Array.isArray(sharedExperiences) ? sharedExperiences : [],
    },
  });

  const { handleSubmit, watch } = formMethods;

  const share = watch('share');
  const selectedExperiences = watch('experiences');

  const disableButton =
    state === 'submitting' ||
    state === 'loading' ||
    (share === 'selection' && selectedExperiences.length === 0);

  const handleClose = () => {
    navigate(closeHref);
  };

  const onSubmit = (data: FieldValues) => {
    submit(data, {
      method: 'post',
      action: location?.pathname,
      encType: 'application/json',
    });
  };

  return (
    <DialogWrapper
      fullWidth
      maxWidth="sm"
      fullScreen={isSm}
      open={true}
      onClose={handleClose}
      title={t('title')}
    >
      <FormProvider {...formMethods}>
        <Stack
          gap={4}
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
        >
          <Text variant="normal" fontSize="small">
            {t('description')}
          </Text>

          <SelectExperiences experiences={experiences} />

          <Stack
            gap={2}
            direction={{ xs: 'column', md: 'row' }}
            justifyContent={'space-between'}
            alignItems={{ xs: 'stretch', md: 'stretch' }}
          >
            <LinkButton
              href={closeHref}
              onClick={closeLinkClick}
              variant="secondary"
              size="large"
              fullWidth
              style={{
                flexShrink: 'unset',
              }}
            >
              {t('cancel')}
            </LinkButton>
            <Button
              type="submit"
              variant="primary"
              size="large"
              fullWidth
              disabled={disableButton}
              style={{
                flexShrink: 'unset',
              }}
            >
              {t('confirm')}
            </Button>
          </Stack>
        </Stack>
      </FormProvider>
    </DialogWrapper>
  );
}
