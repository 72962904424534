import { ErrorRounded } from '@mui/icons-material';
import { Box, BoxProps } from '@mui/material';
import { forwardRef } from 'react';

export const InputStatus = forwardRef<
  HTMLElement,
  BoxProps & { status: 'success' | 'error' }
>(({ status, children, sx, ...props }, ref) => {
  return (
    <Box
      ref={ref}
      p={'6px'}
      pl={2}
      pr={2}
      display={'flex'}
      alignItems={'center'}
      sx={{
        backgroundColor: status === 'success' ? '#EDF9F0' : '#FEEFEF',
        color: status === 'success' ? 'success.main' : 'error.main',
        ...sx,
      }}
      borderRadius={1}
      {...props}
    >
      {status !== 'success' && (
        <ErrorRounded color={status} fontSize={'small'} sx={{ mr: 1 }} />
      )}
      {children}
    </Box>
  );
});
