import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mui/material';
import { AnimatePresence } from 'framer-motion';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { theme } from '../../../Utils/theme';
import { PreviewLayout } from '../preview-layout';
import { PreviewLayoutHeader } from '../preview-layout-header';
import { PreviewFormNoWebsite } from './preview-form/PreviewFormNoWebsite';
import { PreviewFormSubmit } from './preview-form/PreviewFormSubmit';
import { PreviewFormWebsiteField } from './preview-form/PreviewFormWebsiteField';
import {
  PreviewFormData,
  usePreviewFormValidation,
} from './preview-form/use-preview-form-validation';

export const PreviewView = () => {
  const { t } = useTranslation();
  const flags = useFlags();
  const navigate = useNavigate();

  useEffect(() => {
    // Need to check explicitly for false to ensure flags are loaded
    if (flags.featureAlternativeGetStarted === false) {
      navigate('/signup');
    }
  }, [flags, navigate]);

  const formValidationSchema = usePreviewFormValidation(t);
  const form = useForm<PreviewFormData>({
    resolver: yupResolver(formValidationSchema),
    defaultValues: {
      website: '',
    },
  });

  return (
    <FormProvider {...form}>
      <PreviewLayout>
        <Stack gap={4}>
          <PreviewLayoutHeader type="start" />
          <PreviewFormWebsiteField />
        </Stack>
        <Stack
          sx={{
            position: {
              xs: 'fixed',
              sm: 'relative',
            },
            bottom: {
              xs: theme.spacing(8),
              sm: 0,
            },
            gap: 2,
            px: {
              xs: 3,
              sm: 0,
            },
          }}
          alignItems={'center'}
          width="100%"
          left={0}
        >
          <AnimatePresence>
            <PreviewFormSubmit />
            <PreviewFormNoWebsite />
          </AnimatePresence>
        </Stack>
      </PreviewLayout>
    </FormProvider>
  );
};
