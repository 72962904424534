import { Button } from '@holdbar-com/pixel';
import { Stack } from '@mui/material';

import { useTranslate } from '../../../Hooks/useTranslate';

type RequestReportFormActionProps = {
  handleSubmit: () => void;
};

export const RequestReportFormAction = ({
  handleSubmit,
}: RequestReportFormActionProps) => {
  const { t } = useTranslate('dialogs.requestReport');

  return (
    <Stack direction="row" justifyContent="end">
      <Button
        variant="primary"
        size="medium"
        type="submit"
        onClick={handleSubmit}
      >
        {t('send')}
      </Button>
    </Stack>
  );
};
