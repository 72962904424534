import { lightTheme, Text } from '@holdbar-com/pixel';
import { IconButton, Input, Stack, styled } from '@mui/material';
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { useUpdateEvent } from '../../../Hooks/events/useUpdateEvent';
import { TEvent } from '../../../Utils/eventHelpers';
import { EventsListItemEditParticipantsFieldIcon } from './EventsListItemEditParticipantsFieldIcon';
import { useEditParticipantsAmpli } from './use_edit_participants_ampli';
import {
  State,
  useEditParticipantsReducer,
} from './use_edit_participants_reducer';

type EventsListItemEditParticipantsFieldProps = {
  event: TEvent;
  onClose: () => void;
};

export const EventsListItemEditParticipantsField = ({
  event,
  onClose,
}: EventsListItemEditParticipantsFieldProps) => {
  const { t } = useTranslation();
  const { updateEvent } = useUpdateEvent(event.id, event.experienceId);
  const { state, dispatch } = useEditParticipantsReducer();
  const { trackCapacitySaveClick, trackCapacitySaveEnter } =
    useEditParticipantsAmpli();
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [inputValue, setInputValue] = useState(event.slots.total.toString());

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  // closes field when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    if (state.isError) {
      timeout = setTimeout(() => dispatch({ type: 'reset' }), 2000);
    }
    if (state.isSaved) {
      timeout = setTimeout(() => onClose(), 2000);
    }
    return () => clearTimeout(timeout);
  }, [state.isSaved, state.isError]);

  const handleUpdateParticipants = useCallback(async () => {
    dispatch({ type: 'saveStart' });
    const newValue = parseInt(inputValue, 10);
    if (isNaN(newValue) || newValue < (event.slots.booked ?? 0)) {
      dispatch({ type: 'saveError' });
      return;
    }
    try {
      await updateEvent
        .mutateAsync({
          ...event,
          seatCount: { selectedOptionKey: 'custom', value: newValue },
        })
        .then(() => {
          dispatch({ type: 'saveSuccess' });
        });
    } catch (error) {
      console.error('Save operation failed:', error);
      toast.error(t('eventUpsert.toast.error.editEvent'));
      dispatch({ type: 'saveError' });
    }
  }, [event, inputValue, updateEvent, t]);

  const validateAndSetInputValue = useCallback(
    (value: string) => {
      if (value === '' || /^\d+$/.test(value)) {
        setInputValue(value);
        dispatch({ type: 'reset' });
      }
    },
    [setInputValue]
  );

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === 'Enter') {
        handleUpdateParticipants();
      }
    },
    [handleUpdateParticipants]
  );

  return (
    <Stack ref={containerRef} direction="row" alignItems="center" gap={0.5}>
      <Text fontSize="small">{event.slots.booked}</Text>
      <Text fontSize="small">/</Text>
      <StyledInput
        autoFocus
        state={state}
        value={inputValue}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          validateAndSetInputValue(e.target.value)
        }
        onKeyDown={(e) => {
          trackCapacitySaveEnter(event.slots.total, parseInt(inputValue, 10));
          handleKeyDown(e);
        }}
        style={{ outline: 'none' }}
        disableUnderline
        onClick={(e) => e.stopPropagation()}
        endAdornment={
          <StyledIconButton
            onClick={() => {
              trackCapacitySaveClick(
                event.slots.total,
                parseInt(inputValue, 10)
              );
              handleUpdateParticipants();
            }}
          >
            <EventsListItemEditParticipantsFieldIcon state={state} />
          </StyledIconButton>
        }
      />
    </Stack>
  );
};

const StyledInput = styled(Input)<{ state: State }>`
  padding: 0px 8px;
  border: 1px solid
    ${({ state }) =>
      state.isError
        ? lightTheme.palette.error.e400
        : lightTheme.palette.contrast.black};
  border-radius: 8px;
  font-size: 14px;
  max-width: clamp(56px, 100%, 64px);
`;

const StyledIconButton = styled(IconButton)`
  padding: 0;
  color: inherit;
`;
