import { format } from 'date-fns';

import { SearchParamSerializer } from './search-param-serializer';

export class DateSerializer implements SearchParamSerializer<Date> {
  serialize(value: Date) {
    const date = !value || isNaN(value.getTime()) ? new Date() : value;
    return format(date, 'yyyy-MM-dd');
  }

  deserialize(value: string) {
    try {
      const date = !value ? new Date() : new Date(value);
      return isNaN(date.getTime()) ? new Date() : date;
    } catch {
      return new Date();
    }
  }
}
