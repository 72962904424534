import { useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import * as api from '../Api';
import type { CalendarFeedConfig } from '../Api/CalendarFeeds';

export const useCalendarFeed = () => {
  const queryClient = useQueryClient();

  const QueryKey = ['calendarFeed'];

  const feed = useQuery(
    QueryKey,
    async () => {
      await queryClient.cancelQueries(QueryKey);
      return api.getCalendarFeed();
    },
    {
      enabled: true,
      retry: false,
    }
  );

  const accessKey = useMemo<string>(() => {
    return feed.data?.accessKey ?? '';
  }, [feed]);

  const updateFeed = useMutation(
    (payload: CalendarFeedConfig) => api.updateCalendarFeed(payload),
    {
      onMutate: async (payload) => {
        await queryClient.cancelQueries(QueryKey);

        const previous = queryClient.getQueryData(QueryKey);

        queryClient.setQueryData<CalendarFeedConfig>(QueryKey, (prev) => {
          return payload;
        });

        return { previous };
      },
      onError: (err, variables, context: any) => {
        if (context?.previous) {
          queryClient.setQueryData(QueryKey, context.previous);
        }
      },
      onSettled: async () => {
        queryClient.invalidateQueries(QueryKey);
      },
    }
  );

  const deleteFeed = useMutation(() => api.deleteCalendarFeed(), {
    onMutate: async () => {
      await queryClient.cancelQueries(QueryKey);

      const previous = queryClient.getQueryData(QueryKey);

      queryClient.setQueryData<CalendarFeedConfig | {}>(QueryKey, (prev) => {
        return {};
      });

      return { previous };
    },
    onError: (err, variables, context: any) => {
      if (context?.previous) {
        queryClient.setQueryData(QueryKey, context.previous);
      }
    },
    onSettled: async () => {
      queryClient.invalidateQueries(QueryKey);
    },
  });

  return {
    feed,
    updateFeed,
    deleteFeed,
    accessKey,
  };
};
