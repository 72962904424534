import { PopoverProps } from '@mui/material';
import {
  createContext,
  PropsWithChildren,
  SyntheticEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { trackEventDetailsPopupViewed } from '../../../../tracking/events/trackEventDetailsPopupViewed';
import { TEventWithTitle } from '../../../../Utils/eventHelpers';
import { useCalendar } from '../../context';

type EventPopoverContextType = {
  popoverPosition?: PopoverProps['anchorPosition'];

  selectedEvent?: TEventWithTitle;
  setSelectedEventId: (eventId: string | undefined) => void;

  handleSelectEvent: (
    event: TEventWithTitle,
    e: SyntheticEvent<HTMLElement>
  ) => void;
  handleClosePopover: () => void;
};

export const EventPopverContext = createContext<EventPopoverContextType | null>(
  null
);

export const useEventPopover = () => {
  const context = useContext(EventPopverContext);

  if (!context) {
    throw new Error(
      'useEventPopover must be used within a EventPopoverContextType'
    );
  }

  return context;
};

export const EventPopoverContextProvider = ({
  children,
}: PropsWithChildren) => {
  const { isLoading, events } = useCalendar();

  const [selectedEvent, setSelectedEvent] = useState<
    TEventWithTitle | undefined
  >();
  const [selectedEventId, setSelectedEventId] = useState<string | undefined>();

  // If new events are fetched, it might be because of an invalidated cache,
  // so we need to check if the id of event has changed (from virtual to real event).
  useEffect(() => {
    // Wait until the new data is available
    if (isLoading) return;

    if (!selectedEventId) return;

    const event = events?.find((event) => event.id === selectedEventId);
    if (!event) return;

    setSelectedEvent(event);
  }, [selectedEventId, events, isLoading]);

  const [popoverPosition, setPopoverPosition] =
    useState<EventPopoverContextType['popoverPosition']>();

  const handleClosePopover = useCallback(() => {
    setPopoverPosition(undefined);
  }, []);

  const handleSelectEvent = (
    event: TEventWithTitle,
    e: SyntheticEvent<HTMLElement>
  ) => {
    const bounding = e.currentTarget.getBoundingClientRect();
    setPopoverPosition({ top: bounding.bottom, left: bounding.right });
    setSelectedEventId(event.id);

    trackEventDetailsPopupViewed(event, undefined);
  };

  return (
    <EventPopverContext.Provider
      value={{
        selectedEvent,
        popoverPosition,
        setSelectedEventId,
        handleSelectEvent,
        handleClosePopover,
      }}
    >
      {children}
    </EventPopverContext.Provider>
  );
};
