import { useQuery, useQueryClient } from 'react-query';

import * as api from '../Api';

export const useAudits = (
  object: string,
  objectId: string,
  page?: number,
  limit?: number
) => {
  const queryClient = useQueryClient();

  const AuditsQueryKey = ['audits', { object, objectId }, page];

  const audits = useQuery(
    AuditsQueryKey,
    async () => {
      await queryClient.cancelQueries(['audits']);
      const audits = await api.getAudits(object, objectId, page, limit);
      return audits;
    },
    { enabled: !!objectId }
  );

  return { audits };
};
