import { Button } from '@holdbar-com/pixel';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import useResponsive from '../../../../../../Hooks/layout/useResponsive';
import { useExperience } from '../../../../../../Hooks/useExperience';
import { trackEventFlowTimeStepCompleted } from '../../../../../../tracking/events/flow/time/trackEventFlowTimeStepCompleted';
import { useEventUpsert } from '../../../domain/event_upsert_context';
import { EventType, isIntervalSelected } from '../../../domain/types';
import { EventUpsertContent } from '../../layout/EventUpsertContent';
import { EventUpsertTimeForm } from './time_form/EventUpsertTimeForm';
import {
  EventUpsertTimeFormTypes,
  useTimeFormValidation,
} from './time_form/use_time_form_validation';

type EventUpsertTimeProps = {
  onSubmit: () => void;
};

export const EventUpsertTime = ({ onSubmit }: EventUpsertTimeProps) => {
  const { t } = useTranslation();
  const { currentEvent, updateMultipleEventFields, updateEventStepLatest } =
    useEventUpsert();
  const { isSm } = useResponsive();
  const {
    experiences: { data: experiences },
  } = useExperience();

  const formValidationSchema = useTimeFormValidation(t);
  const form = useForm<EventUpsertTimeFormTypes>({
    resolver: yupResolver(formValidationSchema),
    defaultValues: {
      startDateTime: currentEvent.startDateTime,
      endDateTime: currentEvent.endDateTime,
      endDateTimeDisabled: currentEvent.endDateTimeDisabled,
    } as EventUpsertTimeFormTypes,
  });

  const {
    handleSubmit,
    formState: { errors },
  } = form;

  // Validation of intervals is handled manually here and by each interval field
  // because the intervals doesn't include ID's. I don't think it would be optimal
  // use yup to validation for now, until a better model is implemented.
  const hasInvalidIntervals = useCallback(
    (intervals: EventType['intervals']) => {
      if (isIntervalSelected(intervals) && intervals.value.length) {
        return intervals.value.some(
          (val) => parseInt(val.fromTime) >= parseInt(val.toTime)
        );
      }
      return false;
    },
    []
  );

  const action = (
    <Button
      size="medium"
      variant="primary"
      width={isSm ? 'auto' : 208}
      disabled={
        hasInvalidIntervals(currentEvent.intervals) ||
        Object.keys(errors).length > 0
      }
      onClick={handleSubmit(async (data) => {
        await updateMultipleEventFields({
          startDateTime: data.startDateTime,
          endDateTime: data.endDateTime,
        });

        onSubmit();

        trackEventFlowTimeStepCompleted(data, currentEvent, experiences);
      })}
    >
      {t('eventUpsert.actions.finish')}
    </Button>
  );

  return (
    <FormProvider {...form}>
      <EventUpsertContent
        title={t('eventUpsert.flow.time.title')}
        action={action}
        onBack={updateEventStepLatest}
      >
        <EventUpsertTimeForm />
      </EventUpsertContent>
    </FormProvider>
  );
};
