import { Stack, styled } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

import { ArrowLeft } from '../../Components/Arrow/ArrowLeft';

type Props = PropsWithChildren;

export const PreviewLayoutMobile: FC<Props> = ({ children }) => {
  const handleBack = () => {
    const hasHistory = window.history.length > 1;

    if (hasHistory) {
      window.history.back();
    } else {
      window.location.href = 'https://www.holdbar.com/';
    }
  };

  return (
    <StyledSignupMobile>
      <StyledBackButton onClick={handleBack}>
        <ArrowLeft />
      </StyledBackButton>
      {children}
    </StyledSignupMobile>
  );
};

const StyledSignupMobile = styled(Stack)(({ theme }) => ({
  alignItems: 'flex-start',
  minHeight: '100vh',
  width: '100%',
  gap: theme.spacing(4),
  padding: theme.spacing(3),
}));

const StyledBackButton = styled('button')({
  background: 'none',
  color: 'inherit',
  border: 'none',
  padding: 0,
  font: 'inherit',
  cursor: 'pointer',
  outline: 'inherit',
});
