import axios, { IShallowSuccessResponse } from './index';

export type OnBoardingData = {
  key: string;
  metadata?: {
    status?: string;
    requirements?: string[];
    [k: string]: string | undefined | string[];
  };
  progress: number;
  updated: string;
  subjectId?: string;
};

export type OnBoardingResult = {
  [key: string]: OnBoardingData[];
};

export const getOnBoarding = async (): Promise<OnBoardingResult> => {
  if (typeof process.env.REACT_APP_API_ONBOARDING_URL === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_ONBOARDING_URL is not defined'
    );
  }

  const { data } = await axios.get<OnBoardingResult>(
    `${process.env.REACT_APP_API_ONBOARDING_URL}`
  );

  return data;
};

export const updateOnBoardingStep = async (
  type: string,
  payload: { [k: string]: unknown }
) => {
  if (typeof process.env.REACT_APP_API_ONBOARDING_URL === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_ONBOARDING_URL is not defined'
    );
  }

  if (!payload) {
    throw new Error('Missing payload');
  }

  const { data } = await axios.put(
    `${process.env.REACT_APP_API_ONBOARDING_URL}`,
    {
      type,
      steps: [payload],
    }
  );

  return data;
};
