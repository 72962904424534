import styled from '@emotion/styled';
import { lightTheme, Text } from '@holdbar-com/pixel';
import { Box } from '@mui/material';
import {
  eachDayOfInterval,
  endOfMonth,
  format,
  isSameDay,
  startOfMonth,
} from 'date-fns';
import { FC, useMemo } from 'react';

import { useCalendar } from '../../context';
import { CalendarGrid } from '../calendar-grid/CalendarGrid';
import { GroupedRows } from './GroupedRows';

export const CalendarMonthListGrouped: FC = () => {
  const { selectedDate } = useCalendar();

  const dates = useMemo(() => {
    const monthStart = startOfMonth(selectedDate);
    const monthEnd = endOfMonth(monthStart);

    return eachDayOfInterval({ start: monthStart, end: monthEnd });
  }, [selectedDate]);

  return (
    <Grid $columns={dates.length + 1} $divideHeaders gridTemplateColumns={''}>
      <div>{/* Skip first cell */}</div>
      {dates.map((date) => {
        const isToday = isSameDay(date, new Date());

        return (
          <Box
            key={date.toISOString()}
            paddingY={1.5}
            textAlign="center"
            style={{
              backgroundColor: isToday
                ? lightTheme.palette.action.a300
                : 'transparent',
              color: isToday ? '#FFFFFF' : lightTheme.palette.neutral.n300,
            }}
          >
            <DayOfWeek variant="medium" fontSize="small">
              {format(date, 'd')}
            </DayOfWeek>
          </Box>
        );
      })}

      <GroupedRows dates={dates} />
    </Grid>
  );
};

const Grid = styled(CalendarGrid)`
  grid-template-columns: minmax(100px, 200px) repeat(
      ${({ $columns }) => $columns - 1},
      minmax(32px, 1fr)
    );

  width: 100%;

  overflow-x: auto;
`;

const DayOfWeek = styled(Text)`
  color: inherit;
  text-transform: capitalize;
  text-align: center;
`;
