import NiceModal from '@ebay/nice-modal-react';
import TuneIcon from '@mui/icons-material/Tune';
import { Button } from '@mui/material';

import { FiltersModal } from './FiltersModal';

export const Filters = () => {
  return (
    <>
      <Button
        style={{ padding: 0, minWidth: 'auto' }}
        onClick={() => NiceModal.show('filters-modal')}
      >
        <TuneIcon />
      </Button>
      <FiltersModal id={'filters-modal'} />
    </>
  );
};
