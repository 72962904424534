import styled from '@emotion/styled';
import { ComponentPropsWithoutRef, FC, forwardRef } from 'react';

import { OneLineText } from '../../../../Components/OneLineText/OneLineText';
import { TBooking } from '../../../../Hooks/useBookings';
import { IExperience } from '../../../../Hooks/useExperience';
import { useTranslate } from '../../../../Hooks/useTranslate';
import { getTicketTypes } from '../../../events/event_details/BookingsSummary';

type Props = {
  bookings: TBooking[] | undefined;
  experience: IExperience | undefined;
} & ComponentPropsWithoutRef<typeof OneLineText>;

export const TicketTypes: FC<Props> = forwardRef<HTMLSpanElement, Props>(
  ({ bookings, experience, ...rest }, ref) => {
    const { i18n } = useTranslate('utils.generic');

    const eventTickets = getTicketTypes(i18n, bookings, experience);

    const ticketTypes = eventTickets
      ? Object.entries(eventTickets?.variants)
          .map(([name, count]) => `${count} x ${name}`)
          .join(', ')
      : undefined;

    if (!ticketTypes) {
      return null;
    }

    return (
      <StyledOneLineText ref={ref} {...rest}>
        - {ticketTypes}
      </StyledOneLineText>
    );
  }
);

TicketTypes.displayName = 'TicketTypes';

const StyledOneLineText = styled(OneLineText)`
  text-transform: lowercase;
`;
