import { InfoBox } from '../../../../../../../Components/InfoBox/InfoBox';
import useResponsive from '../../../../../../../Hooks/layout/useResponsive';
import { useTranslate } from '../../../../../../../Hooks/useTranslate';
import { useBookingDetailsContext } from '../../../../domain/use_booking_details_context';
import { BookingDetailsSummaryNotesForm } from './BookingDetailsSummaryNotesForm';
import { BookingDetailsSummaryNotesMobile } from './BookingDetailsSummaryNotesMobile';

export const BookingDetailsSummaryNotes = () => {
  const { t } = useTranslate('dialogs.booking');
  const { isSm } = useResponsive();

  const { booking } = useBookingDetailsContext();

  if (!booking) return null;

  if (isSm) {
    return <BookingDetailsSummaryNotesMobile />;
  }

  return (
    <InfoBox title={t('sections.notes')}>
      <BookingDetailsSummaryNotesForm />
    </InfoBox>
  );
};
