import {
  Box,
  ButtonBase,
  ButtonBaseProps,
  Card,
  Chip,
  ChipProps,
  Grid,
  Skeleton,
  Stack,
  styled,
  Typography,
  TypographyProps,
} from '@mui/material';
import React, { ReactNode } from 'react';

import { useTranslate } from '../../Hooks/useTranslate';
import styles from './CustomCard.module.css';

export const states = {
  active: ['Aktiv', 'success'],
  draft: ['Kladde', 'default'],
  unbooked: ['Ikke booket', 'default'],
  done: ['Afsluttet', 'default'],
  moved: ['Flyttet', 'default'],
  inactive: ['Inaktiv', 'warning'],
  archived: ['Arkiveret', 'default'],
  edited: ['Redigeret', 'warning'],
  paid: ['Betalt', 'success'],
  unpaid: ['Ikke betalt', 'warning'],
  cancelled: ['Aflyst', 'error'],
  fullyBooked: ['Fuldt booket', 'success'],
  booked: ['Booket', 'success'],
  invited: ['Inviteret', 'default'],
  'cancellation-failed-partly': ['Delvist aflyst', 'error'],
  'cancellation-failed-entirely': ['Aflysning mislykkedes', 'error'],
  'checked-in': ['Checket ind', 'primary'],
};

export interface ICustomCardProps {
  title?: string;
  status?: keyof typeof states;
  fullWidth?: boolean;
  onClickStatus?: (currentStatus: keyof typeof states) => void;
  image: ReactNode;
  content: ReactNode;
}

const StyledChip = styled(Chip)({
  '&.MuiChip-colorDefault': {
    backgroundColor: '#E3E3E3',
  },
});
export const StatusChip = ({
  status,
  color,
  label,
  ...props
}: ChipProps & { status: keyof typeof states }) => {
  const { t } = useTranslate('utils.statusOptions');

  return (
    <StyledChip
      color={(states?.[status]?.[1] as any) ?? 'default'}
      label={t(status)}
      {...props}
    />
  );
};

export const LeftLabel = ({ children, ...props }: TypographyProps) => (
  <Typography
    fontWeight={{ xs: 600, md: 400 }}
    fontSize={{ xs: '10px', sm: '12px', md: '0.875em' }}
    color={'#8E8E93'}
    minWidth={{ xs: 0, md: 96 }}
    {...props}
  >
    {children}
  </Typography>
);

export const CustomCard = ({
  title,
  status,
  fullWidth = false,
  image,
  content,
  children,
  onClickStatus,
  onClick,
  ...props
}: ICustomCardProps & ButtonBaseProps) => {
  return (
    <Grid item xs={1} md={1} width={fullWidth ? '100%' : 'auto'}>
      <ButtonBase
        onClick={onClick}
        sx={{
          display: 'block',
          textAlign: 'left',
          width: '100%',
          cursor: onClick ? 'pointer' : 'initial',
          pr: { xs: 0, md: 0 },
        }}
        disableRipple
        {...props}
      >
        <Stack
          component={Card}
          position={'relative'}
          sx={{
            ['&:hover']: {
              boxShadow: onClick
                ? '0 16px 16px 0 rgba(0,0,0,0.1), 0 0 10px 0 rgb(181 181 190 / 10%), 0 0 2px 0 rgb(151 151 151 / 20%), 0 0 1px 0 rgb(151 151 151 / 35%);'
                : '0 0 10px 0 rgb(181 181 190 / 10%), 0 0 2px 0 rgb(151 151 151 / 20%), 0 0 1px 0 rgb(151 151 151 / 35%);',
            },
          }}
          p={2}
          direction={'row'}
          justifyContent={'space-between'}
        >
          {status && title && (
            <StatusChip
              sx={{
                position: 'absolute',
                height: { xs: 24, md: 32 },
                top: 24,
                left: 24,
                zIndex: 5,
              }}
              status={status}
              onClick={
                onClickStatus ? () => onClickStatus?.(status) : undefined
              }
            />
          )}
          <Stack
            sx={{
              opacity: status === 'archived' ? 0.38 : 1,
              width: { xs: '100%', md: 'auto' },
              alignItems: { xs: 'flex-start', md: 'center' },
            }}
            direction={'row'}
            spacing={{ xs: 2 }}
          >
            {image}

            <Box sx={{ width: { xs: '100%', md: 'auto' } }}>
              {title === undefined ? (
                <Skeleton width={'100%'} height={29} />
              ) : (
                <Typography
                  className={styles.cardTitle}
                  variant={'h4'}
                  fontSize={{ xs: '1em', sm: '1.375em', md: '1.375em' }}
                >
                  {title}
                </Typography>
              )}

              {content}
            </Box>
          </Stack>

          {children}
        </Stack>
      </ButtonBase>
    </Grid>
  );
};
