import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';

type EventUpsertTimeFormRecurringCustomButtonProps = {
  onClick: () => void;
};

export const EventUpsertTimeFormRecurringEditButton = ({
  onClick,
}: EventUpsertTimeFormRecurringCustomButtonProps) => {
  return (
    <IconButton onClick={onClick}>
      <EditIcon fontSize="small" />
    </IconButton>
  );
};
