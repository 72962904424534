import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';

import {
  StepCompletedProperties,
  trackQuickSetupNextStepClicked,
  trackQuickSetupPreviousStepClicked,
} from '../../tracking/quick-setup/global-events';
import {
  trackQuickSetupDescriptionStepCompleted,
  trackQuickSetupLocationStepCompleted,
  trackQuickSetupMediaStepCompleted,
  trackQuickSetupNameStepCompleted,
  trackQuickSetupPriceStepCompleted,
  trackQuickSetupStartStepCompleted,
  trackQuickSetupTimeStepCompleted,
} from '../../tracking/quick-setup/step-events';
import { EndStep } from './steps/end';
import { TimeStep } from './steps/event-time';
import { DescriptionStep } from './steps/experience-description';
import { LocationStep } from './steps/experience-location';
import { MediaStep } from './steps/experience-media';
import { NameStep } from './steps/experience-name';
import { PriceStep } from './steps/experience-tickets';
import { StartStep } from './steps/start';

type CurrentStep = {
  component: () => JSX.Element;
  isSkippable: boolean;
  showTertiaryAction: boolean;
  trackingFn: ((properties: StepCompletedProperties) => void) | undefined;
};

type OnboardingContextType = {
  step: number;
  stepCount: number;
  prevStep: () => void;
  nextStep: (shouldTrack?: boolean) => void;
  setStep: (index: number) => void;
  StepComponent: () => JSX.Element;
  currentStepKey: Step;
  currentStep: CurrentStep;
  closeSetupFlow: () => void;
};

export const OnboardingContext = createContext<OnboardingContextType | null>(
  null
);

export const useOnboardingFlow = () => {
  const context = useContext(OnboardingContext);

  if (!context) {
    throw new Error(
      'useOnboardingFlow must be used within a CalendarContextProvider'
    );
  }

  return context;
};

export const onboardingSteps = {
  start: {
    component: StartStep,
    isSkippable: false,
    showTertiaryAction: false,
    trackingFn: trackQuickSetupStartStepCompleted,
  },
  name: {
    component: NameStep,
    isSkippable: false,
    showTertiaryAction: true,
    trackingFn: trackQuickSetupNameStepCompleted,
  },
  description: {
    component: DescriptionStep,
    isSkippable: true,
    showTertiaryAction: true,
    trackingFn: trackQuickSetupDescriptionStepCompleted,
  },
  media: {
    component: MediaStep,
    isSkippable: true,
    showTertiaryAction: true,
    trackingFn: trackQuickSetupMediaStepCompleted,
  },
  time: {
    component: TimeStep,
    isSkippable: false,
    showTertiaryAction: true,
    trackingFn: trackQuickSetupTimeStepCompleted,
  },
  location: {
    component: LocationStep,
    isSkippable: false,
    showTertiaryAction: true,
    trackingFn: trackQuickSetupLocationStepCompleted,
  },
  price: {
    component: PriceStep,
    isSkippable: false,
    showTertiaryAction: true,
    trackingFn: trackQuickSetupPriceStepCompleted,
  },
  end: {
    component: EndStep,
    isSkippable: false,
    showTertiaryAction: false,
    trackingFn: undefined,
  },
} as const;

export type Step = keyof typeof onboardingSteps;

export const stepOrder = Object.keys(onboardingSteps) as Step[];

export const OnboardingContextProvider = ({
  children,
  setShouldWarnBeforeClosing,
  setCurrentStepKey,
  closeSetupFlow,
}: PropsWithChildren & {
  setShouldWarnBeforeClosing: Dispatch<SetStateAction<boolean>>;
  setCurrentStepKey: Dispatch<SetStateAction<Step>>;
  closeSetupFlow: () => void;
}) => {
  const [step, setStep] = useState(0);
  const currentStepKey = stepOrder[step];
  const currentStep = onboardingSteps[currentStepKey];
  const StepComponent = currentStep.component;

  const handlePreviousStep = () => {
    const newStep = step > 0 ? step - 1 : step;
    setStep(newStep);
    trackQuickSetupPreviousStepClicked(currentStepKey, stepOrder[newStep]);
  };

  const handleNextStep = (shouldTrack: boolean = true) => {
    const newStep = step < stepOrder.length - 1 ? step + 1 : step;
    setStep(newStep);
    shouldTrack &&
      trackQuickSetupNextStepClicked(currentStepKey, stepOrder[newStep]);
  };

  const handleSetStep = (stepIndex: number) => {
    if (stepIndex > 0 && stepIndex < stepOrder.length - 1) setStep(stepIndex);
  };

  useEffect(() => {
    setCurrentStepKey(currentStepKey);
    if (['start', 'end'].includes(currentStepKey)) {
      return setShouldWarnBeforeClosing(false);
    }
    return setShouldWarnBeforeClosing(true);
  }, [currentStepKey, setShouldWarnBeforeClosing]);

  return (
    <OnboardingContext.Provider
      value={{
        step,
        setStep: handleSetStep,
        stepCount: stepOrder.length,
        currentStepKey,
        currentStep,
        StepComponent,
        prevStep: handlePreviousStep,
        nextStep: handleNextStep,
        closeSetupFlow,
      }}
    >
      {children}
    </OnboardingContext.Provider>
  );
};
