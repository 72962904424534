import { Button, lightTheme, Text } from '@holdbar-com/pixel';
import { MediaItem } from '@holdbar-com/utils-types';
import { ArrowUpwardRounded } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import { useMemo } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';

import * as api from '../../Api';
import {
  getURLFromMediaItem,
  Media,
} from '../../Components/DraggableGallery/Media';
import { GalleryDropzone } from '../../Components/GalleryDropzone/Dropzone';
import { StockPhotoSelector } from '../../features/onboarding/media-upload/stock-photo-selector';
import { ErrorLabel } from '../../Pages/SyiPage/ErrorLabel';
import { useErrors } from '../../Pages/SyiPage/useErrors';
import { TFileTypes } from '../../types';

export const ExperienceSyiSectionPictures = () => {
  const { t } = useTranslation();

  const {
    id: experienceId,
    submitForm,
    isCreateFlow,
  } = useOutletContext<{
    id: string;
    isCreateFlow: boolean;
    submitForm: (forceUpdate: boolean, useModal: boolean) => void;
  }>();

  const { watch, getValues } = useFormContext();

  const { getError, clearError } = useErrors();

  const experienceHeadline = watch('headline');
  const experienceDescription = watch('practicalInfo');

  const { field: media } = useController({
    name: 'media.cover',
  });
  const { field: mediaPromises } = useController({ name: 'mediaPromises' });

  const handlePreparedUpload = (
    newMedia: any,
    fileType: TFileTypes,
    uploadFunc: () => Promise<void>
  ) => {
    const prev = getValues()?.media?.cover ?? [];
    const prevProms = getValues()?.mediaPromises ?? [];
    media.onChange([...prev, newMedia]);
    mediaPromises.onChange([...prevProms, uploadFunc]);
    clearError('media', 'cover');
  };

  const handleDeleteUpload = (mediaItem: MediaItem) => {
    const newMedia = (media.value ?? []).filter(
      (el: any, i: number) =>
        getURLFromMediaItem(mediaItem) !== getURLFromMediaItem(el)
    );

    media.onChange(newMedia);
    if (!isCreateFlow) {
      return api.deleteUpload(mediaItem).then(() => submitForm(true, false));
    }
  };

  const hasMedia = useMemo(() => media?.value?.length > 0, [media.value]);

  return (
    <Stack gap={4}>
      <Stack direction={'row'} justifyContent={'space-between'}>
        <Typography variant={'h4'}>
          {t('experience.create.media.title')}
          <ErrorLabel fontSize={'0.88rem'} label={getError('media', 'cover')} />
        </Typography>
      </Stack>
      {hasMedia && (
        <Media
          onDelete={handleDeleteUpload}
          onUpdate={media.onChange}
          media={media.value}
        />
      )}
      <GalleryDropzone
        modelType={'experience'}
        fileType={'cover'}
        showProgress={true}
        id={experienceId}
        onPrepared={handlePreparedUpload}
        containerProps={{
          sx: {
            borderRadius: '8px',
            backgroundColor: '#E6F0FF40',
            border: `1px dashed ${lightTheme.palette.action.a300}`,
            '&:hover': { backgroundColor: '#E6F0FF40' },
          },
        }}
      >
        <Stack gap={2} py={hasMedia ? 5 : 10}>
          {!hasMedia && (
            <Typography variant={'h5'}>
              {t('experience.create.media.uploadTextEmpty')}
            </Typography>
          )}
          <Stack gap={1} alignItems={'center'}>
            <Button
              size="medium"
              variant={'secondary'}
              leftIcon={<ArrowUpwardRounded fontSize="small" sx={{ mr: 1 }} />}
              style={{ width: 'fit-content' }}
            >
              {t('experience.create.media.uploadButtonLabel')}
            </Button>
            <Text fontSize={'xsmall'} color={lightTheme.palette.neutral.n300}>
              {t('experience.create.media.acceptedFiles')}
            </Text>
          </Stack>
        </Stack>
      </GalleryDropzone>
      <Stack maxWidth={776}>
        <StockPhotoSelector
          id={experienceId}
          uploadedMedia={media.value ?? []}
          experienceName={experienceHeadline}
          experienceDescription={experienceDescription}
          onChange={media.onChange}
          placement={'experience'}
        />
      </Stack>
    </Stack>
  );
};
