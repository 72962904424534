import axios, { AxiosError } from 'axios';

export enum EventDeletionError {
  EVENT_HAS_BOOKINGS = 'EVENT_HAS_BOOKINGS',
}

function isExpectedErrorFormat(error: unknown): error is AxiosError {
  if (!error) return false;

  if (!axios.isAxiosError(error)) {
    return false;
  }

  return (
    error.response?.data.error && typeof error.response?.data.error === 'string'
  );
}

export function isEventDeletionError(
  error: unknown,
  errorType: EventDeletionError
): boolean {
  if (!isExpectedErrorFormat(error)) return false;

  return error.response?.data.error === errorType;
}
