import { addMonths, parseISO } from 'date-fns';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { rrulestr } from 'rrule';

import { DateInput } from '../../../../../../../../Components/inputs/date-input';
import { trackEventFlowRecurringDateOpened } from '../../../../../../../../tracking/events/flow/time/form/trackEventFlowRecurringDateOpened';
import { trackEventFlowRecurringDateSelected } from '../../../../../../../../tracking/events/flow/time/form/trackEventFlowRecurringDateSelected';
import { useEventUpsert } from '../../../../../domain/event_upsert_context';
import { updateRRuleString } from '../rrule_string_helpers';
import { isRecurringEvent } from '../use_time_form_recurring';

export const EventUpsertTimeFormRecurringEndDateField = () => {
  const { t } = useTranslation();
  const { currentEvent, updateEventField } = useEventUpsert();
  const { control } = useFormContext();
  const isRecurring = isRecurringEvent(currentEvent.recurring);

  const handleChange = (date: Date | null) => {
    if (!date || !isRecurring) return;

    const updatedRRuleStr = updateRRuleString(currentEvent.recurring.rrulestr, {
      until: date,
    });

    updateEventField('recurring', {
      ...currentEvent.recurring,
      rrulestr: updatedRRuleStr,
    });

    trackEventFlowRecurringDateSelected(date);
  };

  const onClickField = () => {
    trackEventFlowRecurringDateOpened();
  };

  if (!isRecurring) return null;

  const untilDate = getUntilDateFromRRule(currentEvent.recurring.rrulestr);

  return (
    <Controller
      name="recurringEndDate"
      control={control}
      render={({ field }) => (
        <DateInput
          label={t('eventUpsert.flow.time.form.endDate.label')}
          {...field}
          value={untilDate}
          onChange={(date) => handleChange(parseISO(date))}
          onClick={onClickField}
          minDate={currentEvent.startDateTime}
          required
        />
      )}
    />
  );
};

const getUntilDateFromRRule = (rruleStr: string) => {
  const rrule = rrulestr(rruleStr);
  return rrule.options.until || addMonths(new Date(), 1);
};
