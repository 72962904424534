import { Box } from '@mui/material';
import { useController, useFormContext } from 'react-hook-form';

import { RichEditor } from '../../../Components/RichEditor/RichEditor';
import { useTranslate } from '../../../Hooks/useTranslate';
import { OnboardingFormInputs } from '../onboarding-flow-form';
import { OnboardingStepLayout } from './step-layout';

export const DescriptionStep = () => {
  const { t } = useTranslate('onboarding.dialog.step.description.input');

  const { control } = useFormContext<OnboardingFormInputs>();

  const { field: experienceDescription } = useController<OnboardingFormInputs>({
    name: 'experienceDescription',
    defaultValue: '',
    control,
  });

  return (
    <OnboardingStepLayout showPreview>
      <Box maxHeight="300px" overflow="auto">
        <RichEditor
          placeholder={t('placeholder')}
          value={experienceDescription.value}
          onChange={(nextValue: string) =>
            experienceDescription.onChange(nextValue)
          }
        />
      </Box>
    </OnboardingStepLayout>
  );
};
