import { lightTheme } from '@holdbar-com/pixel';
import { HelpOutlineOutlined } from '@mui/icons-material';
import {
  Box,
  ButtonProps,
  Card,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';

type IStatsCardProps = {
  title: string | undefined;
  value: number | string | undefined;
  diff?: number;
  label?: string;
  tooltip?: string;
  isSelected?: boolean;
};

export const StatsCard = ({
  title,
  value,
  isSelected,
  diff,
  label = 'Sammenlignet med forrige periode',
  tooltip,
  ...props
}: ButtonProps & IStatsCardProps) => {
  return (
    <Card
      {...props}
      variant={'outlined'}
      component={'button'}
      sx={Object.assign(
        {
          width: '100%',
          height: '100%',
          '&:hover': {
            backgroundColor:
              typeof isSelected !== 'boolean' || isSelected
                ? 'white'
                : 'rgba(252,252,252,1)',
          },
          boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.07)',
          cursor: 'pointer',
          textAlign: 'left',
          position: 'relative',
          border: '3px solid transparent',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          ...(isSelected && {
            borderColor: 'primary.main',
          }),
        },
        props?.sx ?? {}
      )}
    >
      {title !== undefined ? (
        <Stack
          direction="row"
          gap={2}
          justifyContent="space-between"
          width={'100%'}
        >
          <Typography
            variant={'h5'}
            align={'left'}
            mr={4}
            sx={{ color: (theme) => theme.palette.grey['600'] }}
          >
            {title}
          </Typography>
          {tooltip && (
            <Tooltip
              title={tooltip}
              placement="top"
              arrow
              sx={{ color: lightTheme.palette.neutral.n200 }}
            >
              <HelpOutlineOutlined fontSize="small" />
            </Tooltip>
          )}
        </Stack>
      ) : (
        <>
          <Skeleton width={120} />
          <Skeleton width={80} />
        </>
      )}

      <Box alignItems={'baseline'} margin={'8px 0 0 0'} display={'flex'}>
        {value !== undefined ? (
          <Typography variant={'h2'} mr={1}>
            {value ?? 0}
          </Typography>
        ) : (
          <Skeleton height={48} width={90} />
        )}
      </Box>
    </Card>
  );
};
