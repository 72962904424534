import { capitalize, OutlinedInput, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';

type EventUpsertTimeFormRecurringMonthFieldProps = {
  startDateTime: string;
};

export const EventUpsertTimeFormRecurringMonthField = ({
  startDateTime,
}: EventUpsertTimeFormRecurringMonthFieldProps) => {
  const { t } = useTranslation();
  const isValidDate = (dateString: string) => !isNaN(Date.parse(dateString));

  const date = isValidDate(startDateTime)
    ? new Date(startDateTime)
    : new Date();

  const day = date.getDate();

  const label = capitalize(
    t('eventUpsert.flow.time.recurringCustomDialog.form.monthly.label', { day })
  );

  return <StyledOutlinedInput readOnly label="" value={label} />;
};

const StyledOutlinedInput = styled(OutlinedInput)`
  cursor: initial;
  pointer-events: none;
`;
