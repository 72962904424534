import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { ExperienceTag } from '@holdbar-com/utils-types';
import { Dialog } from '@mui/material';
import { useState } from 'react';

import useResponsive from '../../../../Hooks/layout/useResponsive';
import { useTranslate } from '../../../../Hooks/useTranslate';
import { DialogHeader } from '../../../../Modals/create-booking/dialog-header';
import { DialogView, viewComponents } from './shared';

export const ExperienceTagsDialog = NiceModal.create(() => {
  const { t } = useTranslate('experience.tags.dialog');
  const { isSm } = useResponsive();

  const [view, setView] = useState<DialogView>('list');
  const [selectedId, setSelectedId] = useState<ExperienceTag['id']>();

  const modal = useModal();

  const handleClose = async () => {
    modal.hide();
    modal.remove();
  };

  // If a tag id is selected, show "update" view
  const currentView = selectedId ? 'update' : view;
  const CurrentView = viewComponents[currentView];

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      fullScreen={isSm}
      open={modal.visible}
      onClose={() => handleClose()}
    >
      <DialogHeader
        title={t(`${currentView}.title`)}
        handleClose={handleClose}
      />
      <CurrentView
        setView={setView}
        selectedId={selectedId}
        setSelectedId={setSelectedId}
      />
    </Dialog>
  );
});
