import { Text } from "@holdbar-com/pixel";
import { Stack } from "@mui/material";
import { Controller } from "react-hook-form";

import { CountrySelect } from "../../../../../Components/CountrySelect/CountrySelect";
import { useTranslate } from "../../../../../Hooks/useTranslate";
import { StyledTextField } from "../../../shared/StyledTextField";

export const CompanyFormAddress = () => {
  const { t } = useTranslate('settings.company');

  return (
    <Stack spacing={2}>
      <Text variant="medium">{t('address.title')}</Text>
      <Controller
        name={'location.address'}
        render={({ field }) => (
          <StyledTextField {...field} label={t('address', 'utils.generic')} />
        )}
      />
      <Controller
        name={'location.zipCode'}
        render={({ field }) => (
          <StyledTextField
            {...field}
            label={t('zipCode', 'utils.generic')}
            type={'number'}
          />
        )}
      />
      <Controller
        name={'location.city'}
        render={({ field }) => (
          <StyledTextField {...field} label={t('city', 'utils.generic')} />
        )}
      />
      <Controller
        name={'location.country'}
        render={({ field }) => (
          <CountrySelect
            value={field.value}
            onChange={field.onChange}
            sx={{ minWidth: 320, maxWidth: 320 }}
          />
        )}
      />
    </Stack>
  );
};
