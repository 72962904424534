import { DeleteRounded } from '@mui/icons-material';
import {
  Chip,
  Grid,
  GridProps,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useRoles } from '../../Hooks/data/useRoles';
import { Input } from '../Input/Input';

export const UserItem = ({
  email,
  name,
  role,
  isCurrent = false,
  onDelete,
  onUpdate,
  ...props
}: GridProps & {
  email?: string;
  isCurrent?: boolean;
  name: string;
  role: string;
  onDelete?: () => void;
  onUpdate: (key: 'email' | 'role', value: any) => void;
}) => {
  const { t } = useTranslation();
  const { roles } = useRoles();
  return (
    <Grid container columns={2} rowSpacing={1} position={'relative'} {...props}>
      <Grid item component={Typography} variant={'h6'} xs={2} mb={1}>
        {name}
        {isCurrent && (
          <Chip
            size={'small'}
            sx={{ ml: 1 }}
            color={'info'}
            label={t('utils.generic.me')}
          />
        )}
      </Grid>
      <Grid item xs={1} pr={1}>
        <TextField
          fullWidth
          disabled={isCurrent}
          InputLabelProps={{ shrink: Boolean(email) }}
          defaultValue={email}
          onChange={(evt) => onUpdate('email', evt.target.value)}
          label={t('utils.generic.email')}
          helperText={t('onboarding.team.userHelpText')}
        />
      </Grid>
      <Grid item xs={1} alignItems={'center'} pl={1} display={'flex'}>
        <Input
          fullWidth
          type={'select'}
          disabled={isCurrent}
          InputLabelProps={{ shrink: Boolean(role) }}
          onChange={(evt) => onUpdate('role', evt.target.value)}
          helperText={t('onboarding.team.roleHelpText')}
          label={t('utils.generic.role')}
          defaultValue={role}
          options={roles.data?.map(({ name, id }) => ({
            label: name,
            key: id,
          }))}
        />
      </Grid>
      {onDelete && (
        <IconButton
          sx={{ position: 'absolute', right: -48, bottom: 32 }}
          size={'medium'}
          onClick={onDelete}
        >
          <DeleteRounded fontSize={'small'} />
        </IconButton>
      )}
    </Grid>
  );
};
