import { useEconomicIntegration } from './integrations/use-economic-integration';
import { useIcalIntegration } from './integrations/use-ical-integration';
import { usePaypalIntegration } from './integrations/use-paypal-integration';
import { useStripeIntegration } from './integrations/use-stripe-integration';
import { useTruestoryIntegration } from './integrations/use-truestory-integration';

export type IntegrationObject = {
  id: string;
  title: string;
  icon: string;
  description: string;
  extendedDescription: string;
  status: string;
  hidden?: boolean;
  warnings?: string[];
  buttonLabel: string | ((status: string) => string);
  onInstall?: (status: string) => () => Promise<void>;
  onUninstall?: () => Promise<void>;
};

export const useIntegrations = () => {
  const icalIntegration = useIcalIntegration();
  const truestoryIntegration = useTruestoryIntegration();

  const otherIntegrations = [
    useEconomicIntegration(),
    usePaypalIntegration(),
    useStripeIntegration(),
  ];

  const { activeIntegrations, availableIntegrations } =
    otherIntegrations.reduce<{
      activeIntegrations: IntegrationObject[];
      availableIntegrations: IntegrationObject[];
    }>(
      (acc, integration) => {
        if (integration) {
          integration.status === 'connected'
            ? acc.activeIntegrations.push(integration)
            : acc.availableIntegrations.unshift(integration);
        }
        return acc;
      },
      {
        activeIntegrations: [],
        availableIntegrations: [icalIntegration, truestoryIntegration],
      }
    );

  return {
    availableIntegrations,
    activeIntegrations,
  };
};
