import { Stack, styled } from '@mui/material';

import useResponsive from '../../../../Hooks/layout/useResponsive';
import { PageContainerStyles } from '../../../AppShell/AppShell';

type PageBreadcrumbProps = {
  children: React.ReactNode;
};

export const PageBreadcrumb = ({ children }: PageBreadcrumbProps) => {
  const { isLg } = useResponsive();

  return (
    <StyledPage gap={3} pt={3}>
      <Stack
        sx={{
          ...PageContainerStyles,
          alignItems: isLg ? 'flex-start' : 'center',
        }}
      >
        {children}
      </Stack>
    </StyledPage>
  );
};

const StyledPage = styled(Stack)(() => ({
  width: '100%',
  paddingBottom: '40px',
}));
