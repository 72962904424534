import { TextField } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { useTranslate } from '../../../Hooks/useTranslate';
import { trackQuickSetupLocationStepLocationFieldOpened } from '../../../tracking/quick-setup/step-events';
import { OnboardingFormInputs } from '../onboarding-flow-form';
import { OnboardingStepLayout } from './step-layout';

export const LocationStep = () => {
  const { t } = useTranslate('onboarding.dialog.step.location.input');

  const {
    register,
    formState: {
      errors: { experienceLocation: locationError },
    },
  } = useFormContext<OnboardingFormInputs>();

  return (
    <OnboardingStepLayout showPreview>
      <TextField
        {...register('experienceLocation', {
          required: {
            value: true,
            message: t('requiredMessage'),
          },
        })}
        label={t('label')}
        placeholder={t('placeholder')}
        InputLabelProps={{ shrink: true }}
        error={!!locationError}
        helperText={locationError?.message}
        onFocus={trackQuickSetupLocationStepLocationFieldOpened}
        required
      />
    </OnboardingStepLayout>
  );
};
