import { Stack } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

import { useTranslate } from "../../../../../Hooks/useTranslate";
import { StyledTextField } from "../../../shared/StyledTextField";
import { CompanyFormData } from "../../domain/use-company-form-validation";

export const CompanyFormDetails = () => {
  const { t } = useTranslate('settings.company');

  const {
    formState: { errors },
  } = useFormContext<CompanyFormData>();

  return (
    <Stack gap={2}>
      <Controller
        name={'name'}
        render={({ field }) => (
          <StyledTextField
            {...field}
            label={t('companyName')}
            error={!!errors.name}
            helperText={errors.name?.message}
          />
        )}
      />
      <Controller
        name={'website'}
        render={({ field }) => (
          <StyledTextField
            {...field}
            label={t('website')}
            error={!!errors.website}
            helperText={errors.website?.message}
          />
        )}
      />
      <Controller
        name={'cvrNr'}
        render={({ field }) => (
          <StyledTextField
            {...field}
            label={t('cvrNr')}
            error={!!errors.cvrNr}
            helperText={errors.cvrNr?.message}
          />
        )}
      />
      <Controller
        name={'companyEmail'}
        render={({ field }) => (
          <StyledTextField
            {...field}
            label={t('email')}
            error={!!errors.companyEmail}
            helperText={errors.companyEmail?.message}
          />
        )}
      />
      <Controller
        name={'companyPhone'}
        render={({ field }) => (
          <StyledTextField
            {...field}
            label={t('phone')}
            error={!!errors.companyPhone}
            helperText={errors.companyPhone?.message}
          />
        )}
      />
    </Stack>
  );
};
