import { Text } from '@holdbar-com/pixel';
import { FormControlLabel, Switch } from '@mui/material';
import { ChangeEvent } from 'react';

import { useTranslate } from '../../../Hooks/useTranslate';
import { trackCalendarViewGroupUpdated } from '../../../tracking/calendar/trackCalendarViewGroupUpdated';
import { useCalendar } from '../context';
import { useShortcuts } from '../../../Hooks/useShortcuts';

export const GroupByExperienceToggle = () => {
  const { t } = useTranslate('calendar.filters');

  const { selectedView, groupByExperience, setGroupByExperience } =
    useCalendar();

  useShortcuts([{ key: 'g', action: () => setGroupByExperience(!groupByExperience) }]);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;

    trackCalendarViewGroupUpdated(checked, selectedView);

    setGroupByExperience(checked);
  };

  return (
    <FormControlLabel
      control={<Switch checked={groupByExperience} onChange={onChange} />}
      label={<Text fontSize="small" variant="medium">{t('groupByExperience')}</Text>}
    />
  );
};
