import styled from '@emotion/styled';
import { lightTheme, Text } from '@holdbar-com/pixel';
import { Experience, ExperienceStatus } from '@holdbar-com/utils-types';
import { KeyboardArrowDownOutlined } from '@mui/icons-material';
import { MenuItem, Select, Stack } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';

import { StatusChip } from '../../../Components/CustomCard/CustomCard';
import { useExperienceEvents } from '../../../Hooks/events/useExperienceEvents';
import useResponsive from '../../../Hooks/layout/useResponsive';
import { getLocalized } from '../../../Hooks/useBookings';
import { useExperience } from '../../../Hooks/useExperience';
import { useProfile } from '../../../Hooks/useProfile';
import { useTranslate } from '../../../Hooks/useTranslate';

export const ExperienceItem = ({ experience }: { experience: Experience }) => {
  const { t, i18n } = useTranslate('experience.list.item');
  const { isMd } = useResponsive();
  const { companyProfileUrl } = useProfile();
  const navigate = useNavigate();

  const { id, status, media, headline, visibility } = experience;

  const storefrontUrl = `${companyProfileUrl()}/experience/${id}`;

  const { updateExperience } = useExperience(id);
  const {
    eventsForExperience: { data: eventsForExperience },
  } = useExperienceEvents(experience.ownerExperienceId, {
    state: 'future',
    status: 'statusIsActive',
  });

  if (!eventsForExperience) return null;

  const { events, totalCount: upcomingEventsCount } = eventsForExperience;

  const { totalSeats, seatsBooked } = events.reduce(
    (seatCounts, event) => {
      return {
        totalSeats: (seatCounts['totalSeats'] += event.slots.total),
        seatsBooked: (seatCounts['seatsBooked'] += event.slots.booked ?? 0),
      };
    },
    { totalSeats: 0, seatsBooked: 0 }
  );

  const upcomingEventsLabel = t('upcomingEvents', {
    count: upcomingEventsCount,
  });
  const bookingCountLabel = t('guestCount', {
    seatsBooked,
    totalSeats,
  });
  const href = `/experience/${id}`;

  if (isMd)
    return (
      <Link to={href}>
        <MobileCard>
          <MobileImage src={media?.primaryImage?.url ?? ''} alt="" />
          <Stack padding={2} paddingBottom={5} flexGrow={1}>
            <Stack
              direction="row"
              alignItems="center"
              alignSelf="flex-end"
              marginBottom="4px"
              gap={1}
            >
              {visibility && (
                <Text fontSize="xsmall" color={lightTheme.palette.neutral.n300}>
                  {t(visibility, 'experience.card.visibility')}
                </Text>
              )}
              <StatusChip status={status} size="small" />
            </Stack>
            <Text fontSize="small" variant="medium">
              {getLocalized(headline, i18n.language)}
            </Text>
            <Text fontSize="small">{upcomingEventsLabel}</Text>
            <Text fontSize="small">{bookingCountLabel}</Text>
          </Stack>
        </MobileCard>
      </Link>
    );

  const handleStatusChange = (newStatus: ExperienceStatus) => {
    if (!experience) return;

    updateExperience.mutateAsync({
      ...experience,
      status: newStatus,
    });
  };

  return (
    <DesktopCard onClick={() => navigate(href)}>
      <DesktopImage src={media?.primaryImage?.url ?? ''} alt="" />
      <GridText
        span={3}
        style={{
          justifySelf: 'flex-start',
          marginLeft: '8px',
        }}
        fontSize="xsmall"
      >
        {getLocalized(headline, i18n.language)}
      </GridText>
      <GridText fontSize="xsmall" textAlign="center">
        {upcomingEventsLabel}
      </GridText>
      <GridText fontSize="xsmall" textAlign="center">
        {bookingCountLabel}
      </GridText>
      <StyledTextLink href={storefrontUrl} onClick={(e) => e.stopPropagation()}>
        {t('storefrontLink')}
      </StyledTextLink>
      <Stack
        gridColumn="span 2"
        direction="row"
        justifySelf="flex-end"
        alignItems="center"
        gap={2}
      >
        {visibility && (
          <Text fontSize="xsmall" color={lightTheme.palette.neutral.n300}>
            {t(visibility, 'experience.card.visibility')}
          </Text>
        )}
        {/* Only allow changing status if current status is active or inactive */}
        {['active', 'inactive'].includes(status) ? (
          <Select
            onClick={(e) => e.stopPropagation()}
            size="small"
            sx={{
              '& .MuiSelect-outlined, .MuiSelect-select': {
                padding: 0,
                paddingRight: '0 !important',
                '&:focus': {
                  outline: '1px solid black',
                },
              },
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
              '& .MuiSelect-icon': {
                display: 'none',
              },
            }}
            value={status}
            renderValue={(value) => (
              <StatusChip
                status={value}
                size="small"
                icon={<KeyboardArrowDownOutlined />}
                sx={{
                  flexDirection: 'row-reverse',
                  '&:hover': {
                    cursor: 'pointer',
                  },
                  '& .MuiChip-icon': {
                    marginRight: '4px',
                    marginLeft: '-4px',
                  },
                }}
              />
            )}
            onChange={(e) =>
              handleStatusChange(e.target.value as ExperienceStatus)
            }
          >
            {status === 'active' ? (
              <MenuItem value="inactive">
                {t('deactivate', 'experience.card.options')}
              </MenuItem>
            ) : (
              <MenuItem value="active">
                {t('activate', 'experience.card.options')}
              </MenuItem>
            )}
          </Select>
        ) : (
          <StatusChip status={status} size="small" />
        )}
      </Stack>
    </DesktopCard>
  );
};

export const DesktopCard = styled.div({
  display: 'grid',
  gridTemplateColumns: 'repeat(12, minmax(0, 1fr));',
  gap: '8px',
  borderRadius: '16px',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: lightTheme.palette.neutral.n100,
  boxShadow: 'none',
  padding: '8px 16px 8px 16px',
  alignItems: 'center',
  justifyItems: 'center',
  overflow: 'hidden',
  background: 'white',
  transition: 'background 0.1s ease-in-out',
  '& > *': {
    maxWidth: '100%',
    overflow: 'hidden',
    wordBreak: 'break-word',
  },
  cursor: 'pointer',
  '&:hover': {
    background: lightTheme.palette.neutral.n50,
  },
});

const GridText = styled(Text)(({ span = 2 }: { span?: number }) => ({
  gridColumn: `span ${span}`,
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
}));

const DesktopImage = styled.img({
  display: 'block',
  width: '80px',
  aspectRatio: 80 / 67,
  objectFit: 'cover',
  borderRadius: '8px',
  gridColumn: 'span 1',
  justifySelf: 'flex-start',
  height: '100%',
});

export const MobileCard = styled(Stack)({
  flexDirection: 'row',
  background: 'white',
  borderRadius: '16px',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: lightTheme.palette.neutral.n100,
  overflow: 'hidden',
  cursor: 'pointer',
});

const MobileImage = styled.img({
  display: 'block',
  width: '108px',
  objectFit: 'cover',
});

const StyledTextLink = styled.a({
  gridColumn: 'span 2',
  padding: 0,
  fontSize: '12px',
  fontWeight: 'normal',
  textDecoration: 'underline',
  transition: 'opacity 0.1s ease-in-out',
  '&:hover': {
    opacity: 0.75,
  },
});
