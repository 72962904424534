import { useMemo } from 'react';
import { TFunction } from 'react-i18next';
import { RRule } from 'rrule';
import * as yup from 'yup';

export const useRecurringCustomFormValidation = (t: TFunction) =>
  useMemo(() => {
    const isWeekday = (value: any) => [0, 1, 2, 3, 4, 5, 6].includes(value);

    return yup.object().shape({
      interval: yup
        .number()
        .required(t('eventUpsert.time.dialog.form.errors.required'))
        .min(1, t('eventUpsert.time.dialog.form.errors.minInterval'))
        .typeError(t('eventUpsert.time.dialog.form.errors.type')),
      frequency: yup
        .mixed()
        .oneOf(
          [RRule.DAILY, RRule.WEEKLY, RRule.MONTHLY],
          t('frequencyInvalid')
        )
        .required(t('eventUpsert.time.dialog.form.errors.required')),
      byweekday: yup.array().when('frequency', {
        is: RRule.WEEKLY,
        then: yup
          .array()
          .of(
            yup.object().shape({
              weekday: yup
                .number()
                .test(
                  'is-weekday',
                  t('eventUpsert.time.dialog.form.errors.type'),
                  isWeekday
                )
                .required(t('eventUpsert.time.dialog.form.errors.required')),
            })
          )
          .min(1, t('eventUpsert.time.dialog.form.errors.minWeekdays'))
          .required(t('eventUpsert.time.dialog.form.errors.required')),
      }),
    });
  }, [t]);

export type EventUpsertRecurringCustomFormTypes = yup.InferType<
  ReturnType<typeof useRecurringCustomFormValidation>
>;
