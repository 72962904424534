import { addDays, startOfWeek } from 'date-fns';
import { useMemo } from 'react';

import { useCalendar } from '../../../context';
import { CalendarWeekListGrouped } from './Grouped';
import { CalendarWeekListUngrouped } from './Ungrouped';

const ARRAY_OF_SEVEN = Array.from({ length: 7 });

export const CalendarWeekList = () => {
  const { selectedDate, groupByExperience } = useCalendar();

  const dates = useMemo(() => {
    const firstInWeek = startOfWeek(selectedDate, {
      weekStartsOn: 1,
    });
    return ARRAY_OF_SEVEN.map((_, i) => addDays(firstInWeek, i));
  }, [selectedDate]);

  if (groupByExperience) {
    return <CalendarWeekListGrouped dates={dates} />;
  }

  return <CalendarWeekListUngrouped dates={dates} />;
};
