import { CheckRounded, CloseRounded } from '@mui/icons-material';
import { Button, ButtonProps, CircularProgress, styled } from '@mui/material';
import { MouseEvent, useEffect, useRef, useState } from 'react';

const Styled = styled(Button)(({ theme }) => ({
  ['&.MuiButton-containedSuccess']: {
    color: theme.palette.success.main,
    backgroundColor: '#E3FCF0',
  },
}));

export const ProgressButton = ({
  label = 'Submit',
  onClick,
  color,
  sx,
  disabled,
  ...props
}: ButtonProps & { label?: string }) => {
  const ref = useRef<HTMLButtonElement | null>(null);

  const [initialDimensions, setInitialDimensions] = useState<{
    w: number;
    h: number;
  } | null>(null);
  const [state, setState] = useState<'idle' | 'loading' | 'success' | 'error'>(
    'idle'
  );

  useEffect(() => {
    if (ref.current) {
      const { width, height } = ref.current.getBoundingClientRect();
      setInitialDimensions({
        w: width,
        h: height,
      });
    }
  }, []);

  const handleClick = async (evt: MouseEvent<HTMLButtonElement>) => {
    setState('loading');
    try {
      await onClick?.(evt);
      setState('success');
    } catch (err) {
      console.log(err);
      setState('error');
    } finally {
      setTimeout(() => setState('idle'), 2000);
    }
  };

  const renderState = (s: typeof state) => {
    switch (s) {
      case 'idle':
        return label;
      case 'error':
        return <CloseRounded color={'inherit'} />;
      case 'success':
        return <CheckRounded color={'inherit'} />;
      case 'loading':
        return <CircularProgress color={'inherit'} size={'1em'} />;
    }
  };

  const renderColor = (s: typeof state) => {
    switch (s) {
      case 'idle':
        return color;
      case 'error':
        return 'error';
      case 'success':
        return 'success';
      case 'loading':
        return color;
    }
  };

  return (
    <Styled
      ref={ref}
      onClick={handleClick}
      color={renderColor(state)}
      disabled={(state === 'idle' && disabled) || state === 'loading'}
      sx={{
        transition: 'all 0.2s ease',
        minHeight: initialDimensions?.h ?? 'auto',
        maxWidth: '50%',
        ml: 'auto',
        maxHeight: initialDimensions?.h ?? 'auto',
        pointerEvents: state !== 'idle' ? 'none' : 'initial',
        borderRadius: 1,
        ...sx,
      }}
      {...props}
      startIcon={(state === 'idle' && props?.startIcon) ?? null}
    >
      {renderState(state)}
    </Styled>
  );
};
