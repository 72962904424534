import { lightTheme } from '@holdbar-com/pixel';
import { CircularProgress } from '@mui/material';
import { Portal } from '@radix-ui/react-portal';

type LoadingFullscreenProps = {
  isLoading: boolean;
};

export const LoadingFullscreen = ({ isLoading }: LoadingFullscreenProps) => {
  return isLoading ? (
    <Portal>
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: lightTheme.palette.contrast.white,
          opacity: 0.75,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 9999,
        }}
      >
        <CircularProgress sx={{ color: lightTheme.palette.neutral.n500 }} />
      </div>
    </Portal>
  ) : null;
};
