import NiceModal from '@ebay/nice-modal-react';
import { Button, lightTheme, Text } from '@holdbar-com/pixel';
import { CameraEnhance } from '@mui/icons-material';
import { Card, Stack, styled } from '@mui/material';
import { FC } from 'react';

import { ExperienceImagesSuggestion } from '../../../Api/Suggestion';
import { useSuggestionDecision } from '../../../Hooks/data/useSuggestions';
import { useTranslate } from '../../../Hooks/useTranslate';
import { SuggestionsExperienceName } from '../suggestions-experience-name';
import { SuggestionExperienceImagesModal } from './suggestion-experience-images-modal';

type Props = {
  suggestion: ExperienceImagesSuggestion;
};

export const SuggestionExperienceImagesCard: FC<Props> = ({ suggestion }) => {
  const { t } = useTranslate('suggestions');

  const { ignoreSuggestion } = useSuggestionDecision(suggestion.suggestionId);

  return (
    <Card
      key={suggestion.suggestionId}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        alignItems: 'end',
        padding: 3,
      }}
    >
      <Stack direction="row" gap={1} flexGrow={1}>
        <CameraEnhance
          sx={{
            width: 32,
            height: 32,
            color: lightTheme.palette.success.s400,
          }}
        />
        <Stack gap={0}>
          <Text
            fontSize="xsmall"
            style={{
              lineHeight: 1,
            }}
          >
            {t('experienceImages.title')}
          </Text>
          <SuggestionsExperienceName experienceId={suggestion.entityId} />
          <Text
            fontSize="small"
            style={{
              marginTop: '8px',
            }}
          >
            {t('experienceImages.description')}
          </Text>
        </Stack>
      </Stack>

      <Stack gap={1} direction="row" justifyContent="end">
        <OutlinedButton size="medium" variant="text" onClick={ignoreSuggestion}>
          {t('ignore')}
        </OutlinedButton>
        <Button
          size="medium"
          variant="primary"
          onClick={() => {
            NiceModal.show(SuggestionExperienceImagesModal, {
              suggestion,
            });
          }}
        >
          {t('view')}
        </Button>
      </Stack>
    </Card>
  );
};

const OutlinedButton = styled(Button)`
  border: 1px solid ${lightTheme.palette.neutral.n200};
  border-radius: 8px;
`;
