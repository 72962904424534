import { Text } from '@holdbar-com/pixel';
import { Divider, Skeleton, Stack } from '@mui/material';
import { Fragment } from 'react';

import { useGetEvent } from '../../../../../../../../Hooks/events/useGetEvent';
import { useTranslate } from '../../../../../../../../Hooks/useTranslate';
import { useBookingDetailsContext } from '../../../../../domain/use_booking_details_context';
import { paymentRows } from '../domain/use_booking_details_payment';
import { BookingDetailsSummaryReceiptError } from './BookingDetailsSummaryReceiptError';

export const BookingDetailsSummaryPaymentMobile = () => {
  const { t } = useTranslate('dialogs.booking');

  const { booking, receipt } = useBookingDetailsContext();
  const {
    event: { data: event },
  } = useGetEvent(booking?.eventId ?? '');

  if (!booking) return null;

  if (!receipt.isLoading && receipt.error) {
    return (
      <Stack gap={2}>
        <Text variant="medium">{t('sections.payment')}</Text>
        <Text fontSize="small">
          <BookingDetailsSummaryReceiptError />
        </Text>
      </Stack>
    );
  }

  return (
    <Stack gap={2}>
      <Text variant="medium">{t('sections.payment')}</Text>
      {paymentRows.map((row) => {
        const paymentRowValue = receipt.isLoading ? (
          <Skeleton width={80} />
        ) : (
          row.value(booking, t, receipt.data, event)
        );

        if (!paymentRowValue) {
          return null;
        }

        return (
          <Fragment key={row.key}>
            <Stack gap={0.5}>
              <Text fontSize="small" variant="medium">
                {t(row.key)}
              </Text>
              <Text fontSize="small">{paymentRowValue}</Text>
            </Stack>
            {row.showDivider && <Divider />}
          </Fragment>
        );
      })}
    </Stack>
  );
};
