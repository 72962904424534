import styled from '@emotion/styled';
import { Button } from '@holdbar-com/pixel';
import { MoreVert } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { Popover } from '@radix-ui/themes';
import { useState } from 'react';

import { StyledPopoverContent } from '../../../../../../Components/Popover/radix_popover_styles';

type BookingDetailsActionsMobileProps = {
  children: React.ReactNode;
};

export const BookingDetailsActionsMobile = ({
  children,
}: BookingDetailsActionsMobileProps) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  return (
    <Popover.Root open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
      <Popover.Trigger>
        <StyledButton size="medium" variant="secondary">
          <MoreVert
            fontSize="small"
            sx={{
              color: 'black',
              display: 'flex',
            }}
          />
        </StyledButton>
      </Popover.Trigger>
      <StyledPopoverContent sideOffset={5} align="end">
        <Stack gap={1} width="100%" py={1}>
          {children}
        </Stack>
      </StyledPopoverContent>
    </Popover.Root>
  );
};

const StyledButton = styled(Button)({
  padding: 8,
  aspectRatio: 1,
});
