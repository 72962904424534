import { Button, lightTheme, Text } from '@holdbar-com/pixel';
import { CheckRounded } from '@mui/icons-material';
import {
  Box,
  BoxProps,
  Card,
  CircularProgress,
  Grow,
  lighten,
  Stack,
  Step,
  StepButton,
  StepConnector,
  Stepper,
  styled,
} from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';

import { ampli } from '../../Ampli';
import useResponsive from '../../Hooks/layout/useResponsive';
import { useOnBoarding } from '../../Hooks/useOnBoarding';
import { useProfile } from '../../Hooks/useProfile';
import { useTranslate } from '../../Hooks/useTranslate';
import { useStripeIntegration } from '../../Sections/SettingsSections/Integrations/domain/integrations/use-stripe-integration';

const onBoardingStepNavigationMap = {
  experience: '/experiences',
  event: '/event/create',
  payment: '/settings/integrations',
  implementation: '/storefront/bookingflow',
};

const onBoardingStepTrackingFns = [
  () => ampli.onboardingListExperienceBulletClicked(),
  () => ampli.onboardingListEventBulletClicked(),
  () => ampli.onboardingListPaymentBulletClicked(),
  () => ampli.onboardingListImplementationBulletClicked(),
];

const animationVariants = {
  enter: (direction: number) => {
    return {
      x: direction > 0 ? 400 : -400,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction: number) => {
    return {
      zIndex: 0,
      opacity: 0,
    };
  },
};

export const OnBoardingWidget = () => {
  const { t } = useTranslate('onboardingSetup');
  const { isSm, isLg } = useResponsive();
  const flags = useFlags();
  const navigate = useNavigate();

  const [[step, direction], setStep] = useState([0, 0]);
  const [finishing, setFinishing] = useState(false);

  const { trackEvent, startTour } = useIntercom();

  const {
    onBoarding: { data: { items: onBoarding = [] } = { items: [] } },
  } = useOnBoarding();

  const { finishOnboarding } = useProfile();

  const stripeIntegration = useStripeIntegration();

  const [items, setItems] = useState<typeof onBoarding>([]);

  useEffect(() => {
    if (onBoarding?.length > 0) {
      const pendingStep = onBoarding.findIndex((el) => !el.completed);
      setItems(onBoarding);
      handleChangeStep(pendingStep !== -1 ? pendingStep : 0)();
    }
  }, [onBoarding]);

  const handleChangeStep = (index: number) => () => {
    onBoardingStepTrackingFns[index]?.();

    setStep((p) => {
      const [current] = p;
      return current > index ? [index, 0] : [index, 1];
    });
  };

  const handleClick = (index: number) => () => {
    const { onClick } = getNextStep(index) ?? getLastStep(index) ?? {};

    const stepKey = items[index]?.key;

    if (!flags.featureSetupGuideV2) {
      const navigation = Object.entries(onBoardingStepNavigationMap).find(
        ([k]) => k === stepKey
      )?.[1];

      navigate(navigation ?? '/');
      return;
    }

    if (stepKey === 'payment') {
      stripeIntegration.onInstall?.(stripeIntegration.status)();
    }

    onClick?.(trackEvent, startTour);
  };

  const handleFinishOnboarding = async () => {
    ampli.onboardingListDoneButtonClicked();

    setFinishing(true);
    await finishOnboarding.mutateAsync();
    setFinishing(false);
  };

  const getLastStep = (index: number) => {
    return [...(items[index]?.steps ?? [])].pop();
  };

  const getNextStep = (index: number) => {
    return items[index]?.steps?.filter((el: any) => el.progress < 100)[0];
  };

  const getProgress = (steps?: { progress: number }[]) => {
    if (steps) {
      const summed = steps.reduce((total, { progress }) => progress + total, 0);
      return Math.round(summed / steps.length);
    }
    return 0;
  };

  const isStepActive = (index: number) => {
    const { depends } = getNextStep(index) ?? getLastStep(index) ?? {};
    if (depends) {
      return depends.some(
        (key) => items.find((el) => el.key === key)?.completed === true
      );
    }
    return true;
  };

  if (!onBoarding?.length || !items.length) return null;

  return (
    <Stack direction={{ xs: 'column', md: 'row' }} gap={6}>
      <GroupItem
        flexGrow={1}
        maxWidth={800}
        minWidth={320}
        itemCount={
          (onBoarding.length === 0 ? 1 : onBoarding.length) - (step + 1)
        }
      >
        <AnimatePresence custom={direction}>
          <motion.div
            style={{
              position: isSm ? 'relative' : 'absolute',
              maxWidth: '100%',
              height: '100%',
              display: 'flex',
            }}
            key={step}
            custom={direction}
            variants={isSm ? {} : animationVariants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{
              x: { type: 'spring', stiffness: 400, damping: 40 },
              opacity: { duration: 0.3 },
            }}
          >
            <Stack
              justifyContent={'space-between'}
              alignItems={'flex-start'}
              width={'100%'}
              gap={isSm ? 4 : 0}
              sx={{
                paddingTop: { xs: 2, md: 4 },
                paddingBottom: { xs: 2, md: 3 },
                paddingX: { xs: 3, md: 5 },
              }}
            >
              <Stack gap={2}>
                <Text fontSize="xlarge" variant="medium">
                  {t(`${items[step]?.key}.title`)}
                </Text>
                <Text>{t(`${items[step]?.key}.description`)}</Text>
              </Stack>
              <Stack direction={'row'} gap={1}>
                {!isLg && (
                  <Button
                    size={'medium'}
                    variant={'primary'}
                    disabled={
                      !isStepActive(step) || items[step]?.completed === true
                    }
                    onClick={handleClick(step)}
                    leftIcon={
                      items[step]?.completed === true && (
                        <CheckRounded color={'success'} sx={{ mr: 1 }} />
                      )
                    }
                    style={{ alignSelf: 'flex-end', marginTop: 'auto' }}
                  >
                    {items[step]?.completed === true
                      ? t('completedButtonLabel')
                      : t('actionButton')}
                  </Button>
                )}
                {!isLg && items[step].key === 'implementation' && (
                  <Button
                    size={'medium'}
                    variant={'secondary'}
                    onClick={handleFinishOnboarding}
                    style={{ alignSelf: 'flex-end', marginTop: 'auto' }}
                    disabled={finishing}
                    rightIcon={
                      finishing && (
                        <CircularProgress
                          size={16}
                          color="inherit"
                          sx={{ ml: 1 }}
                        />
                      )
                    }
                  >
                    {t('finishButton')}
                  </Button>
                )}
              </Stack>
            </Stack>
          </motion.div>
        </AnimatePresence>
      </GroupItem>

      <Stepper
        activeStep={step}
        orientation="vertical"
        sx={{ minWidth: 200, height: '100%' }}
        connector={
          <StepConnector
            sx={{
              '& span': {
                borderColor: `#E5E5EA !important`,
                height: 1,
                borderLeftWidth: '2px',
              },
            }}
          />
        }
      >
        {items
          ?.filter((f) => f.key !== 'success')
          .map((step, index) => (
            <Step
              completed={step?.completed === true}
              sx={{
                cursor: 'pointer',
                '&:hover': { opacity: 0.8 },
                '& svg.Mui-completed': {
                  color: lightTheme.palette.success.s300,
                },
                '& .MuiSvgIcon-root:not(.Mui-active):not(.Mui-completed)': {
                  color: '#E5E5EA',
                  '& text': { fill: '#8E8E93' },
                },
              }}
              key={step.key}
              onClick={handleChangeStep(index)}
            >
              <StepButton
                disableRipple={true}
                sx={{
                  p: 0,
                  m: 0,
                  '& .MuiStepLabel-root': {
                    p: '0 !important',
                    gap: 0.5,
                  },
                }}
              >
                <Stack>
                  <Text
                    variant="medium"
                    fontSize="xsmall"
                    style={{
                      color: step.completed
                        ? lightTheme.palette.neutral.n300
                        : 'black',
                    }}
                  >
                    {t(`${step.key}.stepLabel`)}
                  </Text>
                  <Text
                    fontSize="xsmall"
                    color={
                      step.completed ? lightTheme.palette.neutral.n300 : 'black'
                    }
                  >
                    {getProgress(step.steps)}%
                  </Text>
                </Stack>
              </StepButton>
            </Step>
          ))}
      </Stepper>
    </Stack>
  );
};

const StyledItem = styled(Box)<any>({
  borderRadius: 16,
  zIndex: 10,
});

const GroupItem = ({
  children,
  shouldFocus = false,
  itemCount = 3,
  ...props
}: BoxProps & { shouldFocus?: boolean; itemCount?: number }) => {
  return (
    <Box position={'relative'} mb={2} {...props}>
      <StyledItem
        component={Card}
        p={0}
        position={'relative'}
        boxShadow={'0 4px 16px 0 rgba(0,0,0,0.07)'}
        display={'flex'}
        minHeight={240}
      >
        {children}
      </StyledItem>
      <Box sx={{ transform: 'translateY(0px)' }}>
        {Array.from(Array(itemCount > 0 ? itemCount : 0).keys()).map(
          (el, i) => (
            <Grow in={!shouldFocus} key={`grow-item-${i}`}>
              <Box
                component={Card}
                boxShadow={'0 4px 16px 0 rgba(0,0,0,0.07)'}
                width={`calc(100% - ${(i + 1) * 32}px)`}
                height={4}
                borderRadius={2}
                zIndex={itemCount - i}
                position={'absolute'}
                bottom={-(6 * (i + 1))}
                left={16 * (i + 1)}
                sx={{
                  background: `white linear-gradient(90deg, ${lighten(
                    'rgba(0,0,0,0.07)',
                    0.5
                  )} 0%, ${lighten('rgba(0,0,0,0.07)', 0.1)} 100%)`,
                }}
              />
            </Grow>
          )
        )}
      </Box>
    </Box>
  );
};
