import { useFlags } from 'launchdarkly-react-client-sdk';
import randomBytes from 'randombytes';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { updatePayment } from '../../../../Api';
import { LoadingFullscreen } from '../../../../Components/Loading/LoadingFullscreen';
import { View } from '../../../../Components/View';
import { useUpdateEvent } from '../../../../Hooks/events/useUpdateEvent';
import useResponsive from '../../../../Hooks/layout/useResponsive';
import { useBookings } from '../../../../Hooks/useBookings';
import { useExperience } from '../../../../Hooks/useExperience';
import { useProducts } from '../../../../Hooks/useProducts';
import { mapGuestsToOffers } from '../../../../Modals/create-booking/form/create-booking-form';
import { useEventUpsert } from '../../event_upsert/domain/event_upsert_context';
import { EventStepTypes, getEventSteps } from '../../event_upsert/domain/types';
import { useEventInitial } from '../../event_upsert/domain/use_event_initial_values';
import { EventUpsert } from '../../event_upsert/ui/EventUpsert';

type LocationState = {
  returnUrl?: string;
  experienceId?: string;
};

export const EventCreate = () => {
  const { t } = useTranslation();
  const flags = useFlags();
  const { isSm } = useResponsive();
  const [isLoading, setIsLoading] = useState(false);

  const location = useLocation();

  const navigate = useNavigate();

  const { updateEventField, initializeEvent, currentEvent } = useEventUpsert();
  const { updateEvent } = useUpdateEvent(currentEvent.id);
  const { createBooking } = useBookings(currentEvent.id);
  const { products } = useProducts(currentEvent.experienceId);

  const bookingId = useMemo(() => randomBytes(16).toString('hex'), []);

  const { returnUrl, experienceId } = useMemo(() => {
    const state = location?.state as LocationState;
    return {
      experienceId: state?.experienceId,
      returnUrl: state?.returnUrl,
    };
  }, [location.state]);

  const {
    experience: { data: experience },
  } = useExperience(currentEvent.experienceId || experienceId);

  const initialValues = useEventInitial({
    id: randomBytes(16).toString('hex'),
    experienceId: currentEvent.experienceId || experienceId,
    variant: 'create',
    seatCount: {
      selectedOptionKey: 'experienceDefault',
      value: experience?.seats.seatCount ?? 0,
    },
  });

  useEffect(() => {
    if (!experience) return;
    initializeEvent(initialValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [experience]);

  const handleBack = () => {
    const isNotDetailsStep = currentEvent.step !== 'details';

    if (isSm && isNotDetailsStep) {
      const currentIndex = EventStepTypes.indexOf(currentEvent.step);
      const stepDecrement = currentEvent.visibility === 'private' ? 1 : 2;
      const newStep = EventStepTypes[currentIndex - stepDecrement];

      if (newStep) {
        updateEventField('step', newStep);
        return; // Exit early to avoid navigating away
      }
    }

    if (returnUrl) {
      navigate(returnUrl);
    } else {
      navigate(-1);
    }
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);

      // Omit helper fields
      const {
        variant,
        step,
        startEndTimeDiff,
        booking,
        endDateTimeDisabled,
        ...eventData
      } = currentEvent;

      (eventData.endDateTime as any) = currentEvent.endDateTimeDisabled
        ? null
        : eventData.endDateTime;

      await updateEvent.mutateAsync(eventData);
      toast.success(t('eventUpsert.toast.success.createEvent'));

      // TODO: Use typeguarding to avoid ! operator and ensure that booking is defined
      if (eventData.visibility === 'private' && booking) {
        const paymentId = randomBytes(16).toString('hex');
        const skipBookingConfirmation = !booking.shouldSendNotification;

        const payload: any = {
          eventId: eventData.id,
          experienceId: eventData.experienceId,
          startDateTime: eventData.startDateTime,
          endDateTime: endDateTimeDisabled ? null : eventData.endDateTime,
          channel: 'manual',
          source: 'holdbar',
          status: 'unpaid',
          customer: {
            name: booking.name!,
            email: booking.email!,
            phone: booking.phone!,
          },
          internalNote: booking.internalNotes!,
          language: booking.language!,
          items: booking.guests!,
          ...(booking.shouldCreatePaymentLink && {
            paymentId,
          }),
          metaData: {
            skipBookingConfirmation,
          },
        };

        try {
          await Promise.all([
            createBooking.mutateAsync({ id: bookingId, ...payload }),
            booking.shouldCreatePaymentLink &&
              updatePayment(paymentId, {
                subject: 'booking',
                subjectId: bookingId,
                items: mapGuestsToOffers(products, booking.guests ?? {}),
              }),
          ]);
          toast.success(t('eventUpsert.toast.success.createBooking'));
        } catch {
          toast.error(t('eventUpsert.toast.error.createBooking'));
        }
      }

      navigate(returnUrl ?? `/event/${eventData.id}`, { replace: true });
    } catch (error) {
      toast.error(t('eventUpsert.toast.error.createEvent'));
    } finally {
      setIsLoading(false);
    }
  };

  if (flags.featureEventV2Create === false) {
    return null;
  }

  const viewSubtitle = {
    current:
      getEventSteps(currentEvent.visibility === 'public').indexOf(
        currentEvent.step
      ) + 1,
    total: getEventSteps(currentEvent.visibility === 'public').length,
  };

  return (
    <View back={handleBack}>
      <EventUpsert
        title={t('eventUpsert.title.newEvent')}
        subtitle={t('eventUpsert.subtitle', {
          current: viewSubtitle.current,
          total: viewSubtitle.total,
        })}
        event={currentEvent}
        onSubmit={handleSubmit}
        onBack={handleBack}
      />
      <LoadingFullscreen isLoading={isLoading} />
    </View>
  );
};
