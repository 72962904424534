import {
  addMonths,
  endOfMonth,
  endOfWeek,
  startOfMonth,
  startOfWeek,
  subMonths,
} from 'date-fns';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  getEventTitle,
  getGuidesByIds,
  TEventColor,
  TEventWithTitle,
} from '../../Utils/eventHelpers';
import { useGetLocations } from '../data/useLocations';
import { useExperience } from '../useExperience';
import { useProfile } from '../useProfile';
import { useUsers } from '../useUsers';
import { useGetEvents, usePrefetchEvents } from './useGetEvents';

/**
 * Given a date, the hook will fetch events for the entire month,
 * and prefetch one month prior and one month after.
 */
export const useCalendarEvents = (
  date: Date
): {
  events: TEventWithTitle[];
  isLoading: boolean;
} => {
  const { i18n } = useTranslation();

  const { company } = useProfile();
  const { experiences } = useExperience();
  const { locations } = useGetLocations(company.data?.id ?? '');
  const {
    users: { data: users },
  } = useUsers();

  const { events } = useGetEvents(getFetchingPeriod(date));

  // Prefetch events for the previous and next month
  usePrefetchEvents(getFetchingPeriod(subMonths(date, 1)));
  usePrefetchEvents(getFetchingPeriod(addMonths(date, 1)));

  const experienceColors = useMemo(() => {
    const experienceIds = (experiences.data ?? [])
      .filter((f) => f.status === 'active')
      .map((el) => el.id)
      .filter((el, index, arr) => arr.indexOf(el) === index);

    return experienceIds.reduce<Record<string, TEventColor>>(
      (acc, id, index) => {
        if (!id) return acc;

        const color = getColor(index);

        acc[id] = { backgroundColor: color, color, borderLeftColor: color };
        return acc;
      },
      {}
    );
  }, [experiences.data]);

  const mappedEvents = useMemo(() => {
    if (!events.data || !locations.data || !experiences.data || !users)
      return [];

    return events.data.flatMap((event) => {
      const location = locations.data.find(
        (loc) => loc.locationId === event.locationId
      );

      const experience = experiences.data.find(
        (exp) => exp.id === event.experienceId
      );

      const colors = experienceColors[event.experienceId];
      if (!colors) return [];

      const guides = getGuidesByIds(event.assignedGuides ?? [], users);

      return {
        ...event,
        colors,
        title: getEventTitle(experience, i18n.language),
        location: location?.locationName ?? '-',
        ticketTypes: [],
        variants: experience?.price?.variants,
        guide: {
          email: '',
          name: '',
        },
        guides,
      };
    });
  }, [
    events.data,
    experienceColors,
    experiences.data,
    i18n.language,
    locations.data,
    users,
  ]);

  return {
    isLoading:
      events.isLoading ||
      locations.isLoading ||
      experiences.isLoading ||
      company.isLoading,
    events: mappedEvents,
  };
};
const getFetchingPeriod = (date: Date) => {
  const from = startOfWeek(startOfMonth(date), { weekStartsOn: 1 });
  const to = endOfWeek(endOfMonth(date), { weekStartsOn: 1 });

  return { from, to };
};

const colors = [
  '#EFF6FF',
  '#FFFBE9',
  '#F5FFFC',
  '#F2F2FF',
  '#F8FFF1',
  '#FFF2E8',
  '#FFF7FD',
];

const getColor = (index: number) => colors[index % colors.length];
