import NiceModal from '@ebay/nice-modal-react';
import { captureException } from '@sentry/react';
import { useCallback } from 'react';
import { toast } from 'react-toastify';

import { useGetBookingsForEvent } from '../../../../../../Hooks/data/useBookings';
import { useBookings } from '../../../../../../Hooks/useBookings';
import { useTranslate } from '../../../../../../Hooks/useTranslate';
import { ConfirmDialog } from '../../../../../../Modals/ConfirmDialog';
import { MoveBookingDialog } from '../../../../../../Modals/MoveBookingDialog';
import { OptionsDialog } from '../../../../../../Modals/OptionsDialog';
import {
  trackBookingCheckInCancelled,
  trackBookingCheckInCompleted,
  trackBookingCheckInStarted,
  trackBookingMoveCompleted,
  trackBookingMoveStarted,
  trackBookingRefundCompleted,
  trackBookingRefundExited,
  trackBookingRefundStarted,
} from '../../../../../../tracking/bookings/details';
import { useBookingDetailsContext } from '../../../domain/use_booking_details_context';

export const useBookingDetailsActions = () => {
  const { t } = useTranslate('dialogs.booking');

  const {
    booking,
    receipt: { data: receipt },
  } = useBookingDetailsContext();

  const { cancelBooking, refundBooking, checkInBooking } = useBookings(
    undefined,
    booking?.id
  );

  const { bookingsForEvent } = useGetBookingsForEvent(
    booking?.eventId as string
  );

  const handleCancel = useCallback(async () => {
    let choice: string | undefined;
    if (booking?.refund?.date || !booking?.transaction) {
      await NiceModal.show(ConfirmDialog, {
        title: t('title', 'dialogs.cancelBooking'),
        headline: t('headline', 'dialogs.cancelBooking', {
          customerName: booking?.customer.name,
        }),
        confirmLabel: t('actions.primary', 'dialogs.cancelBooking'),
      });
    } else {
      choice = await NiceModal.show(OptionsDialog, {
        title: t('title', 'dialogs.refund'),
        headline: t('headline', 'dialogs.cancelBooking', {
          customerName: booking?.customer.name,
        }),
        buttons: [
          {
            key: 'cancelOnly',
            label: t('cancelOnly', 'dialogs.cancelBooking.actions'),
            props: {
              variant: 'outlined',
              color: 'secondary',
            },
          },
          {
            key: 'shouldRefund',
            label: t('shouldRefund', 'dialogs.cancelBooking.actions'),
            props: {
              variant: 'contained',
            },
          },
        ],
      });
    }

    toast.loading(t('loadingTitle', 'dialogs.cancelBooking.toasts'), {
      toastId: 'cancelBooking',
    });

    cancelBooking
      .mutateAsync(choice === 'shouldRefund')
      .then(() => {
        toast.success(t('successTitle', 'dialogs.cancelBooking.toasts'));
      })
      .catch(() => {
        toast.error(t('errorTitle', 'dialogs.errorDialog'));
      })
      .finally(() => {
        toast.dismiss('cancelBooking');
        bookingsForEvent.refetch();
      });
  }, [booking, bookingsForEvent]);

  const handleRefund = useCallback(
    async (source: 'popover' | 'button') => {
      if (!booking?.transaction) return;
      trackBookingRefundStarted(booking, source, receipt);

      try {
        await NiceModal.show(ConfirmDialog, {
          title: t('title', 'dialogs.refund'),
          headline: t('headline', 'dialogs.refund', {
            refundAmount: `${
              booking?.transaction?.totalAmount
            } ${booking?.transaction?.currency.toUpperCase()}`,
            customerName: booking?.customer.name,
          }),
          confirmLabel: t('actions.primary', 'dialogs.refund'),
        });
      } catch {
        trackBookingRefundExited(booking, source, receipt);
        return;
      }

      refundBooking
        .mutateAsync()
        .then(() => {
          trackBookingRefundCompleted(booking, source, receipt);
        })
        .catch(() => {
          toast.error(t('errorTitle', 'dialogs.errorDialog'));
        });
    },
    [booking, receipt]
  );

  const handleMoveBooking = useCallback(
    async (source: 'popover' | 'button') => {
      if (booking?.experienceId) {
        trackBookingMoveStarted(booking, source, receipt);

        await NiceModal.show<() => Promise<void>>(MoveBookingDialog, {
          eventId: booking.eventId,
          bookingId: booking.id,
          experienceId: booking?.experienceId,
        });

        trackBookingMoveCompleted(booking, source, receipt);
      }
    },
    [booking, receipt]
  );

  const handleCheckInBooking = useCallback(async () => {
    if (!booking?.id) {
      return;
    }
    try {
      trackBookingCheckInStarted('manual', booking, receipt);
      await NiceModal.show(ConfirmDialog, {
        description: t('title', 'dialogs.booking.checkIn'),
        headline: t('headline', 'dialogs.booking.checkIn'),
        confirmLabel: t('confirm', 'dialogs.booking.checkIn'),
      });
    } catch {
      trackBookingCheckInCancelled('manual', booking, receipt);
      return;
    }

    try {
      await toast.promise(
        checkInBooking.mutateAsync({
          id: booking.id,
          method: 'backoffice-manual',
        }),
        {
          pending: t('pending', 'dialogs.booking.checkIn'),
          success: t('success', 'dialogs.booking.checkIn'),
          error: t('errorTitle', 'dialogs.errorDialog'),
        }
      );
      trackBookingCheckInCompleted('manual', booking, receipt);
    } catch (error) {
      captureException(error);
      toast.error(t('errorTitle', 'dialogs.errorDialog'));
    }
  }, [booking?.id, checkInBooking, t]);

  return {
    handleCancel,
    handleRefund,
    handleMoveBooking,
    handleCheckInBooking,
  };
};
