import useResponsive from './useResponsive';

const useContentLayout = () => {
  const { isXs, isSm, isMd, isLg, isXl } = useResponsive();

  const paddingVariables = {
    xl: '--content-padding-xl',
    lg: '--content-padding-lg',
    md: '--content-padding-md',
    sm: '--content-padding-sm',
    xs: '--content-padding-xs',
  };

  const contentPadding = isXs
    ? `var(${paddingVariables.xs})`
    : isSm
      ? `var(${paddingVariables.sm})`
      : isMd
        ? `var(${paddingVariables.md})`
        : isLg
          ? `var(${paddingVariables.lg})`
          : isXl
            ? `var(${paddingVariables.xl})`
            : `var(${paddingVariables.xl})`;

  return {
    contentPadding,
    contentMaxWidth: 'var(--content-max-width)',
  };
};

export default useContentLayout;
