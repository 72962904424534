import { EconomicConfiguration } from '@holdbar-com/utils-types';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import {
  getEconomicConfiguration,
  saveEconomicConfiguration,
} from '../../../Api';
import { useProfile } from '../../../Hooks/useProfile';
import { EconomicConfigurationQueryKey } from './economic-query-keys';

export type UseEconomicConfigurationReturn = ReturnType<
  typeof useEconomicConfiguration
>;

export const useEconomicConfiguration = () => {
  const { company } = useProfile();
  const queryClient = useQueryClient();

  const configuration = useQuery(
    EconomicConfigurationQueryKey,
    async () => {
      await queryClient.cancelQueries(EconomicConfigurationQueryKey);
      const configuration = await getEconomicConfiguration();
      return configuration && configuration.item;
    },
    {
      enabled: !!company.data?.id,
    }
  );

  const updateConfiguration = useMutation(
    async (payload: EconomicConfiguration) => {
      await saveEconomicConfiguration({ ...payload });
      return payload;
    },
    {
      onMutate: async (payload) => {
        await queryClient.cancelQueries(EconomicConfigurationQueryKey);

        const previous = queryClient.getQueryData(
          EconomicConfigurationQueryKey
        );

        queryClient.setQueryData(EconomicConfigurationQueryKey, payload);

        return { previous };
      },
      onError: (err, variables, context: any) => {
        if (context?.previous) {
          queryClient.setQueryData(
            EconomicConfigurationQueryKey,
            context.previous
          );
        }
      },
    }
  );

  return {
    configuration,
    updateConfiguration,
  };
};
