import { Stack } from '@mui/material';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { MediaUpload } from '../media-upload/media-upload';
import { StockPhotoSelector } from '../media-upload/stock-photo-selector';
import { OnboardingFormInputs } from '../onboarding-flow-form';
import { OnboardingStepLayout } from './step-layout';

export const MODEL_TYPE = 'experience';
export const FILE_TYPE = 'cover';

export const MediaStep = () => {
  const { i18n } = useTranslation();
  const { watch, control } = useFormContext<OnboardingFormInputs>();

  const watchedExperienceId = watch('experienceId');
  const watchedExperienceHeadline = watch('experienceName');
  const watchedExperienceDescription = watch('experienceDescription');

  const { field: media } = useController<
    OnboardingFormInputs,
    'experienceMedia'
  >({
    name: 'experienceMedia',
    defaultValue: [],
    control,
  });

  return (
    <OnboardingStepLayout>
      <Stack gap={3}>
        <MediaUpload
          id={watchedExperienceId}
          uploadedMedia={media.value ?? []}
          onChange={media.onChange}
        />
        <StockPhotoSelector
          id={watchedExperienceId}
          uploadedMedia={media.value ?? []}
          placement={'quick-setup'}
          experienceName={{
            [i18n.language]: watchedExperienceHeadline,
          }}
          experienceDescription={{
            [i18n.language]: watchedExperienceDescription,
          }}
          onChange={media.onChange}
        />
      </Stack>
    </OnboardingStepLayout>
  );
};
