import { Text } from '@holdbar-com/pixel';
import {
  capitalize,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  styled,
} from '@mui/material';
import { TFunction, useTranslation } from 'react-i18next';

import { trackEventFlowRecurringTypeOpened } from '../../../../../../../../tracking/events/flow/time/form/trackEventFlowRecurringTypeOpened';
import { trackEventFlowRecurringTypeSelected } from '../../../../../../../../tracking/events/flow/time/form/trackEventFlowRecurringTypeSelected';
import { useEventUpsert } from '../../../../../domain/event_upsert_context';
import {
  createRecurringFromType,
  getRecurringType,
  RecurringType,
  recurringTypes,
} from '../use_time_form_recurring';

type EventUpsertTimeFormRecurringSelectProps = {
  onSelectCustom: () => void;
};

export const EventUpsertTimeFormRecurringTypeSelect = ({
  onSelectCustom,
}: EventUpsertTimeFormRecurringSelectProps) => {
  const { i18n, t } = useTranslation();
  const { currentEvent, updateEventField } = useEventUpsert();

  const handleChange = (event: SelectChangeEvent<string>) => {
    const type = event.target.value as RecurringType;

    if (type === 'custom') {
      onSelectCustom();
    }

    updateEventField(
      'recurring',
      createRecurringFromType(type, currentEvent.startDateTime)
    );

    trackEventFlowRecurringTypeSelected(type);
  };

  const isDisabled =
    currentEvent.variant === 'edit' || currentEvent.visibility === 'private';

  return (
    <FormControl fullWidth disabled={isDisabled}>
      <InputLabel id="time-form-recurring-type-select">
        {t('eventUpsert.flow.time.form.recurring.label')}
      </InputLabel>
      <Select
        name="time-form-recurring-type-select"
        label={t('eventUpsert.flow.time.form.recurring.label')}
        labelId="time-form-frequency-select"
        value={getRecurringType(currentEvent.recurring)}
        disabled={isDisabled}
        onChange={handleChange}
        onOpen={trackEventFlowRecurringTypeOpened}
        aria-label={t('eventUpsert.flow.time.form.recurring.label')}
        aria-required={true}
        aria-disabled={isDisabled}
        renderValue={(type) =>
          formatFrequencyType(
            type,
            currentEvent.startDateTime,
            i18n.language,
            t
          )
        }
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 600,
            },
          },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
      >
        {recurringTypes.map((type) => (
          <MenuItem key={type} value={type}>
            <StyledText fontSize="small">
              {formatFrequencyType(
                type,
                currentEvent.startDateTime,
                i18n.language,
                t
              )}
            </StyledText>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const formatFrequencyType = (
  type: RecurringType,
  date: string,
  language: string,
  t: TFunction
) => {
  if (type === 'weekly') {
    const dayOfWeek = new Date(date).toLocaleDateString(language, {
      weekday: 'long',
    });
    return capitalize(t(FREQUENCY_TYPE_MAP[type], { day: dayOfWeek }));
  }

  return capitalize(t(FREQUENCY_TYPE_MAP[type]));
};

const FREQUENCY_TYPE_MAP: Record<RecurringType, string> = {
  notSelected: 'eventUpsert.flow.time.form.recurring.options.never',
  daily: 'eventUpsert.flow.time.form.recurring.options.daily',
  weekly: 'eventUpsert.flow.time.form.recurring.options.weekly',
  allBusinessDays:
    'eventUpsert.flow.time.form.recurring.options.allBusinessDays',
  allWeekends: 'eventUpsert.flow.time.form.recurring.options.allWeekends',
  custom: 'eventUpsert.flow.time.form.recurring.options.custom',
};

const StyledText = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: break-spaces;
`;
