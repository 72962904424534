import { Button, lightTheme, LinkButton, Text } from '@holdbar-com/pixel';
import { Stack } from '@mui/system';
import randomBytes from 'randombytes';
import {
  ActionFunctionArgs,
  LoaderFunction,
  redirect,
  useHref,
  useLoaderData,
  useNavigate,
} from 'react-router';
import { Form, useLinkClickHandler } from 'react-router-dom';
import { toast } from 'react-toastify';

import { declineConnectionRequest, getInvitation } from '../../../Api';
import { getPublicCompanyProfile } from '../../../Api';
import useResponsive from '../../../Hooks/layout/useResponsive';
import { useTranslate } from '../../../Hooks/useTranslate';
import { t } from '../../../i18n/config';
import { DialogWrapper } from '../components/dialog-wrapper';
import { connectStore } from '../connect-store';

type LoaderData = {
  companyName: string;
};
export const loader: LoaderFunction<LoaderData> = async ({ params }) => {
  if (!params.token) {
    return {
      status: 404,
    };
  }
  const invitationItem = await getInvitation(params.token);

  if (!invitationItem) {
    throw new Error(
      "Something's wrong. There should be an item in requested state."
    );
  }

  const { name } = await getPublicCompanyProfile(
    invitationItem.connectedCompanyId
  );

  return {
    companyName: name,
  };
};

export async function action({ params }: ActionFunctionArgs) {
  const loadingToastId = randomBytes(16).toString('hex');
  toast.loading(t('connect.dialog.decline.toast.loading'), {
    toastId: loadingToastId,
  });

  const token = params.token as string;

  try {
    await declineConnectionRequest(token);
    connectStore.getState().removeInvitation(token);

    toast.dismiss(loadingToastId);
    toast.success(t('connect.dialog.decline.toast.success'), {
      autoClose: 5000,
    });

    return redirect('../');
  } catch (error) {
    console.error(error);
    toast.dismiss(loadingToastId);
    toast.error(t('connect.dialog.decline.toast.error'), { delay: 500 });
    return {
      success: false,
    };
  }
}

export const DeclineConnectionDialog = () => {
  const { t } = useTranslate('connect.dialog.decline');
  const { isSm } = useResponsive();
  const { companyName } = useLoaderData() as LoaderData;
  const closeHref = useHref(`../`);
  const closeLinkClick = useLinkClickHandler(closeHref);

  const navigate = useNavigate();

  const handleClose = () => {
    navigate(closeHref);
  };

  return (
    <DialogWrapper
      fullWidth
      maxWidth="sm"
      fullScreen={isSm}
      open={true}
      onClose={handleClose}
      title={t('title')}
    >
      <Stack gap={4} component={Form} method="POST">
        <Text variant="normal" fontSize="medium">
          {t('description', { account: companyName })}
        </Text>

        <Stack
          gap={2}
          direction={{ xs: 'column', md: 'row' }}
          justifyContent={'space-between'}
          alignItems={{ xs: 'stretch', md: 'stretch' }}
        >
          <LinkButton
            href={closeHref}
            onClick={closeLinkClick}
            variant="secondary"
            size="large"
            fullWidth
            style={{
              flexShrink: 'unset',
            }}
          >
            {t('cancel')}
          </LinkButton>
          <Button
            type="submit"
            variant="primary"
            size="large"
            fullWidth
            style={{
              color: 'white',
              backgroundColor: lightTheme.palette.error.e300,
              flexShrink: 'unset',
            }}
          >
            {t('confirm')}
          </Button>
        </Stack>
      </Stack>
    </DialogWrapper>
  );
};
