import { Button } from '@holdbar-com/pixel';

import { ShortcutTooltip } from '../../../Components/tooltip/shortcut-tooltip';
import { useShortcuts } from '../../../Hooks/useShortcuts';
import { useTranslate } from '../../../Hooks/useTranslate';
import { useCalendar } from '../context';

export const TodayButton = () => {
  const { t } = useTranslate('utils.generic');
  const { setSelectedDate } = useCalendar();

  const handleTodayButtonClick = () => {
    setSelectedDate(new Date());
  };

  useShortcuts([{ key: 't', action: handleTodayButtonClick }]);

  return (
    <ShortcutTooltip title={t('today')} shortcut="t" hideCommandKey>
      <Button
        size="medium"
        variant="secondary"
        style={{ height: '32px' }}
        onClick={handleTodayButtonClick}
      >
        {t('today')}
      </Button>
    </ShortcutTooltip>
  );
};
