import { lightTheme, LinkButton, Text } from '@holdbar-com/pixel';
import { AddOutlined } from '@mui/icons-material';
import { Box, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useHref, useLinkClickHandler } from 'react-router-dom';

import useResponsive from '../../../Hooks/layout/useResponsive';

export const ConnectViewHeader = () => {
  const { t } = useTranslation();
  const { isMd } = useResponsive();
  const to = useHref('create');
  const linkClickHandler = useLinkClickHandler(to);

  return (
    <Stack direction="row" justifyContent="space-between">
      <Stack>
        <Text variant="medium" fontSize="large" as={'h2'}>
          {t('connect.title')}
        </Text>
        <Text>{t('connect.description')}</Text>
      </Stack>
      <Box
        sx={{
          position: { xs: 'fixed', md: 'static' },
          backgroundColor: lightTheme.palette.contrast.background,
          zIndex: 2,
          bottom: 0,
          left: 0,
          right: 0,
          padding: {
            xs: 2,
            md: 0,
          },
          borderTop: {
            xs: `1px solid ${lightTheme.palette.neutral.n100}`,
            md: 'none',
          },
        }}
      >
        <LinkButton
          href={to}
          variant="primary"
          size={isMd ? 'large' : 'medium'}
          fullWidth={isMd}
          leftIcon={<AddOutlined />}
          onClick={(event) => {
            linkClickHandler(event);
          }}
        >
          {t('connect.addConnection')}
        </LinkButton>
      </Box>
    </Stack>
  );
};
