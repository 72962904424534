import axios, { AxiosError } from 'axios';

export interface ErrorResponse {
  status: number;
  body: {
    error: {
      message: string;
      errors?: ErrorMessage[];
    };
  };
}

export type ErrorMessage = {
  message?: string | undefined;
  path?: string | undefined;
  type?: string | undefined;
  context?: {
    key?: string | undefined;
    label?: string | undefined;
  };
};

export const handleApiError = (error: unknown): ErrorResponse => {
  if (axios.isAxiosError(error)) {
    const serverError = error as AxiosError<ErrorResponse>;
    if (serverError && serverError.response) {
      return serverError.response.data;
    }
  }

  // If it's not an axios error or the structure is not as expected,a generic error structure is returned
  return {
    status: 500,
    body: {
      error: {
        message: 'An unexpected error occurred',
      },
    },
  };
};
