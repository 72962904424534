import { FC } from 'react';

import { BookingStatusFilter } from './BookingStatusFilter';
import { EventStatusFilter } from './EventStatusFilter';
import { ExperiencesFilter } from './ExperiencesFilter';
import { GuidesFilter } from './GuidesFilter';
import { LanguagesFilter } from './LanguagesFilter';
import { LocationsFilter } from './LocationsFilter';

export const AllFilters: FC = () => {
  return (
    <>
      <ExperiencesFilter />
      <LocationsFilter />
      <LanguagesFilter />
      <GuidesFilter />
      <EventStatusFilter />
      <BookingStatusFilter />
    </>
  );
};
