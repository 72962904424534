import { useTranslate } from '../../../Hooks/useTranslate';
import { useUsers } from '../../../Hooks/useUsers';
import { useCalendar } from '../context';
import { Filter } from './view/Filter';

export const GuidesFilter = () => {
  const { t } = useTranslate('');
  const {
    users: { data: users, isLoading },
  } = useUsers();
  const { guideFilters, setGuideFilters } = useCalendar();

  const guides =
    users?.map((guide) => ({
      id: guide.id ?? guide.name ?? guide.email,
      name: guide.name ?? guide.email,
    })) ?? [];

  return (
    <Filter
      inputId="calendar-guides-filter"
      label={t('guides', 'utils.tables.header')}
      options={guides}
      checkedIds={guideFilters}
      onChange={setGuideFilters}
      isLoading={isLoading}
      sortOptions={(a, b) => a.name.localeCompare(b.name)}
      includeEmptyOption
    />
  );
};
