import { Stack, StackProps, Typography } from '@mui/material';

import { IdeaIcon } from '../../Icons';

export const Tip = ({ label, ...props }: StackProps & { label: string }) => {
  return (
    <Stack direction={'row'} spacing={1} {...props}>
      <IdeaIcon />
      <Typography fontSize={'0.88em'} color={'grey.600'}>
        {label}
      </Typography>
    </Stack>
  );
};
