import { lightTheme } from '@holdbar-com/pixel';
import { ExpandLessRounded, ExpandMoreRounded } from '@mui/icons-material';
import { useMemo } from 'react';

import { TBooking } from '../../../../Hooks/useBookings';

type usePageBreadcrumbNavigationProps = {
  bookingsForEventData: TBooking[] | undefined;
};

export const usePageBreadcrumbNavigation = ({
  bookingsForEventData,
}: usePageBreadcrumbNavigationProps) => {
  const bookingsForEvent = useMemo(() => {
    const nonCancelled: string[] = [];
    const cancelled: string[] = [];
    bookingsForEventData?.forEach((booking) => {
      if (booking.status === 'cancelled') {
        cancelled.push(booking.id);
      } else {
        nonCancelled.push(booking.id);
      }
    });
    return [...nonCancelled, ...cancelled];
  }, [bookingsForEventData]);

  return {
    bookingsForEvent,
  };
};

export const navigationDirectionMap = {
  icon: {
    up: ExpandLessRounded,
    down: ExpandMoreRounded,
  },
  key: {
    up: 'j',
    down: 'k',
  },
  title: {
    up: 'prevBooking',
    down: 'nextBooking',
  },
};

export const navigationIconStyles = (theme = lightTheme) => {
  return {
    p: 0,
    color: '#000000',
    outline: `1px solid ${theme.palette.neutral.n100}`,
    borderRadius: 0.5,
    transition: 'outline 0.1s',
    '&:hover': {
      outline: `1px solid ${theme.palette.neutral.n300}`,
    },
    '&:active': {
      outline: `1px solid ${theme.palette.neutral.n400}`,
    },
  };
};
