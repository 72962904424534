import { Text } from '@holdbar-com/pixel';
import { Stack } from '@mui/material';

import useResponsive from '../../../../Hooks/layout/useResponsive';
import { useTranslate } from '../../../../Hooks/useTranslate';
import {
  EventDetailsListColumns,
  EventDetailsListColumnWidths,
} from './shared';

type EBookingListHeaderProps = { showHeaders?: boolean };

export const BookingListHeader = ({
  showHeaders = true,
}: EBookingListHeaderProps) => {
  const { t } = useTranslate('utils.tables.header');
  const { isMd } = useResponsive();

  if (isMd || !showHeaders) {
    return (
      <Stack direction="row" justifyContent="space-between" py={1} px={4}>
        <Text fontSize="small" variant="medium">
          {t('name')}
        </Text>
        <Text fontSize="small" variant="medium">
          {t('countParticipants')}
        </Text>
      </Stack>
    );
  }

  return (
    <Stack direction="row" justifyContent="space-between" py={1} px={4}>
      {EventDetailsListColumns.map((el) => {
        const { ...props } = el.props;
        return (
          <Text
            key={el.key}
            {...props}
            fontSize="small"
            variant="medium"
            style={{
              width:
                EventDetailsListColumnWidths[
                  el.key as keyof typeof EventDetailsListColumnWidths
                ],
              textAlign: el.key === 'count' ? 'center' : 'start',
            }}
          >
            {el.key !== 'none' && t(el.key)}
          </Text>
        );
      })}
    </Stack>
  );
};
