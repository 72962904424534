import { Skeleton, Stack } from '@mui/material';
import { FC } from 'react';

import { GridExperienceCell } from '../calendar-grid/GridExperienceCell';

type Props = {
  numberOfColumns: number;
};

export const GroupedRowSkeleton: FC<Props> = ({ numberOfColumns }) => {
  return (
    <>
      <GridExperienceCell>
        <Skeleton variant="text" width="80%" height={26} />
      </GridExperienceCell>
      {Array.from({ length: numberOfColumns - 1 }).map((_, index) => (
        <Stack p="1px" gap="1px" key={index}>
          {Array.from({ length: index % 3 }).map((_, i) => (
            <Skeleton key={i} variant="rectangular" width={29} height={29} />
          ))}
        </Stack>
      ))}
    </>
  );
};
