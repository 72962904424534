import { capitalize, Stack } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { TFunction, useTranslation } from 'react-i18next';
import { Weekday, WeekdayStr } from 'rrule';
import { ALL_WEEKDAYS } from 'rrule/dist/esm/weekday';

import { EventUpsertTimeFormRecurringWeeklyOption } from './EventUpsertTimeFormRecurringWeeklyOption';
import { EventUpsertRecurringCustomFormTypes } from './use_recurring_custom_form_validation';

export const EventUpsertTimeFormRecurringWeeklySelect = () => {
  const { t } = useTranslation();

  const { setValue, watch } =
    useFormContext<Pick<EventUpsertRecurringCustomFormTypes, 'byweekday'>>();

  const selectedWeekdays: Weekday[] =
    watch('byweekday', []) ??
    [].map((day: Weekday) => new Weekday(day.weekday));

  const isWeekdaySelected = (weekday: Weekday) =>
    selectedWeekdays?.some(
      (selectedDay) => selectedDay.weekday === weekday.weekday
    );

  const handleSelect = (weekdayStr: WeekdayStr) => {
    if (!ALL_WEEKDAYS.includes(weekdayStr)) {
      console.error(`Invalid weekday string: ${weekdayStr}`);
      return;
    }

    const weekday = Weekday.fromStr(weekdayStr);

    // Prevent deselecting weekday if only one is selected
    if (
      selectedWeekdays.length === 1 &&
      selectedWeekdays[0].weekday === weekday.weekday &&
      isWeekdaySelected(weekday)
    ) {
      return;
    }

    const updatedWeekdays = isWeekdaySelected(weekday)
      ? selectedWeekdays.filter((day) => day.weekday !== weekday.weekday)
      : [...selectedWeekdays, weekday];

    setValue('byweekday', updatedWeekdays, {
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  return (
    <Stack direction="row" gap={1}>
      {ALL_WEEKDAYS.map((weekdayStr: WeekdayStr) => {
        const weekday = Weekday.fromStr(weekdayStr);

        return (
          <EventUpsertTimeFormRecurringWeeklyOption
            key={weekdayStr}
            label={formatWeekdayOption(weekday.toString(), t)}
            isSelected={isWeekdaySelected(weekday)}
            onSelect={() => handleSelect(weekdayStr)}
          />
        );
      })}
    </Stack>
  );
};

const formatWeekdayOption = (weekdayStr: string, t: TFunction) => {
  return capitalize(t(WEEKDAY_OPTIONS_TO_LOCALIZED_MAP[weekdayStr]));
};

const WEEKDAY_OPTIONS_TO_LOCALIZED_MAP: Record<string, string> = {
  MO: 'Monday',
  TU: 'Tuesday',
  WE: 'Wednesday',
  TH: 'Thursday',
  FR: 'Friday',
  SA: 'Saturday',
  SU: 'Sunday',
};
