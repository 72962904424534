import { FC } from 'react';

import { useCalendar } from '../../../context';
import { CalendarGrid } from '../../calendar-grid/CalendarGrid';
import { CalendarDateHeader } from './CalendarDateHeader';
import { EventList } from './EventList';

type Props = {
  dates: Date[];
};

export const CalendarWeekListUngrouped: FC<Props> = ({ dates }) => {
  const { events } = useCalendar();

  return (
    <CalendarGrid $columns={7}>
      {dates.map((date) => (
        <CalendarDateHeader key={date.toISOString()} date={date} />
      ))}
      {dates.map((date) => (
        <EventList key={date.toISOString()} date={date} events={events} />
      ))}
    </CalendarGrid>
  );
};
