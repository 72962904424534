import { TokenPayload } from '../Api/Profiles';
import { TBooking } from './useBookings';
import { useProfile } from './useProfile';

type Props = {
  booking?: TBooking;
};

type ReturnType = {
  hasAccessToExperience: boolean;
  bookingExperienceId?: string;
  hasWriteAccess?: boolean;
};

/**
 * Checks access related to a booking
 */
export const useBookingAccess = ({ booking }: Props): ReturnType => {
  const {
    userinfo: { data: userinfo },
  } = useProfile();

  const { hasAccessToExperience, bookingExperienceId } = checkBookingAccess(
    booking,
    userinfo
  );

  const hasWriteAccess =
    userinfo?.scope?.includes('booking.write') &&
    userinfo.companyId === booking?.companyId;

  return {
    hasAccessToExperience,
    bookingExperienceId,
    hasWriteAccess,
  };
};

const checkBookingAccess = (
  booking?: TBooking,
  userinfo?: TokenPayload
): {
  hasAccessToExperience: boolean;
  bookingExperienceId?: string;
} => {
  if (
    !booking?.distributorCompanyId ||
    booking.companyId === userinfo?.companyId
  ) {
    return {
      hasAccessToExperience: true,
      bookingExperienceId: booking?.experienceId,
    };
  }

  const connection = userinfo?.connections.find(
    (x) => x.companyId === booking?.companyId
  );

  if (!connection) {
    return {
      hasAccessToExperience: false,
      bookingExperienceId: booking?.experienceId,
    };
  }

  const hasAccessToExperience =
    connection.sharedExperiences === 'all' ||
    connection.sharedExperiences.includes(booking.experienceId);

  return {
    hasAccessToExperience,
    bookingExperienceId: `${booking.experienceId}:${booking.distributorCompanyId}`,
  };
};
