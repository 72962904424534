import { Skeleton, Stack } from '@mui/material';
import React from 'react';

export const SimpleSkeleton = () => (
  <Stack spacing={2}>
    <Skeleton width={60} />
    <Skeleton width={180} />
    <Skeleton width={240} />
    <Skeleton width={164} />
  </Stack>
);
