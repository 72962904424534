import { ampli } from '../../Ampli';
import { CalendarView } from '../../features/calendar/context';

export const trackCalendarViewChanged = (view: CalendarView) => {
  if (view === 'day') {
    return ampli.goToDayCalendarViewChanged();
  }

  if (view === 'week') {
    return ampli.goToWeekCalendarViewChanged();
  }

  if (view === 'month') {
    return ampli.goToMonthCalendarViewChanged();
  }
};
