import { TFunction } from 'i18next';
import { useMemo } from 'react';
import * as Yup from 'yup';

export const useGetLocationFormSchema = (t: TFunction) =>
  useMemo(
    () =>
      Yup.object().shape({
        locationName: Yup.string()
          .max(20, t('dialogs.location.form.errors.maxLength', { max: 20 }))
          .required(t('dialogs.location.form.errors.required')),

        address: Yup.object().shape({
          line1: Yup.string()
            .max(500, t('dialogs.location.form.errors.maxLength', { max: 500 }))
            .nullable(),

          line2: Yup.string()
            .max(500, t('dialogs.location.form.errors.maxLength', { max: 500 }))
            .nullable(),

          state: Yup.string()
            .max(100, t('dialogs.location.form.errors.maxLength', { max: 100 }))
            .nullable(),

          city: Yup.string()
            .max(100, t('dialogs.location.form.errors.maxLength', { max: 100 }))
            .when('line1', (line1, schema) => {
              return line1
                ? schema.required(t('dialogs.location.form.errors.required'))
                : schema.nullable();
            }),

          postalCode: Yup.string()
            .max(20, t('dialogs.location.form.errors.maxLength', { max: 20 }))
            .when('line1', (line1, schema) => {
              return line1
                ? schema.required(t('dialogs.location.form.errors.required'))
                : schema.nullable();
            }),

          country: Yup.string()
            .required(t('dialogs.location.form.errors.required'))
            .matches(/^[A-Z]{2}$/),
        }),
      }),
    [t]
  );
