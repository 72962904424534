import { lightTheme, LinkButton, Text } from '@holdbar-com/pixel';
import {
  CompanyLocation,
  ConnectedItem,
  Connection,
} from '@holdbar-com/utils-types';
import { ArrowBackRounded } from '@mui/icons-material';
import { Card, Stack, styled } from '@mui/material';
import {
  ActionFunctionArgs,
  LoaderFunctionArgs,
  useHref,
  useLoaderData,
} from 'react-router';
import { Link, Outlet, useLinkClickHandler } from 'react-router-dom';

import { getConnectionItem } from '../../../Api';
import {
  getPublicCompanyProfile,
  getPublicUserProfile,
} from '../../../Api/Profiles';
import { AppShell } from '../../../Components/AppShell/AppShell';
import { PageBreadcrumb } from '../../../Components/Page/page_breadcrumb';
import { PageBreadcrumbItem } from '../../../Components/Page/page_breadcrumb/ui/page_breadcrumb_breadcrumbs/page-breadcrumb-item';
import { PageBreadcrumbBreadcrumbs } from '../../../Components/Page/page_breadcrumb/ui/page_breadcrumb_breadcrumbs/PageBreadcrumbBreadcrumbs';
import { useTranslate } from '../../../Hooks/useTranslate';
import { ConnectBadge } from '../components/connect-badge';
import { decodeConnectionPath } from '../list/connection-path-tools';

export type ConnectionDetailsLoaderData = {
  connectedCompany: {
    id: string;
    name: string;
    location?: CompanyLocation;
    email: string;
    phone: string;
  } | null;
  receiverEmail: string;
  connectedCompanyLabel: string;
  createdBy?: string;
  createdAt: Date;
  state: Connection['state'];
  youShare: ConnectedItem['sharedExperiences'] | null;
  theyShare: ConnectedItem['sharedExperiences'] | null;
  token?: string;
};

export async function loader({
  params,
}: LoaderFunctionArgs): Promise<ConnectionDetailsLoaderData> {
  const { companyId, receiverEmail } = decodeConnectionPath(params.id!);

  if (!companyId || !receiverEmail) {
    throw new Response('', {
      status: 404,
    });
  }

  const connection = await getConnectionItem(companyId, receiverEmail);
  if (!connection) {
    throw new Response('', {
      status: 404,
    });
  }

  const matchingItem = connection.connectedCompanyId
    ? await getConnectionItem(connection.connectedCompanyId, receiverEmail)
    : null;

  const [connectedCompany, createdByUser] = await Promise.all([
    connection.connectedCompanyId
      ? getPublicCompanyProfile(connection.connectedCompanyId)
      : null,
    getPublicUserProfile(connection.createdByUserId),
  ]);

  return {
    connectedCompany: connectedCompany
      ? {
          id: connectedCompany.id,
          name: connectedCompany.name,
          location: connectedCompany.location,
          email: connectedCompany.companyEmail,
          phone: connectedCompany.companyPhone,
        }
      : null,
    receiverEmail: connection.receiverEmail,
    connectedCompanyLabel: connectedCompany
      ? connectedCompany.name
      : receiverEmail,
    createdBy: createdByUser?.name,
    createdAt: new Date(connection.createdAt),
    state: connection.state,
    youShare:
      'sharedExperiences' in connection ? connection.sharedExperiences : null,
    theyShare:
      matchingItem && 'sharedExperiences' in matchingItem
        ? matchingItem.sharedExperiences
        : null,
    token: 'token' in connection ? connection.token : undefined,
  };
}

export function action({}: ActionFunctionArgs) {
  return {};
}

export function ConnectionDetailsPage() {
  const loaderData = useLoaderData() as ConnectionDetailsLoaderData;
  const { t } = useTranslate('settings');
  const editHref = useHref(`edit`);
  const editLinkClicked = useLinkClickHandler(editHref);
  const disconnectHref = useHref(`disconnect`);
  const disconnectLinkClicked = useLinkClickHandler(disconnectHref);

  return (
    <AppShell hideContainerPadding>
      <PageBreadcrumbBreadcrumbs>
        <PageBreadcrumbItem title={t('title')} href="/settings" />
        <PageBreadcrumbItem
          title={t('navigation.connect')}
          href="/settings/connect"
        />
        <PageBreadcrumbItem
          title={loaderData.connectedCompanyLabel}
          href="/settings/connect"
        />
      </PageBreadcrumbBreadcrumbs>
      <PageBreadcrumb>
        <Stack gap={4} width={'100%'}>
          <Stack gap={{ xs: 2, lg: 0 }} pb={2}>
            <Stack
              direction={{ xs: 'column', md: 'row' }}
              justifyContent="space-between"
              alignItems={{ xs: 'start', md: 'center' }}
              gap={1}
            >
              <Stack
                gap={{ xs: 1, md: 2 }}
                direction={{ xs: 'column', md: 'row' }}
              >
                <Link to="../.." relative="path">
                  <StyledBackArrowRounded />
                </Link>
                <Stack gap={1}>
                  <Stack direction="row" gap={2} alignItems={'center'} mt={1}>
                    <Text fontSize="large" variant="medium">
                      {loaderData.connectedCompanyLabel}
                    </Text>
                    <ConnectBadge state={loaderData.state} />
                  </Stack>
                </Stack>
              </Stack>
              <Stack direction={'row'} gap={1}>
                {loaderData.state !== 'disconnected' && (
                  <>
                    <LinkButton
                      size={'small'}
                      variant={'secondary'}
                      style={{
                        color: lightTheme.palette.error.e300,
                      }}
                      href={disconnectHref}
                      onClick={disconnectLinkClicked}
                    >
                      Disconnect
                    </LinkButton>
                    <LinkButton
                      size={'small'}
                      variant={'secondary'}
                      href={editHref}
                      onClick={editLinkClicked}
                    >
                      Edit
                    </LinkButton>
                  </>
                )}
              </Stack>
            </Stack>
          </Stack>

          <Card>
            <pre>{JSON.stringify(loaderData, null, 2)}</pre>
          </Card>
        </Stack>
      </PageBreadcrumb>
      <Outlet />
    </AppShell>
  );
}

const StyledBackArrowRounded = styled(ArrowBackRounded)({
  height: '33px',
  width: '33px',
  padding: '4px',
  cursor: 'pointer',
  borderRadius: '50%',
  marginTop: '3.5px',
  transition: 'background-color 0.1s',
  '&:hover': {
    backgroundColor: lightTheme.palette.neutral.n100,
  },
  '&:active': {
    backgroundColor: lightTheme.palette.neutral.n200,
  },
});
