import { Button } from '@holdbar-com/pixel';
import { Divider, Stack, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CreateLocationDialog } from '../../Components/Dialog/CreateLocationDialog/CreateLocationDialog';
import {
  OptionGroup,
  OptionGroupSelectedSection,
} from '../../Components/OptionGroup/OptionGroup';
import { TranslatableGroup } from '../../Components/TranslatableGroup/translatable-group';
import { useStorefrontLanguages } from '../../Hooks/translatable/use-storefront-languages';
import { useProfile } from '../../Hooks/useProfile';
import { SyiSection } from '../../Pages/SyiPage/SyiSection';
import { useErrors } from '../../Pages/SyiPage/useErrors';
import { LocationsSelect } from './LocationsSelect';
import { TimeSpanPicker } from '../../Components/TimeSpanPicker/timeSpanPicker';

export const visibilityOptions = [
  {
    key: 'public',
    label: 'Offentlig',
    prefillLabel: ' ',
  },
  {
    key: 'private',
    label: 'Privat',
    prefillLabel: '',
  },
];

const infoForGuestsOptions = [
  {
    key: 'no',
    label: 'Nej, de skal bare møde op',
  },
  {
    key: 'yes',
    label: 'Ja, lad mig beskrive',
  },
];

export const minimumParticipantsOptions = [
  {
    key: 'no',
    label: 'Nej, oplevelsen afholdes selv med 0 deltagere',
    prefillLabel: 'Skal eventet afholdes selv med 0 deltagere?',
  },
  {
    key: 'yes',
    label: 'Ja, lad mig definere antal pladser',
    prefillLabel: 'Er minimum deltager antal ${value} for eventet?',
  },
];

export const ExperienceSyiSectionPracticalities = () => {
  const { t } = useTranslation();
  const [isCreateLocationDialogOpen, setIsCreateLocationDialogOpen] =
    useState(false);

  const { company } = useProfile();

  const { control } = useFormContext();

  const { getError, clearError } = useErrors();

  const storefrontLanguages = useStorefrontLanguages();

  const _infoForGuestsOptions = infoForGuestsOptions.map((el) => ({
    ...el,
    label: t(
      `experience.create.practicalities.infoForGuests.options.${el.key}.label`
    ),
  }));

  const _visibilityOptions = visibilityOptions.map((el) => ({
    ...el,
    label: t(
      `experience.create.practicalities.visibility.options.${el.key}.label`
    ),
  }));

  const { field: cutoffTimeSeconds } = useController({
    name: 'cutoffTimeSeconds',
  });

  return (
    <>
      <Stack direction={'row'} justifyContent={'space-between'}>
        <Typography variant={'h4'}>
          {t('experience.create.practicalities.title')}
        </Typography>
      </Stack>

      <Stack mt={2} divider={<Divider />} spacing={4}>
        <SyiSection
          title={t('experience.create.practicalities.visibility.title')}
          error={getError('visibility')}
        >
          <OptionGroup
            name={'visibility'}
            options={_visibilityOptions}
            onChangeValue={() => clearError('visibility')}
          ></OptionGroup>
        </SyiSection>

        <SyiSection
          title={t('experience.create.practicalities.infoForGuests.title')}
          error={getError('infoForGuests')}
        >
          <OptionGroup
            name={'infoForGuests.selectedOptionKey'}
            options={_infoForGuestsOptions}
            onChangeValue={() => clearError('infoForGuests')}
          >
            <OptionGroupSelectedSection optionKey={'yes'}>
              <TranslatableGroup
                langs={storefrontLanguages ?? []}
                id={'infoForGuests.yes'}
                input={{
                  type: 'editor',
                  placeholder: t(
                    `experience.create.practicalities.infoForGuests.options.yes.inputPlaceholder`
                  ),
                }}
              />
            </OptionGroupSelectedSection>
          </OptionGroup>
        </SyiSection>

        <SyiSection
          title={t('experience.create.practicalities.cutoffTimeSeconds.title')}
          error={getError('cutoffTimeSeconds')}
        >
          <TimeSpanPicker
            initialValueSeconds={cutoffTimeSeconds.value}
            onChange={cutoffTimeSeconds.onChange}
          />
        </SyiSection>

        <SyiSection
          title={t('experience.create.practicalities.addresses.title')}
          error={getError('locationIds')}
        >
          <Stack alignItems="flex-start" gap={2}>
            <LocationsSelect
              companyId={company.data?.id ?? ''}
              control={control}
            />
            <Button
              size="medium"
              variant="primary"
              onClick={() => setIsCreateLocationDialogOpen(true)}
            >
              {t('experience.create.practicalities.addresses.addLocation')}
            </Button>
          </Stack>
        </SyiSection>
      </Stack>
      <CreateLocationDialog
        open={isCreateLocationDialogOpen}
        onClose={() =>
          setIsCreateLocationDialogOpen(!isCreateLocationDialogOpen)
        }
      />
    </>
  );
};
