import { lightTheme } from '@holdbar-com/pixel';
import { renderDate } from '@holdbar-com/utils-date';
import { Visibility } from '@holdbar-com/utils-types';
import { InfoOutlined } from '@mui/icons-material';
import {
  Box,
  ButtonBaseProps,
  Skeleton,
  Stack,
  styled,
  Tooltip,
  Typography,
} from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';

import { useExperienceEvents } from '../../Hooks/events/useExperienceEvents';
import { useExperience } from '../../Hooks/useExperience';
import { useProfile } from '../../Hooks/useProfile';
import { trackExperienceBookingFlowLinkCopied } from '../../tracking/experiences/details/card/trackExperienceBookingFlowLinkCopied';
import { trackExperienceBookingFlowLinkOpened } from '../../tracking/experiences/details/card/trackExperienceBookingFlowLinkOpened';
import { languageOptions } from '../../Utils/config';
import { CopyLink } from '../CopyLink/CopyLink';
import {
  CustomCard,
  ICustomCardProps,
  LeftLabel,
} from '../CustomCard/CustomCard';
import { IPictureProps, Picture } from '../Picture/Picture';

interface IExperienceCard {
  languages: string[];
  createdDate: string;
  showUpcomingEventsCount: boolean;
  rating: {
    count: number;
    average: number;
  };
  coverImage: string;
  imageProps?: Partial<IPictureProps>;
  id: string;
  visibility?: Visibility;
}

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  '& p': {
    fontWeight: { xs: 900, md: 500 },
  },
  [theme.breakpoints.down('md')]: {
    justifyContent: 'space-between',
  },
}));

const StyledValue = styled('div')(({ theme }) => ({
  color: '#636366',
  fontWeight: 500,
  fontSize: '1em',
  [theme.breakpoints.down('md')]: {
    color: '#3A3A3C',
    fontWeight: 600,
    fontSize: '12px',
  },
}));

export const ExperienceCard = ({
  title,
  onClickStatus,
  status,
  languages: langs,
  createdDate,
  children,
  showUpcomingEventsCount = false,
  rating,
  imageProps,
  coverImage,
  onClick,
  fullWidth = false,
  id,
  visibility,
  ...props
}: ButtonBaseProps &
  Omit<ICustomCardProps, 'image' | 'content'> &
  Partial<IExperienceCard>) => {
  const { t } = useTranslation();

  const { companyProfileUrl } = useProfile();

  const flags = useFlags();
  const {
    upcomingEventsCount: { data: upcomingEventsCount },
    eventsForExperience: { data: eventsForExperience },
  } = useExperienceEvents(id);

  const {
    experience: { data: experience },
  } = useExperience(id);

  const bookingUrl = `${companyProfileUrl()}/booking/${id}`;

  return (
    <CustomCard
      image={
        <Picture
          borderRadius={2}
          height={{ xs: 124, md: 168 }}
          minWidth={{ xs: 124, md: 168 }}
          source={coverImage ?? ''}
          {...imageProps}
          aspectRatio={1}
        />
      }
      title={title}
      onClickStatus={onClickStatus}
      onClick={onClick}
      status={status}
      fullWidth={fullWidth}
      content={
        <Stack mt={1} spacing={1} justifyContent={'flex-end'}>
          {visibility && (
            <StyledBox>
              <LeftLabel>{t('experience.card.visibility.title')}</LeftLabel>
              <StyledValue>
                <Typography fontSize={'1em'} fontWeight={600}>
                  {t(`experience.card.visibility.${visibility}`)}
                </Typography>
              </StyledValue>
            </StyledBox>
          )}
          <StyledBox>
            <LeftLabel>{t('utils.generic.language')}</LeftLabel>
            {langs === undefined ? (
              <Skeleton height={20} width={40} />
            ) : (
              <Typography
                sx={{ fontSize: { xs: '10px', sm: '16px', md: '20px' } }}
                lineHeight={'1em'}
              >
                {langs?.map((el) => languageOptions(el).emoji).join(' ')}
              </Typography>
            )}
          </StyledBox>
          <StyledBox>
            <LeftLabel>{t('utils.generic.created')}</LeftLabel>
            {createdDate == undefined ? (
              <Skeleton height={20} width={64} />
            ) : (
              <StyledValue>
                <Typography fontSize={'1em'} fontWeight={600}>
                  {renderDate(createdDate, 'dd.MM.yyyy')}
                </Typography>
              </StyledValue>
            )}
          </StyledBox>
          {showUpcomingEventsCount && (
            <StyledBox>
              <LeftLabel>{t('utils.generic.countEvents')}</LeftLabel>
              {upcomingEventsCount === undefined ? (
                <Skeleton height={20} width={32} />
              ) : (
                <StyledValue>
                  <Typography
                    sx={{ display: { xs: 'none', md: 'inline' } }}
                    component={'span'}
                    fontSize={'1em'}
                    fontWeight={600}
                  >
                    {`${
                      upcomingEventsCount > 99 ? '99+' : upcomingEventsCount
                    } ${t('utils.generic.coming')}`}
                  </Typography>
                </StyledValue>
              )}
            </StyledBox>
          )}
          {flags['featureBookingFlowLink'] && (
            <StyledBox gap={lightTheme.tokens.spaces[2]}>
              {!id ? (
                <Skeleton height={20} width="50%" />
              ) : (
                status !== 'draft' && (
                  <>
                    <CopyLink
                      href={bookingUrl}
                      label={t('utils.generic.linkToBookingFlow')}
                      onClickText={() => {
                        trackExperienceBookingFlowLinkOpened(
                          eventsForExperience?.events ?? [],
                          experience
                        );
                      }}
                      onClickCopy={() => {
                        trackExperienceBookingFlowLinkCopied(
                          eventsForExperience?.events ?? [],
                          experience
                        );
                      }}
                    />
                    <Tooltip title={t('utils.generic.bookingLinkTooltip')}>
                      <InfoOutlined
                        sx={{
                          color: lightTheme.palette.neutral.n300,
                          fontSize:
                            lightTheme.tokens.font.presets.large.fontSize,
                        }}
                      />
                    </Tooltip>
                  </>
                )
              )}
            </StyledBox>
          )}
        </Stack>
      }
      {...props}
    >
      {children && (
        <Box
          display={'flex'}
          flexDirection={'column'}
          alignItems={'flex-end'}
          justifyContent={'space-between'}
        >
          {children}
        </Box>
      )}
    </CustomCard>
  );
};
