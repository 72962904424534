import { toISODateTime } from '@holdbar-com/utils-date';
import {
  addHours,
  addMinutes,
  differenceInMinutes,
  format,
  isSameDay,
  set,
} from 'date-fns';
import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TimeInput } from '../../../../../../../Components/inputs/time-input';
import { trackEventFlowStartTimeOpened } from '../../../../../../../tracking/events/flow/time/form/trackEventFlowStartTimeOpened';
import { trackEventFlowStartTimeSelected } from '../../../../../../../tracking/events/flow/time/form/trackEventFlowStartTimeSelected';
import { useEventUpsert } from '../../../../domain/event_upsert_context';
import { isIntervalSelected } from '../../../../domain/types';
import { nextInterval, parseTime } from './event_upsert_time_form';
import { EventUpsertTimeFormTypes } from './use_time_form_validation';

export const EventUpsertTimeFormStartTimeSelect = () => {
  const { t } = useTranslation();
  const { currentEvent, updateEventField } = useEventUpsert();
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext<
    Pick<EventUpsertTimeFormTypes, 'startDateTime' | 'endDateTime'>
  >();

  const selectedStartDateTime = new Date(currentEvent.startDateTime);

  // Automatically update the start time to the next available interval
  // when creating a new event and the time isn't % 5 === 0.
  // ----------------------------------------------------------------
  // Also update the end time to the next available interval when the
  // start time is updated + 1 hour.
  useEffect(() => {
    const now = new Date();
    if (isSameDay(selectedStartDateTime, now) && selectedStartDateTime < now) {
      const nextAvailableTime = nextInterval(now, 5);
      if (selectedStartDateTime < nextAvailableTime) {
        const updatedStartDateTime = new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate(),
          nextAvailableTime.getHours(),
          nextAvailableTime.getMinutes()
        );
        setValue('startDateTime', toISODateTime(updatedStartDateTime));
        updateEventField('startDateTime', toISODateTime(updatedStartDateTime));
      }

      const updatedEndDateTime = nextInterval(
        addHours(selectedStartDateTime, 1),
        5
      );
      setValue('endDateTime', toISODateTime(updatedEndDateTime));
      updateEventField('endDateTime', toISODateTime(updatedEndDateTime));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectChange = async (value: string | undefined) => {
    try {
      if (!value) return;

      const [hours, minutes] = parseTime(value);
      const updatedStartDateTime = set(selectedStartDateTime, {
        hours,
        minutes,
      });

      setValue('startDateTime', toISODateTime(updatedStartDateTime), {
        shouldValidate: true,
      });
      updateEventField('startDateTime', toISODateTime(updatedStartDateTime));

      // When intervals are enabled, the end time should not be updated
      // when the start time is changed, based on the difference between
      // the start and end time. However, the difference between the start
      // and end time should be updated, so the end time stays the same when
      // the intervals are disabled.
      if (isIntervalSelected(currentEvent.intervals)) {
        const endDateTime = new Date(currentEvent.endDateTime);
        const totalDiffInMinutes = differenceInMinutes(
          endDateTime,
          updatedStartDateTime
        );
        updateEventField('startEndTimeDiff', totalDiffInMinutes);
      } else {
        const updatedEndDateTime = nextInterval(
          addMinutes(updatedStartDateTime, currentEvent.startEndTimeDiff),
          5
        );
        setValue('endDateTime', toISODateTime(updatedEndDateTime));
        updateEventField('endDateTime', toISODateTime(updatedEndDateTime));
      }

      trackEventFlowStartTimeSelected(updatedStartDateTime);
    } catch (error) {
      console.error('Error updating start time: ', error);
    }
  };

  const hasBookings = currentEvent.bookings && currentEvent.bookings.length > 0;

  return (
    <Controller
      name="startDateTime"
      control={control}
      render={({ field }) => (
        <TimeInput
          {...field}
          label={t('eventUpsert.flow.time.form.startTime.label')}
          value={
            field.value ? format(new Date(field.value), 'HH:mm') : undefined
          }
          sx={{ flex: 2 }}
          slotProps={{
            textField: {
              error: !!errors.startDateTime,
              required: true,
              id: 'time-start-time-select',
              InputLabelProps: { shrink: true },
            },
          }}
          onChange={handleSelectChange}
          onOpen={trackEventFlowStartTimeOpened}
          disabled={hasBookings}
        />
      )}
    />
  );
};
