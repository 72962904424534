import styled from '@emotion/styled';
import { Text } from '@holdbar-com/pixel';
import { Skeleton } from '@mui/material';

import { useSuggestions } from '../../Hooks/data/useSuggestions';
import { useTranslate } from '../../Hooks/useTranslate';
import { SuggestionCard } from './suggestion-card';

export const SuggestionsList = () => {
  const suggestions = useSuggestions();

  const { t } = useTranslate('utils');

  if (suggestions.isLoading) {
    return (
      <StyledGrid>
        <Skeleton variant={'rounded'} height={200} />
        <Skeleton variant={'rounded'} height={200} />
      </StyledGrid>
    );
  }

  if (suggestions.isError) {
    return <div>{t('errors.generic')}</div>;
  }

  if (suggestions.data?.length === 0) {
    return <Text>{t('noAvailable', 'suggestions')}</Text>;
  }

  return (
    <StyledGrid>
      {suggestions.data?.map((suggestion) => (
        <SuggestionCard key={suggestion.suggestionId} suggestion={suggestion} />
      ))}
    </StyledGrid>
  );
};

const StyledGrid = styled.div`
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr 1fr;
  padding-top: 8px;
`;
