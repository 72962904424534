import { Stack } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { useModal } from "@ebay/nice-modal-react";
import { toast } from "react-toastify";

import { NotificationsResendModalFormAddress } from "./NotificationsResendModalFormAddress";
import { useTranslate } from "../../../../../../../../../../../Hooks/useTranslate";
import { NotificationsResendModalFormActions } from "./NotificationsResendModalFormActions";
import { resendNotification } from "../../../../domain/helpers";
import { NotificationsResendModalFormType } from "./use_notifications_resend_modal_form";
import { Recipient } from "../../../../domain/types";

type NotificationsResendModalFormProps = {
  notificationId: string;
  bookingId: string;
  bookingRecipient: Recipient;
  disallowChangeRecipient?: boolean;
};

export const NotificationsResendModalForm = ({ notificationId, bookingId, bookingRecipient, disallowChangeRecipient }: NotificationsResendModalFormProps) => {
  const { t } = useTranslate('dialogs.booking.notifications.resendModal');

  const modal = useModal();

  const { reset, handleSubmit } = useFormContext<NotificationsResendModalFormType>();

  const handleClose = () => {
    modal.reject();
    modal.hide();
    reset();
  };

  const handleResend = async (newRecipient: NotificationsResendModalFormType) => {
    modal.resolve();
    modal.hide();

    try {
      toast.loading(t('toastLoading'), { toastId: 'resending-loading' });

      await resendNotification(
        notificationId,
        bookingId,
        bookingRecipient,
        newRecipient.address !== bookingRecipient.address ? newRecipient : undefined
      );

      toast.dismiss('resending-loading');
      toast.success(t('toastSuccess'));
    } catch {
      toast.dismiss('resending-loading');
      toast.error(t('toastError'));
    } finally {
      reset();
    }
  };


  return (
    <form>
      <Stack gap={3}>
        <NotificationsResendModalFormAddress disallowChangeRecipient={disallowChangeRecipient} />
        <NotificationsResendModalFormActions handleSubmit={handleSubmit(handleResend)} handleClose={handleClose} />
      </Stack>
    </form>
  );
};
