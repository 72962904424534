import { lightTheme, Text } from '@holdbar-com/pixel';
import { ChevronRightRounded } from '@mui/icons-material';
import { Card, Grid, Stack } from '@mui/material';

import { useTranslate } from '../../../../Hooks/useTranslate';
import { TBoughtVoucher } from '../../../../Hooks/useVoucher';
import { useGiftCardsList } from './domain/use_giftcards_list';

export const GiftCardsListItemDesktop = ({
  giftCard,
  onClick,
}: {
  giftCard: TBoughtVoucher;
  onClick: (voucherId: string) => () => void;
}) => {
  const { t } = useTranslate('giftcards');
  const { listColumns } = useGiftCardsList();

  return (
    <Grid
      container
      key={giftCard.id}
      component={Card}
      p={1.5}
      alignItems={'center'}
      sx={{
        cursor: 'pointer',
        '&:hover': { backgroundColor: 'rgb(252,252,252)' },
      }}
      onClick={onClick(giftCard.id)}
    >
      {listColumns.map((el) => (
        <Grid
          key={el.key}
          item
          {...el.props}
          component={Text}
          fontSize={'small'}
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {el.value?.(giftCard, t)}
        </Grid>
      ))}
    </Grid>
  );
};

export const GiftCardsListItemMobile = ({
  giftCard,
  onClick,
}: {
  giftCard: TBoughtVoucher;
  onClick: (voucherId: string) => () => void;
}) => {
  const { t } = useTranslate('giftcards');
  const { listColumns } = useGiftCardsList();

  return (
    <Stack
      key={giftCard.id}
      direction={'row'}
      justifyContent={'space-between'}
      py={3}
      onClick={onClick(giftCard.id)}
      sx={{
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: lightTheme.palette.neutral.n100,
        },
      }}
    >
      <Text>
        {listColumns.find((el) => el.key === 'created')?.value?.(giftCard, t)}
      </Text>
      <Stack direction={'row'} alignItems={'center'} gap={1}>
        <Text>
          {listColumns.find((el) => el.key === 'code')?.value?.(giftCard, t)}
        </Text>
        <ChevronRightRounded htmlColor={lightTheme.palette.neutral.n300} />
      </Stack>
    </Stack>
  );
};
