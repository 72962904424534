export const AVAILABLE_FONTS = [
  'DM Sans',
  'Montserrat',
  'Arial',
  'Open Sans',
  'Raleway',
  'Poppins',
];

export const BUTTON_BORDER_STYLES = {
  sharp: '0px',
  medium: '8px',
  rounded: '9999px',
};
