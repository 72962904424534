import axios from './index';

export type Domain = {
  type: 'custom' | 'subdomain';
  domain: string;
};

export const domainAvailable = async (domain: string) => {
  domain = domain.toLowerCase();

  if (typeof process.env.REACT_APP_API_DOMAINS_URL === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_DOMAINS_URL is not defined'
    );
  }

  const { data } = await axios.get<{ available: boolean }>(
    `${process.env.REACT_APP_API_DOMAINS_URL}/${domain}/available`
  );

  return data;
};

export const createDomain = async (payload: Domain) => {
  payload.domain = payload.domain.toLowerCase();

  if (typeof process.env.REACT_APP_API_DOMAINS_URL === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_DOMAINS_URL is not defined'
    );
  }

  await axios.post(`${process.env.REACT_APP_API_DOMAINS_URL}`, { ...payload });
};

export const deleteDomain = async (domain: string) => {
  domain = domain.toLowerCase();

  if (typeof process.env.REACT_APP_API_DOMAINS_URL === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_DOMAINS_URL is not defined'
    );
  }

  await axios.delete(`${process.env.REACT_APP_API_DOMAINS_URL}/${domain}`);
};
