import { Skeleton } from '@mui/material';
import { FC } from 'react';
import { useNavigate } from 'react-router';

import { useEconomicIntegration } from '../../Sections/SettingsSections/Integrations/domain/integrations/use-economic-integration';
import { EconomicConfiguration } from './configuration/economic-configuration';
import { useEconomicConfiguration } from './data/use-economic-configuration';
import { EconomicDetails } from './details/economic-details';
import { EconomicIntegrationLoaderError } from './economic-integration-loader-error';

type Props = {
  isDetails?: boolean;
  setConfigurationIsDirty?: (isDirty: boolean) => void;
};

export const EconomicIntegrationLoader: FC<Props> = ({
  isDetails = false,
  setConfigurationIsDirty,
}) => {
  const navigate = useNavigate();

  const { configuration } = useEconomicConfiguration();
  const economicIntegration = useEconomicIntegration();

  if (economicIntegration?.status === 'connect') {
    navigate('/settings/integrations');
    return null;
  }

  if (configuration.isError) {
    return <EconomicIntegrationLoaderError />;
  }

  if (configuration.isLoading || configuration.data === undefined) {
    return <Skeleton variant="rounded" width="100%" height={128} />;
  }

  if (isDetails) {
    if (configuration.data === null) {
      navigate('/settings/integrations/economic/edit', {
        replace: true,
      });
      return null;
    }

    return <EconomicDetails configuration={configuration.data} />;
  }

  return (
    <EconomicConfiguration
      configuration={configuration.data}
      setConfigurationIsDirty={setConfigurationIsDirty}
    />
  );
};
