import { Box, BoxProps, styled, Typography } from '@mui/material';
import React, { ReactNode } from 'react';

const Styled = styled('section')`
  padding: 24px;
  & > h4 {
    margin-bottom: 16px;
  }
`;
export const SectionContainer = ({
  title,
  children,
  ...props
}: BoxProps & { title?: string; children: ReactNode }) => (
  <Box p={3} {...props}>
    {title && (
      <Typography variant={'h4'} mb={2}>
        {title}
      </Typography>
    )}
    {children}
  </Box>
);
