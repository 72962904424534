import { useEffect, useMemo, useRef, useState } from 'react';
import { useAuth } from '../../Hooks/useAuth';
import { useProfile } from '../../Hooks/useProfile';

export const useReturnUrls = () => {
  // We're setting the state from the initially loaded URL, since we expect the page referrer to come from an external page.
  // This is done using the global window object, which is not recommended in React, but in this case it's the only way to get the initial URL,
  // before React has a chance to update the URL. We assume this is safe, since the URL is expected to be called from external pages,
  // and not from within the application.
  const currentUrl = new URL(window.location.href);
  const currentReturnUrl = currentUrl.searchParams.get('returnUrl') || currentUrl.searchParams.get('returnurl');

  // Setup hooks
  const { isAuthenticated } = useAuth();
  const { company } = useProfile();

  // We're using a ref to store the return URL, since we want to use it in a useEffect hook.
  const returnUrl = useRef<string | null>(currentReturnUrl);

  useEffect(() => {

    // If we're not authenticated, or we don't have a company id, or there's no return URL, we can't do anything (yet)...
    if (!returnUrl.current || !isAuthenticated || !company.data?.id) return;

    // At this point we're logged in, we have a company id, and there's a return URL - let's do this!
    const query = `holdbarCompanyId=${company.data.id}`;
    const querySeparator = returnUrl.current.includes('?') ? '&' : '?';
    
    window.location.href = returnUrl.current + querySeparator + query;
  }, [window.location.href, returnUrl.current, isAuthenticated, company]);
};
