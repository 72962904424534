import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Dialog } from '@mui/material';
import { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { useTranslate } from '../../../Hooks/useTranslate';
import { ICancelEventDialogProps, ICancelEventForm } from '../domain/types';
import { useCancelEventLanguages } from '../domain/use_cancel_event_languages';
import { useCancelNotes } from '../domain/use_cancel_notes';
import { CancelEventForm } from './cancel_event_form/CancelEventForm';
import {
  CancelEventFormData,
  useCancelEventFormValidation,
} from './cancel_event_form/use_cancel_event_form_validation';

export const CancelEventDialog = NiceModal.create<ICancelEventDialogProps>(
  ({ booked = false, eventId }) => {
    const { t } = useTranslate('dialogs.cancelEvent');
    const modal = useModal();

    const {
      prevCancellationNote,
      prevCancellationRefundChoice,
      getCancelNotes,
    } = useCancelNotes(eventId);
    const { languages, isBookingsLoading, selectedLanguages } =
      useCancelEventLanguages(eventId);

    const mappedNotesForLanguages = useMemo(() => {
      if (!languages.length || !prevCancellationNote?.length) return [];

      return languages.map((lang) => {
        const note = prevCancellationNote.find((note) => note[lang]);
        return note ? note[lang] : undefined;
      });
    }, [prevCancellationNote, languages, eventId]);

    const formValidationSchema = useCancelEventFormValidation(
      t,
      selectedLanguages.map((lang) => lang.key)
    );
    const form = useForm<CancelEventFormData>({
      resolver: yupResolver(formValidationSchema),
    });

    const handleClose = () => {
      form.reset();
      modal.reject();
      modal.hide();
    };

    const handleCancelEvent = ({ cancelNote, event }: ICancelEventForm) => {
      const shouldRefund = (event?.nativeEvent as any).submitter.value;
      const cancellationNote = getCancelNotes(languages, cancelNote);
      modal.resolve({ shouldRefund, cancellationNote });
      form.reset();
      modal.hide();
    };

    return (
      <FormProvider {...form}>
        <Dialog open={modal.visible} onClose={handleClose}>
          <CancelEventForm
            onCancelEvent={handleCancelEvent}
            onClose={handleClose}
            booked={booked}
            prevCancellationNote={prevCancellationNote}
            prevCancellationRefundChoice={prevCancellationRefundChoice}
            languages={languages}
            selectedLanguages={selectedLanguages}
            isBookingsLoading={isBookingsLoading}
            mappedNotesForLanguages={mappedNotesForLanguages}
          />
        </Dialog>
      </FormProvider>
    );
  }
);
