import { Box, Stack } from '@mui/material';

import { SectionHeadline } from '../../../Components/SectionHeadline/SectionHeadline';
import { useTranslate } from '../../../Hooks/useTranslate';
import { ActiveIntegrationsList } from './ui/active-integrations-list/active-integrations-list';
import { AvailableIntegrationsList } from './ui/available-integrations-list/available-integrations-list';

export const SettingsIntegrationsSection = () => {
  const { t } = useTranslate('settings.integrations');

  return (
    <Stack gap={3}>
      <SectionHeadline title={t('title')} description={t('description')} />
      <AvailableIntegrationsList />
      <Box mt={2}>
        <ActiveIntegrationsList />
      </Box>
    </Stack>
  );
};
