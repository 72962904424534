import { BookingStatus, EventStatus } from '../context';
import { BooleanSerializer } from './serialization/boolean-serializer';
import { DateSerializer } from './serialization/date-serializer';
import { DefaultSerializer } from './serialization/default-serializer';
import { SearchParamSerializer } from './serialization/search-param-serializer';
import { SelectViewSerializer } from './serialization/select-view-serializer';

export const serializers = {
  date: new DateSerializer(),
  view: new SelectViewSerializer(),
  grouped: new BooleanSerializer(),
  experiences: new DefaultSerializer(),
  locations: new DefaultSerializer(),
  guides: new DefaultSerializer(),
  languages: new DefaultSerializer(),
  eventStatus: new DefaultSerializer<EventStatus[]>(),
  bookingStatus: new DefaultSerializer<BookingStatus[]>(),
} as const;

export type SearchParams = keyof typeof serializers;

export const SAVE_PARAM_ON_PROFILE: SearchParams[] = [
  'view',
  'grouped',
  'experiences',
  'locations',
  'guides',
  'languages',
  'eventStatus',
  'bookingStatus',
];

export const FILTER_PARAMS: typeof SAVE_PARAM_ON_PROFILE = [
  'experiences',
  'locations',
  'guides',
  'languages',
  'eventStatus',
  'bookingStatus',
];

export type GetSerializerType<ParamName extends SearchParams> =
  (typeof serializers)[ParamName] extends SearchParamSerializer<infer P>
    ? P
    : never;
