import styled from '@emotion/styled';
import randomBytes from 'randombytes';
import { useCallback } from 'react';

import { updatePayment } from '../../../../../../../../Api';
import { ProgressButton } from '../../../../../../../../Components/ProgressButton/ProgressButton';
import { useBookings } from '../../../../../../../../Hooks/useBookings';
import { useProducts } from '../../../../../../../../Hooks/useProducts';
import { useTranslate } from '../../../../../../../../Hooks/useTranslate';
import { mapGuestsToOffers } from '../../../../../../../../Modals/create-booking/form/create-booking-form';
import { useBookingDetailsContext } from '../../../../../domain/use_booking_details_context';

export const BookingDetailsSummaryPaymentCreateLink = () => {
  const { t } = useTranslate('dialogs.booking');

  const { booking } = useBookingDetailsContext();

  const { updateBooking } = useBookings(undefined, booking!.id);

  const { products } = useProducts(booking?.experienceId);

  const handleCreatePaymentLink = useCallback(async () => {
    const paymentId = randomBytes(16).toString('hex');
    await updatePayment(paymentId, {
      subject: 'booking',
      subjectId: booking!.id,
      items: mapGuestsToOffers(products, booking?.items ?? {}),
    });
    return updateBooking.mutateAsync({
      id: booking!.id,
      paymentId,
      eventId: booking!.eventId,
    });
  }, [booking, products, updateBooking]);

  return (
    <StyledProgressButton
      label={t('generatePaymentLink', 'dialogs.createBooking.actions')}
      onClick={handleCreatePaymentLink}
    />
  );
};

const StyledProgressButton = styled(ProgressButton)({
  padding: 0,
  maxWidth: '100% !important',
  textAlign: 'left',
  minHeight: 'unset',
  height: 'unset',
  fontSize: 'inherit',
  lineHeight: 'inherit',
  color: '#1C1C1E',
  textDecoration: 'underline',
  fontWeight: 400,
  border: 'none',
  '&:hover': {
    backgroundColor: 'transparent',
    textDecoration: 'underline',
  },
});
