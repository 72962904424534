import languages from '@cospired/i18n-iso-languages';
import { Text } from '@holdbar-com/pixel';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Skeleton,
  styled,
} from '@mui/material';
import { useEffect, useMemo } from 'react';
import { FieldError, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useExperience } from '../../../../../../../Hooks/useExperience';
import { useEventUpsert } from '../../../../domain/event_upsert_context';

export const EventUpsertBookingFormLanguageSelect = () => {
  // Manually register languages using @cospired/i18n-iso-languages
  // This is a workaround for the fact that the library does not support
  // tree-shaking, and thus imports all languages by default
  // See: https://www.npmjs.com/package/@cospired/i18n-iso-languages?activeTab=readme
  useEffect(() => {
    languages.registerLocale(
      require('@cospired/i18n-iso-languages/langs/da.json')
    );
    languages.registerLocale(
      require('@cospired/i18n-iso-languages/langs/en.json')
    );
    languages.registerLocale(
      require('@cospired/i18n-iso-languages/langs/sv.json')
    );
    languages.registerLocale(
      require('@cospired/i18n-iso-languages/langs/no.json')
    );
    languages.registerLocale(
      require('@cospired/i18n-iso-languages/langs/de.json')
    );
  }, []);

  const { t, i18n } = useTranslation();
  const {
    watch,
    register,
    setValue,
    formState: { errors },
  } = useFormContext();
  const { currentEvent, updateEventField } = useEventUpsert();

  const selectedValue = watch('language');
  const error = errors['language'] as FieldError | undefined;

  const {
    experience: { data: experience, isLoading, isError },
  } = useExperience(currentEvent.experienceId);

  const languageNames = useMemo(() => {
    if (!experience?.languages) return {};

    return experience.languages.reduce(
      (acc: any, language: string) => {
        acc[language] = languages?.getName(language, i18n.language) || '';
        return acc;
      },
      {} as Record<string, string>
    );
  }, [experience?.languages, i18n.language]);

  useEffect(() => {
    if (experience?.languages.length === 1 && !selectedValue) {
      setValue('language', experience.languages[0]);
      updateEventField('booking', { language: experience.languages[0] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [experience?.languages]);

  if (isLoading && !experience) {
    return <Skeleton variant="rounded" height={56} width="100%" />;
  }

  if (isError || !experience) {
    return null;
  }

  return (
    <FormControl fullWidth required error={!!error}>
      <InputLabel id="booking-form-language-select-label">
        {t('eventUpsert.flow.booking.form.language.label')}
      </InputLabel>
      <Select
        {...register('language')}
        name="booking-form-language-select"
        labelId="booking-form-language-select-label"
        input={
          <OutlinedInput
            label={t('eventUpsert.flow.booking.form.language.label')}
          />
        }
        value={currentEvent.booking?.language ?? ''}
        onChange={(event) => {
          setValue('language', event.target.value);
          updateEventField('booking', {
            ...currentEvent.booking,
            language: event.target.value,
          });
        }}
        error={!!error}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 600,
            },
          },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
      >
        {experience.languages.map((language: string) => (
          <MenuItem key={language} value={language}>
            <StyledText fontSize="small" textTransform="capitalize">
              {languageNames[language]}
            </StyledText>
          </MenuItem>
        ))}
      </Select>

      {error && <FormHelperText error>{error.message}</FormHelperText>}
    </FormControl>
  );
};

const StyledText = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: break-spaces;
`;
