import { AddRounded, ArrowForwardRounded } from '@mui/icons-material';
import { Box, Button, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { experienceSyiSteps } from '../SyiPage/config';

export const OnBoardingSuccessSection = () => {
  const { t } = useTranslation();

  const { wasInvited } = useOutletContext<{ wasInvited: boolean }>();

  const navigate = useNavigate();

  return (
    <Stack spacing={2} pb={4} alignItems={'center'}>
      <Box
        mt={5}
        component={'img'}
        src={'/graphics/onboarding-success.svg'}
        width={'100%'}
        height={300}
        maxWidth={300}
      />
      <Typography mt={2} variant={'h2'}>
        {t('onboarding.success.boxTitle')}
      </Typography>
      <Typography whiteSpace={'pre-wrap'}>
        {t('onboarding.success.boxDescription')}
      </Typography>
      <Stack spacing={2} pt={2} alignItems={'center'}>
        {!wasInvited && (
          <Button
            variant={'contained'}
            size={'large'}
            startIcon={<AddRounded />}
            onClick={() =>
              navigate(`/experience/create/${experienceSyiSteps[0].key}`, {
                replace: true,
                state: { returnUrl: '/dashboard/welcome' },
              })
            }
          >
            {t('onboarding.success.createButtonLabel')}
          </Button>
        )}
        <Button
          variant={'outlined'}
          color={'secondary'}
          size={'large'}
          startIcon={<ArrowForwardRounded />}
          onClick={() => navigate('/dashboard', { replace: true })}
        >
          {t('onboarding.success.goToDesktopButtonLabel')}
        </Button>
      </Stack>
    </Stack>
  );
};
