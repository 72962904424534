import { DoneRounded } from '@mui/icons-material';
import { Grid, GridProps } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { languagesOptionValues as defaultLanguageOptions } from '../../Utils/config';
import { CustomChip } from '../CustomChip/CustomChip';

export const LanguageChips = ({
  multiple = true,
  allowEmpty = false,
  shouldTranslate = true,
  languages = [],
  languageOptions = defaultLanguageOptions,
  onChange,
  ...props
}: Omit<GridProps, 'onChange'> & {
  shouldTranslate?: boolean;
  multiple?: boolean;
  allowEmpty?: boolean;
  languageOptions?: typeof defaultLanguageOptions;
  languages?: string[];
  onChange: (keys: string[]) => void;
}) => {
  const { t } = useTranslation();

  const handleToggle = (key: string) => () => {
    if (multiple) {
      const _new = languages.includes(key)
        ? languages.filter((el: string) => el !== key)
        : [...languages, key];
      if (_new.length === 0 && allowEmpty) {
        return onChange([]);
      }
      if (languages.length > 0 && _new.length > 0) {
        return onChange(_new);
      }
    }
    onChange([key]);
  };
  return (
    <Grid container gap={1} {...props}>
      {Object.entries(languageOptions)?.map(([key, { label, icon }]) => {
        return (
          <Grid
            item
            key={key}
            component={CustomChip}
            onClick={handleToggle(key)}
            selected={languages.includes(key)}
            label={shouldTranslate ? t(`utils.languages.${key}`) : label}
            icon={<img src={icon} alt={key} width={20} height={20} />}
            deleteIcon={<DoneRounded />}
            sx={{
              height: 38,
              fontSize: '0.88em',
              fontWeight: '600',
              borderRadius: 100,
            }}
          />
        );
      })}
    </Grid>
  );
};
