import { Button, lightTheme } from '@holdbar-com/pixel';
import { Stack, styled } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

import { useTranslate } from '../../../../Hooks/useTranslate';
import { useEventActions } from '../../../events/event_actions/useEventActions';
import { useEventPopover } from './event-popover-context';

export const PopoverEventActions = () => {
  const { t } = useTranslate('calendar.popover.actions');

  const { selectedEvent, setSelectedEventId } = useEventPopover();

  const {
    showCancelDialog,
    isCancellationDisabled,
    goToEditEvent,
    showMessageParticipantsDialog,
    isMessageParticipantsDisabled,
    showAddBookingDialog,
    isAddBookingDisabled,
  } = useEventActions('Event Details Popup', selectedEvent);

  const onAddBookingClick = async () => {
    // If the event was a virtual event, the event will be created,
    // and the event will get a different id
    const newEventId = await showAddBookingDialog();
    setSelectedEventId(newEventId);
  };

  return (
    <StyledStack>
      <ActionButton
        textColor={lightTheme.palette.error.e300}
        onClick={showCancelDialog}
        disabled={isCancellationDisabled}
      >
        {t('cancel')}
      </ActionButton>
      <ActionButton onClick={goToEditEvent}>{t('edit')}</ActionButton>
      <ActionButton
        onClick={showMessageParticipantsDialog}
        disabled={isMessageParticipantsDisabled}
      >
        {t('messageAll')}
      </ActionButton>
      <ActionButton
        variant="primary"
        onClick={onAddBookingClick}
        disabled={isAddBookingDisabled}
      >
        {t('addBooking')}
      </ActionButton>
    </StyledStack>
  );
};

const StyledStack = styled(Stack)({
  padding: 32,
  flexDirection: 'row',
  justifyContent: 'flex-end',
  gap: 8,
});

type ActionButtonProps = {
  onClick: () => void;
  textColor?: string;
  variant?: 'primary' | 'secondary';
  disabled?: boolean;
} & PropsWithChildren;

const ActionButton: FC<ActionButtonProps> = ({
  onClick,
  textColor,
  variant = 'secondary',
  disabled,
  children,
}) => {
  return (
    <Button
      variant={variant}
      size="medium"
      style={{ color: !disabled ? textColor : undefined }}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </Button>
  );
};
