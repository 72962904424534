import NiceModal from '@ebay/nice-modal-react';
import { HelpOutline } from '@mui/icons-material';
import {
  Box,
  Button,
  ButtonProps,
  Card,
  FormControlLabel,
  Link,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { ReactNode } from 'react';
import { Control, useController, useForm } from 'react-hook-form';

import { ProgressButton } from '../../Components/ProgressButton/ProgressButton';
import { SimpleSkeleton } from '../../Components/SimpleSkeleton/SimpleSkeleton';
import {
  FacebookProvider,
  GA4Provider,
  GoogleAdsProvider,
  TProvider,
  useMarketing,
} from '../../Hooks/useMarketing';
import { useTranslate } from '../../Hooks/useTranslate';
import { InfoDialog } from '../../Modals/InfoDialog';

type DefaultProvider = {
  enabled: boolean;
  id: 'ga4' | 'facebook' | 'googleAds';
  params: {
    [k: string]: {
      mustConfirm: boolean;
      required: boolean;
      defaultValue: string | boolean | number;
    };
  };
};

const defaultProviders: DefaultProvider[] = [
  {
    id: 'ga4',
    enabled: false,
    params: {
      measurementId: {
        mustConfirm: true,
        required: true,
        defaultValue: '',
      },
      apiSecret: {
        mustConfirm: true,
        required: true,
        defaultValue: '',
      },
    },
  },
  {
    id: 'googleAds',
    enabled: false,
    params: {
      conversionId: {
        mustConfirm: true,
        required: true,
        defaultValue: '',
      },
      purchaseConversionLabel: {
        mustConfirm: true,
        required: true,
        defaultValue: '',
      },
    },
  },
  {
    id: 'facebook',
    enabled: false,
    params: {
      pixelId: {
        mustConfirm: true,
        required: true,
        defaultValue: '',
      },
      accessToken: {
        mustConfirm: true,
        required: true,
        defaultValue: '',
      },
      testId: {
        mustConfirm: true,
        required: false,
        defaultValue: '',
      },
      includeUserData: {
        mustConfirm: false,
        required: false,
        defaultValue: false,
      },
    },
  },
];

const mergeParams = (
  defaultParams: DefaultProvider['params'],
  params: { [k: string]: string | boolean | number }
) => {
  return Object.entries(defaultParams).reduce(
    (merged, [k, { defaultValue, ...values }]) => {
      return {
        ...merged,
        [k]: {
          ...values,
          ['defaultValue']: params[k] ?? defaultValue,
        },
      };
    },
    {}
  );
};

const getProviders = (
  providers:
    | (GA4Provider | FacebookProvider | GoogleAdsProvider)[]
    | undefined
    | null
) => {
  return defaultProviders.map(({ params, id, ...el }) => {
    const match = providers?.find((m) => m.id === id);
    return match
      ? { ...el, ...match, id, params: mergeParams(params, match.params ?? {}) }
      : { ...el, id, params };
  }) as typeof defaultProviders;
};

export const GrowthTrackingSection = () => {
  const { t } = useTranslate('marketing.tracking');
  const { providers, updateProvider } = useMarketing();
  const _providers = getProviders(providers.data);

  const flags = useFlags();
  if (flags?.featureMarketingGoogleAdsIntegration === false) {
    const googleAdsProviderIndex = _providers.findIndex(
      (el) => el.id === 'googleAds'
    );
    if (googleAdsProviderIndex > -1) {
      _providers.splice(googleAdsProviderIndex, 1);
    }
  }
  const handleUpdate =
    (id: 'ga4' | 'facebook' | 'googleAds') =>
      (enabled: boolean, params?: any) => {
        updateProvider.mutate({
          id,
          enabled,
          params,
        });
      };

  return (
    <>
      {providers.isLoading ? (
        <SimpleSkeleton />
      ) : (
        <Stack spacing={3}>
          {_providers.map((el) => {
            const inputs = Object.entries(el.params ?? {}).reduce<any[]>(
              (acc, [key, { defaultValue, required, mustConfirm }]) => {
                const k = `${el.id}.${key}`;

                if (k === 'facebook.testId' && !flags?.featureMetaPixelTestId) {
                  return [...acc];
                }

                return [
                  ...acc,
                  {
                    name: `params.${key}`,
                    defaultValue,
                    key,
                    type:
                      typeof defaultValue === 'boolean' ? 'switch' : 'input',
                    label: t(`providers.${k}.label`),
                    placeholder: t(`providers.${k}.placeholder`),
                    helperText: (
                      <Link>{t('howToFindIt', 'utils.generic')}</Link>
                    ),
                    required,
                    mustConfirm,
                  },
                ];
              },
              []
            );

            return (
              <ProviderItem
                onUpdate={handleUpdate(el.id)}
                provider={el.id}
                inputs={inputs}
              />
            );
          })}
        </Stack>
      )}
    </>
  );
};

type TProviderInput = {
  name: string;
  label: string;
  key: string;
  placeholder: string;
  helperText: string | ReactNode;
  defaultValue: string | boolean;
  type: 'switch' | 'input';
  required: boolean;
  mustConfirm: boolean;
};

type TProviderItemProps = {
  provider: 'ga4' | 'facebook' | 'googleAds';
  inputs: TProviderInput[];
  actions?: {
    type: 'checkbox' | 'toggle';
    name: string;
    description?: string;
    link?: string;
  }[];
  onClick?: () => void;
  onToggle?: (newValue: boolean) => void;
  onUpdate?: (enabled: boolean, params: TProvider['params']) => void;
  buttonProps?: Partial<ButtonProps>;
};

const determineIsDirty = (dirtyFields: object, inputs: TProviderInput[]) => {
  const mustConfirm = inputs.filter((el) => el.mustConfirm).map((m) => m.key);
  return Object.keys(dirtyFields).some((key) => mustConfirm.includes(key));
};

const ProviderItem = ({
  onUpdate,
  provider,
  actions,
  inputs,
  buttonProps = {},
}: TProviderItemProps) => {
  const { sx = {}, ...btnProps } = buttonProps;

  const { t } = useTranslate('marketing.tracking.providers');

  const {
    handleSubmit,
    reset,
    control,
    formState: { dirtyFields },
  } = useForm();

  const { updateProvider } = useMarketing();

  const isDirty = determineIsDirty(dirtyFields?.['params'] ?? {}, inputs);

  const handleSave = () => {
    return new Promise((res, rej) => {
      handleSubmit(
        async ({ params, enabled = true }) => {
          if (params) {
            await updateProvider.mutateAsync({
              id: provider,
              enabled,
              params,
            });
            setTimeout(() => reset({ params, enabled }), 1500);
            res('Success');
          }
          rej();
        },
        (err) => rej(err)
      )();
    });
  };

  return (
    <Box
      component={Card}
      gap={2}
      py={4}
      pr={4}
      display={'inline-flex'}
      alignItems={'flex-start'}
      maxWidth={800}
    >
      <Box
        sx={{
          minWidth: 80,
          minHeight: 80,
          backgroundSize: 'cover',
          borderRadius: 200,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundImage: `url("/tracking-providers/${provider}-logo.png")`,
        }}
      />
      <Box ml={2} width={'100%'} gap={2}>
        <Box>
          <Typography variant={'h5'} fontSize={'1.111em'} mb={1}>
            {t(`${provider}.name`)}
          </Typography>
          <Typography variant={'body1'}>
            {t(`${provider}.description`)}
          </Typography>
          {inputs && (
            <Stack spacing={3} mt={3}>
              {inputs.map((el) => (
                <ProviderInput
                  elementKey={el.key}
                  {...el}
                  submit={handleSave}
                  control={control}
                />
              ))}
            </Stack>
          )}
        </Box>
        <Stack direction={'row'} spacing={1} justifyContent={'flex-end'}>
          <ProgressButton
            sx={{
              visibility: isDirty ? 'initial' : 'hidden',
            }}
            variant={'contained'}
            onClick={handleSave}
            label={t('save', 'buttons')}
          />
        </Stack>
      </Box>
    </Box>
  );
};

const ProviderInput = (
  props: TProviderInput & {
    submit: () => void;
    control: Control;
    elementKey: string;
  }
) => {
  const { t } = useTranslate('marketing.tracking.providers.facebook');

  const { field, formState } = useController({
    name: props.name,
    defaultValue: props.defaultValue,
    rules: { required: props.required },
    control: props.control,
  });

  const isChecked = Boolean(props.defaultValue);

  if (props.type === 'switch') {
    return props.elementKey === 'includeUserData' ? (
      <Box display={'flex'} alignItems={'center'}>
        <FormControlLabel
          control={<Switch defaultChecked={isChecked} />}
          label={props.label}
          {...field}
          onChange={(e) => {
            field.onChange(e);
            setTimeout(() => props.submit(), 20);
          }}
        />
        <Button
          sx={{
            minWidth: 0,
          }}
          onClick={() => {
            NiceModal.show(InfoDialog, {
              info: [
                {
                  title: t(`${props.elementKey}.includeUserDataTitle1`),
                  content: t(`${props.elementKey}.includeUserDataContent1`),
                },
                {
                  title: t(`${props.elementKey}.includeUserDataTitle2`),
                  content: t(`${props.elementKey}.includeUserDataContent2`),
                },
                {
                  title: t(`${props.elementKey}.includeUserDataTitle3`),
                  content: t(`${props.elementKey}.includeUserDataContent3`),
                },
              ],
            });
          }}
        >
          <HelpOutline htmlColor="#666666" fontSize="small" />
        </Button>
      </Box>
    ) : (
      <FormControlLabel
        control={<Switch defaultChecked={isChecked} />}
        label={props.label}
        {...field}
        onChange={(e) => {
          field.onChange(e);
          setTimeout(() => props.submit(), 20);
        }}
      />
    );
  }
  return (
    <TextField
      sx={{ minWidth: 480 }}
      label={props.label}
      //   helperText={props.helperText}
      placeholder={props.placeholder}
      defaultValue={props.defaultValue}
      error={Boolean(
        (formState as any)?.errors?.['params']?.[props.elementKey]
      )}
      {...field}
    />
  );
};
