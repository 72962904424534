import { Text } from '@holdbar-com/pixel';
import { Stack } from '@mui/material';

import { useTranslate } from '../../../Hooks/useTranslate';

export function ErrorElement() {
  const { t } = useTranslate('dialogs.errorDialog');

  return (
    <Stack gap={1}>
      <Text variant="medium" fontSize="large">
        {t('errorTitle')}
      </Text>
      <Text variant="normal" fontSize="small">
        {t('errorSubtitle')}
      </Text>
    </Stack>
  );
}
