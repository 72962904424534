import { Text } from '@holdbar-com/pixel';
import { Box, Stack } from '@mui/material';
import { useController, useFormContext } from 'react-hook-form';

import * as api from '../../../../../Api';
import { TokenPayload } from '../../../../../Api/Profiles';
import { ProfileUpload } from '../../../../../Components/ProfileUpload/ProfileUpload';
import { useTranslate } from '../../../../../Hooks/useTranslate';
import { TImage } from '../../../../../Utils/types';

type CompanyFormPicturesProps = {
  userinfo?: TokenPayload;
};

export const CompanyFormPictures = ({ userinfo }: CompanyFormPicturesProps) => {
  const { t } = useTranslate('settings.company');

  const form = useFormContext();
  const { field: pictures } = useController({ name: 'pictures' });

  const handleUploadPrepared = (
    { key, url }: { [k: string]: string },
    fileType: 'logo' | 'profile' | 'cover',
    uploadFunc: () => Promise<void>
  ) => {
    const company = form.getValues();
    pictures.onChange({
      ...(company.pictures ?? {}),
      [fileType]: { url, key },
    });
    uploadFunc();
  };

  const handleDeleteImage =
    (type: 'logo' | 'cover') =>
      async ({ key }: TImage) => {
        pictures.onChange({
          ...(pictures.value ?? {}),
          [type]: null,
        });
        return api.deleteUpload(undefined, key);
      };

  return (
    <Stack gap={4}>
      <Box>
        <Stack gap={1}>
          <Text variant="medium" fontSize="small">
            {t('logo.title')}
          </Text>
          <Text fontSize="xsmall">{t('logo.helperText')}</Text>
        </Stack>
        <ProfileUpload
          mt={2}
          fileType={'logo'}
          onUploadPrepared={handleUploadPrepared}
          onDelete={handleDeleteImage('logo')}
          image={pictures.value?.logo}
          id={userinfo?.org}
          modelType={'company'}
        />
      </Box>
      <Box>
        <Stack gap={1}>
          <Text variant="medium" fontSize="small">
            {t('coverImage.title')}
          </Text>
          <Text fontSize="xsmall">{t('coverImage.helperText')}</Text>
        </Stack>
        <ProfileUpload
          mt={2}
          fileType={'cover'}
          onUploadPrepared={handleUploadPrepared}
          onDelete={handleDeleteImage('cover')}
          image={pictures.value?.cover}
          id={userinfo?.org}
          modelType={'company'}
          containerProps={{
            width: 300,
            height: (300 / 16) * 10,
            padding: 0,
            borderRadius: 1,
          }}
        />
      </Box>
    </Stack>
  );
};
