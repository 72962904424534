import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';

import {
  createLocation,
  deleteLocation,
  getLocation,
  getLocations,
  getLocationsByExperienceId,
  updateLocation,
} from '../../Api/Locations';
import { ILocationView } from '../../types/api/location';

export const QueryKeys = {
  locations: 'getLocations',
  location: 'getLocation',
};

export const useGetLocations = (
  companyId: string
): { locations: UseQueryResult<ILocationView[]> } => {
  const queryKey = [QueryKeys.locations, companyId];

  const locations = useQuery<ILocationView[]>(
    queryKey,
    () => getLocations(companyId),
    {
      enabled: !!companyId,
    }
  );

  return { locations };
};

export const useGetLocationsByExperienceId = (
  experienceId?: string
): { locations: UseQueryResult<ILocationView[]> } => {
  const queryKey = [QueryKeys.locations, experienceId];

  const locations = useQuery<ILocationView[]>(
    queryKey,
    () => getLocationsByExperienceId(experienceId!),
    {
      enabled: !!experienceId,
    }
  );

  return { locations };
};

export const useGetLocation = (
  locationId: string
): { location: UseQueryResult<ILocationView> } => {
  const queryKey = [QueryKeys.location, locationId];

  const location = useQuery<ILocationView>(
    queryKey,
    () => getLocation(locationId).then((data) => data.item),
    {
      enabled: !!locationId,
    }
  );

  return { location };
};

export const useCreateLocation = () => {
  const queryClient = useQueryClient();

  return useMutation(createLocation, {
    onMutate: async (newLocation) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(QueryKeys.locations);

      // Snapshot the previous value
      const previousLocations = queryClient.getQueryData<ILocationView[]>(
        QueryKeys.locations
      );

      // Optimistically update to the new value
      if (previousLocations) {
        queryClient.setQueryData<ILocationView[]>(
          QueryKeys.locations,
          (old) => [...(old ?? []), newLocation]
        );
      }

      // Return a context object with the snapshotted value
      return { previousLocations };
    },
    onSuccess: async (data, variables, context) => {
      // Invalidate and refetch
      await queryClient.invalidateQueries(QueryKeys.locations);
    },
    onError: (error, variables, context) => {
      // Rollback to the previous cache value
      if (context?.previousLocations) {
        queryClient.setQueryData<ILocationView[]>(
          QueryKeys.locations,
          context.previousLocations
        );
      }
    },
  });
};

// Implementation missing
export const useUpdateLocation = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const queryClient = useQueryClient();

  return useMutation(updateLocation, {
    onSuccess: () => {},
    onError: () => {},
    onSettled: () => {},
  });
};

// Implementation missing
export const useDeleteLocation = (locationId: string) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const queryClient = useQueryClient();

  return useMutation(() => deleteLocation(locationId), {
    onSuccess: () => {},
    onError: () => {},
    onSettled: () => {},
  });
};
