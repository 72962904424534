import { Text } from '@holdbar-com/pixel';
import { Stack } from '@mui/material';

import { useTranslate } from '../../../../../Hooks/useTranslate';
import { FlowTypeEnum } from '../../domain/types';
import { FlowTypeInfo } from './FlowTypeInfo';
import { FlowTypePreviewsLink } from './FlowTypePreviewsLink';
import { FlowTypeSelect } from './FlowTypeSelect';

export const FlowType = () => {
  const { t } = useTranslate('storefront.bookingflow');

  return (
    <Stack gap={3}>
      <Stack gap={2} direction={'row'}>
        <Text variant={'medium'}>{t('flowType.title')}</Text>
        <FlowTypePreviewsLink />
      </Stack>
      <Stack gap={2} direction={'row'}>
        {Object.values(FlowTypeEnum).map((type) => (
          <FlowTypeSelect key={type} type={type} />
        ))}
      </Stack>
      <FlowTypeInfo />
    </Stack>
  );
};
