import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Button, Text } from '@holdbar-com/pixel';
import { AutoAwesomeOutlined, CloseRounded } from '@mui/icons-material';
import { Dialog, IconButton, Stack } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';

import useResponsive from '../../../Hooks/layout/useResponsive';
import { useTranslate } from '../../../Hooks/useTranslate';
import { useGeneratingPreviewState } from '../../preview/generating-preview/use-generating-preview-state';
import { PreviewListLoader } from '../../preview/ui/PreviewListLoader';
import { ExperiencePreviewDialog } from './experience-preview-dialog';

const REDIRECT_DELAY_PREVIEW_MS = 1_000;

export const GeneratingExperienceDialog = NiceModal.create(
  ({
    previewId,
    startManualFlow,
    closeSetup,
  }: {
    previewId: string;
    startManualFlow: () => void;
    closeSetup: () => void;
  }) => {
    const modal = useModal();
    const { isSm } = useResponsive();
    const previewState = useGeneratingPreviewState(previewId);
    const [showWarning, setShowWarning] = useState(false);

    const closeDialog = useCallback(() => {
      modal.resolve();
      modal.remove();
    }, [modal]);

    const handleClose = () => {
      // Warn the user if the generation is in progress - allow closing if failed
      if (previewState.status !== 'failed') {
        setShowWarning(true);
      } else {
        closeDialog();
      }
    };

    const exitSetup = useCallback(() => {
      closeDialog();
      closeSetup();
    }, [closeDialog, closeSetup]);

    useEffect(() => {
      if (previewState.status === 'succeeded') {
        setTimeout(() => {
          NiceModal.show(ExperiencePreviewDialog, {
            experienceId: previewState.experienceId,
          });
          exitSetup();
        }, REDIRECT_DELAY_PREVIEW_MS);
      }
    }, [exitSetup, previewId, previewState.status]);

    const handleStartManualFlow = () => {
      handleClose();
      startManualFlow();
    };

    return (
      <Dialog
        open={modal.visible}
        onClose={handleClose}
        maxWidth="md"
        fullScreen={isSm}
        fullWidth
      >
        <Stack
          flexGrow={1}
          gap={2}
          sx={{ paddingY: { xs: 2, md: 6 }, paddingX: { xs: 2, md: 9 } }}
        >
          <DialogHeader />
          <IconButton
            sx={{
              padding: 0,
              position: 'absolute',
              top: '32px',
              right: '32px',
              zIndex: 1,
            }}
            onClick={() => handleClose()}
          >
            <CloseRounded
              sx={{
                fontSize: { xs: '24px', md: '32px' },
              }}
            />
          </IconButton>
          {previewState.status === 'initiated' && <PreviewListLoader />}
          {previewState.status === 'failed' && (
            <GenerationFailed
              tryAgain={handleClose}
              startManualFlow={handleStartManualFlow}
            />
          )}
        </Stack>
        <CloseGenerationWarning
          open={showWarning && previewState.status !== 'failed'}
          continueSetup={() => setShowWarning(false)}
          closeSetup={closeDialog}
        />
      </Dialog>
    );
  }
);

const DialogHeader = () => {
  const { t } = useTranslate('onboarding.ai.dialog');

  return (
    <Stack gap={2}>
      <Stack direction="row" gap={1} alignItems="baseline">
        <Text fontSize="xlarge" variant="medium">
          {t('title')}
        </Text>
        <AutoAwesomeOutlined />
      </Stack>
      <Text>{t('description')}</Text>
    </Stack>
  );
};

const GenerationFailed = ({
  tryAgain,
  startManualFlow,
}: {
  tryAgain: () => void;
  startManualFlow: () => void;
}) => {
  const { t } = useTranslate('onboarding.ai.dialog.error');

  return (
    <Stack gap={2}>
      <Text>{t('text')}</Text>
      <Stack
        sx={{ flexDirection: { xs: 'column-reverse', md: 'row' } }}
        gap={1}
        justifyContent="flex-end"
      >
        <Button variant="secondary" size="medium" onClick={startManualFlow}>
          {t('startManualFlow', 'onboarding.dialog.action.secondary')}
        </Button>
        <Button variant="primary" size="medium" onClick={tryAgain}>
          {t('confirmLabel', 'dialogs.errorRetry')}
        </Button>
      </Stack>
    </Stack>
  );
};

const CloseGenerationWarning = ({
  open,
  closeSetup,
  continueSetup,
}: {
  open: boolean;
  closeSetup: () => void;
  continueSetup: () => void;
}) => {
  const { t } = useTranslate('onboarding.ai.dialog.warning');

  return (
    <Dialog open={open} onClose={continueSetup}>
      <Stack
        sx={{ background: 'white' }}
        maxWidth="500px"
        padding={4}
        borderRadius={1}
      >
        <Text variant="medium">{t('title')}</Text>
        <Text>{t('description')}</Text>
        <Stack direction="row" marginTop={2} gap={1} justifyContent="flex-end">
          <Button
            variant="secondary"
            size="medium"
            type="button"
            onClick={closeSetup}
          >
            {t('confirmExit')}
          </Button>
          <Button
            variant="primary"
            size="medium"
            type="button"
            onClick={continueSetup}
          >
            {t('cancelExit')}
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};
