import { useMemo } from 'react';
import * as yup from 'yup';

import { useProducts } from '../../Hooks/useProducts';
import { useTranslate } from '../../Hooks/useTranslate';
import { getGuestCount } from '../../Utils/eventHelpers';

export const useVariantSelectValidation = (experienceId: string) => {
  const { t } = useTranslate('utils.errors');

  const { variants } = useProducts(experienceId);

  return useMemo(() => {
    return yup
      .object()
      .required(t('required'))
      .test((value, context) => {
        const totalGuestCount = getGuestCount(value);

        if (totalGuestCount === 0) {
          return context.createError({ message: t('required') });
        }

        const minimumGuestCount = Math.min(
          ...variants.map((variant) => variant.seatProps.min)
        );

        if (totalGuestCount < minimumGuestCount) {
          return context.createError({
            message: t(
              'minSeatsBookedSlots',
              'eventUpsert.flow.details.form.errors',
              {
                totalBookedSlots: minimumGuestCount,
              }
            ),
          });
        }

        return true;
      });
  }, [t, variants]);
};
