import { IconButton } from '@mui/material';

import { useShortcuts } from '../../../../../../Hooks/useShortcuts';
import { useTranslate } from '../../../../../../Hooks/useTranslate';
import { ShortcutTooltip } from '../../../../../tooltip/shortcut-tooltip';
import {
  navigationDirectionMap,
  navigationIconStyles,
} from '../../../domain/use_page_breadcrumb_navigation';

type PageBreadcrumbNavigationButtonProps = {
  direction: 'up' | 'down';
  bookingId: string;
  bookingsForEvent: string[];
  onNavigate: (index: number) => void;
};

export const PageBreadcrumbNavigationButton = ({
  direction,
  bookingId,
  bookingsForEvent,
  onNavigate,
}: PageBreadcrumbNavigationButtonProps) => {
  const { t } = useTranslate('components.pageBreadcrumb');

  const DirectionIcon = navigationDirectionMap.icon[direction];
  const directionKey = navigationDirectionMap.key[direction];
  const directionTitle = navigationDirectionMap.title[direction];

  const isDisabled =
    direction === 'up'
      ? bookingsForEvent.indexOf(bookingId) === 0
      : bookingsForEvent.indexOf(bookingId) === bookingsForEvent.length - 1;

  const handleClick = () => {
    const nextIndex =
      bookingsForEvent.indexOf(bookingId) + (direction === 'up' ? -1 : 1);
    onNavigate(nextIndex);
  };

  useShortcuts([
    {
      key: navigationDirectionMap.key[direction],
      action: () => !isDisabled && handleClick(),
    },
  ]);

  return (
    <ShortcutTooltip
      title={t(directionTitle)}
      shortcut={directionKey}
      hideCommandKey={true}
      hideTitle={false}
    >
      <span>
        <IconButton
          aria-label={directionTitle}
          disabled={isDisabled}
          sx={navigationIconStyles()}
          onClick={handleClick}
        >
          <DirectionIcon fontSize="small" />
        </IconButton>
      </span>
    </ShortcutTooltip>
  );
};
