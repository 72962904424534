import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Button, lightTheme, Text } from '@holdbar-com/pixel';
import { CloseRounded } from '@mui/icons-material';
import { Box, Dialog, Stack } from '@mui/material';

import useResponsive from '../../../../../Hooks/layout/useResponsive';
import { useTranslate } from '../../../../../Hooks/useTranslate';

interface AvailableIntegrationDialogProps {
  integration: {
    icon: string;
    title: string;
    description: string;
    extendedDescription: string;
    buttonLabel?: string;
  };
  onAddIntegration: () => void;
}

export const AvailableIntegrationDialog =
  NiceModal.create<AvailableIntegrationDialogProps>(
    ({ integration, onAddIntegration }) => {
      const { t } = useTranslate('settings.integrations');
      const { isSm } = useResponsive();
      const modal = useModal();

      const handleClose = () => {
        modal.reject();
        modal.hide();
      };

      return (
        <Dialog
          open={modal.visible}
          onClose={handleClose}
          fullWidth
          maxWidth={'sm'}
          fullScreen={isSm}
        >
          <Stack gap={4} p={4} height={'100%'} justifyContent={'space-between'}>
            <Stack gap={2}>
              <Stack direction={'row'} gap={2} justifyContent={'space-between'}>
                <Box
                  component="img"
                  src={integration.icon}
                  sx={{
                    width: 80,
                    height: 80,
                    borderRadius: 100,
                  }}
                />
                <CloseRounded
                  onClick={handleClose}
                  sx={{ cursor: 'pointer' }}
                />
              </Stack>
              <Stack gap={1}>
                <Text fontSize="xlarge" variant="medium">
                  {integration.title}
                </Text>
                <Text fontSize="small" color={lightTheme.palette.neutral.n400}>
                  {integration.extendedDescription}
                </Text>
              </Stack>
            </Stack>
            <Stack
              gap={isSm ? 1 : 2}
              width={'100%'}
              direction={isSm ? 'column-reverse' : 'row'}
            >
              <Button
                variant="secondary"
                size="medium"
                onClick={handleClose}
                style={{ flexGrow: 1, maxWidth: isSm ? '100%' : '50%' }}
              >
                {t('cancel', 'utils.generic')}
              </Button>
              <Button
                variant="primary"
                size="medium"
                type="submit"
                style={{ flexGrow: 1, maxWidth: isSm ? '100%' : '50%' }}
                onClick={() => {
                  onAddIntegration();
                  handleClose();
                }}
              >
                {integration.buttonLabel || t('getStarted', 'utils.generic')}
              </Button>
            </Stack>
          </Stack>
        </Dialog>
      );
    }
  );
