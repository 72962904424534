import { Text } from '@holdbar-com/pixel';
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

import useResponsive from '../../../../Hooks/layout/useResponsive';
import { useEconomicJournals } from '../../data/use-economic-journals';

export const EconomicConfigurationJournal = () => {
  const journals = useEconomicJournals();
  const { control } = useFormContext();
  const { isSm } = useResponsive();

  return (
    <Controller
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          onChange={(_, value) => {
            field.onChange(value?.journalNumber);
          }}
          value={
            journals.data?.find(
              (journal) => journal.journalNumber === field.value
            ) ?? null
          }
          options={journals.data || []}
          renderInput={(params: AutocompleteRenderInputParams) => (
            <TextField {...params} label="Journal" error={!!error} />
          )}
          getOptionLabel={(option) => option.name}
          getOptionKey={(option) => option.journalNumber}
          renderOption={({ key, ...props }, option) => (
            <Box component="li" {...props}>
              <Stack>
                <Text>{option.name}</Text>
                <Typography variant={'body2'}>
                  No.: {option.journalNumber}
                </Typography>
              </Stack>
            </Box>
          )}
          sx={{ width: isSm ? '100%' : 300 }}
        />
      )}
      control={control}
      name={'journal'}
    />
  );
};
