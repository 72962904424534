import { CountryVatRates } from '../Sections/SettingsSections/Company/domain/types';
import axios from './index';

export const getCountryVatRates = async () => {
  if (typeof process.env.REACT_APP_INTERNAL_API_GATEWAY === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_INTERNAL_API_GATEWAY is not defined'
    );
  }

  const { data } = await axios.get<CountryVatRates>(
    process.env.REACT_APP_INTERNAL_API_GATEWAY + '/v1/tax-rates'
  );

  return data;
};
