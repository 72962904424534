import { Experience } from '@holdbar-com/utils-types';

import { ampli } from '../../../../Ampli';
import { TEvent } from '../../../../Utils/eventHelpers';
import { eventProperties } from './eventProperties';

export const trackExperienceDetailsPageOpened = (
  experience: Experience,
  events: TEvent[]
) => {
  const properties = eventProperties(experience, events);

  ampli.experienceDetailsPageOpened(properties);
};
