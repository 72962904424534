import { Text } from "@holdbar-com/pixel";
import { CloseRounded } from "@mui/icons-material";
import { Stack } from '@mui/material';

import { useTranslate } from "../../../../Hooks/useTranslate";
import { CompanyFormVatRegistrationCategory } from "../../../../Sections/SettingsSections/Company/ui/form/VatRegistration/CompanyFormVatRegistrationCategory";
import { CompanyFormVatRegistrationCountry } from "../../../../Sections/SettingsSections/Company/ui/form/VatRegistration/CompanyFormVatRegistrationCountry";
import { CompanyFormVatRegistrationNumber } from "../../../../Sections/SettingsSections/Company/ui/form/VatRegistration/CompanyFormVatRegistrationNumber";
import { VatFormActions } from "./VatFormActions";

export const VatForm = ({
  onSubmit,
  onClose,
}: {
  onSubmit: () => void;
  onClose: () => void;
}) => {
  const { t } = useTranslate('dialogs.vatForm');

  return (
    <form onSubmit={onSubmit}>
      <Stack gap={3} p={4}>
        <CloseRounded
          onClick={onClose}
          sx={{
            cursor: 'pointer',
          }}
        />
        <Stack gap={2}>
          <Text fontSize={'xlarge'} variant="medium">
            {t('title')}
          </Text>
          <Text>{t('description')}</Text>
          <Stack gap={2}>
            <CompanyFormVatRegistrationCountry />
            <CompanyFormVatRegistrationNumber />
            <CompanyFormVatRegistrationCategory />
          </Stack>
        </Stack>
        <VatFormActions onClose={onClose} />
      </Stack>
    </form>
  );
};
