import { Text } from '@holdbar-com/pixel';
import { useNavigate } from 'react-router';

import useResponsive from '../../../Hooks/layout/useResponsive';
import { useTranslate } from '../../../Hooks/useTranslate';
import { DialogWrapper } from './dialog-wrapper';

export function ErrorElement() {
  const { isSm } = useResponsive();
  const navigate = useNavigate();
  const { t } = useTranslate('dialogs.errorDialog');

  return (
    <DialogWrapper
      fullWidth
      maxWidth="sm"
      fullScreen={isSm}
      open={true}
      onClose={() => navigate('/settings/connect')}
      title={t('errorTitle')}
    >
      <Text variant="normal" fontSize="small">
        {t('errorSubtitle')}
      </Text>
    </DialogWrapper>
  );
}
