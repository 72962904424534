import { useProfile } from '../../../Hooks/useProfile';
import { useTranslate } from '../../../Hooks/useTranslate';
import { useCalendar } from '../context';
import { Filter } from './view/Filter';

export const LanguagesFilter = () => {
  const { t } = useTranslate('');

  const { languageFilters, setLanguageFilters } = useCalendar();

  const {
    company: { data, isLoading },
  } = useProfile();

  const options =
    data?.languages?.map((language) => ({
      id: language,
      name: t(`languages.${language}`, 'utils'),
    })) ?? [];

  return (
    <Filter
      inputId="calendar-languages-filter"
      label={t('languages', 'utils.generic')}
      options={options}
      checkedIds={languageFilters}
      onChange={setLanguageFilters}
      isLoading={isLoading}
      sortOptions={(a, b) => a.name.localeCompare(b.name)}
      includeEmptyOption
    />
  );
};
