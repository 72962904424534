import { Link } from "@mui/material";

import { LanguageSelect } from "../../../../Components/LanguageSelect/LanguageSelect";
import { useTranslate } from "../../../../Hooks/useTranslate";
import { SyiSection } from "../../../../Pages/SyiPage/SyiSection";

export const SettingsProfileSectionAdvanced = () => {
  const { t } = useTranslate('settings.profile');

  return (
    <SyiSection title={t('advancedSettings.title')}>
      <LanguageSelect
        renderComp={(onClick) => (
          <Link
            fontSize={'0.88em'}
            onClick={onClick as any}
            sx={{
              cursor: 'pointer',
              '&:hover': {
                textDecoration: 'underline',
              },
            }}
          >
            {t('advancedSettings.changeLanguage')}
          </Link>
        )}
      />
    </SyiSection>
  );
};
