import NiceModal from '@ebay/nice-modal-react';

import { TranslateFunction } from '../../../../Hooks/useTranslate';
import { ConfirmDialog } from '../../../../Modals/ConfirmDialog';

export const showEventDeletionErrorDialog = (t: TranslateFunction) => {
  NiceModal.show(ConfirmDialog, {
    allowCancel: false,
    title: t('title', 'dialogs.deleteEventWithBookings'),
    description: t('description', 'dialogs.deleteEventWithBookings'),
  });
};
