import { lightTheme, Text } from '@holdbar-com/pixel';
import { renderTime } from '@holdbar-com/utils-date';
import { Skeleton, Stack, styled } from '@mui/material';
import { isSameDay } from 'date-fns';
import { FC, useMemo } from 'react';

import { OneLineText } from '../../../../../Components/OneLineText/OneLineText';
import { TEventWithTitle } from '../../../../../Utils/eventHelpers';
import { useCalendar } from '../../../context';
import { EventStatus } from '../../../shared/EventStatus';
import { GuestCountText } from '../../../shared/GuestCountText';
import { StrikeOutText } from '../../../shared/StrikeOutText';
import { useEventPopover } from '../../event-popover/event-popover-context';

type Props = {
  date: Date;
  events?: TEventWithTitle[];
  grouped?: boolean;
};

export const EventList: FC<Props> = ({ date, events, grouped }) => {
  const { isLoading } = useCalendar();
  const { handleSelectEvent } = useEventPopover();

  const eventsForDate = useMemo(
    () =>
      events?.filter((event) => isSameDay(new Date(event.startDateTime), date)),
    [events, date]
  );

  if (isLoading) {
    return (
      <GridList $grouped={grouped}>
        {Array.from({ length: (date.getDay() % 3) + 1 }).map((_, i) => (
          <EventBox key={i} gap={1} $color={lightTheme.palette.neutral.n50}>
            <Skeleton height={19.2} style={{ maxWidth: 100 }} />
            {!grouped && <Skeleton height={19.2} style={{ maxWidth: 150 }} />}
            <Skeleton height={19.2} style={{ maxWidth: 60 }} />
          </EventBox>
        ))}
      </GridList>
    );
  }

  return (
    <GridList $grouped={grouped}>
      {eventsForDate?.map((event) => {
        const strikeOut =
          event.status === 'cancelled' || event.status === 'inactive';
        const PossiblyStrikeOutText = strikeOut ? StrikeOutText : Text;

        return (
          <EventBox
            key={event.id}
            $color={event.colors.backgroundColor}
            onClick={(e) => handleSelectEvent(event, e)}
          >
            <EventStatus event={event} />
            <PossiblyStrikeOutText
              variant={grouped ? 'medium' : 'normal'}
              fontSize="xsmall"
            >
              {renderTime(
                event.startDateTime,
                event.endDateTime ?? event.startDateTime
              )}
            </PossiblyStrikeOutText>
            {!grouped && (
              <OneLineText
                as={PossiblyStrikeOutText}
                variant="medium"
                fontSize="xsmall"
              >
                {event.title}
              </OneLineText>
            )}
            <GuestCountText
              {...event.slots}
              eventVisibility={event.visibility}
              fontSize="xsmall"
              variant="normal"
              as={PossiblyStrikeOutText}
            />
          </EventBox>
        );
      })}
    </GridList>
  );
};

const EventBox = styled(Stack)<{ $color: string }>`
  padding: 4px 8px 8px;

  letter-spacing: 0.8px;

  border-radius: 8px;

  background-color: ${({ $color }) => $color};

  &:hover {
    cursor: pointer;
  }
`;

const GridList = styled(Stack)<{
  $grouped?: boolean;
}>`
  min-height: ${({ $grouped }) => ($grouped ? '160px' : '60vh')};

  padding: 4px;
  gap: 4px;
`;
