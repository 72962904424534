import { Text } from '@holdbar-com/pixel';
import { List, ListItem, Stack } from '@mui/material';

import { useTranslate } from '../../../Hooks/useTranslate';

export const StartManualStep = () => {
  const { t } = useTranslate('onboarding.dialog.step.startManual');

  return (
    <Stack gap={1} marginBottom={4}>
      <Text variant="medium" fontSize="xlarge">
        {t('title')}
      </Text>
      <Stack gap={2}>
        <Text>{t('description')}</Text>
        <Stack gap={1}>
          <Text>{t('list.title')}</Text>
          <List sx={{ listStyleType: 'disc', pl: 3, py: 0 }}>
            {Array(4)
              .fill(undefined)
              .map((_, i) => (
                <ListItem key={i} sx={{ display: 'list-item', pl: 0, pt: 0 }}>
                  <Text>{t(`list.${i}.text`)}</Text>
                </ListItem>
              ))}
          </List>
        </Stack>
      </Stack>
    </Stack>
  );
};
