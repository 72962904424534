import { toast } from 'react-toastify';

import { sendSlackNotification } from '../../../../Api/Notifications';
import { useProfile } from '../../../../Hooks/useProfile';
import { useTranslate } from '../../../../Hooks/useTranslate';

export const useIntegrationRequest = (integration: string) => {
  const { t } = useTranslate('opportunities.request');

  const {
    me: { data: me },
    company: { data: company },
  } = useProfile();

  const handleIntegrationRequest = async () => {
    try {
      toast.loading(t('loading'), { toastId: 'requesting' });

      const message = `New product integration requested:\n>Integration: ${integration}\n>Name: ${me?.name}\n>Email: ${me?.email}\n>Company: ${company?.name}`;
      await sendSlackNotification(message, '#product-integration-requests');

      toast.dismiss('requesting');
      toast.success(t('success'));
    } catch {
      toast.dismiss('requesting');
      toast.error(t('error'));
    }
  };

  return { handleIntegrationRequest };
};
