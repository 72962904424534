import { Experience } from '@holdbar-com/utils-types';

import { ampli } from '../../Ampli';
import { getLocalized } from '../../Hooks/useBookings';

export const trackOverviewExperienceFilterSelected = (
  selected_experience: string,
  experiences?: Experience[]
) => {
  const experienceIds = experiences?.map((experience) => experience.id) ?? [];
  const experienceHeadlines =
    experiences?.map(
      (experience) => getLocalized(experience.headline, 'en') ?? ''
    ) ?? [];
  ampli.overviewExperienceFilterSelected({
    list_of_experience_ids: experienceIds,
    list_of_experiences: experienceHeadlines,
    selected_experience,
  });
};
