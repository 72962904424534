import { FC, useMemo } from 'react';

import { IExperience } from '../../../../Hooks/useExperience';
import { TEventWithTitle } from '../../../../Utils/eventHelpers';
import { GridExperienceCell } from '../calendar-grid/GridExperienceCell';
import { GroupedEventList } from './GroupedEventList';

type Props = {
  dates: Array<Date>;
  experience: IExperience;
  events: Array<TEventWithTitle>;
};

export const GroupedRow: FC<Props> = ({ dates, experience, events }) => {
  const eventsForExperience = useMemo(
    () => events.filter((event) => event.experienceId === experience.id),
    [events, experience.id]
  );

  return (
    <>
      <GridExperienceCell experience={experience} />
      {dates.map((date) => (
        <GroupedEventList
          key={date.toISOString()}
          events={eventsForExperience}
          date={date}
        />
      ))}
    </>
  );
};
