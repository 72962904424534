import { countries } from '@holdbar-com/utils-countries';
import { Select, SelectProps, Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { startTransition } from 'react';

import { useTranslate } from '../../Hooks/useTranslate';

export const CountrySelect = ({
  sx = {},
  value,
  onChange,
  ...props
}: SelectProps) => {
  const { t } = useTranslate('countryCodes');

  const sortedCountries = Object.entries(countries).sort((a, b) =>
    a[1].name.localeCompare(b[1].name)
  );

  return (
    <Select
      onChange={(...params) => {
        startTransition(() => {
          onChange?.(...params);
        });
      }}
      inputProps={{
        sx: {
          display: 'flex',
          alignItems: 'center',
        },
      }}
      MenuProps={{
        sx: {
          maxHeight: 400,
          ...sx,
        },
      }}
      {...props}
      value={value}
      sx={{ ...sx, maxHeight: 56 }}
    >
      {sortedCountries.map(([code, { name, emoji }]) => (
        <MenuItem value={code} key={code}>
          <Typography mr={1} fontSize={'2em'}>
            {emoji}
          </Typography>
          <Typography>{t(code) === code ? name : t(code)}</Typography>
        </MenuItem>
      ))}
    </Select>
  );
};
