import styled from '@emotion/styled';
import { Button, lightTheme, Text } from '@holdbar-com/pixel';
import { Experience } from '@holdbar-com/utils-types';
import {
  KeyboardArrowDownRounded,
  KeyboardArrowUpRounded,
  MoreVertRounded,
} from '@mui/icons-material';
import { Card, Stack } from '@mui/material';
import { Popover } from '@radix-ui/themes';
import { useCallback, useMemo, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import { getLocalized } from '../../../../Hooks/useBookings';
import { useTranslate } from '../../../../Hooks/useTranslate';
import { StorefrontCustomizeFormData } from '../styling/storefront-styling-validation';
import { SelectSortBy } from './select-sort-by';

export const ExperiencesList = ({
  experiences,
}: {
  experiences: Experience[];
}) => {
  const { t, i18n } = useTranslate('storefront.experiences');
  const [popoverId, setPopoverId] = useState<string | undefined>();
  const { resetField, control } = useFormContext<StorefrontCustomizeFormData>();

  const {
    field: { value: currentOrder, onChange: updateOrder },
  } = useController<StorefrontCustomizeFormData, 'sortOrder'>({
    name: 'sortOrder',
    control,
  });

  const {
    field: { value: sortBy, onChange: updateSortBy },
  } = useController<StorefrontCustomizeFormData, 'sortExperiencesBy'>({
    name: 'sortExperiencesBy',
    control,
  });

  const handleMoveUp = useCallback(
    (index: number) => {
      if (index <= 0) return;

      const temp = [...currentOrder];
      const experienceToMove = temp[index];
      temp[index] = temp[index - 1];
      temp[index - 1] = experienceToMove;

      updateOrder(temp);
    },
    [currentOrder, updateOrder]
  );

  const handleMoveDown = useCallback(
    (index: number) => {
      if (index >= currentOrder.length - 1) return;

      const temp = [...currentOrder];
      const experienceToMove = temp[index];
      temp[index] = temp[index + 1];
      temp[index + 1] = experienceToMove;

      updateOrder(temp);
    },
    [currentOrder, updateOrder]
  );

  const handleMoveToTop = useCallback(
    (index: number) => {
      if (index <= 0) return;

      const temp = [...currentOrder];
      const experienceToMove = temp[index];
      temp.splice(index, 1);

      updateOrder([experienceToMove, ...temp]);

      setPopoverId(undefined);
    },
    [currentOrder, updateOrder]
  );

  const handleMoveToBottom = useCallback(
    (index: number) => {
      if (index >= currentOrder.length - 1) return;

      const temp = [...currentOrder];
      const experienceToMove = temp[index];
      temp.splice(index, 1);

      updateOrder([...temp, experienceToMove]);

      setPopoverId(undefined);
    },
    [currentOrder, updateOrder]
  );

  const experienceMap = useMemo(
    () =>
      experiences.reduce(
        (experiences, experience) => {
          return {
            ...experiences,
            [experience.id]: experience,
          };
        },
        {} as Record<string, Experience>
      ),
    [experiences]
  );

  return (
    <Stack marginTop={2} gap={2}>
      <SelectSortBy
        value={sortBy}
        onChange={(selected) => {
          updateSortBy(selected);
          resetField('sortOrder');
        }}
      />
      {sortBy === 'sortOrder' && (
        <Stack gap={1} flexGrow={1}>
          {currentOrder.map((experienceId, index) => {
            const experience = experienceMap[experienceId];

            if (!experience) return null;

            return (
              <Stack
                key={experience.id}
                component={Card}
                alignItems="center"
                direction="row"
                padding={1}
                justifyContent="space-between"
                gap={3}
                boxShadow="none"
                border={`1px solid ${lightTheme.palette.neutral.n100}`}
                borderRadius="8px"
                maxWidth="640px"
              >
                <Stack alignItems="center" direction="row" gap={3}>
                  <Stack flexShrink={0} gap={0.5}>
                    <StyledButton
                      type="button"
                      size="medium"
                      variant="secondary"
                      onClick={() => handleMoveUp(index)}
                      disabled={index === 0}
                    >
                      <KeyboardArrowUpRounded />
                    </StyledButton>
                    <StyledButton
                      type="button"
                      size="medium"
                      variant="secondary"
                      onClick={() => handleMoveDown(index)}
                      disabled={index >= currentOrder.length - 1}
                    >
                      <KeyboardArrowDownRounded />
                    </StyledButton>
                  </Stack>
                  <ClampedText fontSize="xsmall">
                    {getLocalized(experience.headline, i18n.language)}
                  </ClampedText>
                </Stack>

                <Popover.Root
                  open={popoverId === experience.id}
                  onOpenChange={(open) =>
                    setPopoverId(open ? experience.id : undefined)
                  }
                >
                  <Popover.Trigger>
                    <ContextButton variant="text" size="medium">
                      <MoreVertRounded />
                    </ContextButton>
                  </Popover.Trigger>
                  <Popover.Content side="left" align="center">
                    <Stack component={Card} paddingX={0} paddingY={1}>
                      <ContextMenuButton
                        variant="text"
                        size="medium"
                        disabled={index === 0}
                        onClick={() => handleMoveToTop(index)}
                      >
                        {t('moveToTop')}
                      </ContextMenuButton>
                      <ContextMenuButton
                        variant="text"
                        size="medium"
                        disabled={index >= currentOrder.length - 1}
                        onClick={() => handleMoveToBottom(index)}
                      >
                        {t('moveToBottom')}
                      </ContextMenuButton>
                    </Stack>
                  </Popover.Content>
                </Popover.Root>
              </Stack>
            );
          })}
        </Stack>
      )}
    </Stack>
  );
};

const ContextButton = styled(Button)({
  marginRight: '8px',
  height: '24px',
  width: '24px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: 'none',
  border: 'none',
  cursor: 'pointer',
  transition: 'opacity 0.1s ease-in-out',
  '&:hover': {
    opacity: 0.75,
  },
  '& > span': {
    display: 'flex',
  },
});

const ContextMenuButton = styled(Button)({
  fontSize: '14px',
  padding: '8px 16px',
  justifyContent: 'flex-start',
  background: 'none',
  border: 'none',
  fontWeight: 400,
  cursor: 'pointer',
  transition: 'background 0.1s ease-in-out',
  '&:not([disabled]):hover': {
    background: lightTheme.palette.neutral.n50,
  },
});

const StyledButton = styled(Button)({
  height: '24px',
  borderRadius: '4px',
  aspectRatio: 1,
  padding: 0,
  span: {
    display: 'flex',
    justifyContent: 'center',
  },
});

const ClampedText = styled(Text)`
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;
