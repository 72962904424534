import { Stack } from '@mui/material';

import { EconomicConfigurationSectionTitle } from '../economic-configuration-section-title';

type EconomicConfigurationAccountsSectionProps = {
  title: string;
  description: string;
  appendedNode?: React.ReactNode;
  children: React.ReactNode;
};

export const EconomicConfigurationAccountsSection = ({
  title,
  description,
  appendedNode,
  children,
}: EconomicConfigurationAccountsSectionProps) => {
  return (
    <Stack gap={2.5}>
      <EconomicConfigurationSectionTitle
        title={title}
        description={description}
      />
      <Stack direction={'row'} gap={2} flexWrap={'wrap'}>
        {children}
      </Stack>
      {appendedNode}
    </Stack>
  );
};
