import { useFlags } from 'launchdarkly-react-client-sdk';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { disconnectEconomic, initEconomicSetup } from '../../../../../Api';
import { useOnBoarding } from '../../../../../Hooks/useOnBoarding';
import { useTranslate } from '../../../../../Hooks/useTranslate';
import { IntegrationObject } from '../use-integrations';

export const useEconomicIntegration = (): IntegrationObject | null => {
  const { t } = useTranslate('settings.integrations');
  const flags = useFlags();

  const { onBoarding } = useOnBoarding();
  const navigate = useNavigate();

  const { economic } = useMemo(() => {
    const ec = onBoarding.data?.response['economic']?.find(
      (el) => el.key === 'setup'
    );
    return {
      economic: ec,
    };
  }, [onBoarding.data]);

  const handleEconomic = (status: string) => async () => {
    if (status === 'connected') {
      navigate('/settings/integrations/economic');
    } else {
      const { url } = await initEconomicSetup();
      window.open(url, '_blank');
    }
  };

  const handleDisconnectEconomic = async () => {
    toast.loading(t('removingIntegration'), {
      toastId: 'disconnectEconomic',
    });
    await disconnectEconomic();
    toast.dismiss('disconnectEconomic');
    toast.success(t('integrationRemoved'));
    onBoarding.refetch();
  };

  if (!flags?.featureEconomicIntegration) return null;

  return {
    id: 'economic',
    title: t('economic.title'),
    description: t('economic.description'),
    extendedDescription: t('economic.extendedDescription'),
    warnings: (economic?.metadata?.requirements ?? []).map((el) =>
      t(`economic.requirements.${el}`)
    ),
    status: economic?.metadata?.status ?? 'connect',
    icon: '/integrations/e-conomic.png',
    buttonLabel: (status: string) => t(`economic.${status}`),
    onInstall: handleEconomic,
    onUninstall: handleDisconnectEconomic,
  };
};
