import NiceModal from '@ebay/nice-modal-react';
import { Button, lightTheme, Text } from '@holdbar-com/pixel';
import { Box, Card, Stack } from '@mui/material';
import { FC } from 'react';

import { useTranslate } from '../../../../Hooks/useTranslate';
import { Opportunity } from '../domain/types';
import { useOpportunityInterest } from '../domain/useOpportunityInterest';
import { OpportunityDialog } from './opportunity-dialog';

type OpportunityCardProps = {
  opportunity: Opportunity;
};

export const OpportunityCard: FC<OpportunityCardProps> = ({ opportunity }) => {
  const { t } = useTranslate('opportunities');

  const { handleInterest } = useOpportunityInterest(opportunity);

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
        padding: 3,
      }}
    >
      <Stack direction="row" gap={2} flexGrow={1}>
        <Box
          sx={{
            minWidth: 50,
            minHeight: 50,
            width: 50,
            height: 50,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundImage: `url(${opportunity.icon})`,
          }}
        />
        <Stack gap={1}>
          <Stack direction="row" gap={1} alignItems="center">
            <Text fontSize="medium" variant="medium">
              {opportunity.title}
            </Text>
            {opportunity.isFree && (
              <Text fontSize="small" color={lightTheme.palette.neutral.n300}>
                {t('free', 'utils.generic')}
              </Text>
            )}
          </Stack>
          <Text fontSize="xsmall">{t(`${opportunity.id}.description`)}</Text>
        </Stack>
      </Stack>

      <Stack gap={1} direction="row" justifyContent="end">
        <Button
          size="medium"
          variant="secondary"
          onClick={() => {
            NiceModal.show(OpportunityDialog, {
              opportunity,
              onInterest: handleInterest,
            });
          }}
        >
          {t('readmore', 'utils.generic')}
        </Button>
        <Button size="medium" variant="primary" onClick={handleInterest}>
          {t('actions.interested')}
        </Button>
      </Stack>
    </Card>
  );
};
