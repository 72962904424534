import styled from '@emotion/styled';
import { lightTheme, Text } from '@holdbar-com/pixel';
import { HelpOutlineOutlined } from '@mui/icons-material';
import { Box, Stack, TextField } from '@mui/material';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { Tooltip } from '../../../Components/tooltip/tooltip';
import { useProfile } from '../../../Hooks/useProfile';
import { useTranslate } from '../../../Hooks/useTranslate';
import {
  trackQuickSetupPriceStepTicketNameFieldOpened,
  trackQuickSetupPriceStepTicketPriceFieldOpened,
} from '../../../tracking/quick-setup/step-events';
import { OnboardingFormInputs } from '../onboarding-flow-form';
import { OnboardingStepLayout } from './step-layout';

export const PriceStep = () => {
  const { t } = useTranslate('onboarding.dialog.step.price.input');

  const { company } = useProfile();

  const currency = useMemo(
    () => company.data?.defaultCurrency ?? 'DKK',
    [company.data]
  );

  const {
    register,
    formState: {
      errors: { tickets: ticketError },
    },
  } = useFormContext<OnboardingFormInputs>();

  return (
    <OnboardingStepLayout showPreview>
      <Stack direction="row" gap={1}>
        <TextField
          {...register('tickets.0.name', {
            required: { value: true, message: t('name.requiredMessage') },
          })}
          label={t('name.label')}
          placeholder={t('name.placeholder')}
          defaultValue={t('name.placeholder')}
          InputLabelProps={{ shrink: true }}
          error={!!ticketError?.[0]?.name}
          helperText={ticketError?.[0]?.name?.message}
          onFocus={trackQuickSetupPriceStepTicketNameFieldOpened}
          required
          fullWidth
          sx={{ maxWidth: 230 }}
        />
        <Box position="relative">
          <TextField
            {...register('tickets.0.price', {
              required: { value: true, message: t('price.requiredMessage') },
              min: { value: 0, message: t('price.minMessage') },
            })}
            label={t('price.label')}
            type="number"
            placeholder={t('price.placeholder')}
            defaultValue={parseInt(t('price.placeholder'), 10)}
            InputLabelProps={{ shrink: true }}
            error={!!ticketError?.[0]?.price}
            helperText={ticketError?.[0]?.price?.message}
            sx={{ minWidth: 130, maxWidth: 150 }}
            InputProps={{ sx: { paddingRight: 7 } }}
            onFocus={trackQuickSetupPriceStepTicketPriceFieldOpened}
            required
          />
          <CurrencyLabel>
            {currency.toUpperCase()}
            <Tooltip
              title={t('price.tooltip')}
              sx={{ display: { xs: 'none', sm: 'unset' } }}
            >
              <HelpOutlineOutlined fontSize="inherit" />
            </Tooltip>
          </CurrencyLabel>
        </Box>
      </Stack>
    </OnboardingStepLayout>
  );
};

const CurrencyLabel = styled(Text)({
  display: 'flex',
  alignItems: 'center',
  gap: 4,
  position: 'absolute',
  right: 16,
  top: 14,
  color: lightTheme.palette.neutral.n300,
  fontSize: '16px',
  svg: {
    marginBottom: 1,
  },
});
