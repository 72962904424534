import { useQuery, useQueryClient } from 'react-query';

import { getEconomicAccounts } from '../../../Api';
import { useProfile } from '../../../Hooks/useProfile';
import { EconomicAccountsQueryKey } from './economic-query-keys';

export const useEconomicAccounts = () => {
  const { company } = useProfile();
  const queryClient = useQueryClient();

  return useQuery(
    EconomicAccountsQueryKey,
    async () => {
      await queryClient.cancelQueries(EconomicAccountsQueryKey);
      const accounts = await getEconomicAccounts();

      return accounts.items.filter((account) =>
        ['status', 'profitAndLoss'].includes(account.accountType)
      );
    },
    {
      enabled: !!company.data?.id,
    }
  );
};
