import { Switch } from '@mui/material';
import { FC } from 'react';
import { useController } from 'react-hook-form';

import { useProfile } from '../../../../Hooks/useProfile';
import { useFeatureSetting } from '../context/feature_setting_context';

type Props = {
  onToggle?: (enabled: boolean) => void;
};

export const FeatureEnabledSwitch: FC<Props> = ({ onToggle }) => {
  const {
    company: { data: company },
  } = useProfile();

  const { control, save, feature } = useFeatureSetting();

  const enabled = company?.features?.[feature]?.enabled;
  const { field } = useController({
    name: 'enabled',
    control,
    defaultValue: enabled,
  });

  return (
    <Switch
      {...field}
      onChange={(_e, newValue) => {
        field.onChange(newValue);
        onToggle?.(newValue);
        save();
      }}
      checked={enabled}
    />
  );
};
