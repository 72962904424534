import { MenuItem, Select, Stack, TextField } from '@mui/material';
import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { useTranslate } from '../../../../Hooks/useTranslate';
import { CreateBookingFormInputs } from '../create-booking-form';

type CustomerInformationProps = {
  languages: string[];
};

export const CustomerInformation: FC<CustomerInformationProps> = ({
  languages,
}) => {
  const { t } = useTranslate('dialogs.createBooking');

  const {
    register,
    formState: { errors },
  } = useFormContext<CreateBookingFormInputs>();

  return (
    <Stack gap={3}>
      <TextField
        {...register('customer.name', {
          required: t('required', 'utils.errors'),
        })}
        label={t('name', 'utils.generic')}
        fullWidth
        error={Boolean(errors.customer?.name?.message)}
        helperText={errors.customer?.name?.message}
        required
      />
      <TextField
        {...register('customer.email', {
          required: t('required', 'utils.errors'),
          pattern: {
            value: /^[a-zA-Z0-9+._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
            message: t('invalidEmail', 'auth.errors'),
          },
        })}
        type="email"
        label={t('email', 'utils.generic')}
        fullWidth
        error={Boolean(errors.customer?.email?.message)}
        helperText={errors.customer?.email?.message}
        required
      />
      <TextField
        {...register('customer.phone')}
        label={t('phone', 'utils.generic')}
        fullWidth
      />
      <Controller
        name="language"
        render={({ field }) => (
          <Select
            {...field}
            fullWidth
            error={Boolean(errors?.language)}
            required
          >
            {languages.map((language) => (
              <MenuItem key={language} value={language}>
                {t(language, 'utils.languages')}
              </MenuItem>
            ))}
          </Select>
        )}
      />
    </Stack>
  );
};
