import { lightTheme, Text } from '@holdbar-com/pixel';
import { CircularProgress, Stack } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { finalizeEconomicSetup } from '../../Api';
import { useFireOnce } from '../../Hooks/useFireOnce';
import { useOnBoarding } from '../../Hooks/useOnBoarding';
import { useTranslate } from '../../Hooks/useTranslate';

export const EconomicFinalizeConnectionPage = () => {
  const { t } = useTranslate('settings.integrations.economic.finalize');
  const [searchParams] = useSearchParams();
  const { onBoarding } = useOnBoarding();
  const navigate = useNavigate();
  const fireOnce = useFireOnce();

  useEffect(() => {
    const token = searchParams.get('token');
    if (!token) {
      navigate('/settings/integrations');
      return;
    }

    fireOnce(async () => {
      try {
        await finalizeEconomicSetup(token);
        onBoarding.refetch();

        toast.success(t('success'));
        navigate('/settings/integrations/economic');
      } catch (e) {
        console.error(e);
        toast.error(t('error'));
        navigate('/settings/integrations');
      }
    });
  }, [searchParams, onBoarding, t, navigate, fireOnce]);

  return (
    <Stack
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}
    >
      <Stack
        gap={2}
        sx={{
          alignItems: 'center',
        }}
      >
        <CircularProgress sx={{ color: lightTheme.palette.neutral.n500 }} />
        <Text>{t('preparing')}</Text>
      </Stack>
    </Stack>
  );
};
