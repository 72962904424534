import { InfoBox } from '../../../../../../../Components/InfoBox/InfoBox';
import useResponsive from '../../../../../../../Hooks/layout/useResponsive';
import { useTranslate } from '../../../../../../../Hooks/useTranslate';
import { useBookingDetailsContext } from '../../../../domain/use_booking_details_context';
import { BookingDetailsSummaryPaymentActions } from './ui/BookingDetailsSummaryPaymentActions';
import { BookingDetailsSummaryPaymentMobile } from './ui/BookingDetailsSummaryPaymentMobile';
import { BookingDetailsSummaryPaymentTable } from './ui/BookingDetailsSummaryPaymentTable';

export const BookingDetailsSummaryPayment = () => {
  const { t } = useTranslate('dialogs.booking');
  const { booking } = useBookingDetailsContext();

  const { isSm } = useResponsive();

  if (!booking) return null;

  if (isSm) {
    return <BookingDetailsSummaryPaymentMobile />;
  }

  return (
    <InfoBox
      title={t('sections.payment')}
      actions={<BookingDetailsSummaryPaymentActions />}
    >
      <BookingDetailsSummaryPaymentTable />
    </InfoBox>
  );
};
