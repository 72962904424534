import { ampli } from '../../../Ampli';
import { TBooking } from '../../../Hooks/useBookings';
import { eventProperties, transformToUserProperties } from './eventProperties';

export const trackBookingsPageViewed = (
  bookings: TBooking[],
  userId?: string
) => {
  const properties = eventProperties(bookings);
  const userProperties = transformToUserProperties(properties);

  if (userId) ampli.identify(userId, userProperties);
  return ampli.bookingsPageViewed(properties);
};
