import { Box, Stack } from '@mui/material';

import { EventUpsertTimeFormAddIntervalButton } from './EventUpsertTimeFormAddIntervalButton';
import { EventUpsertTimeFormDateSection } from './EventUpsertTimeFormDateSection';
import { EventUpsertTimeFormIntervalList } from './EventUpsertTimeFormIntervalList';
import { EventUpsertTimeFormRecurringSection } from './recurring/EventUpsertTimeFormRecurringSection';

export const EventUpsertTimeForm = () => {
  return (
    <Box component="form">
      <Stack gap={5} maxWidth="424px">
        <Stack gap={3}>
          <EventUpsertTimeFormDateSection />
          <EventUpsertTimeFormIntervalList />
          <EventUpsertTimeFormAddIntervalButton />
        </Stack>
        <Stack gap={3}>
          <EventUpsertTimeFormRecurringSection />
        </Stack>
      </Stack>
    </Box>
  );
};
