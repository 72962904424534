import { Button, lightTheme, Text } from '@holdbar-com/pixel';
import { CloseRounded } from '@mui/icons-material';
import { Box, Stack } from '@mui/material';
import { FC } from 'react';

import { useTranslate } from '../../../Hooks/useTranslate';

type TicketErrorType =
  | 'invalid-format'
  | 'unknown'
  | 'load-fail'
  | 'no-camera'
  | 'camera-permission-denied';

type Props = {
  error: TicketErrorType;
  onClose?: () => void;
};

export const TicketError: FC<Props> = ({ error, onClose }) => {
  const { t } = useTranslate('checkin.scan.error');

  const headline = t(`${error}.headline`, {
    defaultValue: t('unknown.headline'),
  });

  const description = t(`${error}.description`, {
    defaultValue: t('unknown.description'),
  });

  return (
    <Stack justifyContent="space-between" flex={1} gap={3}>
      <Stack gap={2} alignItems="center" marginY={3}>
        <Box position="relative" width={60} height={60}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="-30 -30 60 60"
            fill={lightTheme.palette.error.e100}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
          >
            <circle r="29.5" fill="#FBE9E9" />
          </svg>
          <CloseRounded
            sx={{
              color: lightTheme.palette.error.e300,
              zIndex: 1,
              position: 'absolute',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)',
              fontSize: 34,
            }}
          />
        </Box>
        <Text fontSize="large" variant="medium">
          {headline}
        </Text>
        <Box px={4} textAlign="center">
          {description}
        </Box>
      </Stack>

      {onClose && (
        <Button size={'large'} variant={'primary'} onClick={onClose}>
          {t('ok', 'utils.generic')}
        </Button>
      )}
    </Stack>
  );
};
