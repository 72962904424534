import { lightTheme } from '@holdbar-com/pixel';
import { KeyboardArrowDown } from '@mui/icons-material';
import { createTheme } from '@mui/material';

const fontFamily =
  'DM Sans,-apple-system,Roboto,Helvetica Neue,Arial,sans-serif';

export const TOPBAR_MIN_HEIGHT = 56;

export const theme = createTheme({
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
    unit: 'px',
  },
  direction: 'ltr',
  components: {
    MuiCssBaseline: {
      defaultProps: {
        enableColorScheme: true,
      },
    },
    MuiButtonBase: {
      defaultProps: {},
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          fontSize: lightTheme.tokens.font.presets.small.fontSize,
          lineHeight: lightTheme.tokens.font.presets.small.lineHeight,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: lightTheme.palette.neutral.n500,
          fontSize: lightTheme.tokens.font.presets.small.fontSize,
        },
        focused: {
          fontSize: lightTheme.tokens.font.presets.small.fontSize,
        },
        shrink: {
          fontSize: lightTheme.tokens.font.presets.medium.fontSize,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: lightTheme.tokens.font.presets.small.fontSize,
          lineHeight: lightTheme.tokens.font.presets.small.lineHeight,
        },
        asterisk: {
          color: lightTheme.palette.error.e300,
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        IconComponent: KeyboardArrowDown,
      },
      styleOverrides: {
        icon: {
          color: lightTheme.palette.neutral.n400,
          transition: 'transform 0.2s',
          '&.Mui-expanded': {
            transform: 'rotate(180deg)',
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        disableRipple: true,
        disableFocusRipple: true,
      },
      styleOverrides: {
        root: {
          border: '1px solid',
          borderColor: 'transparent',
          borderRadius: lightTheme.tokens.radii[6],
          fontFamily,
          fontWeight: lightTheme.tokens.font.weights.medium,
          fontStyle: 'normal',
        },
        sizeLarge: {
          height: 56,
          padding: '16px 40px',
          fontSize: lightTheme.tokens.font.presets.large.fontSize,
          lineHeight: lightTheme.tokens.font.presets.large.lineHeight,
          letterSpacing: '0.1px',
        },
        sizeMedium: {
          height: 48,
          padding: '16px 24px',
          fontSize: lightTheme.tokens.font.presets.medium.fontSize,
          lineHeight: lightTheme.tokens.font.presets.medium.lineHeight,
          letterSpacing: '0.15px',
        },
        sizeSmall: {
          height: 40,
          padding: '16px 24px',
          fontSize: lightTheme.tokens.font.presets.small.fontSize,
          lineHeight: lightTheme.tokens.font.presets.small.lineHeight,
          letterSpacing: '0.17px',
        },
      },
      /* As no design for different colors exist the buttons are defined only by variant */
      variants: [
        {
          props: {
            variant: 'contained',
          },
          style: {
            backgroundColor: lightTheme.palette.action.a300,
            color: lightTheme.palette.contrast.white,
            '&:hover': {
              background: lightTheme.palette.action.a400,
            },
            '&:disabled': {
              backgroundColor: lightTheme.palette.neutral.n100,
              color: lightTheme.palette.neutral.n300,
            },
            '&:focus-visible': {
              boxShadow: `0 0 0 3px ${lightTheme.palette.action.a400}`,
            },
          },
        },
        {
          props: {
            variant: 'outlined',
          },
          style: {
            backgroundColor: lightTheme.palette.contrast.white,
            color: lightTheme.palette.contrast.black,
            borderColor: lightTheme.palette.neutral.n300,
            '&:hover': {
              borderColor: lightTheme.palette.neutral.n400,
            },
            '&:disabled': {
              backgroundColor: lightTheme.palette.neutral.n50,
              borderColor: lightTheme.palette.neutral.n200,
              color: lightTheme.palette.neutral.n200,
            },
            '&:focus-visible': {
              borderColor: 'transparent',
              boxShadow: `0 0 0 3px ${lightTheme.palette.action.a200}`,
            },
          },
        },
        {
          props: {
            variant: 'text',
          },
          style: {
            color: lightTheme.palette.contrast.black,
            '&:hover': {
              borderColor: lightTheme.palette.neutral.n50,
              backgroundColor: lightTheme.palette.neutral.n50,
            },
            '&:disabled': {
              color: lightTheme.palette.neutral.n200,
            },
            '&:focus-visible': {
              borderColor: 'transparent',
              boxShadow: `0 0 0 1px ${lightTheme.palette.action.a200}`,
            },
          },
        },
        {
          props: {
            variant: 'contained',
            color: 'error',
          },
          style: {
            backgroundColor: lightTheme.palette.error.e300,
            color: lightTheme.palette.contrast.white,
            '&:hover': {
              background: lightTheme.palette.error.e400,
            },
            '&:disabled': {
              backgroundColor: lightTheme.palette.neutral.n100,
              color: lightTheme.palette.neutral.n300,
            },
            '&:focus-visible': {
              boxShadow: `0 0 0 3px ${lightTheme.palette.action.a400}`,
            },
          },
        },
        {
          props: {
            variant: 'outlined',
            color: 'error',
          },
          style: {
            backgroundColor: lightTheme.palette.contrast.white,
            color: lightTheme.palette.error.e300,
            borderColor: lightTheme.palette.error.e300,
            '&:hover': {
              borderColor: lightTheme.palette.error.e400,
            },
            '&:disabled': {
              backgroundColor: lightTheme.palette.neutral.n50,
              borderColor: lightTheme.palette.neutral.n200,
              color: lightTheme.palette.neutral.n200,
            },
            '&:focus-visible': {
              borderColor: 'transparent',
              boxShadow: `0 0 0 3px ${lightTheme.palette.action.a200}`,
            },
          },
        },
      ],
    },
    MuiIconButton: {
      defaultProps: {
        disableRipple: true,
        disableFocusRipple: true,
      },
      styleOverrides: {
        root: {
          border: '1px solid',
          borderColor: 'transparent',
        },
      },
      variants: [
        {
          props: {
            color: 'primary',
          },
          style: {
            backgroundColor: lightTheme.palette.action.a300,
            color: lightTheme.palette.contrast.white,
            '&:hover': {
              background: lightTheme.palette.action.a400,
            },
            '&:disabled': {
              backgroundColor: lightTheme.palette.neutral.n100,
              color: lightTheme.palette.neutral.n300,
            },
            '&:focus-visible': {
              boxShadow: `0 0 0 3px ${lightTheme.palette.action.a400}`,
            },
          },
        },
        {
          props: {
            color: 'secondary',
          },
          style: {
            backgroundColor: lightTheme.palette.contrast.white,
            color: lightTheme.palette.contrast.black,
            borderColor: lightTheme.palette.neutral.n300,
            '&:hover': {
              borderColor: lightTheme.palette.neutral.n400,
            },
            '&:disabled': {
              backgroundColor: lightTheme.palette.neutral.n50,
              borderColor: lightTheme.palette.neutral.n200,
              color: lightTheme.palette.neutral.n200,
            },
            '&:focus-visible': {
              borderColor: 'transparent',
              boxShadow: `0 0 0 3px ${lightTheme.palette.action.a200}`,
            },
          },
        },
        {
          props: {
            color: 'info',
          },
          style: {
            color: lightTheme.palette.contrast.black,
            '&:hover': {
              borderColor: lightTheme.palette.neutral.n50,
              backgroundColor: lightTheme.palette.neutral.n50,
            },
            '&:disabled': {
              color: lightTheme.palette.neutral.n200,
            },
            '&:focus-visible': {
              borderColor: 'transparent',
              boxShadow: `0 0 0 1px ${lightTheme.palette.action.a200}`,
            },
          },
        },
      ],
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          ['@media screen and (max-width: 699px)']: {
            margin: 0,
            maxHeight: '100%',
            borderRadius: 0,
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          mt: 0.5,
          minWidth: 160,
          elevation: 0,
          color: '#3E5060',
          backgroundImage: 'none',
          bgColor: '#fff',
          border: '1px solid #E0E3E7',
          '& .MuiMenuItem-root': {
            fontSize: lightTheme.typography.medium.normal.fontSize,
            fontWeight: 500,
            '&:hover': {
              backgroundColor: '#F3F6F9',
            },
            '&:focus': {
              backgroundColor: '#F3F6F9',
            },
            '&.Mui-selected': {
              fontWeight: 500,
              color: '#0072E5',
              backgroundColor: 'rgba(194, 224, 255, 0.6)',
            },
          },
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          boxShadow: '0px 4px 20px rgba(170, 180, 190, 0.3)',
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          '@media (min-width:900px)': {
            paddingLeft: '48px',
            paddingRight: '48px',
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: lightTheme.palette.neutral.n100,
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'none',
        color: '#007AFF',
      },
      styleOverrides: {
        root: {
          display: 'inline-flex',
          alignItems: 'center',
          fontFamily,
          '&.MuiTypography-body1 > svg': {
            marginTop: 2,
          },
          '& svg:last-child': {
            marginLeft: 2,
          },
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: 'h1',
          h2: 'h2',
          h3: 'h3',
          h4: 'h4',
          h5: 'h5',
          h6: 'h6',
          subtitle1: 'h2',
          subtitle2: 'h2',
          body1: 'p',
          body2: 'label',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontWeight: 500,
        },
        outlined: {
          color: '#1A2027',
          backgroundColor: 'transparent',
          borderColor: '#CDD2D7',
        },
        filled: {
          border: '1px solid transparent',

          '&:hover': {},
        },
        deleteIcon: {
          color: '#0059B2',
          '&:hover': {
            color: '#003A75',
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 40,
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontWeight: 600,
        },
      },
    },
    MuiListItem: {
      defaultProps: {
        dense: false,
      },
      styleOverrides: {
        root: {
          paddingBottom: 0,
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          padding: '15px',
          textTransform: 'none',
          color: '#8E8E93',
          borderRadius: 12,
          '& .MuiSvgIcon-root': {
            color: '#8E8E93',
          },
          border: '1px solid transparent',
          '&:hover': {
            backgroundColor: '#F3F6F9',
          },
          '&.Mui-selected': {
            color: '#1C1C1E',
            borderRadius: 12,
            borderColor: '#E5E5EA',
            backgroundColor: '#E5E5EA',
            '& .MuiSvgIcon-root': {
              color: '#1C1C1E',
            },
            '&:hover': {
              backgroundColor: '#E5E5EA',
              opacity: 0.95,
            },
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          overflow: 'hidden',
          '& .MuiTab-root:not(:first-child)': {
            marginLeft: 4,
          },
        },
        indicator: {
          height: 'calc(100%)',
          borderRadius: '10px',
          backgroundColor: 'rgba(10,143,214,0.1)',
          zIndex: 0,
        },
      },
    },
    MuiTab: {
      defaultProps: {
        disableTouchRipple: true,
      },
      styleOverrides: {
        root: {
          fontSize: '0.88em',
          fontWeight: 500,
          minHeight: TOPBAR_MIN_HEIGHT,
          borderRadius: 10,
          padding: '12px 18px',
          '&:hover': { backgroundColor: 'rgba(220,220,220,0.08)' },
          '&.Mui-selected': { color: 'primary.main' },
          '& .MuiSvgIcon-root': { opacity: 0.8 },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          background: lightTheme.palette.contrast.white,
          boxShadow: lightTheme.shadows.small,
          borderRadius: lightTheme.tokens.radii[3],
          padding: lightTheme.tokens.spaces[2],
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'none',
          backgroundColor: '#fff',
          '&[href]': {
            textDecorationLine: 'none',
          },
        },
        outlined: {
          display: 'block',
          borderColor: '#E0E3E7',
          'a&, button&': {
            '&:hover': {
              boxShadow: '0px 4px 20px rgba(170, 180, 190, 0.3)',
            },
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {},
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          fontWeight: 700,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '6px 16px',
          borderColor: 'transparent',
          backgroundColor: 'white',
          borderTop: '1px solid #E5E5EA',
          borderBottom: '1px solid #E5E5EA',
        },
        head: {
          color: '#1A2027',
          fontWeight: 700,
        },
        body: {
          color: '#3E5060',
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff',
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontWeight: 500,
          color: '#3E5060',
          borderColor: '#E0E3E7',
          '&.Mui-selected': {
            color: '#007FFF',
            borderColor: '#007FFF !important',
            backgroundColor: '#F0F7FF',
            '&:hover': {
              backgroundColor: '#C2E0FF',
            },
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          padding: '5px 9px',
        },
      },
    },
    /*"MuiSwitch": {
            "styleOverrides": {
                "root": {
                    "width": 32,
                    "height": 20,
                    "padding": 0,
                    "& .MuiSwitch-switchBase": {
                        "&.Mui-checked": {
                            "transform": "translateX(11px)",
                            "color": "#fff"
                        }
                    }
                },
                "switchBase": {
                    "height": 20,
                    "width": 20,
                    "padding": 0,
                    "color": "#fff",
                    "&.Mui-checked + .MuiSwitch-track": {
                        "opacity": 1,
                        "backgroundColor": "#1CAF5E"
                    }
                },
                "track": {
                    "opacity": 1,
                    "borderRadius": 32,
                    "backgroundColor": "#B2BAC2"
                },
                "thumb": {
                    "flexShrink": 0,
                    "width": "14px",
                    "height": "14px"
                }
            }
        },*/
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontWeight: 700,
          color: '#3E5060',
          borderColor: '#E0E3E7',
          '&.Mui-selected': {
            color: '#007FFF',
            borderColor: '#007FFF !important',
            backgroundColor: '#F0F7FF',
            '&:hover': {
              backgroundColor: '#C2E0FF',
            },
          },
        },
      },
    },
  },
  palette: {
    mode: 'light',
    primary: {
      '100': lightTheme.palette.action.a100,
      '200': lightTheme.palette.action.a200,
      '300': lightTheme.palette.action.a300,
      '400': lightTheme.palette.action.a400,
      '500': lightTheme.palette.action.a500,
      main: lightTheme.palette.action.a300,
      light: lightTheme.palette.action.a200,
      dark: lightTheme.palette.action.a400,
      contrastText: '#fff',
    },
    divider: lightTheme.palette.neutral.n200,
    common: {
      black: lightTheme.palette.contrast.black,
      white: lightTheme.palette.contrast.white,
    },
    text: {
      primary: '#2C3131',
      secondary: '#3E5060',
      disabled: 'rgba(0, 0, 0, 0.38)',
    },
    grey: {
      '50': lightTheme.palette.neutral.n50,
      '100': lightTheme.palette.neutral.n100,
      '200': lightTheme.palette.neutral.n200,
      '300': lightTheme.palette.neutral.n300,
      '400': lightTheme.palette.neutral.n400,
      '500': lightTheme.palette.neutral.n500,
      A100: lightTheme.palette.neutral.n100,
      A200: lightTheme.palette.neutral.n200,
      A400: lightTheme.palette.neutral.n400,
      A700: lightTheme.palette.neutral.n500,
    },
    error: {
      '100': lightTheme.palette.error.e100,
      '200': lightTheme.palette.error.e200,
      '300': lightTheme.palette.error.e300,
      '400': lightTheme.palette.error.e400,
      '500': lightTheme.palette.error.e500,
      main: lightTheme.palette.error.e300,
      light: lightTheme.palette.error.e200,
      dark: lightTheme.palette.error.e400,
      contrastText: '#fff',
    },
    success: {
      '100': lightTheme.palette.success.s100,
      '200': lightTheme.palette.success.s200,
      '300': lightTheme.palette.success.s300,
      '400': lightTheme.palette.success.s400,
      '500': lightTheme.palette.success.s500,
      main: lightTheme.palette.success.s300,
      light: lightTheme.palette.success.s200,
      dark: lightTheme.palette.success.s400,
      contrastText: '#fff',
    },
    warning: {
      '100': lightTheme.palette.warning.w100,
      '200': lightTheme.palette.warning.w200,
      '300': lightTheme.palette.warning.w300,
      '400': lightTheme.palette.warning.w400,
      '500': lightTheme.palette.warning.w500,
      main: lightTheme.palette.warning.w300,
      light: lightTheme.palette.warning.w200,
      dark: lightTheme.palette.warning.w400,
      contrastText: '#fff',
    },
    secondary: {
      main: '#1C1C1E',
      light: '#1C1C1E',
      dark: 'rgba(50,50,50,1)',
      contrastText: '#fff',
    },
    info: {
      main: '#FFFFFF',
      light: '#FFFFFF',
      dark: 'rgb(252,252,252)',
      contrastText: '#636366',
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
    background: {
      paper: lightTheme.palette.contrast.white,
      default: lightTheme.palette.contrast.background,
    },
    action: {
      active: 'rgba(0, 0, 0, 0.54)',
      hover: 'rgba(0, 0, 0, 0.04)',
      hoverOpacity: 0.04,
      selected: 'rgba(0, 0, 0, 0.08)',
      selectedOpacity: 0.08,
      disabled: 'rgba(0, 0, 0, 0.26)',
      disabledBackground: 'rgba(0, 0, 0, 0.12)',
      disabledOpacity: 0.38,
      focus: 'rgba(0, 0, 0, 0.12)',
      focusOpacity: 0.12,
      activatedOpacity: 0.12,
    },
  },
  shape: {
    borderRadius: 8,
  },
  unstable_strictMode: true,
  typography: {
    fontFamily,
    h1: {
      fontSize: 'clamp(2.625rem, 1.2857rem + 3.5714vw, 4rem)',
      fontWeight: 800,
      lineHeight: 1.1142857142857143,
    },
    h2: {
      fontSize: 'clamp(1.5rem, 0.9643rem + 1.4286vw, 2.25rem)',
      fontWeight: 700,
      lineHeight: 1.2222222222222223,
    },
    h3: {
      fontSize: '1.5rem',
      lineHeight: 1.2222222222222223,
      letterSpacing: 0.2,
      fontWeight: 700,
    },
    h4: {
      fontSize: '1.4rem',
      lineHeight: 1.5,
      letterSpacing: 0.2,
      fontWeight: 700,
    },
    h5: {
      fontSize: '1em',
      letterSpacing: 0.1,
      fontWeight: 600,
    },
    h6: {
      fontSize: '0.88em',
      lineHeight: 1.5,
      fontWeight: 600,
    },
    button: {
      textTransform: 'initial',
      fontWeight: 500,
      letterSpacing: 0,
      fontSize: '0.875rem',
      lineHeight: 1.75,
    },
    subtitle1: {
      fontSize: '1.125rem',
      lineHeight: 1.3333333333333333,
      letterSpacing: 0,
      fontWeight: 500,
    },
    body1: {
      fontSize: '1rem',
      lineHeight: 1.5,
      letterSpacing: 0,
      fontWeight: 400,
    },
    body2: {
      fontSize: '0.75em',
      lineHeight: 1.4,
      letterSpacing: 0,
      fontWeight: 400,
    },
    caption: {
      display: 'inline-block',
      fontSize: '0.75rem',
      lineHeight: 1.5,
      letterSpacing: 0,
      fontWeight: 500,
    },
    htmlFontSize: 16,
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    subtitle2: {
      fontFamily,
      fontWeight: 500,
      fontSize: '0.875rem',
      lineHeight: 1.57,
    },
    overline: {
      fontFamily,
      fontWeight: 400,
      fontSize: '0.75rem',
      lineHeight: 2.66,
      textTransform: 'uppercase',
    },
  },
  mixins: {
    toolbar: {
      minHeight: 56,
      '@media (min-width:0px) and (orientation: landscape)': {
        minHeight: 48,
      },
      '@media (min-width:600px)': {
        minHeight: 64,
      },
    },
  },
  shadows: [
    'none',
    '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
    '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
    '0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)',
    '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
    '0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)',
    '0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)',
    '0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)',
    '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
    '0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)',
    '0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)',
    '0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)',
    '0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)',
    '0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)',
    '0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)',
    '0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)',
    '0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)',
    '0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)',
    '0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)',
    '0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)',
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)',
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)',
    '0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)',
    '0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)',
    '0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)',
  ],
  transitions: {
    easing: {
      easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
      easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
      easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
      sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
    },
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      standard: 300,
      complex: 375,
      enteringScreen: 225,
      leavingScreen: 195,
    },
  },
  zIndex: {
    mobileStepper: 1000,
    fab: 1050,
    speedDial: 1050,
    appBar: 1100,
    drawer: 1200,
    modal: 1300,
    snackbar: 1400,
    tooltip: 1500,
  },
});
