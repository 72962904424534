export function changeSearchParam(
  searchParams: URLSearchParams,
  name: string,
  value: string
): Record<string, string> {
  const newParameters: Record<string, string> = {};
  searchParams.forEach((value, key) => {
    if (value === '' || key === name) return;
    newParameters[key] = value;
  });

  if (value !== '') {
    newParameters[name] = value;
  }

  return newParameters;
}
