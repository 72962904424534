import styled from '@emotion/styled';
import { lightTheme, Text } from '@holdbar-com/pixel';
import { Stack } from '@mui/material';
import { FC } from 'react';

import { useTranslate } from '../../../Hooks/useTranslate';
import { TEvent } from '../../../Utils/eventHelpers';

type Props = {
  event: TEvent;
};

export const EventStatus: FC<Props> = ({ event }) => {
  const { t } = useTranslate('utils.stateChips');
  if (event.status !== 'cancelled' && event.status !== 'inactive') return null;

  const color =
    event.status === 'cancelled'
      ? lightTheme.palette.error.e300
      : lightTheme.palette.warning.w500;

  return (
    <Stack>
      <StyledText
        variant="normal"
        fontSize="xsmall"
        textDecorationLine="none"
        color={color}
      >
        {t(event.status)}
      </StyledText>
    </Stack>
  );
};

const StyledText = styled(Text)`
  letter-spacing: 0.4px;
`;
