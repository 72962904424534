import { lightTheme } from '@holdbar-com/pixel';
import { CheckRounded } from '@mui/icons-material';
import { Button, Stack, styled } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

import { useTranslate } from '../../../../../Hooks/useTranslate';
import { BUTTON_BORDER_STYLES } from '../customization-options';
import { StorefrontCustomizeFormData } from '../storefront-styling-validation';

export const ButtonStyleSelect = () => {
  const { t } = useTranslate('storefront.customize');

  const { control, watch } =
    useFormContext<Pick<StorefrontCustomizeFormData, 'buttonStyle'>>();

  const selectedButtonStyle = watch('buttonStyle');

  return (
    <Stack direction={'row'} gap={1}>
      <Controller
        control={control}
        name="buttonStyle"
        render={({ field }) => (
          <>
            {Object.keys(BUTTON_BORDER_STYLES).map((style: string) => (
              <StyledButtonPreviewItem
                key={style}
                {...field}
                selectedButtonStyle={selectedButtonStyle}
                styling={style}
                onClick={() => field.onChange(style)}
                variant={'outlined'}
                size={'small'}
                endIcon={
                  selectedButtonStyle === style ? <CheckRounded /> : null
                }
              >
                {t('buttonStyle.' + style)}
              </StyledButtonPreviewItem>
            ))}
          </>
        )}
      />
    </Stack>
  );
};

const StyledButtonPreviewItem = styled(Button)<{
  selectedButtonStyle: string;
  styling: string;
}>`
  padding: 16px;
  border-radius: ${({ styling }) =>
    BUTTON_BORDER_STYLES[styling as keyof typeof BUTTON_BORDER_STYLES]};
  background-color: ${({ selectedButtonStyle, styling }) =>
    selectedButtonStyle === styling
      ? lightTheme.palette.action.a100 + '80'
      : '#FFFFFF'};
  color: ${({ selectedButtonStyle, styling }) =>
    selectedButtonStyle === styling
      ? lightTheme.palette.action.a300
      : 'inherit'};
  border-color: ${({ selectedButtonStyle, styling }) =>
    selectedButtonStyle === styling
      ? lightTheme.palette.action.a300
      : lightTheme.palette.neutral.n200};
  font-weight: ${({ selectedButtonStyle, styling }) =>
    selectedButtonStyle === styling ? '600' : 'unset'};
  &:hover {
    background-color: ${lightTheme.palette.action.a100} + '80';
    border-color: ${lightTheme.palette.action.a300};
  }
`;
