import { FC } from 'react';

import { Suggestion } from '../../Api/Suggestion';
import { SuggestionExperienceImagesCard } from './experience-images/suggestion-experience-images-card';
import { SuggestionExperiencePracticalInfoCard } from './experience-practical-info/suggestion-experience-practical-info-card';

type Props = {
  suggestion: Suggestion;
};

export const SuggestionCard: FC<Props> = ({ suggestion }) => {
  if (
    suggestion.suggestionType === 'description' &&
    suggestion.entityType === 'experience'
  ) {
    return <SuggestionExperiencePracticalInfoCard suggestion={suggestion} />;
  }

  if (
    suggestion.suggestionType === 'image-improvement' &&
    suggestion.entityType === 'experience'
  ) {
    return <SuggestionExperienceImagesCard suggestion={suggestion} />;
  }

  return null;
};
