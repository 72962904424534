import { Stack } from '@mui/material';
import { FC } from 'react';

import { useCalendar } from '../../context';
import { MonthSelector } from '../../shared/MonthSelector';
import { TodayButton } from '../../shared/TodayButton';
import { DateNavigationButtons } from './DateNavigationButtons';

export const CalendarDesktopDateNavigation: FC = () => {
  const { selectedView } = useCalendar();

  return (
    <Stack direction="row" alignItems="center" gap={3} mt={2}>
      <TodayButton />
      <DateNavigationButtons interval={selectedView} />
      <MonthSelector interval={selectedView} />
    </Stack>
  );
};
