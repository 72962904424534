import { addMonths, format } from 'date-fns';
import { enGB } from 'date-fns/locale';
import { RRule, WeekdayStr } from 'rrule';

import {
  Recurring,
  RecurringAllBusinessDays,
  RecurringAllWeekends,
  RecurringCustom,
  RecurringDaily,
  RecurringNotSelected,
  RecurringWeekly,
} from '../../../../domain/types';
import { createRRuleString } from './rrule_string_helpers';

export const recurringTypes = [
  'notSelected',
  'daily',
  'weekly',
  'allBusinessDays',
  'allWeekends',
  'custom',
] as const;

export type RecurringType = (typeof recurringTypes)[number];

export const isRecurringEvent = (
  recurring: Recurring
): recurring is
  | RecurringDaily
  | RecurringAllBusinessDays
  | RecurringAllWeekends
  | RecurringCustom => {
  return recurring.selectedOptionKey === 'yes';
};

export const isRecurringNotSelected = (
  recurring: Recurring
): recurring is RecurringNotSelected => {
  return (
    recurring.selectedOptionKey === 'no' ||
    recurring.selectedOptionKey === undefined
  );
};

export const isRecurringDaily = (
  recurring: Recurring
): recurring is RecurringDaily => {
  return (
    recurring.selectedOptionKey === 'yes' &&
    recurring.frequency.value === 'daily'
  );
};

export const isRecurringWeekly = (
  recurring: Recurring
): recurring is RecurringWeekly => {
  return (
    recurring.selectedOptionKey === 'yes' &&
    recurring.frequency.value === 'weekly'
  );
};

export const isRecurringAllBusinessDays = (
  recurring: Recurring
): recurring is RecurringAllBusinessDays => {
  return (
    recurring.selectedOptionKey === 'yes' &&
    recurring.frequency.value === 'allBusinessDays'
  );
};

export const isRecurringAllWeekends = (
  recurring: Recurring
): recurring is RecurringAllWeekends => {
  return (
    recurring.selectedOptionKey === 'yes' &&
    recurring.frequency.value === 'allWeekends'
  );
};

export const isRecurringCustom = (
  recurring: Recurring
): recurring is RecurringCustom => {
  return (
    recurring.selectedOptionKey === 'yes' &&
    recurring.frequency.value === 'custom'
  );
};

export const getRecurringType = (recurring: Recurring) => {
  if (isRecurringNotSelected(recurring)) {
    return 'notSelected';
  }
  if (isRecurringDaily(recurring)) {
    return 'daily';
  }
  if (isRecurringWeekly(recurring)) {
    return 'weekly';
  }
  if (isRecurringAllBusinessDays(recurring)) {
    return 'allBusinessDays';
  }
  if (isRecurringAllWeekends(recurring)) {
    return 'allWeekends';
  }
  if (isRecurringCustom(recurring)) {
    return 'custom';
  }
  throw new Error('Unknown recurring type');
};

export const createRecurringFromType = (
  type: string | undefined,
  startDateTime: string
): Recurring => {
  switch (type as RecurringType) {
    case 'daily':
      return createRecurringDaily(startDateTime);
    case 'weekly':
      return createRecurringWeekly(startDateTime);
    case 'allBusinessDays':
      return createRecurringAllBusinessDays(startDateTime);
    case 'allWeekends':
      return createRecurringAllWeekends(startDateTime);
    case 'custom':
      return createRecurringCustom(startDateTime);
    default:
      return createRecurringNotSelected();
  }
};

export const createRecurringNotSelected = (): RecurringNotSelected => ({
  selectedOptionKey: 'no',
  frequency: { value: undefined },
});

export const createRecurringDaily = (startDateTime: string): RecurringDaily => {
  const startDate = new Date(startDateTime);
  const untilDate = new Date(addMonths(startDate, 1));

  return {
    selectedOptionKey: 'yes',
    frequency: { value: 'daily' },
    rrulestr: createRRuleString({
      freq: RRule.DAILY,
      interval: 1,
      dtstart: startDate,
      until: untilDate,
      byweekday: [
        RRule.MO,
        RRule.TU,
        RRule.WE,
        RRule.TH,
        RRule.FR,
        RRule.SA,
        RRule.SU,
      ],
    }),
  };
};

export const createRecurringWeekly = (
  startDateTime: string
): RecurringWeekly => {
  const startDate = new Date(startDateTime);
  const untilDate = new Date(addMonths(startDate, 1));
  const startDateWeekDay = format(new Date(startDateTime), 'EEEEEE', {
    locale: enGB,
  }).toUpperCase();

  return {
    selectedOptionKey: 'yes',
    frequency: { value: 'weekly' },
    rrulestr: createRRuleString({
      freq: RRule.WEEKLY,
      interval: 1,
      dtstart: startDate,
      until: untilDate,
      byweekday: [RRule[startDateWeekDay as WeekdayStr]],
    }),
  };
};

export const createRecurringAllBusinessDays = (
  startDateTime: string
): RecurringAllBusinessDays => {
  const startDate = new Date(startDateTime);
  const untilDate = new Date(addMonths(startDate, 1));

  return {
    selectedOptionKey: 'yes',
    frequency: { value: 'allBusinessDays' },
    rrulestr: createRRuleString({
      freq: RRule.DAILY,
      interval: 1,
      dtstart: startDate,
      until: untilDate,
      byweekday: [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR],
    }),
  };
};

export const createRecurringAllWeekends = (
  startDateTime: string
): RecurringAllWeekends => {
  const startDate = new Date(startDateTime);
  const untilDate = new Date(addMonths(startDate, 1));

  return {
    selectedOptionKey: 'yes',
    frequency: { value: 'allWeekends' },
    rrulestr: createRRuleString({
      freq: RRule.DAILY,
      interval: 1,
      dtstart: startDate,
      until: untilDate,
      byweekday: [RRule.SA, RRule.SU],
    }),
  };
};

export const createRecurringCustom = (
  startDateTime: string
): RecurringCustom => {
  const startDate = new Date(startDateTime);
  const untilDate = new Date(addMonths(startDate, 1));
  const startDateWeekDay = format(new Date(startDateTime), 'EEEEEE', {
    locale: enGB,
  }).toUpperCase();

  return {
    selectedOptionKey: 'yes',
    frequency: { value: 'custom' },
    rrulestr: createRRuleString({
      freq: RRule.WEEKLY,
      interval: 1,
      dtstart: startDate,
      until: untilDate,
      byweekday: [RRule[startDateWeekDay as WeekdayStr]],
    }),
  };
};
