import { CompanyProfile, Experience } from '@holdbar-com/utils-types';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { UseQueryResult } from 'react-query';
import { toast } from 'react-toastify';

import { updateExperience } from '../../../Api';
import { FixedActionsBar } from '../../../Components/FixedActionsBar/FixedActionsBar';
import { useProfile } from '../../../Hooks/useProfile';
import { useTranslate } from '../../../Hooks/useTranslate';
import { ExperienceOrderSection } from './experience-order/experience-order-section';
import {
  StorefrontCustomizeFormData,
  useStorefrontCustomizeFormValidation,
} from './styling/storefront-styling-validation';
import { StylingSection } from './styling/styling-section';

export const CustomizeSectionForm = ({
  defaultValues,
  company,
  experiencesQuery,
}: {
  defaultValues: StorefrontCustomizeFormData;
  company: CompanyProfile;
  experiencesQuery: UseQueryResult<Experience[], unknown>;
}) => {
  const { t } = useTranslate('storefront.customize');

  const { updateCompany } = useProfile();

  const formMethods = useForm<StorefrontCustomizeFormData>({
    resolver: yupResolver(useStorefrontCustomizeFormValidation(t)),
    defaultValues: defaultValues,
  });

  const { handleSubmit, formState, reset } = formMethods;

  const onSubmit = async (data: StorefrontCustomizeFormData) => {
    try {
      const {
        fontFamily,
        primaryColor,
        buttonStyle,
        sortOrder,
        sortExperiencesBy,
      } = data;

      const companyPayload: CompanyProfile = {
        ...company,
        customization: {
          fontFamily,
          primaryColor,
          buttonStyle,
        },
        sortExperiencesBy,
      };

      const experienceMap = experiencesQuery.data?.reduce(
        (experiences, experience) => {
          return {
            ...experiences,
            [experience.id]: experience,
          };
        },
        {} as Record<string, Experience>
      );

      await Promise.all([
        updateCompany.mutateAsync(companyPayload),
        // We don't need to map all experiences if we don't use custom sort order
        ...(sortExperiencesBy === 'sortOrder'
          ? sortOrder.map((experienceId, index) => {
              const experience = experienceMap?.[experienceId];
              if (!experience) return;
              // Only update if position is changed
              if (experience.metadata?.sortOrder === index + 1) return;

              return updateExperience(experience.id, {
                ...experience,
                metadata: { ...experience.metadata, sortOrder: index + 1 },
              });
            })
          : []),
      ]);

      reset(data);
      toast.success(t('success'));
    } catch (error) {
      toast.error(t('errors.error'));
    }
  };

  return (
    <FormProvider {...formMethods}>
      <Stack component="form" gap={3} onSubmit={handleSubmit(onSubmit)}>
        <StylingSection />
        <ExperienceOrderSection query={experiencesQuery} />
        <FixedActionsBar
          onReset={() => reset()}
          isSubmitting={formState.isSubmitting}
          unsavedChanges={formState.isDirty}
        />
      </Stack>
    </FormProvider>
  );
};
