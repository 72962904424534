export interface ICancelEventDialogProps {
  booked: boolean;
  eventId: string;
}

export interface ICancellationNote {
  [key: string]: string;
}

export interface ICancelEventData {
  shouldRefund: boolean;
  cancellationNote: ICancellationNote[];
}

export interface ICancelEventForm {
  cancelNote: ICancellationNote[] | string | undefined;
  event: React.BaseSyntheticEvent<object, any, any> | undefined;
}

export enum CancellationStates {
  FAILED_PARTLY = 'cancellation-failed-partly',
  FAILED_ENTIRELY = 'cancellation-failed-entirely',
  CANCELLED = 'cancelled',
}
