import { ExperienceTag } from '@holdbar-com/utils-types';
import { useState } from 'react';
import { toast } from 'react-toastify';

import { useExperienceTags } from '../../../../Hooks/useExperienceTags';
import { useTranslate } from '../../../../Hooks/useTranslate';
import { ExperienceTagDialogContent } from './dialog-content';
import { ExperienceTagForm } from './form';
import { ExperienceTagViewProps } from './shared';

export const CreateExperienceTagView = ({
  setView,
}: ExperienceTagViewProps) => {
  const { createTag } = useExperienceTags();
  const { t } = useTranslate('experience.tags.dialog.actions');
  const [isCreating, setIsCreating] = useState(false);

  const handleSubmit = (data: ExperienceTag['name']) => {
    setIsCreating(true);
    createTag
      .mutateAsync(data)
      .then(() => {
        setView('list');
      })
      .catch((error) => {
        toast.error(t('generic', 'utils.errors'));
        console.error(error);
      })
      .finally(() => setIsCreating(false));
  };

  return (
    <ExperienceTagDialogContent>
      <ExperienceTagForm
        onSubmit={handleSubmit}
        actions={[
          {
            id: 'cancel',
            label: t('cancel'),
            variant: 'secondary',
            type: 'button',
            onClick: () => setView('list'),
            disabled: isCreating,
          },
          {
            id: 'create',
            label: t('createTag'),
            variant: 'primary',
            type: 'submit',
            disabled: isCreating,
          },
        ]}
      />
    </ExperienceTagDialogContent>
  );
};
