import { lightTheme, Text } from '@holdbar-com/pixel';
import { HelpOutlineRounded } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { FC } from 'react';

import { Tooltip } from '../../../Components/tooltip/tooltip';

type Props = {
  title: string;
  description: string;
};

export const EconomicConfigurationSectionTitle: FC<Props> = ({
  title,
  description,
}) => {
  return (
    <Tooltip title={description} sx={{ cursor: 'help', width: 'fit-content' }}>
      <Stack direction={'row'} gap={1}>
        <Text variant="medium" fontSize="small">
          {title}
        </Text>
        <HelpOutlineRounded
          fontSize="small"
          htmlColor={lightTheme.palette.neutral.n200}
        />
      </Stack>
    </Tooltip>
  );
};
