import { Experience } from '@holdbar-com/utils-types';

import { ampli } from '../../../../Ampli';
import { TEvent } from '../../../../Utils/eventHelpers';
import { eventProperties } from '../card/eventProperties';

export const trackExperienceEventStateFilterOpened = (
  events: TEvent[],
  state_selected?: string,
  experience?: Experience
) => {
  if (!experience) return;
  const properties = eventProperties(experience, events);

  ampli.experienceEventStateFilterOpened({
    ...properties,
    state_selected,
  });
};
