import { useFlags } from 'launchdarkly-react-client-sdk';

import { DaysInput } from '../../../../Components/DaysInput/DaysInput';
import FeatureItem from '../../../../Components/FeatureItem/FeatureItem';
import FeatureInput from '../inputs/FeatureInput';

export const AllowGuestsMoveBookingFeature = () => {
  const flags = useFlags();
  if (!flags.featureGuestsMoveSetting) {
    return null;
  }

  return (
    <FeatureItem feature="allowGuestsMoveBooking">
      <FeatureInput name="untilDays" as={DaysInput} required />
    </FeatureItem>
  );
};
