import { lightTheme, Text } from '@holdbar-com/pixel';
import { styled } from '@mui/material';

type EventUpsertTimeFormRecurringWeeklyOptionProps = {
  label: string;
  isSelected: boolean;
  onSelect: () => void;
};

export const EventUpsertTimeFormRecurringWeeklyOption = ({
  label,
  isSelected,
  onSelect,
}: EventUpsertTimeFormRecurringWeeklyOptionProps) => {
  return (
    <StyledWeekdayOption selected={isSelected} onClick={onSelect}>
      <StyledText fontSize="small" variant="medium" isSelected={isSelected}>
        {label.slice(0, 1) ?? ''}
      </StyledText>
    </StyledWeekdayOption>
  );
};

type StyledWeekdayOptionProps = {
  selected: boolean;
};

const StyledWeekdayOption = styled('div')<StyledWeekdayOptionProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${({ selected }) =>
    selected
      ? lightTheme.palette.action.a300
      : lightTheme.palette.neutral.n100};
`;

type StyledTextProps = {
  isSelected: boolean;
};

const StyledText = styled(Text)<StyledTextProps>`
  color: ${({ isSelected }) =>
    isSelected
      ? lightTheme.palette.contrast.white
      : lightTheme.palette.neutral.n400};
`;
