import { Box, Checkbox, FormControlLabel, Stack } from '@mui/material';
import { useEffect } from 'react';
import { FieldError, useFormContext } from 'react-hook-form';

import { VariantSelect } from '../../../../../../../Components/VariantSelect/VariantSelect';
import useResponsive from '../../../../../../../Hooks/layout/useResponsive';
import { useTranslate } from '../../../../../../../Hooks/useTranslate';
import { useEventUpsert } from '../../../../domain/event_upsert_context';
import { EventUpsertBookingFormEmailField } from './EventUpsertBookingFormEmailField';
import { EventUpsertBookingFormLanguageSelect } from './EventUpsertBookingFormLanguageSelect';
import { EventUpsertBookingFormNameField } from './EventUpsertBookingFormNameField';
import { EventUpsertBookingFormNotesField } from './EventUpsertBookingFormNotesField';
import { EventUpsertBookingFormPhoneField } from './EventUpsertBookingFormPhoneField';

export const EventUpsertBookingForm = () => {
  const { t } = useTranslate('dialogs.createBooking');
  const { isMd } = useResponsive();
  const { currentEvent, updateEventField } = useEventUpsert();

  const {
    formState: { errors },
    setValue,
    register,
  } = useFormContext();
  const guestsError = errors['guests'] as FieldError | undefined;

  useEffect(() => {
    if (!(currentEvent.booking?.shouldSendNotification === false)) {
      updateEventField('booking', {
        ...currentEvent.booking,
        shouldSendNotification: true,
      });
      setValue('shouldSendNotification', true);
    } else {
      updateEventField('booking', {
        ...currentEvent.booking,
        shouldSendNotification: false,
      });
      setValue('shouldSendNotification', false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box component="form">
      <Stack
        direction={isMd ? 'column' : 'row'}
        rowGap={3}
        columnGap={6}
        maxWidth={isMd ? '424px' : '90%'}
      >
        <Stack gap={3} flex={2} maxWidth="424px">
          <EventUpsertBookingFormNameField />
          <Stack direction="row" gap={2}>
            <EventUpsertBookingFormEmailField />
            <EventUpsertBookingFormPhoneField />
          </Stack>
          <VariantSelect
            experienceId={currentEvent.experienceId}
            maxGuestCount={currentEvent?.seatCount?.value ?? 0}
            errorMessage={guestsError?.message}
            required
            onChange={(guests) => {
              setValue('guests', guests, { shouldValidate: true });
              updateEventField('booking', { ...currentEvent.booking, guests });
            }}
          />
          <EventUpsertBookingFormLanguageSelect />
          <Stack>
            <FormControlLabel
              {...register('shouldSendNotification')}
              label={t('actions.sendNotification')}
              control={
                <Checkbox
                  checked={!!currentEvent.booking?.shouldSendNotification}
                />
              }
              onChange={(_, checked) => {
                updateEventField('booking', {
                  ...currentEvent.booking,
                  shouldSendNotification: checked,
                });
              }}
            />
            <FormControlLabel
              {...register('shouldCreatePaymentLink')}
              label={t('actions.generatePaymentLink')}
              control={
                <Checkbox
                  checked={!!currentEvent.booking?.shouldCreatePaymentLink}
                />
              }
              onChange={(_, checked) => {
                updateEventField('booking', {
                  ...currentEvent.booking,
                  shouldCreatePaymentLink: checked,
                });
              }}
            />
          </Stack>
        </Stack>
        <Box flex={1}>
          <EventUpsertBookingFormNotesField />
        </Box>
      </Stack>
    </Box>
  );
};
