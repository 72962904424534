import { LinkButton, Text } from '@holdbar-com/pixel';
import { Experience } from '@holdbar-com/utils-types';
import { Stack } from '@mui/material';
import { UseQueryResult } from 'react-query';

import { useTranslate } from '../../../../Hooks/useTranslate';
import { experienceSyiSteps } from '../../../../Pages/SyiPage/config';
import { CardContainer } from '../card-container';
import { ExperiencesList } from './experiences-list';

export const ExperienceOrderSection = ({
  query,
}: {
  query: UseQueryResult<Experience[], unknown>;
}) => {
  const { t } = useTranslate('storefront.experiences');

  const { data: experiences, isLoading } = query;

  if (isLoading) return null;

  return (
    <CardContainer>
      <Stack>
        <Text variant="medium">{t('title')}</Text>
        <Text>{t('description')}</Text>
      </Stack>
      {!experiences || !experiences.length ? (
        <NoExperiences />
      ) : (
        <ExperiencesList experiences={experiences} />
      )}
    </CardContainer>
  );
};

const NoExperiences = () => {
  const { t } = useTranslate('storefront.experiences');

  return (
    <Stack alignItems="flex-start" gap={2} marginTop={2}>
      <Text>{t('noExperiences')}</Text>
      <LinkButton
        size="medium"
        variant="primary"
        href={`experience/create/${experienceSyiSteps[0].key}`}
      >
        {t('buttonLabel', 'experience')}
      </LinkButton>
    </Stack>
  );
};
