import { Button } from "@holdbar-com/pixel";
import { Stack } from "@mui/material";
import { useTranslate } from "../../../../../../../../../../../Hooks/useTranslate";

type NotificationsResendModalFormActionsProps = {
  handleSubmit: () => void;
  handleClose: () => void;
};

export const NotificationsResendModalFormActions = ({ handleSubmit, handleClose }: NotificationsResendModalFormActionsProps) => {
  const { t } = useTranslate('dialogs.booking.notifications');

  return (
    <Stack direction='row' gap={2} justifyContent={'right'}>
      <Button
        variant='secondary'
        size='small'
        aria-label={t('cancel')}
        onClick={handleClose}
        type='button'
      >
        {t('cancel', 'utils.generic')}
      </Button>
      <Button
        variant='primary'
        size='small'
        aria-label={t('resend')}
        onClick={handleSubmit}
        type='submit'
      >
        {(t('resend'))}
      </Button>
    </Stack>
  );
};
