import { ArrowForwardRounded, ErrorRounded } from '@mui/icons-material';
import {
  Box,
  Button,
  Divider,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useMemo } from 'react';
import { useController, useFormState, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';

import { LanguageChips } from '../../Components/LanguageChips/LanguageChips';
import { ProfileUpload } from '../../Components/ProfileUpload/ProfileUpload';
import { Tip } from '../../Components/Tip/Tip';
import { SyiSection } from '../SyiPage/SyiSection';
import type { TInput } from './OnBoardingPage';

const meInputs: TInput[] = [
  {
    key: 'name',
    name: 'Fulde navn',
    helperText: 'Så dit team kan søge dig frem i Holdbar',
    hasHelperText: true,
    props: {
      fullWidth: true,
    },
  },
  {
    key: 'email',
    name: 'Email',
    helperText: 'Så dit team kan skrive til dig',
    hasHelperText: true,
    props: {
      disabled: true,
    },
  },
  {
    key: 'phone',
    name: 'Telefon',
    helperText: 'Så dit team kan ringe til dig',
    hasHelperText: true,
  },
];

export const ErrorBlock = ({ label }: { label: string }) => {
  return (
    <Box p={1}>
      <ErrorRounded />
      <Typography variant={'body2'} color={'error'}>
        {label}
      </Typography>
    </Box>
  );
};

export const OnBoardingProfileSection = () => {
  const { t } = useTranslation();

  const {
    handleDeleteImage,
    handleUploadPrepared,
    handleSubmitMe,
    registerMe,
    wasInvited,
    handleNext,
    getMeValues,
    onSubmitMe,
    handleSubmit,
    me,
  } = useOutletContext<any>();

  const { errors } = useFormState();

  const pictures = useWatch({ name: 'pictures' });

  const picture = useMemo(() => {
    const { pictures } = getMeValues();
    const { localUrl, ...props } = pictures?.profile ?? {};
    return { ...props, url: localUrl };
  }, [pictures]);

  const { field: languages } = useController({
    name: 'languages',
    rules: { required: true },
  });

  return (
    <>
      <Typography variant={'h3'}>{t('onboarding.profile.boxTitle')}</Typography>
      <Typography variant={'h4'} mt={2} mb={2} fontSize={'1.11em'}>
        {t('onboarding.profile.boxSubtitle')}
      </Typography>
      <Typography variant={'h5'} mb={2}>
        {t('onboarding.profile.profilePictureTitle')}
      </Typography>
      <Tip label={t('onboarding.profile.profilePictureHelpText')} />
      <ProfileUpload
        mt={3}
        fileType={'profile'}
        onUploadPrepared={handleUploadPrepared('me')}
        onDelete={handleDeleteImage('profile', handleSubmitMe)}
        label={t('onboarding.profile.profilePictureButtonHelpText')}
        image={picture}
        id={me?.id}
        modelType={'me'}
      />

      <Divider sx={{ mt: 3, mb: 3 }} />

      <Typography variant={'h5'} mb={3}>
        {t('onboarding.profile.infoTitle')}
      </Typography>

      <Grid container spacing={2} columns={2} pl={0}>
        {meInputs.map((el, i) => (
          <Grid item xs={el.props?.fullWidth ? 1 : 1}>
            <TextField
              autoFocus={i === 0}
              label={t(`onboarding.profile.${el.key}Placeholder`)}
              fullWidth={true}
              error={Boolean(errors[el.key])}
              helperText={
                errors[el.key]
                  ? t('utils.errors.required')
                  : t(`onboarding.profile.${el.key}HelpText`)
              }
              {...(el?.props ?? {})}
              {...registerMe(el.key, { required: true })}
            />
          </Grid>
        ))}
        <TextField
          sx={{ visibility: 'hidden' }}
          type={'hidden'}
          {...registerMe('id', { value: me?.data?.id })}
        />
      </Grid>

      <Divider sx={{ mt: 3, mb: 3 }} />

      <SyiSection
        title={t('onboarding.profile.languagesTitle')}
        error={errors['languages'] ? t('utils.errors.required') : ''}
      >
        <LanguageChips
          onChange={languages.onChange}
          multiple={true}
          languages={languages.value}
        />
      </SyiSection>
      <Stack direction={'row'} alignItems={'center'} spacing={1} mt={4}>
        <Button
          size={'large'}
          variant={'contained'}
          onClick={onSubmitMe(wasInvited ? handleSubmit : handleNext)}
          startIcon={<ArrowForwardRounded />}
        >
          {t('buttons.next')}
        </Button>
      </Stack>
    </>
  );
};
