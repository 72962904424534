import { FieldError, useFormContext } from 'react-hook-form';

import { ExperienceSelect } from '../../../../../../../Components/ExperienceSelect.tsx';
import { IExperience } from '../../../../../../../Hooks/useExperience';
import { trackEventFlowExperienceSelectionOpened } from '../../../../../../../tracking/events/flow/details/form/trackEventFlowExperienceSelectionOpened';
import { useEventUpsert } from '../../../../domain/event_upsert_context';

export const EventUpsertDetailsFormExperienceSelect = ({
  disabled = false,
}: {
  disabled?: boolean;
}) => {
  const {
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();

  const { updateEventField } = useEventUpsert();

  const selectedValue = watch('experienceId');
  const error = errors['seatCount'] as FieldError | undefined;

  const handleChange = (experience: IExperience) => {
    setValue('experienceId', experience.id);

    if (experience.id) updateEventField('experienceId', experience.id);
  };

  return (
    <ExperienceSelect
      experienceId={selectedValue}
      errorMessage={error?.message ?? ''}
      onChange={handleChange}
      onOpen={trackEventFlowExperienceSelectionOpened}
      isRequired
      isDisabled={disabled}
    />
  );
};
