import { endOfMonth, startOfMonth, subMonths } from '@holdbar-com/utils-date';
import { Stack } from '@mui/material';
import { parseISO } from 'date-fns';
import { Controller, useFormContext } from 'react-hook-form';

import { DateInput } from '../../../../Components/inputs/date-input';
import useResponsive from '../../../../Hooks/layout/useResponsive';
import { useTranslate } from '../../../../Hooks/useTranslate';
import { trackOverviewFirstPickerDateOpened } from '../../../../tracking/overview/request-report/trackOverviewFirstDateOpened';
import { trackOverviewFirstPickerDateSelected } from '../../../../tracking/overview/request-report/trackOverviewFirstDateSelected';
import { trackOverviewSecondDateOpened } from '../../../../tracking/overview/request-report/trackOverviewSecondDateOpened';
import { trackOverviewSecondPickerDateSelected } from '../../../../tracking/overview/request-report/trackOverviewSecondDateSelected';
import { RequestReportDialogFormType } from '../use-request-report-form-validation';

export const RequestReportFormDates = () => {
  const { t } = useTranslate('utils.generic');
  const { isSm } = useResponsive();

  const {
    formState: { errors },
  } =
    useFormContext<
      Pick<RequestReportDialogFormType, 'startDate' | 'endDate'>
    >();

  return (
    <Stack direction={isSm ? 'column' : 'row'} gap={2}>
      <Controller
        name="startDate"
        defaultValue={startOfMonth(subMonths(new Date(), 1))}
        render={({ field }) => (
          <DateInput
            label={t('startDate')}
            {...field}
            onChange={(date) => {
              field.onChange(parseISO(date));
              trackOverviewFirstPickerDateSelected(date);
            }}
            onClick={trackOverviewFirstPickerDateOpened}
            minDate="2022-01-01"
            required
            error={!!errors.startDate}
          />
        )}
      />
      <Controller
        name="endDate"
        defaultValue={endOfMonth(subMonths(new Date(), 1))}
        render={({ field }) => (
          <DateInput
            label={t('endDate')}
            {...field}
            onChange={(date) => {
              field.onChange(parseISO(date));
              trackOverviewSecondPickerDateSelected(date);
            }}
            onClick={trackOverviewSecondDateOpened}
            minDate="2022-01-01"
            required
            error={!!errors.endDate}
          />
        )}
      />
    </Stack>
  );
};
