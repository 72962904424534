import { useTranslate } from '../../../../../Hooks/useTranslate';
import { useIntegrationRequest } from '../use-integration-request';
import { IntegrationObject } from '../use-integrations';

export const useTruestoryIntegration = (): IntegrationObject => {
  const { t } = useTranslate('settings.integrations');

  const { handleIntegrationRequest } = useIntegrationRequest('Truestory');

  return {
    id: 'truestory',
    title: t('marketplaces.truestory.title'),
    icon: '/integrations/truestory.png',
    description: t('marketplaces.truestory.description'),
    extendedDescription: t('marketplaces.truestory.extendedDescription'),
    status: 'connect',
    buttonLabel: t('request.requestIntegration'),
    onInstall: () => async () => {
      handleIntegrationRequest();
    },
  };
};
