import { Text } from '@holdbar-com/pixel';
import { Stack } from '@mui/material';
import { useController, useFormContext } from 'react-hook-form';

import * as api from '../../../../../Api';
import { ProfileUpload } from '../../../../../Components/ProfileUpload/ProfileUpload';
import { useTranslate } from '../../../../../Hooks/useTranslate';
import { TImage } from '../../../../../Utils/types';

export const ProfileFormPicture = () => {
  const { t } = useTranslate('settings.profile');

  const form = useFormContext();
  const id = form.watch('id');
  const { field: pictures } = useController({ name: 'pictures' });

  const handleUploadPrepared = (
    { key, url }: { [k: string]: string },
    fileType: 'logo' | 'profile',
    uploadFunc: () => Promise<void>
  ) => {
    pictures.onChange({ profile: { url, key } });
    uploadFunc();
  };

  const handleDeleteLogo = async ({ key }: TImage) => {
    pictures.onChange({ profile: null });
    return api.deleteUpload(undefined, key);
  };

  return (
    <Stack gap={1}>
      <Text variant="medium" fontSize="small">
        {t('profilePicture.title')}
      </Text>
      <ProfileUpload
        fileType={'profile'}
        onUploadPrepared={handleUploadPrepared}
        onDelete={handleDeleteLogo}
        label={t('profilePicture.helperText')}
        image={pictures.value?.profile}
        id={id}
        modelType={'me'}
      />
    </Stack>
  );
};
