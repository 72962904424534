import create from 'zustand';
import { persist } from 'zustand/middleware';

interface Store {
  clearAuth: () => void;
  setAuth: (params: {
    access_token: string;
    refresh_token: string;
    token_type: string;
    expires_in: number;
  }) => void;
  auth: {
    access_token: string;
    refresh_token: string;
    token_type: string;
    expires_on?: Date;
    expires_in?: number;
  } | null;
}

export const getDateInFuture = (secs: number) => {
  const d = new Date();
  d.setSeconds(d.getSeconds() + secs);
  return d;
};

export const useAuthStore = create(
  persist<Store>(
    (set, get) => ({
      clearAuth: () => set({ auth: null }),
      setAuth: ({ access_token, expires_in, refresh_token, token_type }) => {
        set({
          auth: {
            access_token,
            refresh_token,
            token_type,
            expires_in,
            expires_on: getDateInFuture(expires_in),
          },
        });
      },
      auth: null,
    }),
    {
      name: 'holdbar-auth',
      getStorage: () => localStorage,
    }
  )
);
