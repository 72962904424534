import { Text } from '@holdbar-com/pixel';
import { Chip, ChipProps, Skeleton, Stack } from '@mui/material';
import { FC } from 'react';

import { useReceipt } from '../../../Hooks/useReceipt';
import { useTranslate } from '../../../Hooks/useTranslate';

type Props = {
  receiptId?: string;
};

export const TicketDetailsPaymentStatus: FC<Props> = ({ receiptId }) => {
  const { t } = useTranslate('dialogs.booking');

  const {
    receipt: { data: receipt, isLoading, error },
  } = useReceipt(receiptId);

  if (isLoading) {
    return <Skeleton variant="rounded" width={200} height={24} />;
  }

  if (error) {
    return <Chip label={t('paymentStatus.error')} color="error" />;
  }

  const data: { label: string; status: ChipProps['color'] } = receipt
    ? {
        label:
          receipt.status === 'new'
            ? t('paid', 'utils.statusOptions')
            : t(receipt.status, 'utils.statusOptions'),
        status: 'success',
      }
    : {
        label: t('unpaid', 'utils.statusOptions'),
        status: 'warning',
      };

  return (
    <Stack direction="row" spacing={1} alignItems={'center'}>
      <Text variant="normal" fontSize="medium">
        {data.label}
      </Text>
      <Chip
        color={data.status}
        sx={{
          height: 10,
          width: 10,
          borderRadius: 12,
        }}
      />
    </Stack>
  );
};
