import { BrowserCodeReader, BrowserQRCodeReader } from '@zxing/browser';
import { useEffect, useRef, useState } from 'react';

type QRScannerState = {
  status: 'pending' | 'granted' | 'denied' | 'no-camera';
  scanResult: string | null;
};

export const useQRScanner = () => {
  const [state, setState] = useState<QRScannerState>({
    status: 'pending',
    scanResult: null,
  });
  const reader = useRef<BrowserCodeReader>();

  useEffect(() => {
    async function scan() {
      try {
        reader.current = new BrowserQRCodeReader();
        await reader.current.decodeFromConstraints(
          {
            audio: false,
            video: {
              facingMode: 'environment',
            },
          },
          'camera',
          (result) => {
            if (!result) {
              return;
            }

            setState((previous) => {
              if (previous.scanResult) {
                return previous;
              }

              return {
                ...previous,
                scanResult: result.getText(),
              };
            });
          }
        );

        setState({
          status: 'granted',
          scanResult: null,
        });
      } catch (error) {
        console.error(error);
        if (error instanceof DOMException) {
          setState({
            status: error.name === 'NotFoundError' ? 'no-camera' : 'denied',
            scanResult: null,
          });

          return;
        }
      }
    }
    scan();

    return () => {
      console.log('Unmount');
      if (reader.current) {
        console.log('Reset code reader');
        BrowserQRCodeReader.releaseAllStreams();
      }
    };
  }, []);

  return {
    ...state,
    reset: () => {
      setState({
        status: 'granted',
        scanResult: null,
      });
    },
  };
};
