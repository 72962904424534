import { lightTheme } from '@holdbar-com/pixel';
import LockIcon from '@mui/icons-material/LockOutlined';
import PersonIcon from '@mui/icons-material/PersonRounded';
import { Stack } from '@mui/material';
import { ComponentPropsWithoutRef, forwardRef } from 'react';

import { OneLineText } from '../../../Components/OneLineText/OneLineText';
import { TEvent } from '../../../Utils/eventHelpers';

type Props = {
  booked?: number | undefined;
  total?: number | undefined;
  eventVisibility?: TEvent['visibility'];
} & ComponentPropsWithoutRef<typeof OneLineText>;

export const GuestCountText = forwardRef<HTMLSpanElement, Props>(
  ({ booked, total, eventVisibility, ...rest }, ref) => {
    const Icon = eventVisibility === 'private' ? LockIcon : PersonIcon;
    return (
      <Stack direction="row" gap={0.5} py={0.5} alignItems="center">
        <Icon
          fontSize="inherit"
          htmlColor={lightTheme.palette.contrast.black}
        />
        <OneLineText ref={ref} textTransform="lowercase" {...rest}>
          {booked ?? 0} / {total ?? 0}
        </OneLineText>
      </Stack>
    );
  }
);

GuestCountText.displayName = 'GuestCountText';
