import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';

import { SectionHeadline } from '../../../Components/SectionHeadline/SectionHeadline';
import { useProfile } from '../../../Hooks/useProfile';
import { useTranslate } from '../../../Hooks/useTranslate';
import { getCompanyFormDefaults } from './domain/helpers';
import {
  CompanyFormData,
  useCompanyFormValidation,
} from './domain/use-company-form-validation';
import { CompanyForm } from './ui/form/CompanyForm';

export const SettingsCompanySection = () => {
  const { t } = useTranslate('settings.company');
  const {
    company: { data: company },
    userinfo: { data: userinfo },
  } = useProfile();

  const companyFormValidation = useCompanyFormValidation();
  const form = useForm<CompanyFormData>({
    resolver: yupResolver(companyFormValidation),
    defaultValues: getCompanyFormDefaults(company),
  });

  return (
    <FormProvider {...form}>
      <Stack gap={5} mb={{ xs: 4, lg: form.formState.isDirty ? 6 : 0 }}>
        <SectionHeadline title={t('title')} description={t('description')} />
        <CompanyForm userinfo={userinfo} />
      </Stack>
    </FormProvider>
  );
};
