import { Text } from '@holdbar-com/pixel';
import { Stack, Switch } from '@mui/material';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { useTranslate } from '../../../../Hooks/useTranslate';
import { EconomicConfigurationExperienceAccounts } from './economic-configuration-experience-accounts';

export const EconomicConfigurationAccountsByExperience = () => {
  const { t } = useTranslate('settings.integrations.economic.configuration');
  const { setValue, watch } = useFormContext();

  const [byExperienceToggle, setExperienceToggle] = useState(false);

  const experienceSales = watch('accounts.experienceSales');
  const accountingByExperience =
    Object.keys(experienceSales ?? {}).length > 0 || byExperienceToggle;

  return (
    <Stack gap={2}>
      <Stack flexDirection="row" gap={0.5} alignItems={'center'}>
        <Switch
          size="small"
          checked={accountingByExperience}
          onChange={(_e, newValue) => {
            setExperienceToggle(newValue);

            if (!newValue) {
              setValue('accounts.experienceSales', undefined, {
                shouldDirty: true,
              });
            }
          }}
        />
        <Text fontSize="small">{t('specifyPerExperience')}</Text>
      </Stack>
      {accountingByExperience && <EconomicConfigurationExperienceAccounts />}
    </Stack>
  );
};
