import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Button, lightTheme, Text } from '@holdbar-com/pixel';
import { CloseRounded } from '@mui/icons-material';
import { Chip, CircularProgress, Dialog, Skeleton, Stack } from '@mui/material';
import { useMemo } from 'react';

import useResponsive from '../../../../Hooks/layout/useResponsive';
import { useTranslate } from '../../../../Hooks/useTranslate';
import { useVoucher } from '../../../../Hooks/useVoucher';
import { ConfirmDialog } from '../../../../Modals/ConfirmDialog';
import { useGiftCardsList } from './domain/use_giftcards_list';

export const GiftCardsListDialog = NiceModal.create(
  ({ voucherId }: { voucherId: string }) => {
    const { t } = useTranslate('giftcards.dialog');
    const { isSm } = useResponsive();

    const modal = useModal();

    const { dialogRows, statusColorMap } = useGiftCardsList();

    const {
      boughtVoucher: { data: voucher, isLoading, isError },
      refundVoucher,
    } = useVoucher(voucherId);

    const voucherIsUndefined = useMemo(
      () => !voucher || Object.keys(voucher ?? {}).length === 0,
      [voucher]
    );

    const handleClose = () => {
      modal.hide();
      modal.reject();
    };

    const handleRefund = async () => {
      try {
        await NiceModal.show(ConfirmDialog, {
          title: t('title', 'dialogs.refund'),
          headline: t('headline', 'dialogs.refund', {
            refundAmount: `${voucher?.originalAmount} ${voucher?.currency.toUpperCase()}`,
            customerName: voucher?.customer.name,
          }),
          confirmLabel: t('actions.primary', 'dialogs.refund'),
        });
        return refundVoucher.mutate();
      } catch (err) {
        console.log(err);
      }
    };

    return (
      <Dialog
        open={modal.visible}
        onClose={handleClose}
        sx={{
          '& .MuiPaper-root': {
            minWidth: isSm ? 0 : 600,
          },
        }}
      >
        <Stack gap={4} p={4}>
          <CloseRounded onClick={handleClose} sx={{ cursor: 'pointer' }} />
          <Stack gap={1}>
            <Stack gap={2} direction={'row'}>
              <Text fontSize={'xlarge'} variant={'medium'}>
                {t('title')}
              </Text>
              {!voucherIsUndefined && voucher && voucher.status && (
                <Chip
                  component={'span'}
                  label={t(voucher?.status, 'giftcards.statusOptions')}
                  color={statusColorMap[voucher?.status]}
                  style={{ fontWeight: 600 }}
                />
              )}
            </Stack>
            {!voucherIsUndefined && !isLoading && (
              <Text
                fontSize={'small'}
                style={{ color: lightTheme.palette.neutral.n400 }}
              >
                ID: {voucher?.id}
              </Text>
            )}
          </Stack>
          <Stack gap={2}>
            {((voucherIsUndefined && isLoading) ||
              (!voucherIsUndefined && !isLoading)) &&
              dialogRows.map((row) => {
                if (row.key === 'refund' && !voucher?.refund?.date) return;

                return (
                  <Stack
                    key={row.key}
                    direction={'row'}
                    gap={1}
                    mt={row.key === 'customerName' ? 2 : 0}
                  >
                    <Text variant={'medium'}>{t(row.key)}:</Text>
                    {isLoading ? (
                      <Skeleton width={100} />
                    ) : (
                      voucher && <Text>{row.value(voucher, t)}</Text>
                    )}
                  </Stack>
                );
              })}
            {voucherIsUndefined && isError && (
              <Text>{t('toast.error', 'dialogs.writeParticipants')}</Text>
            )}
            {voucherIsUndefined && !isError && (
              <CircularProgress size={40} sx={{ alignSelf: 'center', justifySelf: 'center' }} />
            )}
          </Stack>
          {!voucherIsUndefined && !isLoading && (
            <Stack
              direction={isSm ? 'column' : 'row'}
              gap={2}
              justifyContent={'right'}
            >
              {!voucher?.refund?.date && (
                <Button
                  size='medium'
                  variant='secondary'
                  onClick={handleRefund}
                >
                  {t('actions.secondary')}
                </Button>
              )}
              <a href={`mailto:${voucher?.customer?.email}`}>
                <Button
                  size='medium'
                  variant='primary'
                  style={{ width: '100%' }}
                >
                  {t('actions.primary')}
                </Button>
              </a>
            </Stack>
          )}
        </Stack>
      </Dialog>
    );
  }
);
