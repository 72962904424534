import { Button, lightTheme, LinkButton, Text } from '@holdbar-com/pixel';
import { Stack, SxProps, Theme } from '@mui/material';
import { PropsWithChildren, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '../../../Hooks/useAuth';
import { useTranslate } from '../../../Hooks/useTranslate';
import { trackSignUpFlowCompleted } from '../../../tracking/signup/trackSignUpFlowCompleted';
import { SignupForm } from '../signup/signup-form';
import { InvitationData } from './invite-view';

export const InviteDialog = ({
  invitation,
}: {
  invitation: InvitationData;
}) => {
  const { t } = useTranslate('invite');

  const { acceptInvitation } = useAuth();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const { existingUser: isEmailUsed } = invitation;

  const handleSubmit = async (password?: string) => {
    if (!password) return;
    const { profileId } = await acceptInvitation(
      invitation.email,
      invitation.id,
      password,
      invitation.name
    );

    trackSignUpFlowCompleted(profileId, true);
    navigate('/dashboard');
  };

  return (
    <DialogContainer>
      <Text variant="medium" fontSize="xlarge">
        {t('title')}
      </Text>
      <Stack maxWidth="485px" marginTop="16px">
        <Text>{t('description', { companyName: invitation.companyName })}</Text>
        <Text>{isEmailUsed ? t('acceptInvitation') : t('createAccount')}</Text>
      </Stack>
      {isEmailUsed ? (
        <AcceptRejectInvitation invitation={invitation} />
      ) : (
        <Stack marginTop="40px">
          <SignupForm
            invitedEmail={invitation.email}
            isSubmitting={isSubmitting}
            setIsSubmitting={setIsSubmitting}
            callback={handleSubmit}
          />
        </Stack>
      )}
    </DialogContainer>
  );
};

export const AcceptRejectInvitation = ({
  invitation,
}: {
  invitation: InvitationData;
}) => {
  const { t } = useTranslate('invite.action');
  const { acceptInvitation } = useAuth();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const handleAcceptInvitation = async () => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    try {
      const { profileId } = await acceptInvitation(
        invitation.email,
        invitation.id,
        undefined,
        invitation.name
      );

      trackSignUpFlowCompleted(profileId, true);
      navigate('/dashboard');
    } catch (error) {
      alert(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Stack direction="row" justifyContent="flex-end" marginTop="75px" gap={2}>
      <LinkButton
        href="/dashboard"
        variant="secondary"
        size="large"
        disabled={isSubmitting}
      >
        {t('reject')}
      </LinkButton>
      <Button
        variant="primary"
        size="large"
        onClick={() => handleAcceptInvitation()}
        disabled={isSubmitting}
      >
        {t('accept')}
      </Button>
    </Stack>
  );
};

export const DialogContainer = ({
  children,
  sx,
}: PropsWithChildren & { sx?: SxProps<Theme> }) => {
  return (
    <Stack
      sx={{
        height: { xs: '100%', sm: 'unset' },
        width: { xs: '100%', sm: 'unset' },
        maxWidth: '640px',
        backgroundColor: 'white',
        padding: { xs: '16px', sm: '40px 32px 32px 40px' },
        margin: { sm: '16px' },
        borderRadius: { xs: 0, sm: '16px' },
        boxShadow: { xs: 'unset', sm: lightTheme.shadows.xlarge },
        flexGrow: 1,
        ...sx,
      }}
    >
      {children}
    </Stack>
  );
};
