import { Stack } from '@mui/material';
import {
  addDays,
  format,
  isAfter,
  isBefore,
  isEqual,
  isSameDay,
  parseISO,
  setHours,
} from 'date-fns';
import { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { DateInput } from '../../../Components/inputs/date-input';
import { TimeInput } from '../../../Components/inputs/time-input';
import { useTranslate } from '../../../Hooks/useTranslate';
import {
  trackQuickSetupTimeStepFromDateOpened,
  trackQuickSetupTimeStepFromDateSelected,
  trackQuickSetupTimeStepFromTimeOpened,
  trackQuickSetupTimeStepFromTimeSelected,
  trackQuickSetupTimeStepToDateOpened,
  trackQuickSetupTimeStepToDateSelected,
  trackQuickSetupTimeStepToTimeOpened,
  trackQuickSetupTimeStepToTimeSelected,
} from '../../../tracking/quick-setup/step-events';
import { DateWithTime, OnboardingFormInputs } from '../onboarding-flow-form';
import { ErrorText } from './shared';
import { OnboardingStepLayout } from './step-layout';

export const TimeStep = () => {
  const { t } = useTranslate('onboarding.dialog.step.time.input');

  const {
    control,
    watch,
    formState: {
      errors: {
        eventStartDate: startDateError,
        eventStartTime: startTimeError,
        eventEndDate: endDateError,
        eventEndTime: endTimeError,
      },
      isValid,
    },
    setValue,
    setError,
    clearErrors,
  } = useFormContext<OnboardingFormInputs>();

  const watchedStartDate = watch('eventStartDate');
  const watchedEndDate = watch('eventEndDate');
  const watchedStartTime = watch('eventStartTime');
  const watchedEndTime = watch('eventEndTime');

  const shouldValidateEndtime = useMemo(
    () => isSameDay(parseISO(watchedStartDate), parseISO(watchedEndDate)),
    [watchedEndDate, watchedStartDate]
  );

  const minDate = useMemo(
    () => format(addDays(new Date(), 1), 'yyyy-MM-dd'),
    []
  );

  return (
    <OnboardingStepLayout showPreview>
      <Stack gap={3}>
        <Stack direction="row" gap={2} maxWidth={300}>
          <Controller
            name="eventStartDate"
            defaultValue={minDate}
            control={control}
            rules={{
              required: {
                value: true,
                message: t('startDate.requiredMessage'),
              },
              min: {
                value: minDate,
                message: t('startDate.minMessage'),
              },
            }}
            render={({ field: { onChange, ...props } }) => {
              return (
                <DateInput
                  {...props}
                  value={parseISO(watchedStartDate)}
                  label={t('startDate.label')}
                  required
                  minDate={minDate}
                  error={!isValid && !!startDateError}
                  onClick={trackQuickSetupTimeStepFromDateOpened}
                  onChange={(newValue) => {
                    if (!newValue) return onChange(undefined);

                    const startDate = parseISO(newValue);
                    const endDate = parseISO(watchedEndDate);

                    onChange(newValue);
                    if (isAfter(startDate, endDate)) {
                      setValue('eventEndDate', newValue, {
                        shouldValidate: true,
                      });
                    }

                    trackQuickSetupTimeStepFromDateSelected({
                      date: newValue,
                      time: watchedStartTime,
                    });
                  }}
                />
              );
            }}
          />
          <Controller
            name="eventStartTime"
            defaultValue="12:00"
            control={control}
            rules={{
              required: {
                value: true,
                message: t('startTime.requiredMessage'),
              },
            }}
            render={({ field: { onChange, ...props } }) => (
              <TimeInput
                {...props}
                label={t('startTime.label')}
                sx={{ flex: 2 }}
                slotProps={{
                  textField: {
                    error: !isValid && !!startTimeError,
                    required: true,
                    InputLabelProps: { shrink: true },
                  },
                }}
                onChange={(newValue) => {
                  trackQuickSetupTimeStepFromTimeSelected({
                    date: watchedStartDate,
                    time: newValue ?? '',
                  });

                  if (!newValue) {
                    return setError('eventStartTime', {
                      type: 'required',
                      message: t('startTime.requiredMessage'),
                    });
                  } else {
                    clearErrors('eventStartTime');
                  }

                  if (!shouldValidateEndtime) return onChange(newValue);

                  const startDate = DateWithTime(
                    newValue,
                    parseISO(watchedStartDate)
                  );
                  const endDate = DateWithTime(
                    watchedEndTime,
                    parseISO(watchedEndDate)
                  );

                  onChange(newValue);
                  if (
                    isEqual(startDate, endDate) ||
                    isAfter(startDate, endDate)
                  ) {
                    const newEndTime = setHours(
                      endDate,
                      startDate.getHours() + 1
                    );
                    console.log();
                    setValue('eventEndDate', format(newEndTime, 'yyyy-MM-dd'), {
                      shouldValidate: true,
                    });
                    setValue('eventEndTime', format(newEndTime, 'HH:mm'), {
                      shouldValidate: true,
                    });
                  }
                }}
                onOpen={trackQuickSetupTimeStepFromTimeOpened}
              />
            )}
          />
        </Stack>
        <Stack direction="row" gap={2} maxWidth={300}>
          <Controller
            name="eventEndDate"
            defaultValue={format(addDays(new Date(), 1), 'yyyy-MM-dd')}
            control={control}
            rules={{
              required: {
                value: true,
                message: t('endDate.requiredMessage'),
              },
              min: {
                value: watchedStartDate,
                message: t('endDate.minMessage'),
              },
            }}
            render={({ field: { onChange, ...props } }) => {
              return (
                <DateInput
                  {...props}
                  value={parseISO(watchedEndDate)}
                  label={t('endDate.label')}
                  required
                  minDate={minDate}
                  error={!isValid && !!endDateError}
                  onClick={trackQuickSetupTimeStepToDateOpened}
                  onChange={(newValue) => {
                    if (!newValue) return onChange(undefined);

                    const startDate = parseISO(watchedStartDate);
                    const endDate = parseISO(newValue);

                    onChange(newValue);
                    if (isBefore(endDate, startDate)) {
                      setValue('eventStartDate', newValue, {
                        shouldValidate: true,
                      });
                    }

                    trackQuickSetupTimeStepToDateSelected({
                      date: newValue,
                      time: watchedEndTime,
                    });
                  }}
                />
              );
            }}
          />
          <Controller
            name="eventEndTime"
            defaultValue="13:00"
            control={control}
            rules={{
              required: { value: true, message: t('endTime.requiredMessage') },
              // If start date and end data are equal, make sure end time is after start time
              validate: (value) => {
                if (!shouldValidateEndtime) return true;

                const startDate = DateWithTime(watchedStartTime);
                const endDate = DateWithTime(value);

                if (isAfter(endDate, startDate)) return true;

                return t('endTime.minMessage');
              },
            }}
            render={({ field: { onChange, ...props } }) => (
              <TimeInput
                {...props}
                label={t('endTime.label')}
                sx={{ flex: 2 }}
                slotProps={{
                  textField: {
                    error: !isValid && !!endTimeError,
                    required: true,
                    InputLabelProps: { shrink: true },
                  },
                }}
                onChange={(newValue) => {
                  trackQuickSetupTimeStepToTimeSelected({
                    date: watchedEndDate,
                    time: newValue ?? '',
                  });

                  if (!newValue) {
                    return setError('eventEndTime', {
                      type: 'required',
                      message: t('endTime.requiredMessage'),
                    });
                  } else {
                    clearErrors('eventEndTime');
                  }

                  onChange(newValue);
                }}
                onOpen={trackQuickSetupTimeStepToTimeOpened}
              />
            )}
          />
        </Stack>
        {!isValid && (
          <Stack gap={1}>
            {startDateError && <ErrorText>{startDateError.message}</ErrorText>}
            {startTimeError && <ErrorText>{startTimeError.message}</ErrorText>}
            {endDateError && <ErrorText>{endDateError.message}</ErrorText>}
            {endTimeError && <ErrorText>{endTimeError.message}</ErrorText>}
          </Stack>
        )}
      </Stack>
    </OnboardingStepLayout>
  );
};
