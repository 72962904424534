import NiceModal from '@ebay/nice-modal-react';
import { Button, lightTheme, Text } from '@holdbar-com/pixel';
import { ArrowBackRounded } from '@mui/icons-material';
import { Stack, styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { AppShell } from '../../Components/AppShell/AppShell';
import { Badge } from '../../Components/badge/badge';
import Connected from '../../Components/icons/connected';
import { PageBreadcrumb } from '../../Components/Page/page_breadcrumb';
import { PageBreadcrumbItem } from '../../Components/Page/page_breadcrumb/ui/page_breadcrumb_breadcrumbs/page-breadcrumb-item';
import { PageBreadcrumbBreadcrumbs } from '../../Components/Page/page_breadcrumb/ui/page_breadcrumb_breadcrumbs/PageBreadcrumbBreadcrumbs';
import { BookingDetailsActionsMobile } from '../../features/bookings/booking_details/ui/booking_details_actions/ui/BookingDetailsActionsMobile';
import { BookingDetailsActionsPopoverItem } from '../../features/bookings/booking_details/ui/booking_details_actions/ui/BookingDetailsActionsMobileItem';
import { EconomicIntegrationLoader } from '../../features/economic/economic-integration-loader';
import useResponsive from '../../Hooks/layout/useResponsive';
import { useTranslate } from '../../Hooks/useTranslate';
import { useEconomicIntegration } from '../../Sections/SettingsSections/Integrations/domain/integrations/use-economic-integration';
import { ConfirmDialog } from '../../Sections/SettingsSections/Integrations/ui/shared/confirm-dialog';

export const EconomicDetailsPage = () => {
  const { t } = useTranslate('utils.generic');
  const navigate = useNavigate();
  const { isMd } = useResponsive();

  const handleBack = () => {
    navigate('/settings/integrations');
  };

  return (
    <AppShell hideContainerPadding>
      <PageBreadcrumbBreadcrumbs>
        <PageBreadcrumbItem title="Settings" href="/settings" />
        <PageBreadcrumbItem
          title="Integrations"
          href="/settings/integrations"
        />
        <PageBreadcrumbItem
          title={t('title', 'settings.integrations.economic')}
          href="/settings/integrations/economic"
        />
      </PageBreadcrumbBreadcrumbs>
      <PageBreadcrumb>
        <Stack gap={4} width={'100%'}>
          <Stack gap={{ xs: 2, lg: 0 }} pb={2}>
            <Stack direction="row" justifyContent="space-between">
              <Stack gap={isMd ? 1 : 2} direction={isMd ? 'column' : 'row'}>
                <StyledBackArrowRounded onClick={handleBack} />
                <Stack gap={1}>
                  <Stack direction="row" gap={2} alignItems={'center'} mt={1}>
                    <Text fontSize="large" variant="medium">
                      {t('title', 'settings.integrations.economic')}
                    </Text>
                    <Badge appearance="success">
                      <Connected /> {t('integrated')}
                    </Badge>
                  </Stack>
                </Stack>
              </Stack>
              <EconomicDetailsPageHeaderActions />
            </Stack>
          </Stack>
          <EconomicIntegrationLoader isDetails />
        </Stack>
      </PageBreadcrumb>
    </AppShell>
  );
};

const EconomicDetailsPageHeaderActions = () => {
  const { t } = useTranslate('utils.generic');
  const navigate = useNavigate();
  const { isSm } = useResponsive();

  const economicIntegration = useEconomicIntegration();

  const handleRemoveIntegration = () => {
    NiceModal.show(ConfirmDialog, {
      title: t('title', 'settings.integrations.removeIntegrationDialog'),
      description: t(
        'description',
        'settings.integrations.removeIntegrationDialog'
      ),
      cta: t('action', 'settings.integrations.removeIntegrationDialog'),
      onConfirm: economicIntegration?.onUninstall,
    });
  };

  const handleEdit = () => {
    navigate('/settings/integrations/economic/edit');
  };

  if (isSm) {
    return (
      <BookingDetailsActionsMobile>
        <BookingDetailsActionsPopoverItem
          label={t('action', 'settings.integrations.removeIntegrationDialog')}
          onClick={handleRemoveIntegration}
        />
        <BookingDetailsActionsPopoverItem
          label={t('edit')}
          onClick={handleEdit}
        />
      </BookingDetailsActionsMobile>
    );
  }

  return (
    <Stack direction={'row'} gap={1}>
      <Button
        variant="secondary"
        size="medium"
        type={'button'}
        style={{ color: lightTheme.palette.error.e400 }}
        onClick={handleRemoveIntegration}
      >
        {t('action', 'settings.integrations.removeIntegrationDialog')}
      </Button>
      <Button
        variant="secondary"
        size="medium"
        type={'button'}
        onClick={handleEdit}
      >
        {t('edit')}
      </Button>
    </Stack>
  );
};

const StyledBackArrowRounded = styled(ArrowBackRounded)({
  height: '33px',
  width: '33px',
  padding: '4px',
  cursor: 'pointer',
  borderRadius: '50%',
  marginTop: '3.5px',
  transition: 'background-color 0.1s',
  '&:hover': {
    backgroundColor: lightTheme.palette.neutral.n100,
  },
  '&:active': {
    backgroundColor: lightTheme.palette.neutral.n200,
  },
});
