import { ExperienceTag } from '@holdbar-com/utils-types';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import {
  createExperienceTag,
  deleteExperienceTag,
  getExperienceTags,
  updateExperienceTag,
} from '../Api';

export const useExperienceTags = (tagId?: string) => {
  const queryClient = useQueryClient();

  const ExperienceTagQueryKey = ['tag', tagId];
  const ExperienceTagsQueryKey = ['tags'];

  const tag = useQuery<ExperienceTag>(
    ExperienceTagQueryKey,
    () => {
      queryClient.cancelQueries(ExperienceTagQueryKey);
      const tags = queryClient.getQueryData<ExperienceTag[]>(
        ExperienceTagsQueryKey
      );
      return tags?.find((el) => el.id === tagId) ?? ({} as ExperienceTag);
    },
    {
      enabled: !!tagId,
    }
  );

  const tags = useQuery<ExperienceTag[]>(
    ExperienceTagsQueryKey,
    async () => {
      await queryClient.cancelQueries(ExperienceTagsQueryKey);
      return await getExperienceTags();
    },
    {
      retry: false,
      refetchOnWindowFocus: true,
      refetchOnMount: true,
      onSuccess: (data) => {
        data?.forEach((el) => {
          queryClient.setQueryData([ExperienceTagQueryKey[0], el.id], () => ({
            ...el,
          }));
        });
      },
    }
  );

  const createTag = useMutation(
    (data: ExperienceTag['name']) => createExperienceTag(data),
    {
      onSettled: async () => {
        queryClient.invalidateQueries(ExperienceTagQueryKey);
        queryClient.invalidateQueries(ExperienceTagsQueryKey);
        queryClient.invalidateQueries(['experience']);
        queryClient.invalidateQueries(['experiences']);
      },
    }
  );

  const updateTag = useMutation(
    (data: ExperienceTag) => updateExperienceTag(data),
    {
      onMutate: async (data) => {
        await queryClient.cancelQueries(ExperienceTagQueryKey);

        const previous = queryClient.getQueryData<ExperienceTag>(
          ExperienceTagQueryKey
        );

        queryClient.setQueryData<ExperienceTag[]>(
          ExperienceTagsQueryKey,
          (prev) => {
            if (!tagId) return prev ?? [];

            const mapped =
              prev?.map((el) => {
                return el.id === data.id ? { ...data } : el;
              }) ?? [];
            return [
              ...mapped,
              ...(data.id
                ? []
                : [
                    {
                      ...data,
                      tagId,
                    },
                  ]),
            ];
          }
        );

        queryClient.setQueryData<ExperienceTag>(
          ExperienceTagQueryKey,
          (prev) => {
            return { ...prev!, ...data };
          }
        );

        return { previous };
      },
      onSettled: async () => {
        queryClient.invalidateQueries(ExperienceTagQueryKey);
        queryClient.invalidateQueries(ExperienceTagsQueryKey);
        queryClient.invalidateQueries(['experience']);
        queryClient.invalidateQueries(['experiences']);
      },
    }
  );

  const deleteTag = useMutation((id: string) => deleteExperienceTag(id), {
    onMutate: async () => {
      await queryClient.cancelQueries(ExperienceTagQueryKey);

      queryClient.setQueryData<ExperienceTag[]>(
        ExperienceTagsQueryKey,
        (prev) => {
          return prev?.filter((el) => el.id !== tagId) ?? [];
        }
      );
    },
    onSettled: async () => {
      queryClient.invalidateQueries(ExperienceTagQueryKey);
      queryClient.invalidateQueries(ExperienceTagsQueryKey);
      queryClient.invalidateQueries(['experience']);
      queryClient.invalidateQueries(['experiences']);
    },
  });

  return {
    tag,
    tags,
    createTag,
    updateTag,
    deleteTag,
  };
};
