import axios from './index';

export type Price = {
  amount: number;
  vatAmount?: number;
  amountExVat?: number;
  vatId?: string;
  vatRate?: number;
  currency: string;
};

export type Offer = {
  sub: string;
  parent?: string;
  org: string;
  experienceId: string;
  groupValidation?: { min: number; max: number };
  price: Price;
};

export type Payment = {
  subject: string;
  subjectId: string;
  items: {
    [variantId: string]: {
      count: number;
      price: number;
      name: string;
      offer:
        | {
            token: string;
          }
        | Offer;
    };
  };
};

export const updatePayment = async (paymentId: string, payload: Payment) => {
  if (typeof process.env.REACT_APP_API_PAYMENT_URL === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_PAYMENT_URL is not defined'
    );
  }

  await axios.put(
    `${process.env.REACT_APP_API_PAYMENT_URL}/payment/${paymentId}`,
    payload
  );
};

export const getPayment = async (id: string) => {
  if (typeof process.env.REACT_APP_API_PAYMENT_URL === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_PAYMENT_URL is not defined'
    );
  }

  await axios.get(`${process.env.REACT_APP_API_PAYMENT_URL}/${id}`);
};
