import {
  HoldbarVideo,
  Image,
  MediaItem,
  Video,
} from '@holdbar-com/utils-types';
import { useState } from 'react';
import { useTus } from 'use-tus';

import { getSigned, upload } from '../../../Api';
import { useAuthStore } from '../../../Store/useAuthStore';
import { TFileTypes, TModelTypes } from '../../../types';

export const useUploader = ({
  fileType,
  modelType,
  id,
  onUploaded,
}: {
  id: string;
  fileType: TFileTypes;
  modelType: TModelTypes;
  onUploaded: (files: MediaItem[]) => void;
}) => {
  const { setUpload } = useTus({ autoStart: true });
  const { auth } = useAuthStore();

  const [filestoBeUploaded, setFilesToBeUploaded] = useState<
    Array<{
      tempUrl: string;
      progress: number;
      type: 'image' | 'video';
      stockPhotoIndex?: number;
    }>
  >([]);

  const uploadVideo = async (file: File, localUrl: string, index: number) => {
    const videoData = {
      playback: {
        hls: '',
        dash: '',
      },
      type: 'video',
      provider: 'holdbar',
      mimeType: file.type,
      localUrl,
    } as Omit<HoldbarVideo, 'videoId' | 'thumbnail'>;

    return await new Promise<Omit<HoldbarVideo, 'videoId' | 'thumbnail'>>(
      (resolve, reject) => {
        setUpload(file, {
          endpoint: `${process.env.REACT_APP_API_UPLOAD_URL}/video`,
          metadata: {
            filename: file.name,
            filetype: file.type,
          },
          onAfterResponse: (req, res) => {
            const mediaIdHeader = res.getHeader('stream-media-id');
            if (mediaIdHeader) {
              videoData.playback.hls = `https://customer-d4ejfgep0mbcjixt.cloudflarestream.com/${mediaIdHeader}/manifest/video.m3u8`;
              videoData.playback.dash = `https://customer-d4ejfgep0mbcjixt.cloudflarestream.com/${mediaIdHeader}/manifest/video.mpd`;
              resolve(videoData);
            }
          },
          onBeforeRequest(req) {
            // We're generating the URL needed to perform the actual upload
            // on using our API. This means, we need to add authorization to
            // that call - but not the actual upload.
            if (!req.getURL().startsWith('https://upload.videodelivery.net')) {
              req.setHeader(
                'Authorization',
                `${auth?.token_type} ${auth?.access_token}`
              );
            }
          },
          onProgress: (bytesUploaded, bytesTotal) => {
            const progress = Math.round((bytesUploaded / bytesTotal) * 100);

            setFilesToBeUploaded((prev) => {
              const temp = [...prev];
              temp[index] = { ...temp[index], progress };
              return temp;
            });
          },
          onError: (error) => {
            reject(error);
          },
        });
      }
    );
  };

  const uploadImage = async (
    file: File,
    localUrl: string,
    index: number,
    stockPhotoIndex?: number
  ) => {
    const { uploadedTo, url } = await getSigned(
      fileType,
      modelType,
      file.type,
      id!
    );

    await upload(
      file,
      url,
      (progress) => {
        setFilesToBeUploaded((prev) => {
          const temp = [...prev];
          temp[index] = { ...temp[index], progress };
          return temp;
        });
      },
      file.type
    );

    const uploadedImage: Omit<Image, 'preview'> & {
      localUrl: string;
      stockPhotoIndex?: number;
    } = {
      url: uploadedTo,
      type: 'image',
      mimeType: file.type,
      localUrl,
      stockPhotoIndex,
    };

    return uploadedImage;
  };

  const onDrop = async (acceptedFiles: File[]) => {
    onSelect(acceptedFiles);
  };

  const onSelect = async (acceptedFiles: File[], stockPhotoIndex?: number) => {
    setFilesToBeUploaded(() => {
      return acceptedFiles.map((file) => {
        const localUrl = URL.createObjectURL(file);

        return {
          tempUrl: localUrl,
          progress: 2,
          type: file.type.includes('video/') ? 'video' : 'image',
          stockPhotoIndex,
        };
      });
    });

    try {
      const uploadedFiles = await Promise.all(
        acceptedFiles.map(async (file, index) => {
          const localUrl = URL.createObjectURL(file);

          if (file.type.includes('video/')) {
            return uploadVideo(file, localUrl, index) as Promise<Video>;
          } else {
            return uploadImage(
              file,
              localUrl,
              index,
              stockPhotoIndex
            ) as Promise<Image & { localUrl: string }>;
          }
        })
      );

      setFilesToBeUploaded([]);

      onUploaded(uploadedFiles);
      return uploadedFiles;
    } catch (error) {
      console.error(error);
    }
  };

  return {
    onDrop,
    onSelect,
    filestoBeUploaded,
  };
};
