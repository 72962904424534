import { Experience } from '@holdbar-com/utils-types';
import { differenceInCalendarDays } from 'date-fns';

import { getLocalized } from '../../../../Hooks/useBookings';
import { TEvent } from '../../../../Utils/eventHelpers';
import { getFormattedDate } from '../../../helpers';

export const eventProperties = (
  experience: Experience,
  events: TEvent[],
  event_id: string
) => {
  const { id: experience_id, headline, languages: language_list } = experience;

  const { number_of_active_events, first_event_date, guide_set, target_event } =
    events.reduce(
      (acc, event) => {
        if (event.status === 'active') {
          acc.number_of_active_events++;
        }

        if (
          !acc.first_event_date ||
          new Date(event.startDateTime) < acc.first_event_date
        ) {
          acc.first_event_date = new Date(event.startDateTime);
        }

        event.assignedGuides.forEach((guide) => acc.guide_set.add(guide));

        if (event.id === event_id) {
          acc.target_event = event;
        }

        return acc;
      },
      {
        number_of_active_events: 0,
        first_event_date: null as Date | null,
        guide_set: new Set<string>(),
        target_event: null as TEvent | null,
      }
    );

  const event_scheduled_date = target_event
    ? getFormattedDate(new Date(target_event.startDateTime))
    : '';

  const event_capacity = target_event?.slots.total ?? 0;

  const is_private = target_event?.visibility === 'private';

  const days_until_first_event_starts = first_event_date
    ? differenceInCalendarDays(first_event_date, new Date())
    : undefined;

  return {
    experience_name: getLocalized(headline, 'en') ?? '',
    experience_id,
    number_of_active_events,
    event_scheduled_date,
    language_list,
    event_capacity,
    number_of_guests: target_event?.slots.booked ?? 0,
    is_private,
    days_until_first_event_starts,
    number_of_guides: guide_set.size,
    event_id,
  };
};
