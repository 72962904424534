import styled from '@emotion/styled';
import { Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import useResponsive from '../../../Hooks/layout/useResponsive';
import { useFireOnce } from '../../../Hooks/useFireOnce';
import { trackSignUpViewed } from '../../../tracking/signup/trackSignUpViewed';
import { SignupCard } from './signup-card';
import { SignUpHero } from './signup-hero';

export const SignupView = () => {
  const { i18n } = useTranslation();
  const { isMd } = useResponsive();
  const fireOnce = useFireOnce();

  const [searchParams, setSearchParams] = useSearchParams();
  const [hasAppliedLanguage, setHasAppliedLanguage] = useState(false);

  useEffect(() => {
    if (hasAppliedLanguage) return;
    const languageParam = searchParams.get('language');
    if (!languageParam) return;

    i18n.changeLanguage(languageParam);
    setHasAppliedLanguage(true);
  }, [hasAppliedLanguage, i18n, searchParams, setSearchParams]);

  useEffect(() => {
    fireOnce(trackSignUpViewed);
  }, [fireOnce]);

  if (isMd) return <SignupCard />;

  return (
    <Grid>
      <SignUpHero />
      <Stack justifyContent="center" alignItems="center">
        <SignupCard />
      </Stack>
    </Grid>
  );
};

const Grid = styled.div({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  height: '100vh',
});
