import 'video.js/dist/video-js.css';
import './DraggableVideoPreview.css';

import { useEffect, useRef } from 'react';
import videojs from 'video.js';

export const DraggableVideoPreview = ({
  options,
  styles,
  onReady,
}: {
  options: any;
  styles?: {
    parent: { [key: string]: string };
    video: { [key: string]: string };
  };
  onReady?: (player: ReturnType<typeof videojs>) => void;
}) => {
  const videoRef = useRef<HTMLDivElement>(null);
  const playerRef = useRef<ReturnType<typeof videojs> | null>(null);

  useEffect(() => {
    if (!playerRef.current) {
      const videoElement = document.createElement('video-js');
      videoElement.classList.add('vjs-big-play-centered');

      videoRef.current?.appendChild(videoElement);

      const player = (playerRef.current = videojs(videoElement, options, () => {
        onReady && onReady(player);
      }));

      if (styles) {
        Object.keys(styles.parent ?? {}).forEach((key) => {
          videoElement.style.setProperty(key, styles.parent[key]);
        });

        const htmlVideo = videoElement.getElementsByTagName('video')[0];
        Object.keys(styles.video ?? {}).forEach((key) => {
          htmlVideo?.style.setProperty(key, styles.video[key]);
        });
      }
    } else {
      const player = playerRef.current;
      player.options(options);
    }
  }, [options, styles, videoRef]);

  useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <div data-vjs-player className="video-container">
      <div className="video-wrapper" ref={videoRef} />
    </div>
  );
};
