import CloseIcon from '@mui/icons-material/Close';
import { Card, Dialog, Grow, IconButton, Stack } from '@mui/material';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useCreateLocation } from '../../../Hooks/data/useLocations';
import useResponsive from '../../../Hooks/layout/useResponsive';
import { useWarnOnError } from '../../../Hooks/useWarnOnError';
import { ILocationView } from '../../../types/api/location';
import { LocationForm } from './form/LocationForm';
import {
  LocationFormVariant,
  useGetLocationInitialFormValues,
} from './form/use_location_form_values';

type CreateLocationDialogProps = {
  open: boolean;
  onClose: () => void;
};

export const CreateLocationDialog = ({
  open,
  onClose,
}: CreateLocationDialogProps) => {
  const { t } = useTranslation('');
  const { isSm } = useResponsive();

  const { mutate, isLoading, isError } = useCreateLocation();

  useWarnOnError(isError, t('dialogs.location.toast.createError'));

  const handleCreateLocation = useCallback(
    (data: ILocationView) => {
      mutate(
        {
          ...data,
        },
        { onSuccess: onClose }
      );
    },
    [mutate, onClose]
  );

  const initialValues = useGetLocationInitialFormValues();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionComponent={Grow}
      fullScreen={isSm}
      fullWidth={isSm}
    >
      <Card sx={{ overflow: 'visible' }}>
        <Stack alignItems="flex-end">
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <LocationForm
          variant={LocationFormVariant.create}
          initialValues={initialValues}
          isLoading={isLoading}
          onSubmit={handleCreateLocation as any}
        />
      </Card>
    </Dialog>
  );
};
