import { format } from 'date-fns';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useQuery } from 'react-query';

import * as api from '../../Api';
import { Metrics, MetricsV2 } from '../../Api/Analytics';

export type AccumulatedMetricsItem = {
  key: string;
  value: number | undefined;
};

const getDate = (date: Date) => format(date, 'yyyy-MM-dd');

const sumMetric = (key: string, values: { key: string; value: number }[]) => {
  return values.reduce(
    (total, { value, key: k }) => (key === k ? total + value : total),
    0
  );
};

export const useMetrics = (filters: {
  from: Date;
  to: Date;
  experience: string | null;
  selectedMetric: number;
}) => {
  const flags = useFlags();
  const isV2Enabled = flags.featureDashboardMetricsV2;

  const METRICS_QUERY_KEY = ['metrics', isV2Enabled];

  const metrics = useQuery(
    [
      METRICS_QUERY_KEY,
      filters.experience,
      getDate(filters.from),
      getDate(filters.to),
    ],
    () => {
      return api.getMetrics(
        getDate(filters.from),
        getDate(filters.to),
        filters.experience,
        isV2Enabled
      );
    },
    {
      enabled: typeof isV2Enabled === 'boolean',
      keepPreviousData: true,
    }
  );

  if (!metrics.data) {
    return {
      isLoading: metrics.isLoading || metrics.isPreviousData,
      isError: metrics.isError,
      graphSeries: [],
      accumulatedMetricsItems: [],
      metricNames: [],
    };
  }

  let metricsData: Metrics;
  let metadata: MetricsV2['metadata'] | undefined;

  if (isV2Enabled) {
    const { metrics: metricsDataV2, metadata: metadataV2 } =
      metrics.data as MetricsV2;
    metricsData = metricsDataV2;
    metadata = metadataV2;
  } else {
    metricsData = metrics.data as Metrics;
  }

  const metricNames = metricsData
    ? Object.values(metricsData)?.[0].map((x) => x.key)
    : [];

  const accumulatedMetricsItems = metricNames.reduce<AccumulatedMetricsItem[]>(
    (total, key) => {
      return [
        ...total,
        {
          key,
          value: sumMetric(key, Object.values(metricsData ?? {}).flat()),
        },
      ];
    },
    []
  );

  const graphSeries = Object.entries(metricsData ?? {})
    .sort((a, b) => Number(new Date(a[0])) - Number(new Date(b[0])))
    .reduce((acc, [date, values]) => {
      return {
        ...acc,
        [date]: values[filters.selectedMetric]?.value ?? 0,
      };
    }, {});

  return {
    isLoading: metrics.isLoading || metrics.isPreviousData,
    isError: metrics.isError,
    graphSeries,
    accumulatedMetricsItems,
    metricNames,
    metadata,
  };
};
