import { Text } from '@holdbar-com/pixel';
import { Stack } from '@mui/material';

import { useTranslate } from '../../../Hooks/useTranslate';
import { CalendarDesktopDateNavigation } from './date-navigation/CalendarDesktopDateNavigation';
import { CalendarEventPopover } from './event-popover/CalendarEventPopover';
import { EventPopoverContextProvider } from './event-popover/event-popover-context';
import { CalendarDesktopFilters } from './Filters';
import { CalendarDesktopList } from './List';

export const CalendarDesktopView = () => {
  const { t } = useTranslate('calendar');

  return (
    <EventPopoverContextProvider>
      <Stack marginTop={5} gap={2} paddingBottom={5}>
        <Text fontSize="h5">{t('title')}</Text>
        <CalendarDesktopFilters />
        <CalendarDesktopDateNavigation />
        <CalendarDesktopList />
        <CalendarEventPopover />
      </Stack>
    </EventPopoverContextProvider>
  );
};
