import { useMutation, useQueryClient } from 'react-query';

import * as api from '../../Api';
import { eventSchema } from '../../Pages/SyiPage/config';
import { IEvent, stripGuides, TEventFilter } from '../../Utils/eventHelpers';
import { useOnBoarding } from '../useOnBoarding';
import { useProfile } from '../useProfile';
import {
  EVENT_LIST_MAX_EVENTS_PER_PAGE,
  useExperienceEvents,
} from './useExperienceEvents';
import { getEventQueryKey } from './useGetEvent';
import { getEventsQueryKey } from './useGetEvents';

export const useUpdateEvent = (
  eventId?: string,
  experienceId?: string,
  eventFilter?: TEventFilter,
  page?: number
) => {
  const queryClient = useQueryClient();
  const { company, UserInfoQueryKey } = useProfile();
  const { updateStep } = useOnBoarding();

  const { EventsForExperienceQueryKey } = useExperienceEvents(
    experienceId,
    eventFilter,
    page,
    EVENT_LIST_MAX_EVENTS_PER_PAGE
  );
  const EventsQueryKey = getEventsQueryKey();

  const updateEvent = useMutation(
    ({ org, assignedGuides, slots, ...data }: IEvent) => {
      const userinfo = queryClient.getQueryData<{ org?: string }>(
        UserInfoQueryKey
      );
      return api.updateEvent(
        {
          ...data,
          org: org ?? company?.data?.id ?? userinfo?.org,
          assignedGuides: stripGuides(assignedGuides),
        },
        data.id ?? eventId
      );
    },
    {
      onMutate: async (data) => {
        const queryKey = getEventQueryKey(data.id ?? (eventId as string));

        const previousEvent = queryClient.getQueryData<IEvent>(queryKey);

        const previousEventsForExperience = queryClient.getQueryData<{
          totalCount: number;
          events: IEvent[];
        }>(EventsForExperienceQueryKey);

        queryClient.setQueryData<IEvent>(queryKey, (prev) => {
          return { ...(prev ?? {}), slots: {}, ...data };
        });

        queryClient.setQueryData<{
          totalCount: number;
          events: IEvent[];
        }>(EventsForExperienceQueryKey, (prev) => {
          return {
            totalCount: prev?.totalCount ?? 0,
            events:
              prev?.events?.map((el) =>
                el.id === data.id ? { ...el, ...data } : el
              ) ?? [],
          };
        });

        return {
          queryKey,
          previousEvent,
          previousEventsForExperience,
          experienceId: data.experienceId,
        };
      },
      onError: (err, variables, context: any) => {
        if (context?.previousEvent) {
          queryClient.setQueryData<IEvent>(
            context.queryKey,
            context.previousEvent
          );
        }

        if (context?.previousEventsForExperience) {
          queryClient.setQueryData<IEvent[]>(
            EventsForExperienceQueryKey,
            context.previousEvent
          );
        }
      },
      onSuccess: async (data, variables, context) => {
        updateStep(
          { ...variables, id: eventId },
          eventSchema,
          'event',
          'create'
        );
        await queryClient.invalidateQueries(context.queryKey);
        await queryClient.invalidateQueries(EventsQueryKey);
        await queryClient.invalidateQueries(EventsForExperienceQueryKey);
        await queryClient.invalidateQueries([
          'upcomingEventsCount',
          context?.experienceId ?? experienceId,
        ]);
      },
    }
  );

  return { updateEvent };
};
