import { useTranslate } from '../../../Hooks/useTranslate';
import { EventStatus, useCalendar } from '../context';
import { Filter } from './view/Filter';

const states: Array<{ id: EventStatus; translationKey: string }> = [
  {
    id: 'active',
    translationKey: 'active',
  },
  {
    id: 'inactive',
    translationKey: 'inactive',
  },
  {
    id: 'cancelled',
    translationKey: 'cancelled',
  },
];

export const EventStatusFilter = () => {
  const { t } = useTranslate('');

  const { eventStatusFilters, setEventStatusFilters } = useCalendar();

  const options = states.map((state) => ({
    id: state.id,
    name: t(state.translationKey, 'utils.statusOptions'),
  }));

  return (
    <Filter
      inputId="calendar-event-status-filter"
      label={t('status', 'utils.tables.header')}
      options={options}
      checkedIds={eventStatusFilters}
      onChange={(ids) => setEventStatusFilters(ids as EventStatus[])}
    />
  );
};
