import { Experience } from '@holdbar-com/utils-types';

import { ampli } from '../../../../Ampli';
import { EventType } from '../../../../features/events/event_upsert/domain/types';
import { BookingFormData } from '../../../../features/events/event_upsert/ui/booking/ui/booking_form/use_booking_form_validation';
import { eventProperties } from './eventProperties';

export const trackEventFlowBookingStepCompleted = (
  booking: BookingFormData,
  event: EventType,
  experiences?: Experience[]
) => {
  const properties = eventProperties(booking, event, experiences);

  ampli.eventFlowBookingStepCompleted(properties);
};
