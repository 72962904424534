import { Box, Button, Checkbox, FormControlLabel, Stack } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { AuthStatus, useAuth } from '../../Hooks/useAuth';
import { useMenu } from '../../Hooks/useMenu';
import type { TInput } from './AuthForm';
import { AuthForm } from './AuthForm';
import { AuthLayout } from './AuthLayout';
import { CompanyPicker } from './CompanyPicker';

interface LoginFormValues {
  email: string;
  password: string;
}
interface ChooseAccountFormValues {
  org: string;
}

const inputs: Array<TInput<LoginFormValues>> = [
  {
    key: 'email',
    type: 'text',
    isRequired: true,
    autoFocus: true,
    variant: 'outlined',
  },
  {
    key: 'password',
    type: 'password',
    isRequired: true,
    variant: 'outlined',
  },
];

enum PageState {
  Login,
  ChoseOrg,
}

export const LoginPage = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const menu = useMenu();
  const location = useLocation();
  const { signIn, switchToOrg } = useAuth();
  const [pageState, setPageState] = useState<PageState>(PageState.Login);
  const [companies, setCompanies] = useState<
    { id: string; name: string; logo?: string }[]
  >([]);

  const onSubmit = async (data: LoginFormValues | ChooseAccountFormValues) => {
    const { email, password, org } = data as {
      email: string;
      password: string;
      org?: string;
    };

    const auth = await (org ? switchToOrg(org) : signIn(email, password));

    if (auth.status === AuthStatus.SELECT_ORG) {
      setCompanies(auth.companies ?? []);
      setPageState(PageState.ChoseOrg);
    } else if (auth.status === AuthStatus.AUTHENTICATED) {
      //@ts-ignore
      const frontPage = menu.frontPageBasedOnScopes;
      const from = location.state?.from?.pathname || frontPage || '/';
      navigate(from, { replace: true });
    } else {
      // Handle error?
      throw new Error(auth.message);
    }
  };

  const handleGoToForgotPassword = () => navigate('/forgot');

  const handleGoToCreate = () => navigate('/signup');

  return pageState === PageState.Login ? (
    <AuthLayout title={t('auth.titles.login')}>
      <AuthForm inputs={inputs} onSubmit={onSubmit}>
        <Box
          sx={{
            display: { xs: 'flex', md: 'none' },
            justifyContent: 'space-between',
          }}
        >
          <FormControlLabel
            sx={{ color: '#3A3A3C', display: 'none' }}
            control={<Checkbox size="small" defaultChecked />}
            label="remember me"
          />
          <Button
            sx={{ color: '#007AFF', fontSize: '14px' }}
            onClick={handleGoToForgotPassword}
          >
            {t('auth.buttons.forgotPassword')}
          </Button>
        </Box>
      </AuthForm>

      <Stack
        sx={{ display: { xs: 'none', md: 'inline-flex' } }}
        direction={'column'}
        mt={{ xs: 4, md: 8 }}
        spacing={0.5}
      >
        <Button onClick={handleGoToForgotPassword}>
          {t('auth.buttons.forgotPassword')}
        </Button>
        <Button onClick={handleGoToCreate}>
          {t('auth.buttons.createAccount')}
        </Button>
      </Stack>
    </AuthLayout>
  ) : (
    <AuthLayout title={t('auth.titles.selectOrg')}>
      <CompanyPicker onSubmit={onSubmit} companies={companies} />
    </AuthLayout>
  );
};
