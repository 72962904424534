import { lightTheme, Text } from '@holdbar-com/pixel';
import { styled } from '@mui/material';
import { Link } from 'react-router-dom';

import useResponsive from '../../Hooks/layout/useResponsive';
import { useTranslate } from '../../Hooks/useTranslate';

export const ListEmpty = () => {
  const { t } = useTranslate('calendar.list');

  const { isSm } = useResponsive();

  if (isSm)
    return (
      <StyledMobileText color={lightTheme.palette.neutral.n300}>
        {t('emptyDescription')}
      </StyledMobileText>
    );

  return (
    <StyledDesktopText color={lightTheme.palette.neutral.n300}>
      {t('emptyDescription')}
    </StyledDesktopText>
  );
};

const StyledMobileText = styled(Text)({
  textAlign: 'center',
  marginTop: 24,
});

const StyledDesktopText = styled(Text)({
  textAlign: 'center',
  marginBlock: 58,
});

export const StyledLink = styled(Link)({
  '&:hover': {
    textDecoration: 'underline',
  },
});
