import { Connection } from '@holdbar-com/utils-types';

import { Badge, BadgeProps } from '../../../Components/badge/badge';
import Broken from '../../../Components/icons/broken';
import Connected from '../../../Components/icons/connected';
import Pending from '../../../Components/icons/pending';
import { useTranslate } from '../../../Hooks/useTranslate';

const badges: Record<
  Connection['state'],
  { appearance: BadgeProps['appearance']; icon: React.ReactNode }
> = {
  connected: {
    appearance: 'success',
    icon: <Connected />,
  },
  pending: {
    appearance: 'warning',
    icon: <Pending />,
  },
  disconnected: {
    appearance: 'default',
    icon: <Broken />,
  },
  requested: {
    appearance: 'warning',
    icon: <Pending />,
  },
};
export function ConnectBadge({ state }: { state: Connection['state'] }) {
  const { t } = useTranslate('connect');
  const badge = badges[state];

  return (
    <Badge size="medium" appearance={badge.appearance}>
      {badge.icon}
      {t(state)}
    </Badge>
  );
}
