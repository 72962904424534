import { Stack } from '@mui/material';
import { useState } from 'react';
import { RRule } from 'rrule';

import { useEventUpsert } from '../../../../../domain/event_upsert_context';
import { Recurring, RecurringCustom } from '../../../../../domain/types';
import { updateRRuleString } from '../rrule_string_helpers';
import {
  isRecurringCustom,
  isRecurringEvent,
} from '../use_time_form_recurring';
import { EventUpsertTimeFormRecurringEditButton } from './EventUpsertTimeFormRecurringEditButton';
import { EventUpsertTimeFormRecurringTypeSelect } from './EventUpsertTimeFormRecurringTypeSelect';
import { EventUpsertTimeFormRecurringEndDateField } from './EventUpsertTimeFromRecurringEndDateField';
import { EventUpsertTimeFormRecurringDialog } from './recurring_dialog/EventUpsertTimeFormRecurringDialog';
import { EventUpsertRecurringCustomFormTypes } from './recurring_dialog/use_recurring_custom_form_validation';

export const EventUpsertTimeFormRecurringSection = () => {
  const { currentEvent, updateEventField } = useEventUpsert();
  const [prevRecurring, setPrevRecurring] = useState<Recurring | undefined>();
  const isRecurring = isRecurringEvent(currentEvent.recurring);

  const handleSubmit = (data?: EventUpsertRecurringCustomFormTypes) => {
    if (!data || !isRecurring) return;

    // Byweekday overrules the frequency in the backend. Therefore, we need to
    // manually remove it from the rrule string if the frequency is not weekly.
    const updatedRRuleStr = updateRRuleString(
      (currentEvent.recurring as RecurringCustom).rrulestr,
      {
        interval: data?.interval,
        freq: data?.frequency,
        byweekday: data.frequency === RRule.WEEKLY ? data?.byweekday : [],
      }
    );

    updateEventField('recurring', {
      ...currentEvent.recurring,
      rrulestr: updatedRRuleStr,
    });

    setPrevRecurring(undefined);
  };
  const handleClose = () => {
    prevRecurring && updateEventField('recurring', prevRecurring);
    setPrevRecurring(undefined);
  };

  const isCustom = isRecurringCustom(currentEvent.recurring);
  const isOpen = !!prevRecurring;

  return (
    <Stack gap={3}>
      <Stack direction="row" gap={1}>
        <EventUpsertTimeFormRecurringTypeSelect
          onSelectCustom={() => setPrevRecurring(currentEvent.recurring)}
        />
        {isCustom && (
          <EventUpsertTimeFormRecurringEditButton
            onClick={() => setPrevRecurring(currentEvent.recurring)}
          />
        )}
        {isOpen && (
          <EventUpsertTimeFormRecurringDialog
            onSubmit={handleSubmit}
            onClose={handleClose}
          />
        )}
      </Stack>
      <EventUpsertTimeFormRecurringEndDateField />
    </Stack>
  );
};
