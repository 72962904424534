import { capitalize } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';

import { InfoBox } from '../../../../../../../Components/InfoBox/InfoBox';
import { Timeline } from '../../../../../../../Components/Timeline/Timeline';
import useResponsive from '../../../../../../../Hooks/layout/useResponsive';
import { useAudits } from '../../../../../../../Hooks/useAudits';
import {
  TranslateFunction,
  useTranslate,
} from '../../../../../../../Hooks/useTranslate';
import { useBookingDetailsContext } from '../../../../domain/use_booking_details_context';
import {
  getDaysSinceConfirmationSent,
  isCustomerNotification,
} from './domain/helpers';
import { BookingDetailsSummaryNotificationsActions } from './ui/BookingDetailsSummaryNotificationsActions';
import { BookingDetailsSummaryNotificationsMobile } from './ui/BookingDetailsSummaryNotificationsMobile';

export const BookingDetailsSummaryNotifications = () => {
  const { t } = useTranslate('dialogs.booking.notifications');
  const { isSm } = useResponsive();
  const [daysSinceConfirmationSent, setDaysSinceConfirmationSent] =
    useState<number>();

  const { booking } = useBookingDetailsContext();

  const {
    audits: { data: audits, isLoading: auditsLoading },
  } = useAudits('Booking', booking?.id ?? '');

  useEffect(() => {
    if (audits?.items) {
      const daysSinceConfirmationSent = getDaysSinceConfirmationSent(
        audits.items
      );
      setDaysSinceConfirmationSent(daysSinceConfirmationSent);
    }
  }, [audits]);

  const notifications = useMemo(
    () =>
      (audits?.items ?? [])
        .filter((log) => isCustomerNotification(log))
        .map((notification, index) => {
          const { createdAt, metadata } = notification;
          const status = metadata.notificationStatus;
          const isImportedNotification = Boolean(metadata.isImported);

          return {
            date: new Date(createdAt),
            title: constructNotificationTitle(
              t,
              metadata.notificationType,
              status,
              booking?.source,
              metadata.notificationRecipient
            ),
            ...(index === 0 && booking?.channel !== 'integration'
              ? {
                  content: (
                    <BookingDetailsSummaryNotificationsActions
                      notificationId={metadata.notificationId}
                      bookingId={booking?.id ?? ''}
                      name={booking?.customer?.name ?? ''}
                      address={booking?.customer?.email ?? ''}
                      hidePreview={
                        isImportedNotification || status === 'skipped'
                      }
                      disallowChangeRecipient={isImportedNotification}
                      daysSinceConfirmationSent={daysSinceConfirmationSent}
                    />
                  ),
                }
              : {}),
          };
        }),
    [audits, booking, isCustomerNotification, t]
  );

  const activities = booking
    ? [
        ...(!booking.movedFromEvent
          ? [
              {
                date: new Date(booking.created),
                title: t('bookingCreated', {
                  source: capitalize(booking?.source ?? ''),
                }),
              },
            ]
          : []),
        ...notifications,
      ]
    : [];

  if (!auditsLoading && activities.length === 0) {
    return null;
  }

  if (isSm) {
    return <BookingDetailsSummaryNotificationsMobile activities={activities} />;
  }

  return (
    <InfoBox title={t('notifications', 'dialogs.booking.sections')}>
      <Timeline activities={activities} isLoading={auditsLoading} />
    </InfoBox>
  );
};

const constructNotificationTitle = (
  t: TranslateFunction,
  notificationType: string,
  status: string,
  source?: string,
  recipient?: string
) => {
  const notificationTitle = t(`${notificationType}.${status}`, {
    source: capitalize(source ?? ''),
  });

  if (!recipient) {
    return notificationTitle;
  }

  const notificationRecipientMessage = t('recipient', {
    recipient: recipient,
  });

  return notificationTitle
    .trimEnd()
    .concat(' ')
    .concat(notificationRecipientMessage);
};
