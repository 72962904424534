import { SearchRounded } from '@mui/icons-material';
import { TextField } from '@mui/material';

import { useTranslate } from '../../../../Hooks/useTranslate';

export const GiftCardsSearchFormInput = ({
  query,
  setQuery,
}: {
  query: string;
  setQuery: (query: string) => void;
}) => {
  const { t } = useTranslate('utils.generic');

  return (
    <TextField
      InputProps={{ endAdornment: <SearchRounded /> }}
      size={'small'}
      placeholder={t('search')}
      value={query}
      onChange={(e) => setQuery(e.target.value)}
    />
  );
};
