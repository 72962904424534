import { lightTheme, Text } from '@holdbar-com/pixel';
import { renderDateTime } from '@holdbar-com/utils-date';
import { Experience } from '@holdbar-com/utils-types';
import { ArrowBackRounded } from '@mui/icons-material';
import { Skeleton, Stack } from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { ShowForScope } from '../../../Components/AllowForScope/AllowForScope';
import { StatusChip } from '../../../Components/CustomCard/CustomCard';
import useResponsive from '../../../Hooks/layout/useResponsive';
import { getLocalized } from '../../../Hooks/useBookings';
import { useHistory } from '../../../Hooks/useHistory';
import { useTranslate } from '../../../Hooks/useTranslate';
import { CancellationStates } from '../../../Modals/cancel_event/domain/types';
import { TEvent } from '../../../Utils/eventHelpers';
import { TStatus } from '../../../Utils/types';
import { EventDetailsActions } from './actions';
import { EventDetailsContext } from './EventDetailsView';

export const getStatus = (
  el: TEvent,
  experienceStatus: Experience['status'] | undefined
): TStatus => {
  if (!el || el.status === 'draft') return 'draft';
  if (el.status === 'cancelled') return 'cancelled';
  if (el.status === CancellationStates.FAILED_PARTLY)
    return CancellationStates.FAILED_PARTLY;
  if (el.status === CancellationStates.FAILED_ENTIRELY)
    return CancellationStates.FAILED_ENTIRELY;

  if (new Date(el.startDateTime) < new Date()) return 'done';
  return el.status === 'inactive' || experienceStatus === 'inactive'
    ? 'inactive'
    : 'active';
};

export const Header = () => {
  const { t } = useTranslate('events.detailsPage.card');
  const { isLg } = useResponsive();
  const { i18n } = useTranslation();

  const { event, experience, isLoading } = useContext(EventDetailsContext);

  const { goBack } = useHistory();

  const handleBack = () => {
    const fallbackLink = event?.experienceId
      ? `/experience/${event.experienceId}`
      : '/experiences';

    goBack(fallbackLink);
  };

  const eventTime = event
    ? renderDateTime(event.startDateTime, event.endDateTime, {
        standard: 'ddmmyyyyhh',
        sameDay: 'dmhh',
      })
    : undefined;

  const experienceTitle = getLocalized(experience?.headline, i18n.language);

  return (
    <Stack direction="row" justifyContent="space-between" gap={2}>
      <Stack gap={isLg ? 1 : 2} direction={isLg ? 'column' : 'row'}>
        <ArrowBackRounded onClick={handleBack} sx={ArrowStyles} />
        <Stack gap={1}>
          <Stack direction="row" gap={2} alignItems={'center'} mt={1}>
            {!isLoading ? (
              Boolean(eventTime && experienceTitle) && (
                <Text fontSize="large" variant="medium">
                  {`${eventTime} - ${experienceTitle}`}
                </Text>
              )
            ) : (
              <Skeleton width={200} height={25.2} />
            )}
            {event?.status && (
              <StatusChip
                sx={{
                  fontSize: { xs: '12px', md: '14px' },
                  alignSelf: 'flex-start',
                }}
                size={'small'}
                status={getStatus(event, experience?.status)}
              />
            )}
          </Stack>
          {!isLoading ? (
            <Text fontSize="xsmall" color={lightTheme.palette.neutral.n300}>
              {t(`visibility.${event?.visibility ?? 'public'}`)}
            </Text>
          ) : (
            <Skeleton width={75} height={19.2} />
          )}
        </Stack>
      </Stack>
      <ShowForScope scopes={['event.write']}>
        <EventDetailsActions />
      </ShowForScope>
    </Stack>
  );
};

const ArrowStyles = {
  height: '33px',
  width: '33px',
  padding: '4px',
  cursor: 'pointer',
  borderRadius: '50%',
  marginTop: '3.5px',
  transition: 'background-color 0.1s',
  '&:hover': {
    backgroundColor: lightTheme.palette.neutral.n100,
  },
  '&:active': {
    backgroundColor: lightTheme.palette.neutral.n200,
  },
};
