import { useTheme } from '@mui/material';
import { ApexOptions } from 'apexcharts';
import Chart from 'react-apexcharts';

export const Graph = ({ series, labels }: ApexOptions) => {
  const theme = useTheme();
  const options = {
    labels,
    colors: [theme.palette.primary.main, 'var(--color-green)'],
    chart: {
      height: '100%',
      type: 'line',
      stacked: true,
      animations: {
        speed: 500,
      },
      toolbar: {
        show: false,
      },
    },
    grid: {
      borderColor: '#F9F9F9',
      strokeDashArray: 0,
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
      padding: {
        top: 0,
        left: 15,
        right: 0,
        bottom: 0,
      },
    },
    stroke: {
      width: 3,
      curve: 'smooth',
    },
    yaxis: {
      decimalsInFloat: 0,
    },
    xaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      x: {
        show: false,
      },
    },
  } as ApexOptions;

  return (
    <Chart
      options={options}
      series={series}
      type="line"
      width="100%"
      height={'100%'}
    />
  );
};
