import styled from '@emotion/styled';
import { Grid } from '@mui/material';
import { useMemo } from 'react';

import { useIntegrations } from '../../domain/use-integrations';
import { AvailableIntegrationsListItem } from './available-integrations-list-item';

export const AvailableIntegrationsList = () => {
  const { availableIntegrations } = useIntegrations();

  const integrationsList = useMemo(
    () =>
      availableIntegrations.map((integration) => (
        <AvailableIntegrationsListItem key={integration.id} {...integration} />
      )),
    [availableIntegrations]
  );

  return (
    <StyledGrid container gap={2}>
      {integrationsList}
    </StyledGrid>
  );
};

const StyledGrid = styled(Grid)`
  @media (max-width: 600px) {
    display: grid;
    grid-gap: 16px;
    padding: 4px;
    grid-auto-flow: column;
    grid-auto-columns: minmax(260px, 1fr);
    overflow-x: auto;
    overflow-y: -webkit-paged-y;
    height: fit-content;
  }
`;
