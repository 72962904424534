export default function Pending({ size = 14 }: { size?: number }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 2V3.66667M8 12.5V15.1667M3.83333 8.5H1.5M14.1667 8.5H13.1667M12.3047 12.8047L11.8333 12.3333M12.4428 4.11052L11.5 5.05333M3.28105 13.219L5.16667 11.3333M3.41912 3.97245L4.83333 5.38667"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
