import { isArray } from 'lodash';

import { SearchParamSerializer } from './search-param-serializer';

const VALUE_SPLITTER = ',';

export class DefaultSerializer<T = string[]>
  implements SearchParamSerializer<T>
{
  serialize(value: T) {
    if (typeof value === 'string') return value;

    if (isArray(value)) {
      return value.join(VALUE_SPLITTER);
    }

    throw new Error('Unsupported value type - needs a serializer');
  }

  deserialize(value: string): T {
    return value.split(VALUE_SPLITTER).filter(Boolean) as T;
  }
}
