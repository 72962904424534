import { useEffect } from 'react';
import { toast } from 'react-toastify';

export const useWarnOnError = (isError: boolean, message: string) => {
  useEffect(() => {
    if (isError) {
      toast.error(message, {
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    }
  }, [isError, message]);
};
