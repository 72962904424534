import { Stack } from '@mui/material';
import { useEffect } from 'react';

import { PageBreadcrumb } from '../../../../Components/Page/page_breadcrumb';
import { PageBreadcrumbBreadcrumbsBooking } from '../../../../Components/Page/page_breadcrumb/ui/page_breadcrumb_breadcrumbs/booking/PageBreadcrumbBreadCrumbsBooking';
import { PageBreadcrumbBreadcrumbsEvent } from '../../../../Components/Page/page_breadcrumb/ui/page_breadcrumb_breadcrumbs/event/PageBreadcrumbBreadcrumbsEvent';
import { PageBreadcrumbBreadcrumbsExperience } from '../../../../Components/Page/page_breadcrumb/ui/page_breadcrumb_breadcrumbs/experience/PageBreadcrumbBreadcrumbsExperience';
import { PageBreadcrumbBreadcrumbsExperienceDetails } from '../../../../Components/Page/page_breadcrumb/ui/page_breadcrumb_breadcrumbs/experience/PageBreadcrumbBreadcrumbsExperienceDetails';
import { PageBreadcrumbBreadcrumbs } from '../../../../Components/Page/page_breadcrumb/ui/page_breadcrumb_breadcrumbs/PageBreadcrumbBreadcrumbs';
import { useFireOnce } from '../../../../Hooks/useFireOnce';
import { trackBookingDetailsPageOpened } from '../../../../tracking/bookings/details';
import { useBookingDetailsContext } from '../domain/use_booking_details_context';
import { BookingDetailsHeader } from './booking_details_header/BookingDetailsHeader';
import { BookingDetailsSummary } from './booking_details_summary/BookingDetailsSummary';
import { PageBreadcrumbBreadCrumbsBookingsList } from '../../../../Components/Page/page_breadcrumb/ui/page_breadcrumb_breadcrumbs/booking/PageBreadcrumbBreadCrumbsBookingsList';
import { useBookingAccess } from '../../../../Hooks/useBookingAccess';

export const BookingDetailsView = () => {
  const { booking } = useBookingDetailsContext();
  const { bookingExperienceId, hasAccessToExperience } = useBookingAccess({
    booking,
  });
  const fireOnce = useFireOnce();

  useEffect(() => {
    if (booking?.experienceHeadline) {
      fireOnce(() => trackBookingDetailsPageOpened(booking));
    }
  }, [booking, fireOnce]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {!hasAccessToExperience ? (
        <PageBreadcrumbBreadcrumbs>
          <PageBreadcrumbBreadCrumbsBookingsList />
          <PageBreadcrumbBreadcrumbsBooking bookingId={booking?.id} />
        </PageBreadcrumbBreadcrumbs>
      ) : (
        <PageBreadcrumbBreadcrumbs>
          <PageBreadcrumbBreadcrumbsExperience />
          <PageBreadcrumbBreadcrumbsExperienceDetails
            experienceId={bookingExperienceId}
          />
          <PageBreadcrumbBreadcrumbsEvent eventId={booking?.eventId} />
          <PageBreadcrumbBreadcrumbsBooking bookingId={booking?.id} />
        </PageBreadcrumbBreadcrumbs>
      )}
      <PageBreadcrumb>
        <Stack width={'100%'} maxWidth={1200} minWidth={350} gap={4}>
          <BookingDetailsHeader />
          <BookingDetailsSummary />
        </Stack>
      </PageBreadcrumb>
    </>
  );
};
