import { lightTheme } from '@holdbar-com/pixel';
import { CircularProgress } from '@mui/material';

type LoadingProps = {
  isLoading: boolean;
};

export const Loading = ({ isLoading }: LoadingProps) => {
  return isLoading ? (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: lightTheme.palette.contrast.white,
        opacity: 0.75,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 9999,
      }}
    >
      <CircularProgress sx={{ color: lightTheme.palette.neutral.n500 }} />
    </div>
  ) : null;
};
