import styled from '@emotion/styled';
import { lightTheme, Text } from '@holdbar-com/pixel';

function stringAvatar(name: string) {
  const [firstLetter] = name.split('');
  return firstLetter.toUpperCase();
}

export function Avatar({
  imageUrl,
  stringValue,
  className,
}: {
  imageUrl?: string;
  stringValue: string;
  className?: string;
}) {
  if (imageUrl) {
    return (
      <StyledSpan>
        <StyledImage alt="" src={imageUrl} className={className} />
      </StyledSpan>
    );
  }
  return (
    <StyledSpan>
      <Text fontSize="large" variant="medium">
        {stringAvatar(stringValue)}
      </Text>
    </StyledSpan>
  );
}

const StyledSpan = styled('span')({
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '48px',
  height: '48px',
  borderRadius: '50%',
  color: lightTheme.palette.neutral.n400,
  backgroundColor: lightTheme.palette.neutral.n50,
});

const StyledImage = styled('img')({
  width: '100%',
  height: '100%',
  borderRadius: '50%',
});
