import { theme } from './theme';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import ToolbarPlugin from './plugins/ToolbarPlugin';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table';
import { ListItemNode, ListNode } from '@lexical/list';
import { CodeHighlightNode, CodeNode } from '@lexical/code';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import {
  TRANSFORMERS,
  $convertToMarkdownString,
  $convertFromMarkdownString,
} from '@lexical/markdown';
import './styles.css';
import CodeHighlightPlugin from './plugins/CodeHighlightPlugin';
import AutoLinkPlugin from './plugins/AutoLinkPlugin';
import { $getRoot } from 'lexical';
import { useEffect } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { Box } from '@mui/material';
const editorConfig = {
  // The editor theme
  theme,
  // Handling of errors during update
  onError(error) {
    console.log(error);
  },

  // Any custom nodes go here
  nodes: [
    HeadingNode,
    ListNode,
    ListItemNode,
    QuoteNode,
    CodeNode,
    CodeHighlightNode,
    TableNode,
    TableCellNode,
    TableRowNode,
    AutoLinkNode,
    LinkNode,
  ],
};

const UpdatePlugin = ({ value }) => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    editor.update(
      () => {
        const currentHtmlString = $convertToMarkdownString(TRANSFORMERS);

        if (currentHtmlString !== value) {
          $convertFromMarkdownString(value, TRANSFORMERS);
        }
      },
      {
        tag: 'collaboration',
      }
    );
  }, [value]);
};

export const RichEditor = ({ placeholder, value, onChange, ...props }) => {
  const handleChange = (state, editor) => {
    editor.update(() => {
      if ($getRoot().getTextContent()) {
        const markdown = $convertToMarkdownString(TRANSFORMERS);
        const a = markdown.replaceAll('\n\n', '');
        const b = value.replaceAll('\n\n', '');
        if (a !== b) {
          onChange(markdown);
        }
      } else {
        onChange('');
      }
    });
  };

  return (
    <LexicalComposer
      initialConfig={{
        ...editorConfig,
      }}
    >
      <Box className="editor-container" {...props}>
        <ToolbarPlugin toolbarCommands={[]} />
        <div className="editor-inner">
          <UpdatePlugin value={value} />
          <RichTextPlugin
            contentEditable={<ContentEditable className="editor-input" />}
            placeholder={
              <div className="editor-placeholder">{placeholder}</div>
            }
          />
          <OnChangePlugin onChange={handleChange} />
          <HistoryPlugin />
          <CodeHighlightPlugin />
          <ListPlugin />
          <LinkPlugin />
          <AutoLinkPlugin />
        </div>
      </Box>
    </LexicalComposer>
  );
};
