import { toISODateTime } from '@holdbar-com/utils-date';
import {
  differenceInMinutes,
  format,
  isBefore,
  isSameDay,
  parseISO,
  set,
} from 'date-fns';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { DateInput } from '../../../../../../../Components/inputs/date-input';
import { trackEventFlowEndDateOpened } from '../../../../../../../tracking/events/flow/time/form/trackEventFlowEndDateOpened';
import { trackEventFlowEndDateSelected } from '../../../../../../../tracking/events/flow/time/form/trackEventFlowEndDateSelected';
import { useEventUpsert } from '../../../../domain/event_upsert_context';
import { isIntervalSelected } from '../../../../domain/types';
import { parseTime } from './event_upsert_time_form';
import { EventUpsertTimeFormTypes } from './use_time_form_validation';

export const EventUpsertTimeFormEndDateField = () => {
  const { t } = useTranslation();
  const { currentEvent, updateEventField } = useEventUpsert();
  const {
    control,
    setValue,
    watch,
    formState: { errors },
    setError,
  } = useFormContext<
    Pick<
      EventUpsertTimeFormTypes,
      'endDateTime' | 'endDateTimeDisabled' | 'startDateTime'
    >
  >();

  const handleChangeDate = (dateInput: string | undefined) => {
    if (!dateInput)
      return setError('endDateTime', {
        type: 'required',
        message: t('eventUpsert.flow.time.form.errors.required'),
      });

    const date = parseISO(dateInput);

    const existingEndDateTime = parseISO(currentEvent.endDateTime);
    const updatedEndDateTime = set(date, {
      hours: existingEndDateTime.getHours(),
      minutes: existingEndDateTime.getMinutes(),
      seconds: existingEndDateTime.getSeconds(),
    });

    const startDate = parseISO(currentEvent.startDateTime);
    let totalDiffInMinutes = differenceInMinutes(updatedEndDateTime, startDate);

    // If end date is set to a date before the current start date,
    // we set the start date to the same date, to prevent having
    // and end date before the start date
    if (!isSameDay(date, startDate) && isBefore(date, startDate)) {
      const [hours, minutes] = parseTime(
        format(parseISO(currentEvent.startDateTime), 'HH:mm')
      );
      const newStartDate = set(updatedEndDateTime, { hours, minutes });

      setValue('startDateTime', toISODateTime(newStartDate), {
        shouldValidate: true,
      });
      totalDiffInMinutes = differenceInMinutes(
        updatedEndDateTime,
        newStartDate
      );
    }

    setValue('endDateTime', toISODateTime(updatedEndDateTime), {
      shouldValidate: true,
    });
    updateEventField('startEndTimeDiff', totalDiffInMinutes);
    updateEventField('endDateTime', toISODateTime(updatedEndDateTime));

    trackEventFlowEndDateSelected(date);
  };

  const onClickField = () => {
    trackEventFlowEndDateOpened();
  };

  const hasBookings = currentEvent.bookings && currentEvent.bookings.length > 0;
  const endDateTimeDisabled = watch('endDateTimeDisabled');

  return (
    <Controller
      name="endDateTime"
      control={control}
      render={({ field }) => (
        <DateInput
          label={t('eventUpsert.flow.time.form.endDate.label')}
          {...field}
          onChange={handleChangeDate}
          onClick={onClickField}
          minDate={format(new Date(), 'yyyy-MM-dd')}
          required
          disabled={
            isIntervalSelected(currentEvent.intervals) ||
            hasBookings ||
            endDateTimeDisabled
          }
          error={!!errors.endDateTime}
          helperText={errors.endDateTime?.message}
        />
      )}
    />
  );
};
