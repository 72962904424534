import { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { DefaultSerializer } from '../../features/calendar/search-params/serialization/default-serializer';
import { SearchParamSerializer } from '../../features/calendar/search-params/serialization/search-param-serializer';
import { changeSearchParam } from '../../Utils/search-params';

export type SearchParamOptions<Name, T> = {
  serializer?: SearchParamSerializer<T>;
  trackingFn?: (value: T) => void;
  onChange?: (name: Name, value: T) => Promise<void> | void;
};

export function useSearchParam<Name extends string, T = string[]>(
  name: Name,
  options?: SearchParamOptions<Name, T>
): [T, Dispatch<SetStateAction<T>>] {
  const [searchParams, setSearchParams] = useSearchParams();

  const { serializer, onChange, trackingFn } = options ?? {};

  const value = useMemo(() => {
    const defaultSerializer = new DefaultSerializer<T>();

    const queryValue = searchParams.get(name) ?? '';
    const deserialize =
      serializer?.deserialize ?? defaultSerializer.deserialize;
    return deserialize(queryValue);
  }, [name, searchParams, serializer?.deserialize]);

  const setValue = useCallback<Dispatch<SetStateAction<T>>>(
    (values) => {
      const newValues = values instanceof Function ? values(value) : values;

      trackingFn?.(newValues);
      onChange?.(name, newValues);

      const defaultSerializer = new DefaultSerializer<T>();
      const serialize = serializer?.serialize ?? defaultSerializer.serialize;

      setSearchParams((prev) =>
        changeSearchParam(prev, name, serialize(newValues))
      );
    },
    [name, onChange, serializer?.serialize, setSearchParams, trackingFn, value]
  );

  return [value, setValue];
}
