import NiceModal from '@ebay/nice-modal-react';
import { Button, lightTheme, Text } from '@holdbar-com/pixel';
import {
  Box,
  Card,
  CircularProgress,
  Divider,
  Grid,
  List,
  ListItem,
  Stack,
} from '@mui/material';
import React, { Fragment, ReactNode } from 'react';
import { toast } from 'react-toastify';

import { useTranslate } from '../../../Hooks/useTranslate';
import { InfoDialog } from '../../../Modals/InfoDialog';
import { useManageLanguages } from './use-manage-languages';
import { useTranslationStatus } from './use-translation-status';

export const StorefrontLanguageSection = () => {
  const { t } = useTranslate('storefront.languages');
  const { t: languageTranslations } = useTranslate('utils.languages');

  const [loadingButton, setLoadingButton] = React.useState<string | null>(null);

  const {
    isLoading,
    activeLanguages,
    notUsedLanguages,
    addLanguage,
    removeLanguage,
    setDefaultLanguage,
  } = useManageLanguages();

  const missingTranslationsForLanguage = useTranslationStatus();

  function showMissingTranslationsDialog(language: string) {
    const isMissingTranslations = missingTranslationsForLanguage(language);

    if (!isMissingTranslations) {
      return;
    }

    const contentTranslationsPromptShown = sessionStorage.getItem(
      'contentTranslationsPromptShown'
    );

    if (
      contentTranslationsPromptShown &&
      contentTranslationsPromptShown.toLowerCase() === 'true'
    ) {
      return;
    }

    NiceModal.show(InfoDialog, {
      info: [
        {
          title: '',
          content: t('missingTranslationsDialog'),
        },
      ],
    }).then(() => {
      sessionStorage.setItem('contentTranslationsPromptShown', 'true');
    });
  }

  return (
    <Stack gap={6}>
      <Stack gap={0.5}>
        <Text fontSize="large" variant="medium" as="h2">
          {t('title')}
        </Text>
        <Text as="p" fontSize={'small'}>
          {t('description')}
        </Text>
      </Stack>

      <Box component={Card} maxWidth={628} px={4}>
        <List>
          {activeLanguages.map((activeLanguage, index) => (
            <Fragment key={activeLanguage.key}>
              <ListItem disableGutters>
                <Grid
                  container
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  spacing={1}
                  paddingBottom={2}
                >
                  <Grid item flexGrow={1}>
                    <Stack>
                      <Text fontSize="medium" fontWeight="medium">
                        {languageTranslations(activeLanguage.key)}
                      </Text>
                      {activeLanguage.isDefault && (
                        <Text
                          fontSize="xsmall"
                          fontWeight="medium"
                          color={lightTheme.palette.neutral.n300}
                        >
                          {t('defaultLanguage')}
                        </Text>
                      )}
                    </Stack>
                  </Grid>
                  <Grid item>
                    <Button
                      size={'medium'}
                      variant={'secondary'}
                      onClick={() => {
                        setLoadingButton(`${activeLanguage.key}-setdefault`);
                        reactWithToast(
                          setDefaultLanguage(activeLanguage.key),
                          t('defaultLanguageSet'),
                          t('defaultLanguageNotSet'),
                          t('atLeastOneLanguage')
                        );
                      }}
                      disabled={activeLanguage.isDefault || isLoading}
                    >
                      <ButtonContent
                        isLoading={
                          isLoading &&
                          loadingButton === `${activeLanguage}-setdefault`
                        }
                      >
                        {t('setDefaultLanguage')}
                      </ButtonContent>
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      size="medium"
                      variant="secondary"
                      disabled={activeLanguages.length === 1 || isLoading}
                      onClick={() => {
                        setLoadingButton(`${activeLanguage.key}-remove`);
                        reactWithToast(
                          removeLanguage(activeLanguage.key),
                          t('languageRemoved'),
                          t('languageNotRemoved'),
                          t('atLeastOneLanguage')
                        );
                      }}
                      style={{
                        color:
                          activeLanguages.length > 1
                            ? lightTheme.palette.error.e300
                            : undefined,
                      }}
                    >
                      <ButtonContent
                        isLoading={
                          isLoading &&
                          loadingButton === `${activeLanguage}-remove`
                        }
                      >
                        {t('removeLanguage')}
                      </ButtonContent>
                    </Button>
                  </Grid>
                </Grid>
              </ListItem>
              {notUsedLanguages.length === 0 &&
              index === activeLanguages.length - 1 ? null : (
                <Divider component={'li'} sx={{ marginBottom: 2 }} />
              )}
            </Fragment>
          ))}
          {notUsedLanguages.map((notUsedLanguage) => (
            <ListItem
              key={notUsedLanguage}
              disableGutters
              sx={{
                paddingY: 1,
              }}
            >
              <Grid
                container
                justifyContent="space-between"
                alignItems={'center'}
              >
                <Grid item flexGrow={1}>
                  <Text>{languageTranslations(notUsedLanguage)}</Text>
                </Grid>
                <Grid item>
                  <Button
                    size="medium"
                    variant="primary"
                    disabled={isLoading}
                    onClick={() => {
                      setLoadingButton(`${notUsedLanguage}-add`);
                      reactWithToast(
                        addLanguage(notUsedLanguage),
                        t('languageAdded'),
                        t('languageNotAdded'),
                        t('atLeastOneLanguage')
                      ).then(() => {
                        showMissingTranslationsDialog(notUsedLanguage);
                      });
                    }}
                  >
                    <ButtonContent
                      isLoading={
                        isLoading && loadingButton === `${notUsedLanguage}-add`
                      }
                    >
                      {t('addLanguage')}
                    </ButtonContent>
                  </Button>
                </Grid>
              </Grid>
            </ListItem>
          ))}
        </List>
      </Box>
    </Stack>
  );
};

async function reactWithToast(
  promise: Promise<{ status: 'success' | 'error' }>,
  successMessage: string,
  errorMessage: string,
  atLeastOneLanguageErrorMessage: string
) {
  const result = await promise;
  if (result.status === 'success') {
    toast.success(successMessage);
  } else if (
    result.status === 'error' &&
    'error' in result &&
    result.error === 'atLeastOneLanguage'
  ) {
    toast.error(atLeastOneLanguageErrorMessage);
  } else {
    toast.error(errorMessage);
  }

  return promise;
}

function ButtonContent({
  isLoading,
  children,
}: {
  isLoading: boolean;
  children: ReactNode;
}) {
  return isLoading ? (
    <CircularProgress size="1em" color="inherit" />
  ) : (
    <>{children}</>
  );
}
