import { Text } from '@holdbar-com/pixel';
import { Chip, Stack } from '@mui/material';

import { Page } from '../Components/Page/Page';
import { SuggestionsList } from '../features/suggestions/suggestions-list';
import { useTranslate } from '../Hooks/useTranslate';

export const SuggestionsPage = () => {
  const { t } = useTranslate('suggestions');

  return (
    <Page
      sx={{
        maxWidth: '1200px',
        pr: { xs: 0, md: 2 },
        pb: { xs: 0, md: 10 },
      }}
    >
      <Stack direction={'row'} alignItems={'center'} gap={2} sx={{ mb: 2 }}>
        <Text fontSize={'h5'}>{t('title')}</Text>
        <Chip
          variant="filled"
          label={
            <Text variant="medium" fontSize="small">
              Beta
            </Text>
          }
          size="small"
        />
      </Stack>

      <SuggestionsList />
    </Page>
  );
};
