import NiceModal from '@ebay/nice-modal-react';
import {
  AddRounded,
  DeleteForeverRounded,
  EditRounded,
  LaunchRounded,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Chip,
  Divider,
  FormControl,
  GridProps,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';
import { format, formatISO } from 'date-fns';
import { enGB } from 'date-fns/locale';
import randomBytes from 'randombytes';
import React, {
  ChangeEvent,
  Fragment,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useController, useFormContext, useWatch } from 'react-hook-form';
import { Link, useOutletContext } from 'react-router-dom';
import { Options, RRule, WeekdayStr } from 'rrule';

import { StatusChip } from '../../Components/CustomCard/CustomCard';
import { DateController } from '../../Components/DateController/DateController';
import { ExperienceCard } from '../../Components/ExperienceCard/ExperienceCard';
import { FrequencyUntilSelect } from '../../Components/FrequencyUntilSelect/FrequencyUntilSelect';
import { LanguageChips } from '../../Components/LanguageChips/LanguageChips';
import {
  OptionGroup,
  OptionGroupSelectedSection,
} from '../../Components/OptionGroup/OptionGroup';
import { useExperience } from '../../Hooks/useExperience';
import { useTranslate } from '../../Hooks/useTranslate';
import { TUser } from '../../Hooks/useUsers';
import { FrequencyDialog } from '../../Modals/FrequencyDialog';
import { SyiSection } from '../../Pages/SyiPage/SyiSection';
import { useErrors } from '../../Pages/SyiPage/useErrors';
import { languageOptions as getLanguageOptions } from '../../Utils/config';
import { SOURCE_LANG } from '../ExperienceSyiSections/ExperienceSyiSectionDescription';

const _recurringOptions = [
  {
    key: 'no',
    label: 'Nej, det afholdes kun denne ene gang',
  },
  {
    key: 'yes',
    label: 'Ja, lad mig definere frekvens',
  },
];

const _intervalOptions = [
  {
    key: 'no',
    label: 'Nej, kun et om dagen',
  },
  {
    key: 'yes',
    label: 'Ja, lad mig definere intervaller',
  },
];

const _weekdays: WeekdayStr[] = ['MO', 'TU', 'WE', 'TH', 'FR', 'SA', 'SU'];

const DEFAULT_FREQUENCY_COUNT = 100;

const frequencyOptions = [
  {
    key: 'daily',
    label: 'Dagligt',
    rruleOptions: {
      freq: RRule.DAILY,
      interval: 1,
      count: DEFAULT_FREQUENCY_COUNT,
      byweekday: _weekdays.map((el: WeekdayStr) => RRule[el]),
    },
  },
  {
    key: 'weekly',
    label: 'Ugentligt',
    rruleOptions: {
      freq: RRule.WEEKLY,
      interval: 1,
      count: DEFAULT_FREQUENCY_COUNT,
    },
  },
  {
    key: 'allBusinessDays',
    label: 'Alle hverdage',
    rruleOptions: {
      freq: RRule.DAILY,
      interval: 1,
      count: DEFAULT_FREQUENCY_COUNT,
      byweekday: [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR],
    },
  },
  {
    key: 'allWeekends',
    label: 'Alle weekender',
    rruleOptions: {
      freq: RRule.DAILY,
      interval: 1,
      count: DEFAULT_FREQUENCY_COUNT,
      byweekday: [RRule.SA, RRule.SU],
    },
  },
  {
    key: 'custom',
    label: 'Tilpasset',
  },
];

const pad = (val: number) => (val < 10 ? `0${val}` : `${val}`);

const getTimeOptions = (
  fromMinutes: number = 0,
  intervals: number[] = [0, 15, 30, 45]
) => {
  const output: { key: string; label: string }[] = [];
  for (let i = 0; i < 24; i++) {
    for (let x = 0; x < 60; x++) {
      if (i * 60 + x < fromMinutes) continue;
      if (intervals.includes(x)) {
        output.push({
          key: `${i * 60 + x}`,
          label: `${pad(i)}:${pad(x)}`,
        });
      }
    }
  }
  return output;
};

export const mapAssignableGuidesToUsers = (ids: string[], users?: TUser[]) => {
  return (
    ids?.reduce((acc, id) => {
      const found = users?.find((el) => el.id === id);
      return found ? [...acc, found] : acc;
    }, [] as any[]) ?? []
  );
};

const calcTimeDiff = (fromMins: number, toMins: number) => {
  const diff = toMins - fromMins;
  const h = Math.floor(diff / 60);
  const m = diff % 60;
  return `${h > 0 ? `${h} t. ${m > 0 ? `${m} min` : ''}` : `${diff} min`}`;
};

export const IntervalContainer = ({
  renderItem,
  defaultValue,
  controllerKey,
}: {
  defaultValue: any;
  controllerKey: string;
  renderItem: (
    el: any,
    i: number,
    props: {
      onChange: (evt: ChangeEvent<HTMLInputElement>) => void;
      onDeleteItem: () => void;
    }
  ) => ReactNode;
}) => {
  const { t } = useTranslate('utils.generic');

  const { setValue } = useFormContext();

  const {
    field: { value, onChange },
  } = useController({ name: controllerKey, defaultValue });

  useEffect(() => {
    if (!value || value?.length === 0) {
      setValue(controllerKey, defaultValue, { shouldDirty: false });
    }
  }, []);

  const getNextTime = ({
    fromTime,
    toTime,
  }: {
    fromTime: string;
    toTime: string;
  }) => {
    if (!fromTime || !toTime) return {};
    return {
      id: randomBytes(16).toString('hex'),
      fromTime: getTimeOptions(parseInt(toTime))[0]?.key,
      toTime: getTimeOptions(
        parseInt(toTime) + (parseInt(toTime) - parseInt(fromTime))
      )[0]?.key,
    };
  };

  const handleAddVariant = () => {
    const nextTime = getNextTime(value?.[value.length - 1] ?? {});
    onChange([...(value ?? []), nextTime]);
  };

  const handleDeleteVariant = (index: number) => () => {
    const _filtered = [
      ...(value ?? []).filter((_: never, i: number) => i !== index),
    ];
    onChange(_filtered.length > 0 ? _filtered : null);
  };

  return (
    <>
      <Stack spacing={2}>
        {value?.map((el: any, i: number) => (
          <Fragment key={el.id}>
            {renderItem(el, i, {
              onDeleteItem: handleDeleteVariant(i),
              onChange: (evt) => onChange(evt.target.value),
            })}
          </Fragment>
        ))}
      </Stack>
      <Button
        sx={{ borderRadius: 100, mt: 2 }}
        variant={'outlined'}
        startIcon={<AddRounded />}
        onClick={handleAddVariant}
      >
        {t('addTime')}
      </Button>
    </>
  );
};

const IntervalController = ({
  formKey,
  onDelete,
  ...props
}: GridProps & { onDelete: () => void; formKey: string }) => {
  const { t } = useTranslate('utils.generic');

  const {
    field: { value, onChange },
  } = useController({ name: `${formKey}.fromTime` });
  const {
    field: { value: valueTo, onChange: onChangeTo },
  } = useController({ name: `${formKey}.toTime` });

  return (
    <Stack spacing={2} direction={'row'} alignItems={'center'}>
      <FormControl sx={{ width: 320 }}>
        <InputLabel
          sx={{ backgroundColor: 'white', pl: 0.5, pr: 0.5 }}
          id="frequency-select-label"
        >
          {t('fromTime')}
        </InputLabel>
        <Select
          labelId="frequency-select-label"
          id="frequency-select"
          value={value}
          MenuProps={{ sx: { maxHeight: 400 } }}
          onChange={(evt) => onChange(evt.target.value as string)}
        >
          {getTimeOptions().map((el) => (
            <MenuItem value={el.key}>{el.label}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl sx={{ width: 320 }}>
        <InputLabel
          sx={{ backgroundColor: 'white', pl: 0.5, pr: 0.5 }}
          id="interval-select-label"
        >
          {t('toTime')}
        </InputLabel>
        <Select
          labelId="interval-select-label"
          id="interval-select"
          value={valueTo}
          disabled={!value}
          MenuProps={{ sx: { maxHeight: 400 } }}
          onChange={(evt) => onChangeTo(evt.target.value as string)}
        >
          {getTimeOptions(parseInt(value)).map((el) => (
            <MenuItem value={el.key}>
              {el.label} ({calcTimeDiff(parseInt(value), parseInt(el.key))})
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <IconButton onClick={onDelete}>
        <DeleteForeverRounded />
      </IconButton>
    </Stack>
  );
};

const getMins = (date: Date) => {
  const h = date.getHours();
  const m = date.getMinutes();
  return `${h * 60 + m}`;
};

const addDaysToDate = (date: Date, days = 1) => {
  date.setDate(date.getDate() + 1);
  return date;
};

const alignDay = (str: string, str2: string) => {
  const d = new Date(str).getDate();
  const m = new Date(str).getMonth();
  const y = new Date(str).getFullYear();
  const _new = new Date(str2);
  _new.setFullYear(y, m, d);
  return _new.toString();
};

export const toISODateTime = (date: Date) => {
  // the formatISO-function from date-fns returns
  // a string in the time zone of the browser.
  // Since all events are added in "local time",
  // we will simply remove any time zone notation.

  // Examples of formats:
  //       > _.formatISO(new Date(2023, 11, 4, 15, 0, 10))
  // UTC   < "2023-12-04T15:00:10Z"
  // UTC+1 < "2023-12-04T15:00:10+01:00"

  // Remove any non-UTC time zone
  const [_t] = formatISO(date).split('+');

  // Remove UTC time zone
  return _t.replace('Z', '');
};

export const applyMinsToDate = (date: string, mins: number) => {
  const h = Math.floor(mins / 60);
  const m = Math.floor(mins % 60);
  const d = new Date(date);
  d.setHours(h, m);
  return toISODateTime(d);
};

export const EventSyiSectionDetails = () => {
  const { t } = useTranslate('events.create.details');

  const { setValue, control, getValues, formState } = useFormContext();

  const { field: experienceId } = useController({ name: 'experienceId' });

  const { getError, clearError } = useErrors();

  const { state, shouldBeCloned } = useOutletContext<any>();

  const {
    experiences: { data: experiences },
    experience: { data: experience, isLoading },
  } = useExperience(experienceId.value);
  const { field: languages } = useController({
    name: 'languages',
    defaultValue:
      experience?.languages && experience?.languages.length <= 1
        ? experience?.languages
        : [],
  });

  const { field: bookings } = useController({ name: 'bookings' });
  const { field: isOneDay } = useController({
    name: 'isOneDay',
    defaultValue: true,
  });
  const { field: startDateTime } = useController({ name: 'startDateTime' });
  const { field: endDateTime } = useController({ name: 'endDateTime' });
  const { field: frequency } = useController({
    name: 'recurring.frequency.value',
  });
  const {
    field: { value: parentId },
  } = useController({ name: 'parentId' });

  const startDateInput = useWatch({ name: 'startDateTime' });
  const endDateInput = useWatch({ name: 'endDateTime' });

  const isRecurring = useMemo(() => {
    if (formState.touchedFields.hasOwnProperty('recurring')) {
      const { recurring, intervals } = getValues();
      return (
        recurring?.selectedOptionKey === 'yes' ||
        intervals?.selectedOptionKey === 'yes'
      );
    }
    return false;
  }, [formState.touchedFields]);

  const hasBookings = useMemo(() => {
    return Boolean(bookings.value?.length > 0);
  }, [bookings]);

  useEffect(() => {
    if (startDateInput) {
      clearError('startDateTime');
    }
    if (endDateInput) {
      clearError('endDateTime');
    }
  }, [startDateInput, endDateInput]);

  useEffect(() => {
    const { endDateTime, startDateTime } = state ?? {};
    if (endDateTime && startDateTime) {
      setValue('startDateTime', startDateTime, { shouldDirty: false });
      setValue('endDateTime', endDateTime, { shouldDirty: false });
    }
  }, []);

  const [intervalOptions, setIntervalOptions] = useState(
    (isOneDay.value
      ? _intervalOptions
      : _intervalOptions.map((el) => {
          return { ...el, disabled: true };
        })
    ).map((el) => ({ ...el, label: t(`intervals.options.${el.key}`) }))
  );

  const [recurringOptions, setRecurringOptions] = useState(
    (isOneDay.value
      ? _recurringOptions
      : _recurringOptions.map((el) => {
          return { ...el, disabled: false };
        })
    ).map((el) => ({ ...el, label: t(`recurring.options.${el.key}`) }))
  );

  const _frequencyOptions = frequencyOptions.map((el) => ({
    ...el,
    label: t(`recurring.frequency.options.${el.key}`),
  }));

  const handleAddEndDate = () => {
    endDateTime.onChange(
      toISODateTime(
        addDaysToDate(new Date(endDateTime?.value ?? startDateTime?.value))
      )
    );
    setValue('intervals', {
      selectedOptionKey: 'no',
      value: null,
    });
    setValue('recurring', {
      selectedOptionKey: 'no',
      value: null,
    });
    isOneDay.onChange(false);
    setIntervalOptions((p) =>
      p.map((el) => {
        return { ...el, disabled: true };
      })
    );
    setRecurringOptions((p) =>
      p.map((el) => {
        return { ...el, disabled: false };
      })
    );
  };

  const handleDeleteEndDate = () => {
    endDateTime.onChange(
      toISODateTime(new Date(alignDay(startDateTime.value, endDateTime.value)))
    );
    isOneDay.onChange(true);
    setIntervalOptions((p) =>
      p.map((el) => {
        return { ...el, disabled: false };
      })
    );
    setRecurringOptions((p) =>
      p.map((el) => {
        return { ...el, disabled: false };
      })
    );
  };

  const handleEditCustomFrequency = async () => {
    const { weekdays, freq, untilType, interval, until, count } =
      (await NiceModal.show(FrequencyDialog, { control })) as {
        freq: 'DAILY' | 'WEEKLY' | 'YEARLY';
        [key: string]: any;
      };

    const sd = new Date(startDateTime.value);

    generateRuleString({
      dtstart: new Date(
        Date.UTC(
          sd.getFullYear(),
          sd.getMonth(),
          sd.getDate(),
          sd.getHours(),
          sd.getMinutes(),
          0
        )
      ),
      freq: RRule[freq],
      count: untilType === 'occurrence' ? until : count,
      interval,
      until: untilType === 'date' && until ? new Date(until) : null,
      byweekday: weekdays.map((el: WeekdayStr) => RRule[el]),
    });
  };

  const generateRuleString = (
    options: Partial<Options>,
    execSave: (val: string) => void = (val) =>
      setValue('recurring.rrulestr', val, { shouldDirty: true })
  ) => {
    const rule = new RRule({
      byhour: 0,
      byminute: 0,
      bysecond: 0,
      ...options,
    });
    execSave(rule.toString());
  };

  const handleChangeFrequency =
    (opts: any = {}) =>
    async (evt: SelectChangeEvent) => {
      frequency.onChange(evt.target.value as string);
      switch (evt.target.value) {
        case 'daily':
        case 'allBusinessDays':
        case 'allWeekends':
          const options =
            frequencyOptions.find((el) => el.key === evt.target.value)
              ?.rruleOptions ?? {};
          return generateRuleString({ ...options, ...opts } as any);
        case 'weekly':
          const weekday = format(new Date(startDateTime.value), 'EEEEEE', {
            locale: enGB,
          }).toUpperCase();
          const _options =
            frequencyOptions.find((el) => el.key === evt.target.value)
              ?.rruleOptions ?? {};
          return generateRuleString({
            ...({ ..._options, ...opts } as any),
            byweekday: [RRule[weekday as WeekdayStr]],
          });
        case 'custom':
          return handleEditCustomFrequency();
      }
    };

  const handleResetExperience = () => {
    setValue('experienceId', null, { shouldDirty: false });
  };

  const handleChangeRecurring = (val: string) => {
    if (val === 'no') {
      setValue('recurring.rrulestr', null, { shouldDirty: true });
    }
  };

  const handleChangeExperience = (evt: SelectChangeEvent) => {
    experienceId.onChange(evt.target.value as string);
  };

  const handleChangeUntil = () => {
    const [type, val] = getValues(['recurring.untilType', 'recurring.until']);

    handleChangeFrequency({
      count: type === 'occurrence' && val ? val : DEFAULT_FREQUENCY_COUNT,
      until: type === 'date' && val ? new Date(val) : null,
    })({ target: { value: frequency.value } } as any);
  };

  const languageOptions = useMemo(() => {
    return experience?.languages?.reduce(
      (obj: { [k: string]: any }, key: string) => {
        return {
          ...obj,
          [key]: {
            ...getLanguageOptions(key),
          },
        };
      },
      {}
    );
  }, [experience]);

  return (
    <>
      <Stack direction={'row'} justifyContent={'space-between'}>
        <Typography variant={'h4'}>{t('title')}</Typography>
      </Stack>

      <Stack mt={2} divider={<Divider />} spacing={4}>
        <SyiSection
          Component={Stack}
          alignItems={'flex-start'}
          title={t('experienceTitle')}
        >
          {experienceId.value ? (
            <Box display={'flex'} alignItems={'center'}>
              <ExperienceCard
                coverImage={experience?.pictures?.cover?.[0]?.url}
                title={experience?.headline?.[experience.languages[0]]}
                languages={experience?.languages}
                status={experience?.status ?? 'draft'}
                rating={{ average: 4, count: 34 }}
                createdDate={experience?.dates?.created}
                id={experience?.id}
              />
              {!shouldBeCloned && !hasBookings && (
                <Stack ml={2} spacing={1} alignItems={'center'}>
                  <IconButton color={'error'} onClick={handleResetExperience}>
                    <DeleteForeverRounded color={'error'} />
                  </IconButton>
                  <Link to={`/experience/${experienceId.value}`}>
                    <IconButton>
                      <LaunchRounded fontSize={'small'} />
                    </IconButton>
                  </Link>
                </Stack>
              )}
            </Box>
          ) : (
            <FormControl sx={{ minWidth: 320 }}>
              <InputLabel
                sx={{ backgroundColor: 'white', pl: 0.5, pr: 0.5 }}
                id="select-experience"
              >
                {t('buttons.selectExperience')}
              </InputLabel>
              <Select
                labelId="select-experience"
                value={experienceId.value}
                onChange={handleChangeExperience}
              >
                {experiences?.map((el) =>
                  el?.headline?.[SOURCE_LANG] ? (
                    <Box
                      component={MenuItem}
                      key={el.id}
                      value={el.id}
                      justifyContent={'space-between'}
                    >
                      <Box display={'flex'} alignItems={'center'} mr={2}>
                        <Box
                          component={'img'}
                          src={el?.pictures?.cover?.[0]?.url}
                          width={48}
                          height={48}
                          borderRadius={1}
                          mr={1}
                        />
                        {el?.headline?.[SOURCE_LANG] ?? ''}
                      </Box>
                      <StatusChip status={'active'} size={'small'} />
                    </Box>
                  ) : null
                )}
              </Select>
            </FormControl>
          )}
        </SyiSection>

        {experienceId.value && (
          <>
            <SyiSection title={t('languageTitle')}>
              <LanguageChips
                onChange={languages.onChange}
                multiple={true}
                allowEmpty={true}
                languages={languages.value}
                languageOptions={languageOptions}
                mt={3}
              />
            </SyiSection>

            <SyiSection
              Component={Stack}
              alignItems={'start'}
              spacing={3}
              error={getError('startDateTime') ?? getError('endDateTime')}
              typographyProps={{ mb: 1 }}
              title={t('selectTimeTitle')}
            >
              {hasBookings && <Chip label={t('selectTimeWithBookings')} />}
              <DateController
                startDateTime={startDateTime}
                endDateTime={endDateTime}
                hideTo={!isOneDay.value}
                disabled={hasBookings}
                name={'startDateTime'}
                label={
                  isOneDay.value
                    ? t('date', 'utils.generic')
                    : t('startDate', 'utils.generic')
                }
              />
              {!isOneDay.value ? (
                <DateController
                  label={t('endDate', 'utils.generic')}
                  hideFrom={true}
                  disabled={hasBookings}
                  onDelete={hasBookings ? undefined : handleDeleteEndDate}
                  startDateTime={endDateTime}
                  endDateTime={endDateTime}
                  name={'endDateTime'}
                />
              ) : (
                <Button
                  disabled={hasBookings || endDateTime.value === null}
                  sx={{ borderRadius: 100, mt: '0 !important' }}
                  color={'secondary'}
                  variant={'outlined'}
                  onClick={handleAddEndDate}
                  startIcon={<AddRounded />}
                >
                  {t('buttons.addEndDate')}
                </Button>
              )}
            </SyiSection>

            {!hasBookings && (
              <>
                <SyiSection title={t('intervals.title')}>
                  <OptionGroup
                    name={'intervals.selectedOptionKey'}
                    options={intervalOptions}
                  >
                    <OptionGroupSelectedSection optionKey={'yes'} mt={2}>
                      <IntervalContainer
                        defaultValue={[
                          {
                            fromTime: getMins(
                              new Date(startDateTime.value ?? null)
                            ),
                            toTime: getMins(
                              new Date(endDateTime.value ?? null)
                            ),
                          },
                        ]}
                        controllerKey={'intervals.value'}
                        renderItem={(el, i, { onDeleteItem, onChange }) => (
                          <IntervalController
                            mb={2}
                            formKey={`intervals.value.${i}`}
                            onDelete={onDeleteItem}
                          />
                        )}
                      />
                    </OptionGroupSelectedSection>
                  </OptionGroup>
                </SyiSection>

                <SyiSection title={t('recurring.title')}>
                  <OptionGroup
                    name={'recurring.selectedOptionKey'}
                    options={recurringOptions}
                    onChangeValue={handleChangeRecurring}
                  >
                    <OptionGroupSelectedSection optionKey={'yes'} mt={2}>
                      <FormControl sx={{ minWidth: 320 }}>
                        <InputLabel
                          sx={{ backgroundColor: 'white', pl: 0.5, pr: 0.5 }}
                          id="frequency-select-label"
                        >
                          {t('recurring.frequency.selectLabel')}
                        </InputLabel>
                        <Select
                          labelId="frequency-select-label"
                          id="frequency-select"
                          value={frequency.value}
                          onChange={handleChangeFrequency()}
                        >
                          {_frequencyOptions.map((el) => (
                            <MenuItem value={el.key}>{el.label}</MenuItem>
                          ))}
                        </Select>
                        {frequency.value === 'custom' && (
                          <Box mt={1}>
                            <Button
                              onClick={handleEditCustomFrequency}
                              startIcon={<EditRounded />}
                            >
                              {t('edit', 'utils.generic')}
                            </Button>
                          </Box>
                        )}

                        {frequency.value !== 'custom' && frequency.value && (
                          <FrequencyUntilSelect
                            control={control}
                            typeFormKey={'recurring.untilType'}
                            valueFormKey={'recurring.until'}
                            onChange={handleChangeUntil}
                            mt={2}
                          />
                        )}
                      </FormControl>
                    </OptionGroupSelectedSection>
                  </OptionGroup>
                </SyiSection>
              </>
            )}
          </>
        )}
      </Stack>
    </>
  );
};
