import { useMemo } from 'react';

import { getLocalized } from '../../../../Hooks/useBookings';
import { IExperience, useExperience } from '../../../../Hooks/useExperience';
import { useTranslate } from '../../../../Hooks/useTranslate';
import { TEventWithTitle } from '../../../../Utils/eventHelpers';
import { useCalendar } from '../../context';

type UseGroupedEventsReturn =
  | Record<string, { events: Array<TEventWithTitle>; experience: IExperience }>
  | undefined;

export const useGroupedEvents = (): UseGroupedEventsReturn => {
  const { i18n } = useTranslate('');
  const { events, experienceFilters } = useCalendar();
  const {
    experiences: { data },
  } = useExperience();

  const activeExperiences = useMemo(
    () =>
      data
        ?.filter((exp) => exp.status === 'active')
        ?.filter(
          ({ id }) =>
            experienceFilters.length === 0 ||
            experienceFilters.includes(id ?? '')
        ),
    [data, experienceFilters]
  );

  return useMemo(
    () => groupEventsByExperience(activeExperiences, events, i18n.language),
    [activeExperiences, events, i18n.language]
  );
};

const groupEventsByExperience = (
  experiences?: IExperience[],
  events?: TEventWithTitle[],
  language?: string
): UseGroupedEventsReturn => {
  if (!events || !experiences) return undefined;

  const groups = experiences.reduce<NonNullable<UseGroupedEventsReturn>>(
    (groups, experience) => {
      groups[experience.id ?? ''] = { events: [], experience };
      return groups;
    },
    {}
  );

  events.forEach((event) => {
    groups[event.experienceId].events.push(event);
  });

  // Sort the entries by experience name
  return Object.fromEntries(
    Object.entries(groups).sort(([, a], [, b]) => {
      const headlineA =
        getLocalized(a.experience.headline, language ?? 'en') ?? '';
      const headlineB =
        getLocalized(b.experience.headline, language ?? 'en') ?? '';

      return headlineA.localeCompare(headlineB, language, {
        sensitivity: 'base',
      });
    })
  );
};
