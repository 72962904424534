import { Outlet } from 'react-router-dom';

import { PageTabs } from '../Components/Page/PageTabs';
import { useTranslate } from '../Hooks/useTranslate';

export const GrowthPage = () => {
  const { t } = useTranslate('growth');

  const tabs = [
    { key: 'opportunities', scopes: ['marketing.write'] },
    { key: 'discounts', scopes: ['marketing.write'] },
    { key: 'emails', scopes: ['marketing.write'] },
    { key: 'tracking', scopes: ['marketing.write'] },
  ];

  return (
    <PageTabs title={t('title')} tabs={tabs}>
      <Outlet />
    </PageTabs>
  );
};
