import { Divider, Grid, Stack, TextField, Typography } from '@mui/material';
import { useMemo } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { OptionGroup } from '../../Components/OptionGroup/OptionGroup';
import { Tip } from '../../Components/Tip/Tip';
import { SyiSection } from '../../Pages/SyiPage/SyiSection';
import { useErrors } from '../../Pages/SyiPage/useErrors';

export const seatTypeOptions = [
  {
    key: 'single',
    unitLabelPlural: 'personer',
    unitLabelSingular: 'person',
    label: 'Som enkeltpersoner',
    allowParticipantType: true,
  },
  {
    key: 'couple',
    unitLabelPlural: 'par',
    unitLabelSingular: 'par',
    label: 'Som par',
    allowParticipantType: true,
  },
  {
    key: 'group',
    unitLabelPlural: 'grupper',
    unitLabelSingular: 'gruppe',
    label: 'Som grupper',
    allowParticipantType: true,
    mustHaveMinMax: true,
  } /*,{
    key: 'unique',
    unitLabelPlural: 'unikke enheder',
    label: 'Unik enhed',
    allowParticipantType: false,
    isNameable: true,
    canHaveMinMax: true
}*/,
];

export const ExperienceSyiSectionSeats = () => {
  const { t } = useTranslation();

  const { register, setValue } = useFormContext();

  const { getError, clearError } = useErrors();

  const { field: seatType } = useController({ name: 'seats.type' });
  const { field: seatCount } = useController({ name: 'seats.seatCount' });
  const { field: seatsCustomName } = useController({
    name: 'seats.customName',
  });

  const _seatTypeOptions = seatTypeOptions.map((el) => ({
    ...el,
    label: t(`experience.create.seats.type.options.${el.key}.label`),
  }));

  const selectedType = useMemo(() => {
    return _seatTypeOptions.find((el) => el.key === seatType.value);
  }, [seatType.value]);

  const handleChangeType = (newValue: string) => {
    clearError('seats.type');
    if (['single', 'couple'].includes(newValue)) {
      setValue('seats.maxParticipants', null);
    }
  };

  return (
    <>
      <Typography variant={'h4'} mb={2}>
        {t('experience.create.seats.title')}
      </Typography>

      <Tip label={t('experience.create.seats.titleHelpText')} />

      <Stack mt={3} divider={<Divider />} spacing={4}>
        <SyiSection
          title={t('experience.create.seats.type.title')}
          error={getError('seats.type')}
        >
          <OptionGroup
            field={seatType}
            options={_seatTypeOptions}
            onChangeValue={handleChangeType}
          />

          {/*{
                        selectedType?.isNameable && (
                            <>
                                <SyiSection mt={4} mb={4} title={t('experience.create.seats.type.options.unique.inputNameTitle')}>
                                    <Tip mt={2} mb={2.5} label={t('experience.create.seats.type.options.unique.inputNameTip')}/>
                                    <TextField
                                        sx={{ minWidth: 320, maxWidth: 320 }}
                                        label={t('experience.create.seats.type.options.unique.inputNamePlaceholder')}
                                        {...seatsCustomName}
                                        onChange={(val) => {
                                            seatsCustomName.onChange(val);
                                            clearError('seats.customName')
                                        }}
                                    />
                                </SyiSection>
                                <Divider/>
                            </>
                        )
                    }*/}

          <SyiSection
            mt={4}
            title={t('experience.create.seats.seatCount.title')}
            error={getError('seats.seatCount')}
          >
            <TextField
              disabled={!selectedType}
              sx={{ minWidth: 320, maxWidth: 320 }}
              label={t('experience.create.seats.seatCount.inputPlaceholder')}
              type={'number'}
              {...seatCount}
              inputProps={{
                min: 0,
              }}
              onChange={(val) => {
                seatCount.onChange(parseInt(val.target.value));
                clearError('seats.seatCount');
              }}
              helperText={t('experience.create.seats.seatCount.inputHelpText')}
            />
          </SyiSection>
        </SyiSection>

        {selectedType?.mustHaveMinMax && (
          <SyiSection
            title={`${t('experience.create.seats.type.utils.inputMinMaxTitle', { unitType: t(`experience.create.seats.utils.${selectedType.key}.singular`) ?? seatsCustomName.value ?? '' })}`}
          >
            <Grid container columns={2} spacing={3}>
              <Grid item xs={1}>
                <TextField
                  label={`${t('experience.create.seats.type.utils.inputMinLabel')}${selectedType?.mustHaveMinMax ? '*' : ''}`}
                  type={'number'}
                  fullWidth
                  inputProps={{
                    min: 0,
                  }}
                  {...register(`seats.minParticipants`, {
                    valueAsNumber: true,
                    min: 0,
                  })}
                />
              </Grid>
              <Grid item xs={1}>
                <TextField
                  label={`${t('experience.create.seats.type.utils.inputMaxLabel')}${selectedType?.mustHaveMinMax ? '*' : ''}`}
                  type={'number'}
                  fullWidth
                  inputProps={{
                    min: 0,
                  }}
                  {...register(`seats.maxParticipants`, {
                    valueAsNumber: true,
                    min: 0,
                  })}
                />
              </Grid>
            </Grid>

            <Tip
              mt={3}
              label={t('experience.create.seats.type.utils.inputHelpText')}
            />
          </SyiSection>
        )}
      </Stack>
    </>
  );
};
