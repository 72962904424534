import { useCallback, useEffect } from 'react';

import { ampli } from '../../../Ampli';

export const useEditParticipantsAmpli = () => {
  useEffect(() => {
    ampli.editCapacityOpen();
    return () => {
      ampli.editCapacityClose();
    };
  }, []);

  const trackCapacitySaveClick = useCallback(
    (previousCapacity: number, newCapacity: number) => {
      ampli.editCapacitySave({
        previousCapacity,
        newCapacity,
        trigger: 'click',
      });
    },
    []
  );

  const trackCapacitySaveEnter = useCallback(
    (previousCapacity: number, newCapacity: number) => {
      ampli.editCapacitySave({
        previousCapacity,
        newCapacity,
        trigger: 'enter',
      });
    },
    []
  );

  return {
    trackCapacitySaveClick,
    trackCapacitySaveEnter,
  };
};
