import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

import { useTranslate } from '../../../../../Hooks/useTranslate';
import { AVAILABLE_FONTS } from '../customization-options';
import { StorefrontCustomizeFormData } from '../storefront-styling-validation';

export const FontFamilySelect = () => {
  const { t } = useTranslate('storefront.customize');

  const {
    control,
    formState: { errors },
  } = useFormContext<Pick<StorefrontCustomizeFormData, 'fontFamily'>>();

  return (
    <Controller
      name="fontFamily"
      control={control}
      render={({ field }) => (
        <FormControl variant="outlined">
          <InputLabel id="font-select-label">{t('font')}</InputLabel>
          <Select
            {...field}
            label={t('font')}
            labelId="font-select-label"
            error={!!errors.fontFamily}
            sx={{ width: 222 }}
          >
            {AVAILABLE_FONTS.map((font) => (
              <MenuItem key={font} value={font}>
                {font}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    />
  );
};
