import axios from './index';

export type ExperiencePracticalInfoSuggestion = {
  suggestionId: string;
  suggestionType: 'description';
  entityType: 'experience';
  entityId: string;
  before: {
    practicalInfo: Record<string, string>;
    whatsIncluded: Record<string, string>;
  };
  after: {
    practicalInfo: Record<string, string>;
    whatsIncluded: Record<string, string>;
  };
};

export type ExperienceImagesSuggestion = {
  suggestionId: string;
  suggestionType: 'image-improvement';
  entityType: 'experience';
  entityId: string;
  content: Record<string, string>;
  score: number;
  totalScore: number;
};

export type Suggestion =
  | ExperiencePracticalInfoSuggestion
  | ExperienceImagesSuggestion;

export const getSuggestions = async (): Promise<Suggestion[]> => {
  if (typeof process.env.REACT_APP_INTERNAL_API_GATEWAY === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_BOOKINGS_URL is not defined'
    );
  }

  const url = `${process.env.REACT_APP_INTERNAL_API_GATEWAY}/v1/suggestions/`;
  const { data } = await axios.get<{ items: Suggestion[] }>(url);

  return data.items;
};

export type SuggestionDecision = 'accept' | 'ignore';

export const suggestionDecision = async (
  suggestionId: string,
  decision: SuggestionDecision
) => {
  if (typeof process.env.REACT_APP_INTERNAL_API_GATEWAY === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_BOOKINGS_URL is not defined'
    );
  }

  const url = `${process.env.REACT_APP_INTERNAL_API_GATEWAY}/v1/suggestions/${suggestionId}/decide/`;
  const { data } = await axios.post(url, { decision });

  return data;
};
