import { Experience } from '@holdbar-com/utils-types';

import { ampli } from '../../../../Ampli';
import { EventType } from '../../../../features/events/event_upsert/domain/types';
import { EventUpsertTimeFormTypes } from '../../../../features/events/event_upsert/ui/time/ui/time_form/use_time_form_validation';
import { eventProperties } from './eventProperties';

export const trackEventFlowTimeStepCompleted = (
  timeForm: EventUpsertTimeFormTypes,
  event: EventType,
  experiences?: Experience[]
) => {
  const properties = eventProperties(timeForm, event, experiences);

  ampli.eventFlowTimeStepCompleted(properties);
};
