import NiceModal, { useModal } from '@ebay/nice-modal-react';
import styled from '@emotion/styled';
import { Button, lightTheme, Text } from '@holdbar-com/pixel';
import { CameraAlt, CameraEnhance, CloseRounded } from '@mui/icons-material';
import {
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Stack,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { ExperienceImagesSuggestion } from '../../../Api/Suggestion';
import { useSuggestionDecision } from '../../../Hooks/data/useSuggestions';
import useResponsive from '../../../Hooks/layout/useResponsive';
import { useExperience } from '../../../Hooks/useExperience';
import { useLocalizedStringFormatter } from '../../calendar/desktop/hooks/useLocalizedStringFormatter';
import { ChatgptMarkdown } from '../chatgpt-markdown';
import { SuggestionsExperienceName } from '../suggestions-experience-name';

type Props = {
  suggestion: ExperienceImagesSuggestion;
};

export const SuggestionExperienceImagesModal = NiceModal.create<Props>(
  ({ suggestion }: Props) => {
    const modal = useModal();
    const { isSm } = useResponsive();

    const {
      experience: { data, isError },
    } = useExperience(suggestion.entityId);

    const localize = useLocalizedStringFormatter();

    const { ignoreSuggestion, acceptSuggestion } = useSuggestionDecision(
      suggestion.suggestionId
    );

    const { t } = useTranslation();

    if (isError) {
      return <Text>{t('utils.errors.generic')}</Text>;
    }

    const handleClose = () => {
      modal.hide();
      modal.remove();
    };

    const handleCallToAction = () => {
      acceptSuggestion();
      handleClose();
      toast.success('We will get in touch soon!');
    };

    const images = (data?.pictures?.cover ?? []).slice(0, 4);

    return (
      <Dialog
        fullWidth
        maxWidth={'md'}
        fullScreen={isSm}
        open={modal.visible}
        onClose={handleClose}
        sx={{ minHeight: '300px' }}
        PaperProps={{
          sx: {
            minHeight: '300px',
            padding: 3,
            paddingTop: 5,
          },
        }}
      >
        <IconButton
          sx={{
            top: 0,
            position: 'absolute',
            right: 0,
            color: lightTheme.palette.contrast.black,
          }}
          onClick={handleClose}
        >
          <CloseRounded sx={{ fontSize: { xs: '24px', md: '32px' } }} />
        </IconButton>
        <DialogContent sx={{ padding: 0 }}>
          <LayoutGrid>
            <Stack gap={2}>
              <Stack
                direction="row"
                gap={1}
                alignItems="center"
                flexWrap="wrap"
              >
                <CameraEnhance
                  sx={{
                    width: 32,
                    height: 32,
                    color: lightTheme.palette.success.s500,
                  }}
                />
                <Stack>
                  <Text
                    fontSize="xsmall"
                    style={{
                      lineHeight: 1,
                    }}
                  >
                    {t('suggestions.experienceImages.title')}
                  </Text>
                  <SuggestionsExperienceName
                    experienceId={suggestion.entityId}
                  />
                </Stack>
                <Chip
                  variant="filled"
                  label={
                    <Text variant="medium" fontSize="xsmall">
                      Beta
                    </Text>
                  }
                  size="small"
                />
              </Stack>

              <ChatgptMarkdown>{localize(suggestion.content)}</ChatgptMarkdown>
            </Stack>

            <ImagesGrid>
              {images.map((picture) => (
                <img
                  key={picture.url}
                  src={picture.url}
                  alt={''}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    overflow: 'hidden',
                  }}
                />
              ))}
            </ImagesGrid>
          </LayoutGrid>
        </DialogContent>
        <DialogActions sx={{ paddingTop: 3 }}>
          <OutlinedButton
            size="medium"
            variant="text"
            onClick={() => {
              ignoreSuggestion();
              handleClose();
            }}
          >
            {t('suggestions.ignore')}
          </OutlinedButton>
          <Button
            size="medium"
            variant="primary"
            onClick={() => handleCallToAction()}
            rightIcon={
              <CameraAlt
                width={16}
                sx={{
                  marginLeft: 1,
                }}
              />
            }
          >
            {t('suggestions.experienceImages.getBetterPhotos')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
);

const LayoutGrid = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 16px;
`;

const ImagesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;

  border: 1px solid ${lightTheme.palette.neutral.n100};
  border-radius: 8px;
  padding: 8px;
  margin-top: 8px;
`;

const OutlinedButton = styled(Button)`
  border: 1px solid ${lightTheme.palette.neutral.n200};
  border-radius: 8px;
`;
