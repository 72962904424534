import { TextField } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { EventUpsertRecurringCustomFormTypes } from './use_recurring_custom_form_validation';

export const EventUpsertTimeFormRecurringIntervalSelect = () => {
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext<Pick<EventUpsertRecurringCustomFormTypes, 'interval'>>();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = Number(event.target.value);

    setValue('interval', inputValue, {
      shouldValidate: true,
    });
  };

  return (
    <TextField
      {...register('interval')}
      label=""
      onChange={handleChange}
      error={!!errors.interval}
      helperText={errors.interval?.message ?? ' '}
      sx={{ maxWidth: '76px' }}
      type="number"
      InputProps={{ inputProps: { min: 0 } }}
    />
  );
};
