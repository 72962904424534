import randomBytes from 'randombytes';
import { ComponentPropsWithoutRef, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useLocation,
  useMatch,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { toast } from 'react-toastify';

import { AppShell } from '../Components/AppShell/AppShell';
import { LoadingFullscreen } from '../Components/Loading/LoadingFullscreen';
import { useExperience } from '../Hooks/useExperience';
import { useProfile } from '../Hooks/useProfile';
import { ExperienceDetailsPage } from './ExperienceDetailsPage';
import { experienceSchema, experienceSyiSteps } from './SyiPage/config';
import { SyiPage } from './SyiPage/SyiPage';

const defaultValues = {
  seats: {
    type: 'single',
  },
  price: {
    hasVariants: true,
  },
  cutoffTimeSeconds: 0,
  infoForGuests: {
    selectedOptionKey: 'no',
  },
  customData: {
    selectedOptionKey: 'no',
  },
  notifications: {
    selectedOptionKey: 'emailAndText',
  },
  visibility: 'public',
};

export const ExperiencePage = ({
  children,
}: ComponentPropsWithoutRef<'main'>) => {
  const { t } = useTranslation();

  const { id } = useParams<{ id: string }>();

  const [isLoading, setIsLoading] = useState(false);

  const {
    company: { data: company, isLoading: isLoadingCompany },
  } = useProfile();

  const {
    params: { type },
  } = useMatch(':key/:id/:type/*') ?? { params: {} };

  const { state } = useLocation();
  const navigate = useNavigate();

  const _id = useMemo(
    () => (id !== 'create' && id ? id : randomBytes(16).toString('hex')),
    [id]
  );

  const location = useLocation();

  const { experience, updateExperience, deleteExperience } = useExperience(
    _id!
  );

  const _steps = useMemo(() => {
    return experienceSyiSteps.map((el) => {
      return {
        ...el,
        label: t(`experience.create.progress.${el.key}`),
      };
    });
  }, []);

  if (id !== 'create' && type !== 'edit') {
    return (
      <AppShell hideContainerPadding>
        <ExperienceDetailsPage experienceId={_id}>
          {children}
        </ExperienceDetailsPage>
      </AppShell>
    );
  }

  if (isLoadingCompany)
    return <LoadingFullscreen isLoading={isLoadingCompany} />;

  const onSubmit = async (experience: any) => {
    setIsLoading(true);
    await updateExperience
      .mutateAsync(experience)
      .then(() => {
        navigate(`/experience/${_id}`, { replace: true });
        toast.success(
          id === 'create'
            ? t('experience.create.toast.creatingSuccess')
            : t('experience.edit.toast.editingSuccess')
        );
      })
      .catch(() => {
        toast.error(t('experience.create.toast.creatingError'));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <SyiPage
        modelType={'experience'}
        steps={_steps}
        returnUrl={location?.state?.returnUrl ?? '/experiences'}
        title={
          id === 'create'
            ? `${t('utils.generic.new')} ${t('utils.generic.experience')}`
            : `${t('utils.generic.edit')} ${t('utils.generic.experience')}`
        }
        deleteItem={deleteExperience}
        updateItem={updateExperience}
        item={experience}
        schema={experienceSchema(company)}
        state={state}
        onSubmit={onSubmit}
        defaultValues={{
          status: 'draft',
          ...(id === 'create'
            ? {
                ...defaultValues,
                languages: company?.languages,
              }
            : {}),
        }}
        id={_id}
        isCreateFlow={id === 'create'}
        isLoading={isLoading}
      >
        {children}
      </SyiPage>
    </>
  );
};
