import FeatureItem from '../../../../Components/FeatureItem/FeatureItem';
import { useProfile } from '../../../../Hooks/useProfile';

const FEATURE_ID = 'giftCards';

export const GiftCardsFeature = () => {
  const { companyProfileUrl } = useProfile();

  const link = companyProfileUrl() + '/giftcards';

  return <FeatureItem feature={FEATURE_ID} link={link} />;
};
