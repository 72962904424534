import { Box, BoxProps, Link, Typography } from '@mui/material';
import { ComponentPropsWithoutRef, forwardRef } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkBreaks from 'remark-breaks';

// eslint-disable-next-line react/display-name
export const Markdown = forwardRef(
  (
    {
      children,
      ...props
    }: Omit<BoxProps, 'children'> & ComponentPropsWithoutRef<any>,
    ref
  ) => {
    return (
      <Box ref={ref} {...props}>
        <ReactMarkdown
          remarkPlugins={[remarkBreaks]}
          components={{
            a: (props) => <Link {...props} />,
            li: ({ node, ...props }) => (
              <li style={{ lineHeight: 2, marginLeft: 16 }} {...props} />
            ),
            p: (props) => <Typography {...props} />,
            h2: (props) => <Typography {...props} variant={'h3'} />,
          }}
        >
          {children}
        </ReactMarkdown>
      </Box>
    );
  }
);
