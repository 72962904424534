import { Box, BoxProps, Card, LinearProgress } from '@mui/material';
import { forwardRef } from 'react';

export const ContentBlock = forwardRef(
  ({ children, progress, ...props }: BoxProps & { progress: number }, ref) => {
    return (
      <Box
        component={Card}
        p={0}
        width={'100%'}
        maxWidth={620}
        {...props}
        position={'relative'}
      >
        <Box
          p={4}
          zIndex={100}
          position={'absolute'}
          top={0}
          left={0}
          width={'100%'}
          sx={{ backgroundColor: 'rgba(255,255,255,0.95)' }}
        >
          <LinearProgress
            sx={{ borderRadius: 100, height: 8 }}
            variant={'determinate'}
            value={progress}
          />
        </Box>
        <Box
          ref={ref}
          mt={0}
          mb={0}
          p={4}
          pt={10}
          maxHeight={'90vh'}
          overflow={'auto'}
        >
          {children}
        </Box>
      </Box>
    );
  }
);
