import { lightTheme, Text } from '@holdbar-com/pixel';
import { Stack, styled } from '@mui/material';
import { useMemo } from 'react';

import { CopyLink } from '../../../../../Components/CopyLink/CopyLink';
import { useProfile } from '../../../../../Hooks/useProfile';
import { useTranslate } from '../../../../../Hooks/useTranslate';
import { trackStorefrontImplementationLinkCopyButtonClicked } from '../../../../../tracking/storefront/implementation/trackStorefrontImplementationLinkCopyButtonClicked';
import { trackStorefrontImplementationLinkKeyboardCopied } from '../../../../../tracking/storefront/implementation/trackStorefrontImplementationLinkKeyboardCopied';
import { FlowTypeEnum } from '../../domain/types';
import { useBookingFlowSectionContext } from '../../domain/use_booking_flow_section_context';
import { IntegrationLinksDropdowns } from './IntegrationLinksDropdowns';

export const IntegrationLinks = () => {
  const { t } = useTranslate('storefront.bookingflow');

  const { selectedFlowType, selectedExperience, selectedLanguage } =
    useBookingFlowSectionContext();

  const {
    company: { data: company },
    companyProfileUrl,
  } = useProfile();

  const integrationLink = useMemo(() => {
    if (selectedFlowType === FlowTypeEnum.Storefront) {
      return `${companyProfileUrl()}/${selectedLanguage}${typeof selectedExperience === 'string' ? '' : `/experience/${selectedExperience.experienceId}`}`;
    } else if (selectedFlowType === FlowTypeEnum.Checkout) {
      if (typeof selectedExperience === 'string') return;
      return `${companyProfileUrl()}/${selectedLanguage}/booking/${selectedExperience.experienceId}`;
    } else if (selectedFlowType === FlowTypeEnum.Widget) {
      const { companyId, selectedExperienceAttribute, distributorAttribute } =
        typeof selectedExperience === 'string'
          ? {
              companyId: company?.id,
              selectedExperienceAttribute: '',
              distributorAttribute: '',
            }
          : {
              // For a shared expeerience we create a widget that matches the
              // original widget, but add a `distributor-company-id` attribute
              companyId: selectedExperience.companyId,
              // We need to use the `ownerExperienceId` instead of the shared experience id, if it is present
              selectedExperienceAttribute: `data-experience-id="${selectedExperience.ownerExperienceId ?? selectedExperience.experienceId}"`,
              distributorAttribute: selectedExperience.distributorId
                ? `distributor-company-id="${selectedExperience.distributorId}"`
                : '',
            };

      return `<!-- Holdbar Widget Code -->
      <script src="https://widgets.holdbar.com/widgets/holdbar-booking-widget.js${process.env.REACT_APP_ENVIRONMENT !== 'production' ? '?environment=' + process.env.REACT_APP_ENVIRONMENT ?? 'development' : ''}"></script>
      <div class="holdbar-booking-widget" data-company-id="${companyId}" ${selectedExperienceAttribute} ${distributorAttribute} data-language="${selectedLanguage}"></div>
      <!-- End Holdbar Widget Code -->`;
    }
  }, [
    selectedFlowType,
    selectedExperience,
    selectedLanguage,
    company,
    companyProfileUrl,
  ]);

  return (
    <Stack gap={4} data-intercom-target={'integration-links-section'}>
      <Stack gap={1}>
        <Text variant={'medium'}>{t('links.title')}</Text>
        <Text fontSize={'small'}>{t('links.description')}</Text>
      </Stack>
      <IntegrationLinksDropdowns languages={company?.languages} />
      <Stack gap={1}>
        {selectedFlowType === FlowTypeEnum.Widget && (
          <Text fontSize={'small'}>{t('links.scriptHelperText')}</Text>
        )}
        <StyledLinkContainer
          data-intercom-target={'integration-link'}
          onCopy={() => {
            trackStorefrontImplementationLinkKeyboardCopied(integrationLink);
          }}
        >
          <Text style={{ whiteSpace: 'pre-line' }}>{integrationLink}</Text>
          <CopyLink
            href={integrationLink ?? ''}
            label=""
            color={lightTheme.palette.action.a300}
            size={'xlarge'}
            onClickCopy={() =>
              trackStorefrontImplementationLinkCopyButtonClicked(
                integrationLink
              )
            }
          />
        </StyledLinkContainer>
      </Stack>
    </Stack>
  );
};

const StyledLinkContainer = styled(Stack)`
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  background-color: ${lightTheme.palette.neutral.n50};
  border: 1px solid ${lightTheme.palette.neutral.n200};
  border-radius: 20px;
  padding: 24px;
  word-break: break-all;
`;
