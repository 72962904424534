import { Text } from '@holdbar-com/pixel';
import {
  capitalize,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Skeleton,
  styled,
} from '@mui/material';
import { useEffect, useMemo } from 'react';
import { Controller, ControllerProps, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useGetLocationsByExperienceId } from '../../Hooks/data/useLocations';
import { useErrors } from '../../Pages/SyiPage/useErrors';
import { ILocationView } from '../../types/api/location';

type LocationSelectProps = {
  experienceId: string;
  control: ControllerProps['control'];
};

export const LocationSelect = ({
  experienceId,
  control,
}: LocationSelectProps) => {
  const { t } = useTranslation('');
  const { clearError } = useErrors();
  const { setValue, watch } = useFormContext();

  const {
    locations: { data: locations, isLoading },
  } = useGetLocationsByExperienceId(experienceId);

  const watchedLocation = watch('locationId');

  const formattedAddress = useMemo(() => {
    if (!locations || !watchedLocation) {
      return { value: '', selectedOptionKey: 'custom' };
    }
    const location = locations.find(
      (loc) => loc.locationId === watchedLocation
    );

    if (!location) return { value: '', selectedOptionKey: 'custom' };

    return createFormattedAddress(location);
  }, [locations, watchedLocation]);

  useEffect(() => {
    setValue('addresses', formattedAddress);
  }, [formattedAddress, setValue]);

  const locationMap = useMemo(() => {
    return new Map(locations?.map((loc) => [loc.locationId, loc.locationName]));
  }, [locations]);

  if (!locations && isLoading) {
    return <Skeleton variant="rounded" height={56} width="60%" />;
  }

  if (!locations) {
    return null;
  }

  const label = t('events.create.practicalities.addresses.locations');

  const renderValue = (selected: unknown) => {
    if (typeof selected === 'string') {
      const name = locationMap.get(selected);
      return name ? capitalize(name) : '';
    }
    return '';
  };

  return (
    <FormControl sx={{ width: '60%' }} required>
      <InputLabel id="location-select-label">{label}</InputLabel>
      <Controller
        name="locationId"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Select
              label={label}
              value={value ?? ''}
              input={<OutlinedInput label={label} />}
              onChange={(event) => {
                clearError('locationId');
                onChange(event.target.value);
              }}
              renderValue={(selected) => renderValue(selected)}
            >
              {locations.map((location) => (
                <MenuItem
                  key={location.locationId}
                  value={location.locationId}
                  disableRipple
                >
                  <StyledText fontSize="small">
                    {location.locationName}
                  </StyledText>
                </MenuItem>
              ))}
            </Select>
          );
        }}
      />
    </FormControl>
  );
};

const StyledText = styled(Text)`
  &::first-letter {
    text-transform: uppercase;
  }
  text-transform: lowercase;
`;

const createFormattedAddress = (location: ILocationView) => {
  const parts = [
    location.address.line1,
    location.address.postalCode,
    location.address.city,
  ]
    .filter(Boolean)
    .map((part) => part?.trim());

  return {
    value: parts.length > 0 ? parts.join(', ') : location.locationName,
    selectedOptionKey: 'custom',
  };
};
