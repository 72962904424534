import axiosStatic from 'axios';

import axios from './index';

export type TTerms = {
  [type: string]: { versions: { [lang: string]: { content: string } } };
};

export const getTerms = async () => {
  if (typeof process.env.REACT_APP_API_TERMS_URL === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_TERMS_URL is not defined'
    );
  }

  try {
    const { data } = await axios.get<TTerms>(
      `${process.env.REACT_APP_API_TERMS_URL}/company`
    );
    return data;
  } catch (error) {
    if (axiosStatic.isAxiosError(error)) {
      if (error.response && error.response.status === 404) {
        return null;
      }
    }

    throw error;
  }
};

export const updateTerms = async (
  type: string,
  payload: { [k: string]: unknown }
) => {
  if (typeof process.env.REACT_APP_API_TERMS_URL === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_TERMS_URL is not defined'
    );
  }

  if (!payload) {
    throw new Error('Missing payload');
  }

  const { data } = await axios.put(
    `${process.env.REACT_APP_API_TERMS_URL}/company/${type}`,
    payload
  );

  return data;
};

export const createFromTemplate = async <T>(
  type: string,
  language: string,
  variables: { [k: string]: string }
) => {
  if (typeof process.env.REACT_APP_API_TERMS_URL === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_TERMS_URL is not defined'
    );
  }

  if (!type) {
    throw new Error('Missing type');
  }

  const { data } = await axios.post<T>(
    `${process.env.REACT_APP_API_TERMS_URL}/template/${type}`,
    { language, variables }
  );

  return data;
};
