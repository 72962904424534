import { Divider } from '@mui/material';

import useResponsive from '../../../../../../Hooks/layout/useResponsive';
import { useBookingDetailsContext } from '../../../domain/use_booking_details_context';

export const BookingDetailsSummaryMobileDivider = () => {
  const { isSm } = useResponsive();
  const { booking } = useBookingDetailsContext();

  if (!isSm || !booking) return null;

  return <Divider sx={{ width: '100%' }} />;
};
